import lodashKeyBy from 'lodash/keyBy';
import lodashMapKeys from 'lodash/mapKeys';

import {
  BUCKET_VALUE_CHANGED,
  GENERIC_BUCKET_VALUE_CHANGED,
  GENERIC_INITIALIZE_AGGREGATE,
  GENERIC_SUMMARISATION_LIST_CHANGED,
  GENERIC_SUMMARISATION_LIST_RESET,
} from '../reduxActions/actionNameEnums';

const initialState = {};

const genericSummarisationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GENERIC_SUMMARISATION_LIST_CHANGED:
      const newObject = payload.metricData.map((filter) => {
        return {
          ...filter,
          section: payload.type,
        };
      });
      const metricObject = lodashMapKeys(newObject, (data, keys) => data.value);
      return { ...state, [payload.type]: metricObject };
    case GENERIC_INITIALIZE_AGGREGATE:
      const typeAggregate = payload.value.filter(
        (metric) => metric.section === payload.type,
      );
      return {
        ...state,
        [payload.type]: lodashKeyBy(typeAggregate, (metric) => metric.value),
      };
    case GENERIC_SUMMARISATION_LIST_RESET:
      return initialState;
    case GENERIC_BUCKET_VALUE_CHANGED:
      const bucketValueChanged = {
        [payload.metricName]: {
          ...state[payload.type][payload.metricName],
          groupBy: {
            ...state[payload.type][payload.metricName].groupBy,
            bucketValue: payload.bucketValue,
          },
        },
      };
      const newState = {
        ...state,
        [payload.type]: { ...state[payload.type], ...bucketValueChanged },
      };
      return newState;
    default:
      return state;
  }
};

export default genericSummarisationReducer;
