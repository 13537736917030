import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import GeneralFilter from 'src/components/common/GeneralFilter';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import PopOverPane from './popOverPane';
import SuggestedFilter from './suggestedFilter';

const useStyles = createUseStyles({
  switchStyle: {
    marginRight: '10px',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  aggregate: {
    width: '50px',
  },
  rightPane: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
  },
});

const useStyleOptions = createUseStyles({
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
    height: '30px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E7F7FE',
    },
  },
});

const ListOptions = (props) => {
  const classes = useStyleOptions();
  const { options, onChange } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {options.map((item) => (
        <div
          onClick={() => {
            onChange(item.name);
          }}
          className={classes.option}
        >
          {item.prettyName}
        </div>
      ))}
    </div>
  );
};

const CoverageSection = (props) => {
  const classes = useStyles();
  const {
    coverage,
    filterData,
    onChangeRadio,
    onChangeFilterData,
    isFilterListMutable = true,
    showfreezeOption = true,
    description,
  } = props;
  const { enableCustomColumnDrawer, initialSuggestedFilters = [] } = props;
  const {
    filtersOptions,
    onSelectFilterMetrics,
    selectedData,
    onRemoveFilter,
  } = coverage;
  const { title } = props;
  const filtersMetaData = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );

  const getSelectOptions = (row) => {
    const filterListRadio = filtersMetaData[row.type] || [];
    const defaultSelection =
      filterListRadio.find((filter) => filter.prettyName === 'Is not null') ||
      filterListRadio[0];
    const filterRadioChangeHandler = onChangeRadio(row.value);
    const filterProps = {
      type: row.type,
      searchOptions: row.searchOptions,
      metricsName: row.value,
      filterMetaData: row.filterMetaData,
      filterListRadio,
      onChangeRadio: filterRadioChangeHandler,
      onChangeFilterData: onChangeFilterData(row.value),
      filterData,
      showfreezeOption,
      selectedFilterReportType: row.reportType,
      showAdditionalFilters: true,
      prettyName: row.prettyName,
    };
    const selectedRadio = filterData[row.value].selectedFilter;
    const selectedRadioMetaData = filterListRadio.find(
      (filter) => filter.name === selectedRadio,
    );
    const isRequired = row.filterMetaData?.required || false;
    return {
      filterListRadio,
      defaultSelection,
      filterRadioChangeHandler,
      filterProps,
      selectedRadio,
      selectedRadioMetaData,
      isRequired,
    };
  };

  return (
    <SectionPartContainer
      enableCustomColumnDrawer={enableCustomColumnDrawer}
      showTitle={isFilterListMutable}
      title={title || 'Coverage'}
      description={description}
      onChangeSelect={onSelectFilterMetrics}
      options={filtersOptions}
      initialValue={selectedData.map((element) => element.value)}
      showCount
    >
      {selectedData.map((row) => {
        const {
          filterListRadio,
          defaultSelection,
          filterRadioChangeHandler,
          filterProps,
          selectedRadioMetaData,
          isRequired,
        } = getSelectOptions(row);
        return (
          <RowContainer
            onRemove={() => {
              onRemoveFilter(row.value);
            }}
            isTitleRequired={isRequired}
            title={row.prettyName}
            description={row.description}
            showLeftPane={true}
            showLeftPaneFilterComponent={true}
            aggregate={row.aggregatePrettyName}
            isFilterListMutable={isFilterListMutable}
            LeftPaneComponent={PopOverPane}
            filterData={row.filterData}
            metricsName={row.value}
            LeftPaneFilterComponent={SuggestedFilter}
            initialSuggestedFilters={initialSuggestedFilters}
            rowType={row.type}
            leftPaneProps={{
              classes,
              value: selectedRadioMetaData || defaultSelection,
              Content: (
                <ListOptions
                  options={
                    isRequired
                      ? filterListRadio.filter(
                          (filterElement) =>
                            filterElement.name !== 'doNotApplyThisFilter',
                        )
                      : filterListRadio
                  }
                  onChange={(k) => {
                    filterRadioChangeHandler(k);
                  }}
                  value={selectedRadioMetaData || defaultSelection}
                  {...filterProps}
                />
              ),
            }}
          >
            <GeneralFilter {...filterProps} />
          </RowContainer>
        );
      })}
      <div className={classes.switchContainer}></div>
    </SectionPartContainer>
  );
};

export default memo(CoverageSection);
