import { dbTypes } from 'src/constants';

const getDownloadRowLimitWarningMessage = (params) => {
  const {
    downloadRowLimitConfig,
    previousColumnsLength,
    currentColumnsLength,
    enableRedshiftDump,
    selectedDbType,
    maxElasticSearchRowsToDownload,
  } = params;

  if (enableRedshiftDump && selectedDbType === dbTypes.REDSHIFT) {
    return;
  }

  if (!Array.isArray(downloadRowLimitConfig) || !downloadRowLimitConfig.length) {
    return;
  }

  if (previousColumnsLength >= currentColumnsLength) {
    return;
  }

  for (const configObject of downloadRowLimitConfig) {
    const { minCols, rows } = configObject;
    let requiredRows = rows;

    if (minCols === currentColumnsLength) {
      if (selectedDbType === dbTypes.ELASTIC_SEARCH && rows > maxElasticSearchRowsToDownload) {
        requiredRows = maxElasticSearchRowsToDownload;
      }

      return `Since the column count is >= ${minCols}, download will be restricted to ${requiredRows} rows`;
    }
  }

  return '';
};

export {
  getDownloadRowLimitWarningMessage,
};
