import React, { memo, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import CreateMetricQuery from './CreateMetricQuery';
import 'src/components/pages/chartBuilder/chartBuilder.style.css';
import MetricVisualisationPane from './MetricVisualisationPane';
import { useParams } from 'react-router-dom';
import {
  DISABLE_INCIDENT_ALERT_STATUS,
  GET_INCIDENT_ALERTS_BY_METRIC_ID,
} from '../../incidentAlerts/queries/incidentAlertsQuery';
import { useLazyQuery } from '@apollo/client';
import { message, Modal, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSubmitReport } from 'src/components/pages/chartBuilder/hooks/submitReportHook';
import LoadingComponent from 'src/components/common/LoadingComponent';
import { GET_REPORT_BY_ID } from '../../../pages/dashboard/hooks/dashboardQueries';
import { processData } from 'src/components/pages/reports/utils';

const useStyle = createUseStyles({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px 16px 0px 16px',
    width: '100%',
  },
  disableSaveMessage: {
    backgroundColor: '#e5f7ff',
    padding: '1rem',
    border: '0.5px solid #006EC3',
    borderRadius: '0.3rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
});

const columns = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'CREATED ON',
    dataIndex: 'creationTime',
    key: 'creationTime',
  },
  {
    title: 'LIVE INCIDENTS',
    dataIndex: 'numLiveAlerts',
    key: 'numLiveAlerts',
  },
  {
    title: 'INCIDENTS CREATED',
    dataIndex: 'numAlertsCreated',
    key: 'numAlertsCreated',
  },
];

const DisabledSavingMessage = (props) => {
  const classes = useStyle();
  const { setIsAlertDisableModalVisible } = props;
  return (
    <div className={classes.disableSaveMessage}>
      <ExclamationCircleOutlined style={{ color: '#006EC3' }} /> This Metric is
      being used to generate alerts. Therefore, saving has been disabled for
      this Metric.{' '}
      <span
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => setIsAlertDisableModalVisible(true)}
      >
        Please disable all alerts to enable saving.
      </span>
    </div>
  );
};

const MetricBuilder = () => {
  const classes = useStyle();
  const { metricId } = useParams();
  const [incidentAlerts, setIncidentAlerts] = useState<any>([]);
  const [reportData, setReportData] = useState<any>(null);
  const [isAlertDisableModalVisible, setIsAlertDisableModalVisible] = useState(false);
  const [reportLoading, setReportLoading] = useState(true);

  const submitMetric = useSubmitReport({
    type: 'metric',
    validateObject: true,
    validateTimeline: true,
  });

  const [getIncidentAlertsByMetricId, { loading: loadingIncidentAlerts }] =
    useLazyQuery(GET_INCIDENT_ALERTS_BY_METRIC_ID, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getIncidentAlertsByMetricId.length > 0) {
          setIsAlertDisableModalVisible(true);
        }
        setIncidentAlerts(data.getIncidentAlertsByMetricId);
      },
    });

  const [disableIncidentAlertsStatus] = useLazyQuery(
    DISABLE_INCIDENT_ALERT_STATUS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setIsAlertDisableModalVisible(false);
        setIncidentAlerts([]);
        message.success('Disabled alerts successfully!');
      },
      onError: () => {
        message.error('Failed to disable alerts');
      },
    },
  );

  const [getReportById] = useLazyQuery(
    GET_REPORT_BY_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setReportData(processData(data.getReportById));
        setReportLoading(false);
      },
      onError: data => {
          message.error({ key:'ReportStatus', duration: 0.5, content: 'Failed fetching Report Data' });
      },
    },
  );

  const onConfirm = () => {
    disableIncidentAlertsStatus({
      variables: {
        ids: incidentAlerts.map((alert) => {
          return alert.id;
        }),
        status: 'disable',
      },
    });
  };

  const onCancel = () => {
    setIsAlertDisableModalVisible(false);
  };

  const generateDataColumns = () => {
    const dataColumns = columns?.map((col, i) => {
      return {
        dataIndex: col.dataIndex,
        title: col.title,
        key: col.key,
        render: (x, record, index) => {
          switch (col.key) {
            case 'creationTime':
              const time = moment(parseFloat(x)).format('D MMM YYYY, hh:mm A');
              return <div>{time}</div>;
            default:
              return x;
          }
        },
      };
    });
    return dataColumns;
  };

  useEffect(() => {
    if (metricId) {
      getIncidentAlertsByMetricId({ variables: { metricId } });
      getReportById({ variables: { reportId: metricId }});
    } else {
      setReportLoading(false);
    }
  }, [metricId]);

  if (reportLoading) {
    return <LoadingComponent />;
  }

  return (
    <div className={classes.layout}>
      <Modal
        title={'Manage'}
        visible={isAlertDisableModalVisible}
        onOk={onConfirm}
        onCancel={onCancel}
        okText="Disable Alerts"
        okType="danger"
        width={1000}
      >
        <DisabledSavingMessage />
        <Table
          columns={generateDataColumns()}
          dataSource={incidentAlerts}
          loading={loadingIncidentAlerts}
        />
      </Modal>
      {incidentAlerts?.length > 0 && (
        <DisabledSavingMessage
          setIsAlertDisableModalVisible={setIsAlertDisableModalVisible}
        />
      )}
      <div style={{ display: 'flex', height: '100%' }}>
        <CreateMetricQuery
          submitMetric={submitMetric}
          reportData={reportData}
        />
        <div style={{ display: 'flex', flex: 1 }}>
          <MetricVisualisationPane
            isAlertDisableModalVisible={isAlertDisableModalVisible}
            setIsAlertDisableModalVisible={setIsAlertDisableModalVisible}
            incidentAlerts={incidentAlerts}
          />
        </div>
      </div>
    </div>
  );
};
export default memo(MetricBuilder);
