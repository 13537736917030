import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tableColor } from 'src/components/utils/colors';
import { SheetComponent } from '@antv/s2-react';
import {
  GetChartConfigData,
  getColor,
} from 'src/components/common/utils/utils';
import { setLang, DataCell } from '@antv/s2';
import '@antv/s2-react/dist/style.min.css';
import { getMapping, getFormattedColumns } from '../utils/visualisationUtils';
import {
  SET_TABLE_PAGE_SIZE,
  SET_COLUMN_WIDTH_METRICES,
  SET_FIELD_VALUE,
} from 'src/reduxActions/actionNameEnums';
import { customSort } from '../utils/customSort';
import { calculateTableColumnWidth } from './utils';
setLang('en_US');

class CustomDataCell extends DataCell {
  getBackgroundColor() {
    const defaultBackgroundColor = super.getBackgroundColor();
    const pageSize: any = this.meta.pageSize || 10;

    if (this.meta.showAggregate && this.meta.rowIndex % pageSize === 0) {
      return {
        ...defaultBackgroundColor,
        backgroundColor: '#808080',
        backgroundColorOpacity: 0.3,
      };
    }

    return defaultBackgroundColor;
  }

  getTextStyle(): any {
    const defaultTextStyle = super.getTextStyle();
    const pageSize: any = this.meta.pageSize || 10;
    const configData = this.meta.configData;
    if (this.meta.showAggregate && this.meta.rowIndex % pageSize === 0) {
      return {
        ...defaultTextStyle,
        fill: '#000000',
        fontWeight: 700,
      };
    }
    if(typeof this.meta.data[this.meta.valueField] === 'number') {
      return {
        ...defaultTextStyle,
        textAlign:(configData as any)?.graphic?.numberAlign?.data || 'right',
      };
    }
    return defaultTextStyle;
  }
}

const TableViz = (props) => {
  const {
    formData,
    dashboardPageSize,
    chartOptions,
    chartConfig,
    chartRef,
    containerWidth,
    dashboardColumnWidth,
    visualizeMetaData,
    chartInteractionHook,
    chartsFromDashboard,
    showUnderlyingDataDownload,
    reportId,
    formatting,
  } = props;
  const chartData = [...props.chartData];
  const { aggregateData = [] } = visualizeMetaData;
  const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
  const [showPagination, setShowPagination] = useState(true);

  const dispatch = useDispatch();
  const configObject = {
    chartConfig,
    configType: 'table',
    formData,
    chartsFromDashboard,
  };
  const configData = GetChartConfigData(configObject);
  const storedPageSize = useSelector(
    (state: any) => state.chartsMetaData.pageSize,
  );
  const pageSize = chartsFromDashboard ? dashboardPageSize : storedPageSize;
  const metrics = useSelector((state: any) => state.metrics);
  const columns = formData.columns;

  const dataConvertor = (name) => {
    const filter = chartOptions.find((option) => option.option === name);
    return JSON.parse(filter?.data || '{}').value;
  };

  const addAggregateData = (data, index = 0) => {
    const requiredAggregateRow = aggregateData[0];

    // aggregate data will only have the aggregates of columns which are not summarise by
    // adding a hard coded string to summarise by columns of aggregate row
    columns.forEach((column) => {
      if (!requiredAggregateRow.hasOwnProperty(column)) {
        requiredAggregateRow[column] = 'Aggregate';
      }
    });

    while (index < data.length) {
      data.splice(index, 0, requiredAggregateRow);
      index += pageSize;
    }
  };

  const showAggregate = dataConvertor('showAggregate') && aggregateData.length;

  // returns chart data by sorting query data and adding aggregate data at required positions according to page size
  // to keep the aggregate rows persistant across every page
  const sortParams = columns.map((column) => {
    const metric = formData.meta.find(({ field }) => column === field) || {};
    const { type, operation } = metric;
    const typeForSort = (operation?.type ? operation.type : type) || 'text';
    return {
      sortFieldId: column,
      sortFunc: params => {
        const { data, sortMethod, sortFieldId } = params;
        if (!['asc', 'desc'].includes(sortMethod)) {
          return data;
        }
        const sortComparator = customSort(typeForSort, sortMethod);
        const sortedData = [...props.chartData].sort((a, b) => {
          const data1 = a[sortFieldId];
          const data2 = b[sortFieldId];

          if (data1 === data2) {
            return 0;
          }
          if (data1 === null) {
            return sortMethod === 'asc' ? 1 : -1;
          }
          if (data2 === null) {
            return sortMethod === 'asc' ? -1 : 1;
          }
          return sortComparator(data1, data2);
        });
        if (showAggregate) {
          addAggregateData(sortedData, pageSize - 1);
        }
        return sortedData;
      },
    };
  });
  const s2DataConfig: any = {
    fields: { columns },
    data: chartData,
    sortParams,
  };

  if (showAggregate) {
    // adding query data to aggregate data at required positions according to page size to keep the aggregate rows persistant across every page
    addAggregateData(chartData);
  }

  const getFill = (type) => {
    const formattedColumns = getFormattedColumns({
      formatting,
      formData,
    });
    const mapping: any = [];

    Object.entries(formattedColumns).map(([key, value]) => {
      const map = getMapping({
        visualizeMetaData,
        formData,
        formatting,
        totalData: chartData,
        fieldKey: key,
        fieldName: value,
        type,
        isNumberChart: false,
      });
      mapping.push(map);
    });

    return mapping;
  };

  if (dashboardColumnWidth) {
    const sumOfColumnWidth = Object.values(dashboardColumnWidth).reduce(
      (total: number, value: any) => total + value,
      0,
    );

    if (containerWidth > sumOfColumnWidth) {
      const remainingWidth = containerWidth - sumOfColumnWidth;
      const factor = remainingWidth / sumOfColumnWidth;

      Object.keys(dashboardColumnWidth).forEach((key) => {
        dashboardColumnWidth[key] += dashboardColumnWidth[key] * factor;
      });
    }
  }
  const widthByFieldValue = calculateTableColumnWidth({metrics, dashboardColumnWidth, columns, containerWidth});

  useEffect(() => {
    if (!chartsFromDashboard) {
      columns.map((column) => {
        const size = Math.floor(containerWidth / columns.length);
        const matchingKey = Object.keys(metrics).find(
          (key) => metrics[key].aliasName === column,
        );
        if (matchingKey && !metrics[matchingKey].savedMetric) {
          metrics[matchingKey].columnWidth = Math.max(size, 100);
        }
      });
      if (containerWidth) {
        //this condition is used as if we don't have a container Width then width of table could be NaN
        dispatch({
          type: SET_COLUMN_WIDTH_METRICES,
          payload: metrics,
        });
      }
    }
  }, [columns, containerWidth]);

  useEffect(() => {
    if (
      chartsFromDashboard &&
      chartData.length > 0 &&
      chartData.length <= pageSize
    ) {
      setShowPagination(false);
    } else {
      setShowPagination(true);
    }
  }, [chartData]);

  const s2options = {
    // incrementing the frozenRowCount to freeze the aggregate data row as well
    frozenRowCount: showAggregate ? 1 + dataConvertor('frozenRowCount') : dataConvertor('frozenRowCount'),
    frozenColCount: dataConvertor('frozenColCount'),
    frozenTrailingRowCount: dataConvertor('frozenTrailingRowCount'),
    frozenTrailingColCount: dataConvertor('frozenTrailingColCount'),
    tooltip: {
      showTooltip: true,
      content: (data,defaultTooltipShowOptions) => {
        return configData?.graphic?.tooltip?.data ? (
          <div className="antv-s2-tooltip-name">{defaultTooltipShowOptions.data.name}</div>
        ) : (
          <></>
        );
      },
    },
    style: {
      colCfg: {
        widthByFieldValue: containerWidth ? widthByFieldValue : null,
      },
    },
    pagination: {
      pageSize,
      current: 1,
    },
    dataCell: (viewMeta) => {
      return new CustomDataCell({ ...viewMeta, pageSize, showAggregate, configData }, viewMeta?.spreadsheet);
    },
    ...(formatting?.length && {
      conditions: {
        background: getFill('backgroundColor'),
        text: getFill('textColor'),
      },
    }),
  };

  const onDataCellClick = async (...args) => {
    const { x, y } = args[0].event;
    chartInteractionHook.setPopOverPosition({ x, y });
    const currentDisplayData =
      args[0].viewMeta.spreadsheet.dataSet.getDisplayDataSet();
    const rawData = currentDisplayData[args[0].viewMeta.rowIndex];
    const name = args[0].viewMeta.valueField;
    const columnData = { rawData, name };
    const showChildReport = visualizeMetaData?.columnsData[name]?.linkedReport
      ?.reportId
      ? true
      : false;
    const urlPath = visualizeMetaData?.summarizeData?.find(item => item.name === name)?.urlPath ||
      visualizeMetaData?.columnsData[name]?.urlPath;
    const showViewDetails = !!urlPath;
    await chartInteractionHook.onChartColumnClick({
      showChildReport,
      showUnderlyingDataDownload,
      showViewDetails,
      reportData: { visualizeMetaData, columnData },
      reportId,
      enableDummyDataSwitch,
    });
    dispatch({
      type: SET_FIELD_VALUE,
      payload: args[0].viewMeta.fieldValue,
    });
  };

  const handleLayoutPagination = (paginationInfo: { pageSize: number }) => {
    if (!chartsFromDashboard) {
      dispatch({
        type: SET_TABLE_PAGE_SIZE,
        payload: paginationInfo.pageSize,
      });
    }
  };

  const theme: Record<string, any> = {
    dataCell: {
      text: {
        textAlign: configData?.graphic?.textAlign?.data || 'left',
      },
    },
  };
  const palette = {
    brandColor: '#ffffff',
    semanticColors: {},
    others: {},
    basicColors: getColor(configData?.graphic?.color?.data, tableColor),
  };
  const handleColWidthResize = (props) => {
    if (!chartsFromDashboard) {
      const columnSizeObj = props.style.colCfg.widthByFieldValue;
      const [key] = Object.keys(columnSizeObj);
      widthByFieldValue[key] = columnSizeObj[key];
      Object.keys(metrics).forEach((key) => {
        const aliasName = metrics[key]['aliasName'];
        if (widthByFieldValue.hasOwnProperty(aliasName)) {
          metrics[key].columnWidth = widthByFieldValue[aliasName];
        }
      });
      dispatch({
        type: SET_COLUMN_WIDTH_METRICES,
        payload: metrics,
      });
    }
  };

  return (
    <SheetComponent
      onDataCellClick={onDataCellClick}
      showPagination={showPagination}
      dataCfg={s2DataConfig}
      themeCfg={{ theme, palette }}
      options={s2options}
      onLayoutResizeColWidth={handleColWidthResize}
      sheetType="table"
      onLayoutPagination={handleLayoutPagination}
      adaptive={{
        width: true,
        height: true,
        getContainer: () => chartRef?.current,
      }}
    />
  );
};
const areEqual = (prevProps, nextProps) => {
  // additional props in parent like classes and chartOptions
  // were causing a re-render
  if (
    prevProps.formData === nextProps.formData &&
    prevProps.chartData === nextProps.chartData &&
    prevProps.containerWidth === nextProps.containerWidth
  ) {
    return true; // do not re-render
  }
  return false; // will re-render
};

export default memo(TableViz, areEqual);
