import { Button, Popover, Badge } from 'antd';
import React, { memo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FilterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  CHILD_GLOBAL_FILTER_RADIO_SELECTED,
  CHILD_GLOBAL_FILTER_SELECTED_VALUE_CHANGED,
  CHILD_GLOBAL_FILTER_RESET,
  CHILD_GLOBAL_SELECTED_BOOL_VALUE_CHANGED,
} from 'src/reduxActions/actionNameEnums';
import GeneralFilter from 'src/components/common/GeneralFilterDashboard';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

const useStyles = createUseStyles({
  base: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0.2rem 0.5rem',
    justifyContent: 'space-between',
  },
  textStyle: {
    color: '#262626',
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '24px',
    cursor: 'pointer',
    padding: '8px',
    '&:hover': {
      background: '#efefef',
    },
  },
  addReportButton: {
    borderRadius: '4px',
    margin: 'auto',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.7rem',
  },
  createDashboard: {
    borderRadius: '4px',
    backgroundColor: '#1890FF',
    marginLeft: '16px',
    marginRight: '4px',
  },
  downOutlinedIcon: {
    marginLeft: '8px',
    cursor: 'pointer',
  },
  lock: {
    fontSize: '20px',
    margin: 'auto 10px',
  },
  filterPane: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: 'auto 10px',
    '&:hover': {
      background: '#efefef',
    },
    padding: '8px',
  },
  filterText: {
    marginLeft: '6px',
  },
  filter: {
    '&:hover': {
      width: 'auto !important',
      minWidth: '160px',
    },
  },
  popoverTime: {
    '& .ant-popover-inner-content': {
      padding: '0px 0px',
    },
  },
  arrowPosition1: {
    '& .ant-popover-arrow': {
      left: '15%',
    },
  },
  arrowPosition2: {
    '& .ant-popover-arrow': {
      left: '33%',
    },
  },
  blinkingDot: {
    animation: '$blink 1s infinite',
    position: 'absolute',
    bottom: '3%',
    right: '12%',
    backgroundColor: '#FF0000',
  },
  '@keyframes blink': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.3,
    },
    '100%': {
      opacity: 1,
    },
  },
  prettyNameLabel: {
    padding: '0 0.3rem',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  prettyNameContainer: {
    width: '92px',
    height: '24px',
    marginRight: '8px',
    padding: '0px 2px',
    cursor: 'pointer',
    border: '1px solid #D9D9D9',
    borderRadius: '2px',
  },
  filterIcon: {
    fontSize: '14px',
    marginRight: '10px',
  },
  filterTabs: {
    display: 'flex',
    flexGrow: 100,
    overflow: 'scroll',
  },
  resetButton: {
    color: '#FF4D4F',
    fontSize: '0.7rem',
  },
});

const GlobalFilterTopbar = (props) => {
  const {
    filterData = [],
    isSticky = true,
    onApplyFilter,
    onResetFilter,
    childReportIndex,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = Object.keys(filterData).map((metric) => filterData[metric]);
  const [filterApplied, setFilterApplied] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState<String>('');
  const [onReset, setOnReset] = useState(true);
  const filtersMetaData = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const boolInitialState = {};
  for (const key in filterData) {
    if (filterData.hasOwnProperty(key) && filterData[key].type === 'boolean') {
      boolInitialState[filterData[key].value] = [];
    }
  }
  const [checkedValues, setCheckedValues] = useState(boolInitialState);
  const handleBoolChange = (key, checkedValues) => {
    setCheckedValues((prevState) => ({
      ...prevState,
      [key]: checkedValues,
    }));
  };

  const onChangeRadio = (metricName, filterType) => (value) => {
    let filter;
    if (filtersMetaData) {
      filter = filtersMetaData[filterType]?.find((obj) => obj.name === value);
    }
    const filterInputType = filter?.type;
    if (filterInputType === 'noInput') {
      setFilterApplied(false);
    }
    dispatch({
      type: CHILD_GLOBAL_FILTER_RADIO_SELECTED,
      payload: {
        metricName,
        selectedFilter: value,
        filterInputType,
        childReportIndex,
        filterType,
        setFilterApplied,
      },
    });
  };

  const onChangeFilterData = (metricName) => (data) => {
    setFilterApplied(false);
    dispatch({
      type: CHILD_GLOBAL_FILTER_SELECTED_VALUE_CHANGED,
      payload: {
        metricName,
        childReportIndex,
        data: {
          value: data,
        },
      },
    });
  };

  const onChangeBoolData =
    (metricName) =>
    (checkedValues: CheckboxValueType[], selectedFilter, data) => {
      setFilterApplied(false);
      handleBoolChange(metricName, checkedValues);
      dispatch({
        type: CHILD_GLOBAL_SELECTED_BOOL_VALUE_CHANGED,
        payload: {
          metricName,
          selectedFilter,
          childReportIndex,
          data: {
            value: data,
          },
        },
      });
    };
  //we update the dashboard tab filters in an existing dashboardGroup or adding new dashboardGroup

  const value = filters.map((item) => item.value);
  const initialState = value.reduce((acc, item) => {
    return { ...acc, [item]: false };
  }, {});
  const [filterColor, setFilterColor] = useState(initialState);

  const handleChange = (key) => {
    setFilterColor((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const resetFilter = () => {
    dispatch({
      type: CHILD_GLOBAL_FILTER_RESET,
      payload: {
        childReportIndex,
      },
    });
    onResetFilter();
    setFilterColor(initialState);
    setCheckedValues(boolInitialState);
    setFilterApplied(true);
    setOnReset(!onReset);
  };

  const applyingFilters = async () => {
    const applied = await onApplyFilter();
    setFilterColor(initialState);
    applied.map((item) => {
      handleChange(item.metric);
    });
    setFilterApplied(true);
  };
  const getClassName = (prettyName) => {
    return prettyName.length >= 11 ||
      (prettyName.match(/[A-Z]/g) || []).length > 2
      ? classes.filter
      : '';
  };

  return (
    <>
      <div
        style={{
          position: isSticky ? 'sticky' : 'relative',
          top: isSticky ? '2.35rem' : '0px',
          zIndex: '1',
        }}
      >
        <div className={classes.base}>
          <FilterOutlined className={classes.filterIcon} />
          <div className={classes.filterTabs}>
            {filters.map((row, i) => {
              const filterRadioChangeHandler = onChangeRadio(
                row.value,
                row.type,
              );
              const filterListRadio = filtersMetaData[row.type] || [];
              const filterProps = {
                metricsName: row.value,
                type: row.type,
                filterMetaData: row.filterMetaData,
                filterListRadio,
                onChangeBoolData: onChangeBoolData(row.value),
                onChangeRadio: filterRadioChangeHandler,
                onChangeFilterData: onChangeFilterData(row.value),
                filterData,
                showfreezeOption: false,
                searchOptions: row.searchOptions,
                selectedFilterReportType: row.reportType,
                checkedValues,
                onReset,
                prettyName: row.prettyName,
              };
              return (
                <Popover
                  trigger="click"
                  placement="bottom"
                  arrowPointAtCenter={false}
                  overlayClassName={`${
                    i === 0
                      ? classes.arrowPosition1
                      : i === 1
                      ? classes.arrowPosition2
                      : ''
                  }
        ${row.type !== 'boolean' ? classes.popoverTime : ''}`}
                  content={<GeneralFilter {...filterProps} />}
                  onVisibleChange={(visible) => {
                    visible
                      ? setSelectedFilter(row.value)
                      : setSelectedFilter('');
                  }}
                >
                  <div
                    className={`${getClassName(row.prettyName)} ${
                      classes.prettyNameContainer
                    }`}
                    style={{
                      borderColor:
                        filterColor[row.value] || selectedFilter === row.value
                          ? '#1890FF'
                          : '#C8C8C8',
                    }}
                  >
                    <div
                      style={{
                        color:
                          filterColor[row.value] || selectedFilter === row.value
                            ? '#1890FF'
                            : '#000000D9',
                      }}
                      className={classes.prettyNameLabel}
                    >
                      {row.prettyName}
                    </div>
                  </div>
                </Popover>
              );
            })}
          </div>
          <Button
            style={{
              borderColor: !filterApplied ? '#1890FF' : '#d9d9d9',
              color: !filterApplied ? '#1890FF' : '#d9d9d9',
            }}
            disabled={filterApplied}
            onClick={() => applyingFilters()}
            className={classes.addReportButton}
            size="small"
          >
            Apply Filter
            {!filterApplied ? (
              <Badge
                dot={true}
                offset={[5, -5]}
                className={classes.blinkingDot}
              />
            ) : null}
          </Button>
          <Button
            type="link"
            className={classes.resetButton}
            onClick={resetFilter}
          >
            Reset Filters
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(GlobalFilterTopbar);
