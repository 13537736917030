import TagManager from 'react-gtm-module';
import { getSharedCredential } from 'src/components/common/utils/utils';
import config from 'src/settings';

export const initializeTagManager = () => {
  const {
    'user-id': userId,
    'project-name': userProject,
    'organisation-id': userOrganisation,
  } = getSharedCredential();

  const tagManagerArgs = {
    gtmId: config.GOOGLE_TAG_MANAGER_ID,
    dataLayer: {
      userId,
      userProject,
      userOrganisation,
    },
  };
  TagManager.initialize(tagManagerArgs);
};
