import { useState } from 'react';
import { GET, POST } from '../../../api/apiHandler';
import config from '../../../settings';

const { BACKEND_URL } = config;
interface QueryResult<TData> {
  loading: boolean;
  called: boolean;
  data?: TData;
  error?: any;
}

interface QueryOptions {
  type: 'GET' | 'POST';
  baseURL?: string;
  onCompleted?: (data, variables, additonalVariables) => any;
  onError?: (err) => any;
}

interface QueryVariables {
  variables?: any;
  URLQueryPath?: string;
  headers?: any;
  additionalVariables?: any;
}

const useLazyAPI = <TData = any>(
  endpoint: string,
  options: QueryOptions,
): [(variables?: any) => void, QueryResult<TData>] => {
  const [queryResult, setQueryResult] = useState<QueryResult<TData>>({
    loading: false,
    called: false,
    data: undefined,
    error: undefined,
  });

  const apiMethod = (variables, URLQueryPath, headers) => {
    const baseURL = options.baseURL || BACKEND_URL;
    const url = `${baseURL}api/${endpoint}${
      URLQueryPath ? `/${URLQueryPath}` : ''
    }`;
    switch (options.type) {
      case 'GET':
        return GET(url, variables, undefined, headers);
      case 'POST':
        return POST(url, variables, undefined, headers);
      default:
        return;
    }
  };

  const runQuery = async (queryOptions: QueryVariables = {}) => {
    setQueryResult({ ...queryResult, loading: true, called: true });
    try {
      const response = await apiMethod(
        queryOptions.variables,
        queryOptions.URLQueryPath,
        queryOptions.headers,
      );
      if (response.isSuccess) {
        const data = response.data;
        setQueryResult({
          ...queryResult,
          loading: false,
          data,
          error: undefined,
        });

        if (options.onCompleted && data) {
          options.onCompleted(
            data,
            queryOptions.variables,
            queryOptions.additionalVariables,
          );
        }
      }
      else {
        throw new Error(response.errorMessage);
      }
    } catch (error) {
      setQueryResult({ ...queryResult, loading: false, error });

      if (options.onError) {
        options.onError(error);
      }
    }
  };

  return [runQuery, queryResult];
};

export default useLazyAPI;
