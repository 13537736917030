import { combineReducers } from 'redux';
import filterData from '../reducers/filtersList';
import genericFilterData from './genericFilter';
import havingFilterData from '../reducers/havingFilterList';
import masterData from '../reducers/masterData';
import metrics from './metrics';
import summarization from './summarisation';
import genericSummarisation from './genericSummarisation';
import sortBy from './sortBy';
import chartsMetaData from './visualisation';
import reportMetaData from './reportMetaData';
import dashboardFilter from './dashboardFilters';
import config from './configReducer';
import customMetrices from './customMetrices';
import editDashboard from './editDashboard';
import dashboardGroupMetaData from './dashboardGroupMetaData';
import childReportModalData from './childReportModalData';
import underlyingMetrics from './underlyingMetrics';
import automationData from './automationData';
import actionData from './actionsReducer';
import reportConfig from './reportConfig';
import mobileDashboardSearchFilters from './mobileDashboardSearchFilters';

export default combineReducers({
  filterData,
  masterData,
  metrics,
  summarization,
  sortMetaData: sortBy,
  chartsMetaData,
  reportMetaData,
  dashboardFilter,
  config,
  customMetrices,
  editDashboard,
  havingFilterData,
  dashboardGroupMetaData,
  genericFilterData,
  genericSummarisation,
  childReportModalData,
  underlyingMetrics,
  automationData,
  actionData,
  reportConfig,
  mobileDashboardSearchFilters,
});
