import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import SerchableSelect from 'src/components/common/FilterComponent/SearchableSelect';

const useStyle = createUseStyles({
  textBox: {
    width: '20rem',
    padding: '0.6rem',
    display: 'flex',
  },
});

const TextFilter = (props) => {
  const {
    onChangeFilterDataForSimple,
    selectedValue,
    metricsName,
    selectedFilterReportType,
    prettyName,
  } = props;
  const classes = useStyle();
  return (
    <div className={classes.textBox}>
      <SerchableSelect
        multipleSelect={true}
        metricsName={metricsName}
        onChange={onChangeFilterDataForSimple}
        value={selectedValue?.data}
        selectedFilterReportType={selectedFilterReportType}
        prettyName={prettyName}
        isEditing={false}
        autoFocus={true}
        defaultOpen={true}
      />
    </div>
  );
};

export default TextFilter;
