import { useLazyQuery } from '@apollo/client';
import { message } from 'antd';
import { REMOVE_DASHBOARD } from './dashboardQueries';

export const useRemoveReportFromDashboard = (props) => {
    const {
        onCompletedCallBack = () => {},
        onErrorCallBack =  () => {},
        onRefetchDashboardData,
        refetchMetaData,
        dashboardTab,
    } = props;
    const [deleteReportFromDashboard, { loading: deleteInProgress, data }] = useLazyQuery(REMOVE_DASHBOARD, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            message.success({key:'deleteReportFromDashboard', content: 'Report Deleted', duration: 0.5});
            onRefetchDashboardData();
            refetchMetaData(dashboardTab);
        },
        onError: data => {
            message.error({key:'deleteReportFromDashboard', content: 'Failed to Delete Report', duration: 0.5});
            onErrorCallBack();
        },
    });
    const onDeleteReportFromDashboard = (reportId, dashboardId) => {
        message.loading({key:'deleteReportFromDashboard', content: 'Report Deletion in Progress', duration: 0});
        deleteReportFromDashboard({variables:{
            reportId,
            dashboardId,
        }});
    };

    return {
        deleteInProgress,
        onDeleteReportFromDashboard,
    };
};
