import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import { CHART_OPTION_VALUE_CHANGED } from 'src/reduxActions/actionNameEnums';
import FilterMenu from 'src/components/common/FilterComponent/SingleSelectionMenu';
import RowContainer from 'src/components/common/rowContainer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import lodashCapitalize from 'lodash/capitalize';
import SortBySelector from './sortBySelector';
import { ReactComponent as DragSVG } from 'src/assets/drag.svg';

const useStyle = createUseStyles({
  title: {
    height: '14px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '14px',
    letterSpacing: '0',
    color: '#303030',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '38px',
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: '#F1F2F6',
  },
  emptyContainer: {
    height: '3rem',
  },
});
const reorderSortMetrics = (result, initialArray) => {
  const newMetricList = [...initialArray];
  const [removed] = newMetricList.splice(result.source.index, 1);
  newMetricList.splice(result.destination.index, 0, removed);
  const newList = newMetricList.map((item) => item.value);
  const metricData = newList.map((value) =>
    initialArray.find((metric) => metric.value === value),
  );
  return metricData;
};
const CustomDraggable = (props) => {
  const classes = useStyle();
  const {
    onChange,
    prettyName,
    value,
    type = 'singleSelect',
    data,
    placeHolder = 'Select',
    size = 'small',
    showSearch = false,
    style,
    onChangeRowColumn,
    draggableColumns,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const sectionOne = draggableColumns[0].value;
  const sectionTwo = draggableColumns[1].value;
  const rowColumnValueUpdated = (optionName, value) => {
    dispatch({
      type: CHART_OPTION_VALUE_CHANGED,
      payload: {
        optionName,
        value,
        section: 'rowColumns',
      },
    });
  };
  const onChangeSortBy = (index, type) => (val) => {
    if (type === 'rows') {
      sectionOne[index] = { ...sectionOne[index], chartSortBy: val };
    }
    if (type === 'columns') {
      sectionTwo[index] = { ...sectionTwo[index], chartSortBy: val };
    }
    dispatch({
      type: CHART_OPTION_VALUE_CHANGED,
      payload: {
        type,
        value: type === 'rows' ? sectionOne : sectionTwo,
        section: 'rowColumns',
      },
    });
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.droppableId === 'droppableColumn' &&
      isNaN(result.destination.index)
    ) {
      //draggig from row and dropping at column(column is empty)
      rowColumnValueUpdated('column', [sectionOne[result.source.index]]);
      const newMetricList = [...sectionOne];
      newMetricList.splice(result.source.index, 1);
      rowColumnValueUpdated('row', newMetricList);
      return;
    }
    if (
      result.destination.droppableId === 'droppableRow' &&
      isNaN(result.destination.index)
    ) {
      //draggig from column and dropping at row(row is empty)
      rowColumnValueUpdated('row', [sectionTwo[result.source.index]]);
      const newMetricList = [...sectionTwo];
      newMetricList.splice(result.source.index, 1);
      rowColumnValueUpdated('column', newMetricList);
      return;
    }
    if (
      result.destination.droppableId === 'droppableRow' &&
      result.source.droppableId === 'droppableColumn'
    ) {
      //dragging from column and dropping at row (both are not empty)
      sectionOne.splice(
        result.destination.index,
        0,
        sectionTwo[result.source.index],
      );
      rowColumnValueUpdated('row', sectionOne);
      const newMetricList = [...sectionTwo];
      newMetricList.splice(result.source.index, 1);
      rowColumnValueUpdated('column', newMetricList);
      return;
    }
    if (
      result.destination.droppableId === 'droppableColumn' &&
      result.source.droppableId === 'droppableRow'
    ) {
      //dragging from row and dropping at column (both are not empty)
      sectionTwo.splice(
        result.destination.index,
        0,
        sectionOne[result.source.index],
      );
      rowColumnValueUpdated('column', sectionTwo);
      const newMetricList = [...sectionOne];
      newMetricList.splice(result.source.index, 1);
      rowColumnValueUpdated('row', newMetricList);
      return;
    }
    if (
      result.destination.droppableId === 'droppableRow' &&
      result.source.droppableId === 'droppableRow'
    ) {
      // dragging from row and dropping at row
      const value = reorderSortMetrics(result, sectionOne);
      rowColumnValueUpdated('row', value);
      return;
    }
    if (
      result.destination.droppableId === 'droppableColumn' &&
      result.source.droppableId === 'droppableColumn'
    ) {
      // dragging from column and dropping at column
      const value = reorderSortMetrics(result, sectionTwo);
      rowColumnValueUpdated('column', value);
      return;
    }
  };
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {draggableColumns.map((draggableColumn, i) => {
          const value = draggableColumn.value;
          const name = lodashCapitalize(draggableColumn.name);
          return (
            <>
              <Droppable droppableId={draggableColumn.droppableId}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <div className={classes.titleContainer}>
                      <span className={classes.title}>{name}</span>
                    </div>
                    {value.length ? (
                      value.map((row, j) => {
                        return (
                          <Draggable
                            key={row.prettyName}
                            draggableId={row.prettyName}
                            index={j}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                snapshot={snapshot}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <RowContainer
                                  title={row.prettyName}
                                  description={row.description}
                                  hideRemove={true}
                                  showLeftPane={true}
                                  LeftPaneComponent={SortBySelector}
                                  draggableIcon={true}
                                  leftPaneProps={{
                                    onChange: onChangeSortBy(
                                      j,
                                      draggableColumn.name,
                                    ),
                                    value: row.chartSortBy,
                                    ContentComponent: FilterMenu[row.type],
                                    type: row.type,
                                    rows: row.operations,
                                    metricsName: row.value,
                                  }}
                                />
                                {provided.placeholder}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <Draggable draggableId={draggableColumn.name}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <br />
                            <br />
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    )}
                  </div>
                )}
              </Droppable>
              {draggableColumns.length === i + 1 ? (
                <></>
              ) : (
                <>
                  <div className={classes.emptyContainer} />
                </>
              )}
            </>
          );
        })}
      </DragDropContext>
    </>
  );
};

export default CustomDraggable;
