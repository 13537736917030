import { useSelector } from 'react-redux';
import lodashKeys from 'lodash/keys';

const useChartOptions = (chartOptionsDashboard:any = null) => {
    const chartOptions = useSelector((state:any) => state.chartsMetaData.chartOptions);
    if(chartOptionsDashboard){
        return chartOptionsDashboard;
    }
    const chartOptionsKeys = lodashKeys(chartOptions);
    const optionList = chartOptionsKeys.reduce((accumulator, currentValue) => {
        const sectionData = chartOptions[currentValue];
        const sectionKeys = lodashKeys(sectionData);
        const optionArr:any[] = [];
        sectionKeys.forEach(element => {
            const option = sectionData[element];
            const optionsReframed = {
                section: currentValue,
                option: element,
                data: JSON.stringify({value:option.data}),
            };
            optionArr.push(optionsReframed);
        });
        return accumulator.concat(optionArr);
    },[]);
    return optionList;
};

export default useChartOptions;
