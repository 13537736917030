/* eslint-disable react/no-danger */
import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import visualisation from './visualisations';
import classNames from 'classnames';
import { Spin,Empty } from 'antd';
import useChartOptions from './hooks/useChartOptions';
import { CloseCircleOutlined } from '@ant-design/icons';
import { isReportTypeHtmlEditior, isHtmlSupported } from 'src/components/utils/utils';
import { useSelector } from 'react-redux';

const useStyle = createUseStyles({
  chartContainer: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px',
    overflow: 'auto',
    scrollbarWidth: 'none',
    '& .antv-s2-pagination': {
      zIndex: 1,
      width: 'calc(100% - 20px)',
    },
    backgroundColor: '#FFFFFF',
  },
  contentDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  contentDisplayError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    gap: '5px',
  },
  errorCircle: {
    color: 'white',
    background: 'red',
    borderRadius: '10px',
  },
  tableContainer: {
    '& .ant-table-pagination.ant-pagination': {
      margin: '10px 0px',
    },
    '& .ant-table-pagination': {
      marginRight: '10px !important',
      position: 'fixed',
      right: '50px',
      top: '0',
    },
  },
  switcherContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyItems: 'flex-end',
    marginRight: '1rem',
  },
  previewHTML: {
    overflow: 'scroll',
    height:'100%',
  },
});

const ChartContainer = (props) => {
    const classes = useStyle();
    const {
      type,
      chartData,
      formData,
      chartStyle,
      loaded,
      error,
      fetching,
      pageSize,
      chartOptions,
      updateChartRef,
      visualizeMetaData,
      chartInteractionHook,
      showUnderlyingDataDownload,
      reportId,
      chartsFromDashboard = false,
      chartConfig = null,
      formatting,
      columnWidth = null,
      fields,
      setFields,
      dataMartType,
      htmlData,
      reportType,
      newRelicUrl,
      isStacked,
    } = props;
    const optionList = useChartOptions(chartOptions);
    const selectedReport = useSelector(
      (state: any) => state.reportMetaData.selectedReport,
    );
    const initailDefaultType = isHtmlSupported(selectedReport) ? 'html' : 'bar';
    const ChartComponent = visualisation[type || initailDefaultType];
    const chartPadding = ['pivotTable', 'table', 'number'].includes(type) ? '' : '5px';
    const enclosingDiv = React.useRef(null as any);

    let width;
    if (enclosingDiv.current) {
        width = enclosingDiv.current.clientWidth;
    }

    if(chartsFromDashboard && isReportTypeHtmlEditior(reportType)){
      return <div className={classes.previewHTML} dangerouslySetInnerHTML={{ __html: htmlData }} />;
    }

    const isSingleBigNumber = () => {
      return type==='number' && chartData.length===1;
    };
    return (
      <>
        {chartData.length === 0 && !fetching ? (
          <div className={classes.contentDisplay}>
            <Empty />
          </div>
        ) : (
          <div
            ref={enclosingDiv}
            style={{
              ...chartStyle,
              padding: chartPadding,
              display: isSingleBigNumber() ? 'flex' : '',
            }}
            className={classNames('charts-container', classes.chartContainer)}
          >
            {fetching ? (
              <Spin className={classes.contentDisplay} />
            ) : error ? (
              <div className={classes.contentDisplayError}>
                <CloseCircleOutlined className={classes.errorCircle} />
                <span>You do not have access to view this report. Please contact admin{' '}</span>
              </div>
            ) : loaded ? (
              <>
                <ChartComponent
                  classes={classes}
                  updateChartRef={updateChartRef}
                  containerWidth={width}
                  visualizeMetaData={visualizeMetaData}
                  chartData={chartData}
                  chartConfig={chartConfig}
                  formatting={formatting}
                  formData={formData}
                  chartOptions={optionList}
                  dashboardPageSize={pageSize}
                  dashboardColumnWidth={columnWidth}
                  chartInteractionHook={chartInteractionHook}
                  showUnderlyingDataDownload={showUnderlyingDataDownload}
                  reportId={reportId}
                  chartsFromDashboard={chartsFromDashboard}
                  fields={fields}
                  setFields={setFields}
                  chartRef={
                    ['pivotTable', 'table'].includes(type)
                      ? enclosingDiv
                      : undefined
                  }
                  singleNumber={isSingleBigNumber()}
                  dataMartType={dataMartType}
                  htmlData={htmlData}
                  newRelicUrl={newRelicUrl}
                  isStacked={isStacked}
                />
              </>
            ) : (
              <div className={classes.contentDisplay}>
                Waiting to fetch ...{' '}
              </div>
            )}
          </div>
        )}
      </>
    );
};

export default memo(ChartContainer);
