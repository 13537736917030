import { Button, Popover, message } from 'antd';
import React, { useEffect, useState, memo } from 'react';
import { createUseStyles } from 'react-jss';
import GenericSummariseBy from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/genericSummariseBy';
import FiltersSection from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/filters';
import ObjectSection from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/object';
import SortBySection from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/sortBy';
import CoverageSection from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/coverage';
import ActionSection from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/action';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTER_RADIO_SELECTED,
  FILTER_SELECTED_VALUE_CHANGED,
  GENERIC_FILTER_RADIO_SELECTED,
  GENERIC_FILTER_SELECTED_VALUE_CHANGED,
  REMOVE_CUSTOM_COLUMNS_GLOBAL,
  REMOVE_CUSTOM_COLUMNS_LOCAL,
  SET_CUSTOM_COLUMNS_GLOBAL,
  SET_CUSTOM_COLUMNS_LOCAL,
} from 'src/reduxActions/actionNameEnums';
import CustomColumnDrawer from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/customColumnsDrawer';
import lodashValues from 'lodash/values';
import MetricColumns from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/metricColumns';
import { MoreOutlined } from '@ant-design/icons';

const useStyles = createUseStyles({
  createReportSection: {
    padding: '0px',
    margin: '16px 0px 0px 0px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
  },
  title: {
    height: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '16px',
  },
  moreOutlined: {
    zIndex: 0,
    cursor: 'pointer',
    right: '0',
  },
  metricSectionContainer: {
    width: '100%',
    display: 'flex',
    padding: '1rem',
    background: 'white',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 0,
  },
});

const MetricSetupSection = (props) => {
  const [customColumnType, setCustomColumnType] = useState<string>('');
  const {
    aggregate,
    filters,
    sortBy,
    setReset,
    havingFilters,
    initialSuggestedFilters = [],
    coverage,
    timeline,
    summariseBy,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterData = useSelector((state: any) => state.filterData);
  const coverageData = useSelector(
    (state: any) => state.genericFilterData['coverage'],
  );
  const selectedObject = useSelector(
    (state: any) => state.reportMetaData.object,
  );
  const selectedMetricsAsObject = useSelector((state: any) => state.metrics);
  const columnsMap = useSelector((state: any) =>
    customColumnType === 'global'
      ? state.customMetrices.global
      : customColumnType === 'local'
      ? state.customMetrices.local
      : null,
  );
  const columns = lodashValues(columnsMap);
  const [visible, setVisible] = useState<boolean>(false);
  const { reportObject } = props;

  const onChangeRadio = (metricsName) => (value) => {
    dispatch({
      type: FILTER_RADIO_SELECTED,
      payload: {
        metricsName,
        selectedFilter: value,
      },
    });
  };

  const onChangeFilterData =
    (metricsName) =>
    (data, additionalFiterData = null) => {
      const payload = { metricsName, data: { value: data } };
      if (additionalFiterData) {
        payload.data['additionalFiterData'] = additionalFiterData;
      }
      return dispatch({
        type: FILTER_SELECTED_VALUE_CHANGED,
        payload,
      });
    };

  const onChangeCoverageFilterData =
    (metricsName) =>
    (data, additionalFiterData = null) => {
      const payload = {
        metricsName,
        data: { value: data },
        filterType: 'coverage',
      };
      if (additionalFiterData) {
        payload.data['additionalFiterData'] = additionalFiterData;
      }
      return dispatch({
        type: GENERIC_FILTER_SELECTED_VALUE_CHANGED,
        payload,
      });
    };

  const onChangeColumnDataGlobal = (key, data) => {
    dispatch({
      type: SET_CUSTOM_COLUMNS_GLOBAL,
      payload: {
        name: key,
        body: data,
      },
    });
  };

  const onRemove = (metric) => {
    filters.onRemoveFilter(metric.name);
    if (havingFilters?.onRemoveFilter) {
      havingFilters.onRemoveFilter(metric.name);
    }
    aggregate.onRemoveSummarisation(metric.name);
    aggregate.metricsMetaData.onRemoveMetrics(metric.name);
    dispatch({
      type: REMOVE_CUSTOM_COLUMNS_GLOBAL,
      payload: {
        name: metric.id,
      },
    });
  };

  const onChangeColumnDataLocal = (key, data) =>
    dispatch({
      type: SET_CUSTOM_COLUMNS_LOCAL,
      payload: {
        name: key,
        body: data,
      },
    });

  const onRemoveLocal = (metric) =>
    dispatch({
      type: REMOVE_CUSTOM_COLUMNS_LOCAL,
      payload: {
        name: metric.id,
      },
    });

  useEffect(() => {
    setVisible(customColumnType ? true : false);
  }, [customColumnType]);

  useEffect(() => {
    // initialise sortBy field with pre-selected timeline field
    if (!sortBy.selectedSortBy.length && timeline.selectedAggregate[0]?.value) {
      onChangeTimeline([timeline.selectedAggregate[0]?.value], null);
    }
  }, [timeline.selectedAggregate]);

  const onChangeCoverage = (metricsName) => (value) => {
    dispatch({
      type: GENERIC_FILTER_RADIO_SELECTED,
      payload: {
        metricsName,
        filterType: 'coverage',
        selectedFilter: value,
      },
    });
  };

  const onChangeTimeline = (metricsName, _values) => {
    let toRet = false;
    metricsName.map((metric) => {
      if (selectedMetricsAsObject[metric]) {
        message.error(`Column ${metric} already exists`);
        toRet = true;
      }
    });
    if (toRet) return;
    timeline.onChangeAggregateSelection(metricsName, { updateSortBy: true });
  };

  const onRemoveTimeline = (metricName) => {
    timeline.onRemoveSummarisation(metricName, { updateSortBy: true });
  };

  const onChangeSummariseBy = (metricsName, _values) => {
    let toRet = false;
    metricsName.map((metric) => {
      if (selectedMetricsAsObject[metric]) {
        message.error(`Column ${metric} already exists`);
        toRet = true;
      }
    });
    if (toRet) return;
    summariseBy.onChangeAggregateSelection(metricsName, {
      updateSortBy: false,
    });
  };

  const onRemoveSummariseBy = (metricName) => {
    summariseBy.onRemoveSummarisation(metricName, { updateSortBy: false });
  };

  const hideRemoveSortBy = (value) => {
    const timelineMetrics = timeline.selectedAggregate.filter(
      (aggregate) => aggregate.section === 'timeline',
    );

    if (timelineMetrics.some((timeline) => timeline.value === value)) {
      return true;
    }
    return false;
  };

  const onSelectSortMetric = (fn, value) => {
    const timelineMetrics = timeline.selectedAggregate.filter(
      (aggregate) => aggregate.section === 'timeline',
    );
    fn(value, [
      ...aggregate.metricsMetaData.selectedMetrics,
      ...timelineMetrics,
    ]);
  };

  const floatingButton = (
    <Button onClick={() => setCustomColumnType('global')}>
      {' '}
      Custom Metrices{' '}
    </Button>
  );

  const onSelectFilter = (a, b) => {
    if (!timeline.selectedAggregate?.length) {
      onChangeTimeline([a[a.length - 1]], null);
    }
  };

  return (
    <div className={classes.metricSectionContainer}>
      <div style={{ display: 'flex', width: '100%', marginBottom: '1rem' }}>
        <div style={{ fontSize: '10px', fontWeight: '400', color: '#8C8C8C' }}>
          Pick and choose the data elements here
        </div>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <Popover placement="leftTop" trigger="click" content={floatingButton} getPopupContainer={(trigger: any) => trigger.parentNode} >
            <MoreOutlined className={classes.moreOutlined} />
          </Popover>
        </div>
      </div>
      <ObjectSection
        object={selectedObject}
        filterData={filterData}
        aggregate={aggregate}
        filters={filters}
        isMandatory={true}
      />
      <CoverageSection
        initialSuggestedFilters={initialSuggestedFilters}
        filterData={coverageData}
        aggregate={aggregate}
        onChangeRadio={onChangeCoverage}
        onChangeFilterData={onChangeCoverageFilterData}
        filters={filters}
        coverage={coverage}
        description="Suggested filters for objects should be added here with parameter 'is not null'"
      />
      <FiltersSection
        initialSuggestedFilters={initialSuggestedFilters}
        filterData={filterData}
        aggregate={aggregate}
        onChangeRadio={onChangeRadio}
        onChangeFilterData={onChangeFilterData}
        filters={filters}
        description="Non-frozen filters will appear to user as suggested filters. Frozen filters will be hidden"
        onSelectFilter={onSelectFilter}
      />
      <GenericSummariseBy
        aggregate={timeline}
        title="Timeline"
        options={timeline.aggregateOptions.filter((option) =>
          ['timestampz', 'timestamp', 'date'].includes(option.type),
        )}
        type="timeline"
        onChange={onChangeTimeline}
        onRemove={onRemoveTimeline}
        isMandatory={true}
        maximumSelection={1}
      />
      <GenericSummariseBy
        aggregate={summariseBy}
        title="Summarise By"
        description="Each breakup will be individually considered against the threshold. Field parameters will be passed on as incident remarks"
        options={summariseBy.aggregateOptions}
        resetAggregate={false}
        type="summariseBy"
        onChange={onChangeSummariseBy}
        onRemove={onRemoveSummariseBy}
      />
      <MetricColumns
        setCustomColumnType={setCustomColumnType}
        aggregate={aggregate}
        maximumSelection={1}
        description="Only 1 field can be added"
        timeline={timeline}
        summariseBy={summariseBy}
      />
      <SortBySection
        sortBy={sortBy}
        hideRemove={hideRemoveSortBy}
        onSelectSortMetric={onSelectSortMetric}
        showAddButton={false}
      />
      <CustomColumnDrawer
        setReset={setReset}
        visible={visible}
        setCustomColumnType={setCustomColumnType}
        columns={columns}
        customColumnType={customColumnType}
        hideAddMoreOption={customColumnType === 'global' ? false : true}
        onChange={
          customColumnType === 'global'
            ? onChangeColumnDataGlobal
            : customColumnType === 'local'
            ? onChangeColumnDataLocal
            : null
        }
        reportObject={reportObject}
        onRemove={
          customColumnType === 'global'
            ? onRemove
            : customColumnType === 'local'
            ? onRemoveLocal
            : null
        }
      />
      <ActionSection />
    </div>
  );
};

export default memo(MetricSetupSection);
