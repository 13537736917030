import { Checkbox, Select, Space, TimePicker} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashOmit from 'lodash/omit';
const { Option } = Select;
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const AdditionalFilters = (props) => {
    const {
        metricsName,
        onChangeFilterData,
        filterData,
    } = props;
    const selectedValue = filterData?.[metricsName];
    const timeRangeValue:any = selectedValue?.data?.additionalFiterData?.fromTime ?
        [moment(selectedValue.data.additionalFiterData.fromTime, 'HH:mm:ss'), moment(selectedValue.data.additionalFiterData.toTime, 'HH:mm:ss')]
        : null;

    const onHolidaysChange = (e) => {
        if(e.target.checked){
            const updatedAdditionalFiltersValue = {
                ...selectedValue?.data?.additionalFiterData,
                holidays: selectedValue.holidays,
            };
            onChangeFilterData(selectedValue.data.value, updatedAdditionalFiltersValue);
        }else{
            const newState = lodashOmit({...selectedValue?.data?.additionalFiterData} || {}, ['holidays']);
            onChangeFilterData(selectedValue.data.value, newState);
        }
    };
    const onTimeRangeChange = (data) => {
        if(data){
            const updatedAdditionalFiltersValue = {
                ...selectedValue?.data?.additionalFiterData,
                fromTime: data[0].format('HH:mm:ss'),
                toTime: data[1].format('HH:mm:ss'),
            };
            onChangeFilterData(selectedValue.data.value, updatedAdditionalFiltersValue);
        }else{
            const newState = lodashOmit({...selectedValue?.data?.additionalFiterData} || {}, ['fromTime', 'toTime']);
            onChangeFilterData(selectedValue.data.value, newState);
        }
    };
    const onDaySelect = (days: string[]) => {
        let updatedAdditionalFiltersValue = {
            ...selectedValue?.data?.additionalFiterData,
            days,
        };
        if(!days.length){
            updatedAdditionalFiltersValue = lodashOmit(updatedAdditionalFiltersValue, ['days']);
        }
        onChangeFilterData(selectedValue.data.value, updatedAdditionalFiltersValue);
    };
    return <>
            <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            <Checkbox
                defaultChecked={false}
                checked={!!(selectedValue?.data?.additionalFiterData?.holidays?.length)}
                disabled={!selectedValue?.holidays?.length || lodashIsEmpty(selectedValue?.data?.value)}
                onChange={onHolidaysChange}>
                Exclude Holidays
            </Checkbox>
            <TimePicker.RangePicker
                value={timeRangeValue}
                onChange={onTimeRangeChange}
                disabled={lodashIsEmpty(selectedValue?.data?.value)}
            />
            <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select days"
                defaultValue={[]}
                value={selectedValue?.data?.additionalFiterData?.days || []}
                onChange={onDaySelect}
                optionLabelProp="label"
                onClick={(event) => {
                    event.preventDefault();
                }}
                disabled={lodashIsEmpty(selectedValue?.data?.value)}
            >
                {days.map(day => {
                    return (
                        <Option value={day} label={day}>
                            <div>
                                {day}
                            </div>
                        </Option>
                    );
                })}
            </Select>
            </Space>
</>;
};

export default AdditionalFilters;
