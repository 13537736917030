export const getPermissionType = (userType) => {
  if (userType !== undefined) {
    if (userType.includes('BUSINESS_INTELLIGENCE_USER')) {
      return 'user';
    }
    if (userType.includes('BUSINESS_INTELLIGENCE_ADMIN')) {
      return 'admin';
    }
    if (userType.includes('BUSINESS_INTELLIGENCE_SUPER_ADMIN')) {
      return 'super_admin';
    }
  }
  return 'user';
};
