import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useReportDashboard } from 'src/components/common/hooks/useReportDashboardRelation';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { GET_REPORTS_SEARCH_BY_NAME } from 'src/api/routes';

export const useAddReportDashboardPage = (
  dashboardId,
  refetchMetaData,
  reportIdsAlreadyInDashboard: any = [],
  type,
  canEditDefaultDashboard,
  onRefetchDashboardData,
  isDefaultEditable,
  updateReportPositions,
) => {
  const [visible, setVisibility] = useState(false);
  const [getReports, { loading: loadingReports, data }] = useLazyAPI(
    GET_REPORTS_SEARCH_BY_NAME,
    {
      type: 'POST',
      onCompleted: (data) => {},
    },
  );

  const fetchReports = (tab) => {
    const filters: any = [];
    const typeFilter = canEditDefaultDashboard
      ? ['default']
      : ['user', 'shared'];
    filters.push({
      key: 'type',
      data: typeFilter,
    });
    getReports({
      variables: {
        tab,
        filters,
      },
    });
  };

  useEffect(() => {
    const tab = 'default';
    fetchReports(tab);
  }, [type]);

  const [selectedReportsData, setSelectedReportsData] = useState([]);
  const { addingReport, addReportsToDashboard } = useReportDashboard({
    onCompletedCallBack: () => {
      setVisibility(false);
      refetchMetaData(dashboardId);
      updateReportPositions(selectedReportsData);
    },
  });

  const onAddReport = (dashboardId) => {
    if (selectedReportsData.length === 0) {
      return message.error('Select atleast one report to add');
    }
    return addReportsToDashboard(selectedReportsData, dashboardId);
  };

  const getAllReports = (data) => {
    if (isDefaultEditable) {
      return data?.reports?.length
        ? data.reports.filter(
            (data) =>
              (!reportIdsAlreadyInDashboard?.includes(data._id) &&
                data.type === 'default') ||
              data.type === 'user',
          )
        : [];
    }
    return data?.reports?.length
      ? data.reports.filter(
          (data) =>
            !reportIdsAlreadyInDashboard?.includes(data._id) &&
            data.type === 'user',
        )
      : [];
  };

  return {
    setSelectedReportsData,
    onAddReport,
    reports: getAllReports(data),
    addingReport,
    loadingReports,
    visible,
    setVisibility,
    selectedReportsData,
  };
};
