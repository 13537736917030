import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';
import { CHANGE_METRIC_STRING_MANUAL_QUERY } from 'src/reduxActions/actionNameEnums';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import { isValidJSON } from 'src/components/common/utils/utils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RowContainer from 'src/components/common/rowContainer';
import { createUseStyles } from 'react-jss';
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { useCustomMetrices } from './useMetricsHook';

const useStyles = createUseStyles({
  linkReportContainer: {
    backgroundColor: '#FDF5DB',
    minHeight: '46px',
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0px',
  },
  linkIcon: {
    margin: '0px 12px 0px 12px',
    color: '#006EC3',
  },
  deleteIcon: {
    margin: '0px 12px 0px 12px',
    color: '#FF4D4F',
    cursor: 'pointer',
  },
  deleteIconContainer: {
    marginLeft: 'auto',
  },
  linkReportTitle: {
    color: '#006EC3',
  },
});

const CustomQueryColumnsSection = (props) => {
  const classes = useStyles();
  const metricString = useSelector(
    (state: any) => state.reportMetaData.metricString,
  );
  const filteredColumns = isValidJSON(metricString)
    ? JSON.parse(metricString)
    : [];
  const useCustomMetrics = useCustomMetrices(filteredColumns);
  const selectedAggregate = filteredColumns.filter((cols) => cols?.groupBy);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = filteredColumns;
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    useCustomMetrics.reorderCustomMetricString(newItems);
  };

  return (
    <SectionPartContainer
      title={'Columns'}
      onTitleChanges={console.log}
      showAddButton={true}
      isCustomInput={true}
    >
      <div style={{ marginTop: '16px' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {filteredColumns.map((row, index) => {
                  const isRowInAggregate = selectedAggregate.some(
                    (aggregate) => aggregate.metric === row.metric,
                  );
                  const showLinkReport =
                    selectedAggregate.length === 0 ? false : !isRowInAggregate;
                  return (
                    <Draggable
                      key={row.metric}
                      draggableId={row.metric}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          snapshot={snapshot}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <RowContainer
                            isColumn={true}
                            columnDataType={row?.type}
                            columnOperation={row?.operation}
                            columnName={row?.metric}
                            linkedReport={row?.linkedReport}
                            title={row.alias || row.prettyName}
                            prettyName={row.prettyName}
                            setDummyDataVisibility={false}
                            hideRemove={true}
                            showLinkReport={showLinkReport}
                            onAddLinkedReport={(
                              reportId,
                              reportName,
                              linkedMetrices,
                            ) =>
                              useCustomMetrics.onAddLinkedReport(
                                row.metric,
                                reportId,
                                reportName,
                                linkedMetrices,
                              )
                            }
                          >
                            {row.linkedReport &&
                            !lodashIsEmpty(row.linkedReport) ? (
                              <div className={classes.linkReportContainer}>
                                <LinkOutlined className={classes.linkIcon} />
                                <span className={classes.linkReportTitle}>
                                  {row.linkedReport.reportName}
                                </span>
                                <div className={classes.deleteIconContainer}>
                                  <DeleteOutlined
                                    onClick={() =>
                                      useCustomMetrics.onRemoveLinkedReport(row.metric)
                                    }
                                    className={classes.deleteIcon}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </RowContainer>

                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </SectionPartContainer>
  );
};

export default memo(CustomQueryColumnsSection);
