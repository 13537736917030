export const calculateTableColumnWidth = (props) => {
  const { metrics, dashboardColumnWidth, columns, containerWidth } = props;
  const columnWidth = {};
  for (const key in metrics) {
    if (metrics[key]) {
      const { aliasName } = metrics[key];
      if (metrics[key]?.columnWidth) {
        columnWidth[aliasName] = metrics[key]?.columnWidth;
      }
    }
  }
  const widthByFieldValue = dashboardColumnWidth
    ? dashboardColumnWidth
    : !columnWidth || Object.keys(columnWidth).length === 0
    ? columns.reduce((acc, column) => {
        const size = Math.floor(containerWidth / columns.length) + 1;
        acc[column] = Math.max(size, 100);
        return acc;
      }, {})
    : columnWidth;
  return widthByFieldValue;
};

export const calculatePivotColumnWidth = (props) => {
  const {
    metrics,
    dashboardColumnWidth,
    columns,
    containerWidth,
    distinctColCount,
  } = props;
  const columnWidth = {};
  for (const key in metrics) {
    if (metrics[key]) {
      const { aliasName } = metrics[key];
      if (metrics[key]?.columnWidth && columns.includes(aliasName)) {
        columnWidth[aliasName] = metrics[key]?.columnWidth;
      }
    }
  }
  const columnsCount = distinctColCount || columns.length;
  const widthByFieldValue = dashboardColumnWidth
    ? dashboardColumnWidth
    : !columnWidth || Object.keys(columnWidth).length === 0
    ? columns.reduce((acc, column) => {
        const size = Math.floor(containerWidth / columnsCount) + 1;
        acc[column] = Math.max(size, 100);
        return acc;
      }, {})
    : columnWidth;
  return widthByFieldValue;
};
