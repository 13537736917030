import { Radio, Switch, Tooltip, Checkbox } from 'antd';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import FiltersMap from 'src/components/common/FilterComponent';
import moment from 'moment';
import { DatePicker, InputNumber, Button, Select } from 'antd';
const { RangePicker } = DatePicker;
import SerchableSelect from 'src/components/common/FilterComponent/SearchableSelect';
import {
  quickSelectionsOptions,
  handleQuickSelection,
} from 'src/components/common/utils/quickSelectionUtils';

const useStyle = createUseStyles({
  basePane: {
    width: '33rem',
    padding: '10px',
    display: 'flex',
  },
  quickSelectionButton: {
    border: '0.5px solid transparent',
    borderColor: '#D9D9D9',
    backgroundColor: '#FAFAFA',
    color: '#000000',
    marginRight: '20px',
  },
  quickTimeFilter: {
    borderRight: '1px solid #ccc',
    height: '100%',
    margin: '0 10px',
  },
});

const TimeFilter = (props) => {
  const {
    onChangeFilterDataForSimple,
    selectedValue,
    setSelectedDateRange,
    selectedDateRange,
    visiblePopOver,
  } = props;
  const classes = useStyle();
  const onRangeChange = (data) => {
    if (data?.length >= 1) {
      onChangeFilterDataForSimple({
        from: data[0].format('YYYY-MM-DD'),
        to: data[1].format('YYYY-MM-DD'),
      });
      setSelectedDateRange(data);
    }
  };

  const renderExtraFooter = () => {
    return (
      <div>
        {quickSelectionsOptions.map((selection) => (
          <div style={{ display: 'flex', height: '40px' }}>
            <Button
              className={classes.quickSelectionButton}
              onClick={() =>
                handleQuickSelection(
                  selection.value,
                  onRangeChange,
                  setSelectedDateRange,
                )
              }
            >
              {selection.label}
            </Button>
          </div>
        ))}
      </div>
    );
  };

  let rangeValue: any = selectedValue?.data?.value
    ? [
        moment(selectedValue?.data.value.from),
        moment(selectedValue?.data.value.to),
      ]
    : null;
  if (selectedDateRange) {
    rangeValue = selectedDateRange;
  }

  return (
    <div className={classes.basePane}>
      <div className={classes.quickTimeFilter}>{renderExtraFooter()}</div>
      <div>
        <RangePicker
          open={visiblePopOver}
          value={rangeValue}
          onChange={(e) => onRangeChange(e)}
        />
      </div>
    </div>
  );
};

export default TimeFilter;
