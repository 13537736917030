import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { GET_LANDING_PAGE } from 'src/appQuery';
import {
  CHANGE_LANDING_PAGE_ID,
  LANDING_PAGE_LOADED,
} from 'src/reduxActions/actionNameEnums';

interface LandingPageData {
  dashboardGroupId: string;
}

const LandingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [landingPageData, setLandingPageData] =
    useState<LandingPageData | null>(null);

  const [getLandingPage, { loading }] = useLazyQuery(GET_LANDING_PAGE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLandingPageData(data.getLandingDashboardPage);
      redirectLandingPage(data.getLandingDashboardPage);
    },
    onError: (data) => {
      message.error({
        key: 'landingPage',
        content: 'Failed to Fetch landing page',
      });
    },
  });

  const redirectLandingPage = (data) => {
    if (history.location.pathname === '/') {
      if (data.dashboardGroupId && !isMobile) {
        history.replace(`/dashboard/${data.dashboardGroupId}`);
      } else {
        history.replace('/dashboard');
      }
    }
    if (data.dashboardGroupId && !isMobile) {
      dispatch({
        type: CHANGE_LANDING_PAGE_ID,
        payload: data.dashboardGroupId,
      });
    }
    dispatch({
      type: LANDING_PAGE_LOADED,
      payload: true,
    });
  };

  useEffect(() => {
    if (landingPageData === null) {
      getLandingPage();
      return;
    }

    if (location.pathname === '/') {
      redirectLandingPage(landingPageData);
    }
  }, [location.pathname]);

  return <></>;
};

export default LandingPage;
