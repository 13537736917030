import React from 'react';
import CustomInput from './CustomInput';
import CustomSelect from './CustomSelect';
import CustomSwitch from './CustomSwitch';
import CustomColorPicker from './CustomColorPicker';
import CustomNumberInput from './CustomNumberInput';
import ChartConfigRadio from 'src/components/pages/chartBuilder/createQueryComponents/chartConfig/ChartConfigRadio';
import ColorPickerSelect from 'src/components/pages/chartBuilder/createQueryComponents/chartConfig/ColorPickerSelect';

const InputMap = {
  singleSelect: CustomSelect,
  input: CustomInput,
  numberInput: CustomNumberInput,
  switch: CustomSwitch,
  Colorpicker: CustomColorPicker,
  ColorpickerSelect: ColorPickerSelect,
  customRadio: ChartConfigRadio,
};

export default InputMap;
