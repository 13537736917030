import React, { useState } from 'react';
import {
  Button,
  Modal,
  message,
} from 'antd';
import lodashIsEmpty from 'lodash/isEmpty';
import CreateLinkReportWizardContent from './linkReportWizard/CreateLinkReportWizardContent';
import StepOne from './linkReportWizard/StepOne';
import StepTwo from './linkReportWizard/StepTwo';
import lodashUnion from 'lodash/union';
import { useSelector } from 'react-redux';

const LinkReportModal = (props) => {
  const { visible, setVisible, onAddLinkedReport, linkedReport: savedLinkedReport } = props;
  const [linkedReport, setLinkedReport] = useState<any>(savedLinkedReport || undefined);
  const [selectedReportId, setSelectedReportId] = useState<any>(savedLinkedReport?.reportId || undefined);
  const { filterData, summarization, reportMetaData } = useSelector((state: any) => state);
  const filterDataWithoutDefaultFilters = {};
  for (const key in filterData) {
      if (filterData.hasOwnProperty(key) && !filterData[key].isDefaultFilter) {
        filterDataWithoutDefaultFilters[key] = filterData[key];
      }
  }
  const parentFilterKeysForChildReport = lodashUnion(Object.keys(filterDataWithoutDefaultFilters), Object.keys(summarization));
  const [currentStep, setCurrentStep] = useState(0);

  const wizardSteps = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepOne
            setSelectedReportId={setSelectedReportId}
            selectedReportId={selectedReportId}
            setLinkedReport={setLinkedReport}
          />
        );
      case 1:
        return (
          <StepTwo
            setLinkedReport={setLinkedReport}
            linkedReport={linkedReport}
            selectedReportId={selectedReportId}
            parentReportType={reportMetaData.selectedReport}
          />
        );
      default:
        return (
          <StepOne
            setSelectedReportId={setSelectedReportId}
            selectedReportId={selectedReportId}
            setLinkedReport={setLinkedReport}
          />
        );
    }
  };

  const validateLinkedReport = (errors) => {
    if (linkedReport?.reportId) {
      const linkedMetrices = linkedReport?.linkedMetrices || {};
      const linkedMetriceKeys = Object.keys(linkedMetrices);
      if (linkedMetriceKeys.length === 0) {
        errors.push('Please map the linked report columns');
      } else {
        parentFilterKeysForChildReport.forEach((evaluationKey) => {
          if (!linkedMetrices[evaluationKey]) {
            errors.push('Please map all the linked report columns');
          }
        });
      }
    }
  };

  const onSubmit = () => {
    if (!lodashIsEmpty(linkedReport)) {
      const errors: string[] = [];
      validateLinkedReport(errors);
      if (errors.length) {
        errors.map(error => message.warning(error));
        return;
      }
      onAddLinkedReport(linkedReport.reportId, linkedReport.reportName, linkedReport.linkedMetrices);
      setVisible(false);
      setLinkedReport(linkedReport);
      setCurrentStep(0);
      setSelectedReportId(linkedReport.reportId);
    } else {
      message.error('Please select all the linked metrices');
    }
  };

  const resetModal = () => {
    setLinkedReport(savedLinkedReport || undefined);
    setCurrentStep(0);
    setSelectedReportId(savedLinkedReport?.reportId || undefined);
  };

  const onClickNext = () => {
    if (currentStep === 0) {
      if (!selectedReportId) {
        message.error('Please select a Report');
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  };

  const onClickBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const onCancel = () => {
    setVisible(false);
    resetModal();
  };

  return (
    <>
      <Modal
        visible={visible}
        width={'80%'}
        title="Define Link Report"
        style={{ top: 20 }}
        onCancel={onCancel}
        footer={[
          <Button onClick={onCancel}>Cancel</Button>,
          <Button
            disabled={currentStep === 0}
            type="primary"
            onClick={onClickBack}
          >
            Back
          </Button>,
          <>
            {currentStep === 1 ? (
              <Button type="primary" onClick={onSubmit}>
                Submit
              </Button>
            ) : (
              <Button type="primary" onClick={onClickNext}>
                Next
              </Button>
            )}
          </>,
        ]}
      >
        <CreateLinkReportWizardContent currentStep={currentStep} />
        <div style={{ minHeight: '400px' }}>{wizardSteps()}</div>
      </Modal>
    </>
  );
};

export default LinkReportModal;
