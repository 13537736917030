import { gql } from '@apollo/client';

export const SAVEREPORT = gql`
  query SAVEREPORT(
    $id: String
    $isNew: Boolean!
    $name: String!
    $description: String!
    $reportType: String
    $customAggregateMetrices: [customAggregateMetricInput]
    $customMetrices: [customMetricInput]
    $metrices: [metricInput]!
    $filters: [whereHavingInput]!
    $havingFilters: [havingClauseInput]
    $chartType: String!
    $pageSize: Int
    $chartConfig: String
    $chartOptions: [chartOptionInput]
    $tab: String
    $category: String
    $suggestedFilterStrings: [suggestedFilterStringsKeyValueInput]
    $object: reportObjectInput
    $underlyingMetrics: [underlyingMetricInput]
    $formatting: String
    $automationSchedules: [automationSchedulesInput]
    $metricType: String
    $isAggregate: Boolean
    $actions: [actionInput]
    $dataMartType: String
    $isAdminReport: Boolean
    $queryString: String
    $htmlData: String
    $mongoCollectionName: String
    $underlyingQueryString: String
  ) {
    saveReport(
      id: $id
      isNew: $isNew
      name: $name
      description: $description
      reportType: $reportType
      customAggregateMetrices: $customAggregateMetrices
      customMetrices: $customMetrices
      metrices: $metrices
      filters: $filters
      havingFilters: $havingFilters
      chartType: $chartType
      pageSize: $pageSize
      chartConfig: $chartConfig
      chartOptions: $chartOptions
      tab: $tab
      category: $category
      suggestedFilterStrings: $suggestedFilterStrings
      object: $object
      underlyingMetrics: $underlyingMetrics
      formatting: $formatting
      automationSchedules: $automationSchedules
      metricType: $metricType
      isAggregate: $isAggregate
      actions: $actions
      dataMartType: $dataMartType
      isAdminReport: $isAdminReport
      queryString: $queryString
      htmlData: $htmlData
      mongoCollectionName: $mongoCollectionName
      underlyingQueryString: $underlyingQueryString
    ) {
      success
      error
    }
  }
`;

export const DOWNLOADREPORT = gql`
query DOWNLOADREPORT(
    $reportType:String,
    $metrices:[metricInput]!,
    $customAggregateMetrices: [customAggregateMetricInput],
    $customMetrices: [customMetricInput],
    $suggestedFilterStrings:[suggestedFilterStringsKeyValueInput],
    $filters:[whereHavingInput]!,
    $havingFilters:[havingClauseInput]
    $name:String
    $dataMartType: String
    $isAdminReport: Boolean
    $queryString: String
    $mongoCollectionName: String
    ){
    buildQuery(
        reportType:$reportType,
        metrices:$metrices,
        filters:$filters,
        havingFilters:$havingFilters,
        customMetrices: $customMetrices,
        customAggregateMetrices: $customAggregateMetrices,
        suggestedFilterStrings:$suggestedFilterStrings,
        name:$name,
        dataMartType: $dataMartType
        isAdminReport: $isAdminReport
        queryString: $queryString
        mongoCollectionName: $mongoCollectionName
  )
}
`;

export const DOWNLOAD_UNDERLYING_DATA_REPORT = gql`
  query DOWNLOAD_UNDERLYING_DATA_REPORT(
    $reportType: String!
    $metrices: [metricInput]!
    $underlyingMetrics: [underlyingMetricInput]
    $customMetrices: [customMetricInput]
    $filters: [whereHavingInput]!
    $name: String
    $underlyingQueryString: String
    $dataMartType: String
  ) {
    buildAndExecuteUnderlyingQuery(
      reportType: $reportType
      metrices: $metrices
      filters: $filters
      customMetrices: $customMetrices
      name: $name
      underlyingMetrics: $underlyingMetrics
      underlyingQueryString: $underlyingQueryString
      dataMartType: $dataMartType
    )
  }
`;
