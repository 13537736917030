import lodashUnset from 'lodash/unset';
import lodashForOwn from 'lodash/forOwn';
import lodashMapKeys from 'lodash/mapKeys';
import lodashOmit from 'lodash/omit';

import {
    HAVING_FILTER_RADIO_SELECTED,
    HAVING_FILTER_DATA_CHANGED,
    HAVING_FILTER_DATA_RESET,
    HAVING_FILTER_DATA_REMOVED,
    HAVING_FILTER_SELECTED_VALUE_CHANGED,
    HAVING_INITIALIZE_FILTERS,
    CHANGE_HAVING_AGGREGATE,
 } from '../reduxActions/actionNameEnums';

const initialState = {};

const havingFilterReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type){
        case HAVING_INITIALIZE_FILTERS:
        case HAVING_FILTER_DATA_CHANGED:
            const defaultFilterSelected = payload.metricData.map(filter => {
                return {
                    selectedFilter: payload.filtersMetaData['number'][0].name,
                    ...filter,
                };
            });
            const metricObject = lodashMapKeys(defaultFilterSelected, (data, keys) => data.value);
            return metricObject;
        case HAVING_FILTER_DATA_REMOVED:
            return lodashOmit(state, payload);
        case HAVING_FILTER_DATA_RESET:
            const baseState = {};
            lodashForOwn(state, (value, key) => {
                if(value.filterMetaData?.required){
                    const newValue = lodashOmit(value, ['data', 'selectedFilter']);
                    baseState[key] = newValue;
                }
            });
            return baseState;
        case HAVING_FILTER_RADIO_SELECTED:
            const radioChange = {
                [payload.metricsName]: {
                    ...state[payload.metricsName],
                    selectedFilter: payload.selectedFilter,
                    },
            };
            const newState = Object.assign({}, state, radioChange);
            lodashUnset(newState, `${payload.metricsName}.data`);
            return newState;
        case HAVING_FILTER_SELECTED_VALUE_CHANGED:
            const changeData = {
                [payload.metricsName]: {
                    ...state[payload.metricsName],
                    data: payload.data,
                },
            };
            return Object.assign({}, state, changeData);
        case CHANGE_HAVING_AGGREGATE:
            const aggregateObject = {
                [payload.name]: {
                    ...state[payload.name],
                    havingOperation: payload.value,
                },
            };
            return Object.assign({}, state, aggregateObject);
        default:
            return state;
    }
};

export default havingFilterReducer;
