import React, { useEffect, useState, memo, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { tableColor } from 'src/components/utils/colors';
import moment from 'moment';
import {
  GetChartConfigData,
  getColor,
} from 'src/components/common/utils/utils';
import { SheetComponent } from '@antv/s2-react';
import lodashConcat from 'lodash/concat';
import lodashCountBy from 'lodash/countBy';
import {
  SET_TABLE_PAGE_SIZE,
  SET_FIELD_VALUE,
  SET_COLUMN_WIDTH_METRICES,
} from 'src/reduxActions/actionNameEnums';
import { useDispatch } from 'react-redux';
import '@antv/s2-react/dist/style.min.css';
import { setLang, DataCell } from '@antv/s2';
import { getFormattedColumns, getMapping } from '../utils/visualisationUtils';
import lodashCloneDeep from 'lodash/cloneDeep';
import {
  getCurrentBucketValue,
  getToFromTimeStringObject,
} from '../utils/timeTransformer';
import { useSubmissionData } from 'src/components/pages/chartBuilder/hooks/dataSubmissionHook';
import lodashPick from 'lodash/pick';
import lodashIsEqual from 'lodash/isEqual';
import lodashValues from 'lodash/values';
import { createUseStyles } from 'react-jss';
import { useLazyQuery } from '@apollo/client';
import {
  getfilterWithUnSelectedValues,
  transformFilterWithSelectedValue,
  transformFilters,
} from '../utils/filter';
import { GET_REPORT_DRILLDOWN_DATA } from 'src/components/pages/chartBuilder/createQueryComponents/graphqlQuery';
import { calculatePivotColumnWidth } from './utils';

const useStyles = createUseStyles({
  blurred: {
    filter: 'blur(5px)',
  },
});

setLang('en_US');

class CustomDataCell extends DataCell {
  getTextStyle(): any {
    const defaultTextStyle = super.getTextStyle();
    const configData = this.meta.configData;
    if(this.meta.data && typeof this.meta.data[this.meta.valueField] === 'number') {
      return {
        ...defaultTextStyle,
        textAlign: (configData as any)?.graphic?.numberAlign?.data || 'right',
      };
    }
    return {
      ...defaultTextStyle,
      textAlign:(configData as any)?.graphic?.textAlign?.data || 'left',
    };
  }
}

const PivotTable = (props) => {
  const pivotClasses = useStyles();
  const {
    chartData,
    classes,
    formData: formDataProps,
    chartConfig,
    chartsFromDashboard,
    chartOptions,
    containerWidth,
    dashboardColumnWidth,
    chartRef,
    dashboardPageSize,
    visualizeMetaData,
    chartInteractionHook,
    showUnderlyingDataDownload,
    reportId,
    formatting,
    fields = formDataProps.fields,
    setFields,
  } = props;
  const reportChartOptions = useSelector(
    (state: any) => state.chartsMetaData.chartOptions,
  );
  const [distinctColCount, setDistinctColCount] = useState();
  const dispatch = useDispatch();
  const metrics: any = useSelector((state: any) => state.metrics);
  const [showPagination, setShowPagination] = useState(true);
  let drilldownWidthValue = 200;
  const hasDrilldown = Object.values(metrics).some((obj: any) => {
    if (obj && obj.drilldown) {
      drilldownWidthValue = obj.columnWidth;
      return true;
    }
    return false;
  });
  const [totalData, setTotalData] = useState<any>(chartData);
  const [loadingDrillDown, setLoadingDrillDown] = useState<any>(false);
  const [formData, setFormData] = useState<any>(formDataProps);
  const dashboardFilter = useSelector((state: any) => state.dashboardFilter);
  const supportedFilters = useSelector(
    (state: any) => state.masterData?.supportedFilters,
  );
  const dashboardGroupMetaData = useSelector(
    (state: any) => state.dashboardGroupMetaData,
  );
  // used for maintaining drilldown keys which have been clicked on the table to avoid multiple calls
  const [drilldownkeys, setDrilldownkeys] = useState<any>(new Set());
  const drilldownMetric = visualizeMetaData.summarizeData.find(
    (metric) => metric?.drilldown?.toApply === true,
  );
  // Create a ref to store the previous state
  const prevChartDataRef = useRef();

  useEffect(() => {
    setTotalData(chartData);
    if (
      chartsFromDashboard &&
      chartData.length > 0 &&
      chartData.length <= dashboardPageSize
    ) {
      setShowPagination(false);
    } else {
      setShowPagination(true);
    }
  }, [chartData]);

  useEffect(() => {
    // since chartData is an object reference, we need to use lodash and store it prev state to compare
    if (!lodashIsEqual(prevChartDataRef.current, chartData)) {
      setTotalData(chartData);
    }
    prevChartDataRef.current = chartData;
  }, [chartData]);

  const s2DataConfig: any = {
    fields,
    data: chartData,
    totalData,
    meta: !drilldownMetric ? formData.meta : undefined,
  };
  const configObject = {
    chartConfig,
    configType: 'pivotTable',
    formData,
    chartsFromDashboard,
  };
  const filter = chartOptions.find((option) => option.option === 'freeze');
  const freezecols = JSON.parse(filter?.data || '{}').value;
  const configData = GetChartConfigData(configObject);
  const pageSize = useSelector((state: any) => state.chartsMetaData.pageSize);
  const enableDummyDataSwitch = useSelector(
    (state: any) => state.reportMetaData.dummyDataSwitch,
  );

  const getFill = (type) => {
    const formattedColumns = getFormattedColumns({
      formatting,
      formData,
    });
    const mapping: any = [];

    Object.entries(formattedColumns).map(([key, value]) => {
      const map = getMapping({
        visualizeMetaData,
        formData,
        formatting,
        totalData,
        fieldKey: key,
        fieldName: value,
        type,
        isNumberChart: false,
      });
      mapping.push(map);
    });

    return mapping;
  };

  const rows = hasDrilldown
    ? formDataProps.fields.rows[0]
    : formDataProps.fields.rows;
  const columns = lodashConcat(formDataProps.fields.values, rows);
  const widthByFieldValue = calculatePivotColumnWidth({
    metrics,
    dashboardColumnWidth,
    columns,
    containerWidth,
    distinctColCount,
  });

  const calculateSumOfColumnWidth = (
    dashboardColumnWidth,
    chartData,
    pivotColumns,
    formDataProps,
    rows,
  ) => {
    let sumOfColumnWidth = 0;
    let totalCols = 0;

    if (pivotColumns?.value && pivotColumns?.value.length === 1) {
      const distinctValues = chartData.map(
        (item) => item[formDataProps.fields.columns[0]],
      );
      const prettyNamesCountMap = lodashCountBy(distinctValues);

      formDataProps.fields.values.forEach((prettyName) => {
        if (dashboardColumnWidth && dashboardColumnWidth[prettyName]) {
          sumOfColumnWidth +=
            dashboardColumnWidth[prettyName] *
            Object.keys(prettyNamesCountMap).length;
        }
      });

      rows.forEach((row) => {
        sumOfColumnWidth += dashboardColumnWidth[row];
      });

      totalCols =
        Object.keys(prettyNamesCountMap).length *
          formDataProps.fields.values.length +
          rows.length ?? 0;
    } else if (pivotColumns?.value && pivotColumns?.value.length > 1) {
      const distinctValues = formDataProps.fields.columns.map((item) => item);
      const prettyNamesCountMap =
        lodashCountBy(chartData, (item) =>
          distinctValues.map((key) => item[key]).join('_'),
        ) || {};

      formDataProps.fields.values.forEach((prettyName) => {
        if (dashboardColumnWidth && dashboardColumnWidth[prettyName]) {
          sumOfColumnWidth +=
            dashboardColumnWidth[prettyName] *
            Object.keys(prettyNamesCountMap).length;
        }
      });

      rows.forEach((row) => {
        sumOfColumnWidth += dashboardColumnWidth[row];
      });

      totalCols =
        Object.keys(prettyNamesCountMap).length *
          formDataProps.fields.values.length +
          rows.length || 0;
    }

    return { sumOfColumnWidth, totalCols };
  };

  const adjustColumnWidths = (sumOfColumnWidth, totalCols) => {
    if (containerWidth > sumOfColumnWidth && sumOfColumnWidth > 0) {
      const remainingWidth = containerWidth - sumOfColumnWidth;
      const inc = remainingWidth / totalCols;

      rows.forEach((row) => {
        dashboardColumnWidth[row] += inc;
      });
      formDataProps.fields.values.forEach((prettyName) => {
        if (dashboardColumnWidth && dashboardColumnWidth[prettyName]) {
          dashboardColumnWidth[prettyName] += inc;
        }
      });
    }
  };

  // to maintain auto expand
  if (dashboardColumnWidth) {
    const pivotColumn = chartOptions.find(
      (chartOption) => chartOption.option === 'column',
    );
    const pivotColumns = pivotColumn
      ? JSON.parse(pivotColumn.data || '{}')
      : null;
    const { sumOfColumnWidth, totalCols } = calculateSumOfColumnWidth(
      dashboardColumnWidth,
      chartData,
      pivotColumns,
      formDataProps,
      rows,
    );
    adjustColumnWidths(sumOfColumnWidth, totalCols);
  }
  useEffect(() => {
    if (!chartsFromDashboard) {
      columns.map((column) => {
        const size = Math.floor(containerWidth / (distinctColCount|| 1));
        const matchingKey = Object.keys(metrics).find(
          (key) => metrics[key].aliasName === column,
        );
        if (matchingKey && !metrics[matchingKey].savedMetric) {
          metrics[matchingKey].columnWidth = Math.max(size, 100);
        }
      });
      if (containerWidth) {
        //this condition is used as if we don't have a container Width then width of table could be NaN
        dispatch({
          type: SET_COLUMN_WIDTH_METRICES,
          payload: metrics,
        });
      }
    }
  }, [containerWidth, formDataProps]);

  useEffect(() => {
    const pivotColumns = reportChartOptions?.rowColumns?.column ?? {};
    const pivotRows = reportChartOptions?.rowColumns?.row ?? {};
    if (Object.keys(pivotColumns).length && pivotColumns.data.length) {
      if (pivotColumns.data.length === 1) {
        const distinctValues = chartData.map(
          (item) => item[formDataProps.fields.columns[0]],
        );
        const prettyNamesCountMap = lodashCountBy(distinctValues);
        setDistinctColCount(
          Object.keys(prettyNamesCountMap).length *
            formDataProps.fields.values.length +
            reportChartOptions?.rowColumns?.row?.data?.length ?? 0,
        );
      } else {
        const distinctValues = formDataProps.fields.columns.map((item) => item);
        const prettyNamesCountMap =
          lodashCountBy(chartData, (item) =>
            distinctValues.map((key) => item[key]).join('_'),
          ) || {};
        setDistinctColCount(
          Object.keys(prettyNamesCountMap).length *
            formDataProps.fields.values.length +
            reportChartOptions?.rowColumns?.row?.data?.length ?? 0,
        );
      }
    } else if (Object.keys(pivotRows).length && pivotRows.data.length) {
      setDistinctColCount(
        pivotRows.data.length + (formDataProps.fields.values.length ?? 0),
      );
    }
  }, [reportChartOptions]);

  const getQueryValueInIsoFormat = (val, colName) => {
    let metaData:any = {};
    visualizeMetaData?.summarizeData.forEach((metric) => {
      if (metric.name === colName) {
        metaData = {
          bucketValue: metric.bucketValue,
          type: metric.type,
        };
      }
    });

    if (metaData && metaData?.type === 'text') {
      // summarise by field is a hub field
      return val;
    }

    // summarize by field is a timestamp field
    switch (metaData?.bucketValue) {
      case 'minute':
      case 'hour':
      case 'day':
      case 'week':
        return moment.utc(val, 'D MMM YYYY HH:mm').toISOString();
      case 'month':
        return moment.utc(val, 'MMM YYYY').startOf('month').toISOString();
      case 'quarter':
        return moment.utc(val, 'MMM YYYY').startOf('quarter').toISOString();
      case 'year':
        return moment.utc(val, 'MMM YYYY').startOf('year').toISOString();
      default:
        return val;
    }
  };

  const showAggregate = JSON.parse(
    chartOptions.find((option) => option.option === 'showAggregate')?.data ||
      '{}',
  ).value;
  const { aggregateData = [] } = visualizeMetaData;

  const s2Options = {
    frozenColCount: freezecols,
    tooltip: {
      showTooltip: configData?.graphic?.tooltip?.data,
    },
    pagination: drilldownMetric
      ? {
          pageSize: 10000,
          current: 1,
        }
      : {
          pageSize: dashboardPageSize || pageSize || 10,
          current: 1,
        },
    hierarchyType: drilldownMetric ? 'tree' : 'grid',
    ...(formatting?.length && {
      conditions: {
        background: getFill('backgroundColor'),
        text: getFill('textColor'),
      },
    }),
    style: {
      rowExpandDepth: -1,
      colCfg: {
        widthByFieldValue: containerWidth ? widthByFieldValue : null,
      },
      rowCfg: {
        widthByField: containerWidth ? widthByFieldValue : null,
        treeRowsWidth: drilldownWidthValue,
      },
    },
    totals: {
      row: {
        showSubTotals: true,
      },
    },
    cornerText: fields?.rows?.[0],
    dataCell: (viewMeta) => {
      return new CustomDataCell({ ...viewMeta, configData }, viewMeta?.spreadsheet);
    },
  };

  if (showAggregate && aggregateData.length) {
    s2Options.totals = {
      row: {
        showGrandTotals: true,
        reverseLayout: true,
        calcTotals: {
          // Returning pre calculated aggregate data by column values
          calcFunc: (query) => {
            const pivotCols = fields?.columns;

            const value = aggregateData.find((data) => {
              return pivotCols.every(
                (pivotCol) => {
                  const queryValue = getQueryValueInIsoFormat(query[pivotCol], pivotCol);
                  return String(queryValue) === String(data[pivotCol]);
                },
              );
            });

            return value ? value[query.$$extra$$] : null;
          },
        },
      },
    };
  }

  const getCurrentDrillDownData = (viewMeta) => {
    const {
      name,
      drilldown,
      bucketValue: initialBucketValue,
    } = drilldownMetric || {};
    if (name) {
      const level = viewMeta.rowId.split('[&]').length - 2;
      if (level <= 0) {
        return {
          currentDrillDownPrettyName: name,
          currentBucketValue: initialBucketValue,
        };
      }
      if (drilldown.type === 'timestamp') {
        const currentBucketValue = getCurrentBucketValue(
          initialBucketValue,
          level + 1,
        );
        return {
          currentDrillDownPrettyName: `${name} (${currentBucketValue})`,
          currentBucketValue,
        };
      }
      return { currentDrillDownPrettyName: `${name} (Child ${level})` };
    }
    return {};
  };

  const onDataCellClick = async (...args) => {
    const { x, y } = args[0].event;
    chartInteractionHook.setPopOverPosition({ x, y });
    const rawData = args[0].viewMeta.data;
    const name = args[0].viewMeta.valueField;
    const { currentDrillDownPrettyName, currentBucketValue } =
      getCurrentDrillDownData(args[0].viewMeta);
    const drilldownData = {
      currentDrillDownPrettyName,
      ...(drilldownMetric || {}),
      currentBucketValue,
    };
    const columnData = { rawData, name, drilldownData };
    const showChildReport = visualizeMetaData?.columnsData[name]?.linkedReport
      ?.reportId
      ? true
      : false;
    const urlPath = visualizeMetaData?.summarizeData?.find(item => item.name === name)?.urlPath ||
      visualizeMetaData?.columnsData[name]?.urlPath;
    const showViewDetails = !!urlPath;
    await chartInteractionHook.onChartColumnClick({
      showChildReport,
      showUnderlyingDataDownload,
      showViewDetails,
      reportData: { visualizeMetaData, columnData },
      reportId,
      enableDummyDataSwitch,
    });
    dispatch({
      type: SET_FIELD_VALUE,
      payload: args[0].viewMeta.fieldValue,
    });
  };

  const handleLayoutPagination = (paginationInfo: { pageSize: number }) => {
    if (!chartsFromDashboard && paginationInfo.pageSize !== pageSize) {
      dispatch({
        type: SET_TABLE_PAGE_SIZE,
        payload: paginationInfo.pageSize,
      });
    }
  };

  const palette = {
    brandColor: '#ffffff',
    semanticColors: {},
    others: {},
    basicColors: getColor(configData?.graphic?.color?.data, tableColor),
  };

  const submissionData = useSubmissionData();

  const onFetchDrillDownDataCallback = ({ getReportDrilldownData }) => {
    const drilldownMetric = visualizeMetaData.summarizeData.find(
      ({ drilldown }) => drilldown?.toApply === true,
    );
    const { chartData: nextChartData, nextLevelFieldName } =
      getReportDrilldownData;
    setTotalData([...totalData, ...JSON.parse(nextChartData)]);
    if (nextLevelFieldName) {
      const cloneFields = lodashCloneDeep(fields);
      if (!cloneFields.rows.includes(nextLevelFieldName)) {
        cloneFields.rows.push(nextLevelFieldName);
      }
      const cloneFormData = lodashCloneDeep(formData);
      cloneFormData.meta.push({
        field: nextLevelFieldName,
        name: drilldownMetric.name,
      });
      cloneFormData.fields = cloneFields;
      setFields(cloneFields);
      setFormData(cloneFormData);
    }
    setLoadingDrillDown(false);
  };

  const [getReportDrilldownData] = useLazyQuery(GET_REPORT_DRILLDOWN_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted: onFetchDrillDownDataCallback,
  });

  const onDrillDownClick = async (args) => {
    const { meta } = args;
    const { id, query, value } = meta;
    if (!drilldownkeys.has(id)) {
      setLoadingDrillDown(true);
      const level = meta.level + 1;
      // for timestamp category
      let data;
      if (drilldownMetric.drilldown.type === 'timestamp') {
        const currentBucketValue = getCurrentBucketValue(
          drilldownMetric.bucketValue,
          level,
        );
        if (!currentBucketValue) {
          setDrilldownkeys(new Set([...drilldownkeys, id]));
          setLoadingDrillDown(false);
          return;
        }
        data = {
          value: getToFromTimeStringObject(currentBucketValue, value),
        };
      } else {
        data = {
          value: [value],
        };
      }
      const drilldownData = {
        metric: drilldownMetric.metric,
        data: JSON.stringify(data),
        field: drilldownMetric.name,
        type: drilldownMetric.drilldown.type,
      };
      let reportData;
      if (!reportId) {
        const reportSubmissionData = submissionData.getSubmissionVariables();
        reportData = lodashPick(reportSubmissionData.variables, [
          'reportType',
          'customAggregateMetrices',
          'customMetrices',
          'metrices',
          'filters',
          'chartType',
          'chartOptions',
        ]);
      }
      let globalFilters = [];
      if (dashboardGroupMetaData?.dashboardGroupId) {
        const filters = lodashValues(
          dashboardFilter[dashboardGroupMetaData.currentDashboardGroupTab],
        );
        const filtersWithUnselectedValues = getfilterWithUnSelectedValues(
          filters,
          supportedFilters,
        ) as Array<any>;
        const filterWithSelectedValue = filters.filter(
          (filter) => !filtersWithUnselectedValues.includes(filter.metric),
        );
        const refinedFilterWithSelectedValue = filterWithSelectedValue.filter(
          transformFilterWithSelectedValue,
        );
        globalFilters = transformFilters(refinedFilterWithSelectedValue);
      }
      await getReportDrilldownData({
        variables: {
          parentData: JSON.stringify(query),
          drilldownData,
          level,
          initialBucketValue: drilldownMetric?.bucketValue || undefined,
          reportId,
          reportData,
          isDummyDataRequest: enableDummyDataSwitch,
          globalFilters,
        },
      });
      setDrilldownkeys(new Set([...drilldownkeys, id]));
    }
  };

  const handleColWidthResize = (props, type) => {
    if (!chartsFromDashboard) {
      const columnSizeObj =
        type === 'column'
          ? props.style.colCfg.widthByFieldValue
          : type === 'row'
          ? props.style.rowCfg.widthByField
          : props.style.rowCfg;
      const [key] = Object.keys(columnSizeObj);
      widthByFieldValue[key] = columnSizeObj[key];
      Object.keys(metrics).forEach((key) => {
        const aliasName = metrics[key]['aliasName'];
        if (widthByFieldValue.hasOwnProperty(aliasName)) {
          metrics[key].columnWidth = widthByFieldValue[aliasName];
        }
        if (type === 'drilldown' && metrics[key]['drilldown']) {
          metrics[key].columnWidth = props.style.rowCfg.treeRowsWidth;
        }
      });
      dispatch({
        type: SET_COLUMN_WIDTH_METRICES,
        payload: metrics,
      });
    }
  };

  return (
    <SheetComponent
      onDataCellClick={onDataCellClick}
      dataCfg={s2DataConfig}
      showPagination={drilldownMetric ? false : showPagination}
      options={s2Options}
      themeCfg={{ palette }}
      loading={loadingDrillDown}
      onLayoutResizeColWidth={(props) => handleColWidthResize(props, 'column')}
      onLayoutResizeRowWidth={(props) => handleColWidthResize(props, 'row')}
      onLayoutResizeTreeWidth={(props) =>
        handleColWidthResize(props, 'drilldown')
      }
      onLayoutAfterCollapseRows={onDrillDownClick}
      onLayoutPagination={handleLayoutPagination}
      adaptive={{
        width: true,
        height: true,
        getContainer: () => chartRef?.current,
      }}
    />
  );
};
const areEqual = (prevProps, nextProps) => {
  if (
    lodashIsEqual(prevProps.formData, nextProps.formData) &&
    lodashIsEqual(prevProps.totalData, nextProps.totalData) &&
    lodashIsEqual(prevProps.chartData, nextProps.chartData) &&
    lodashIsEqual(prevProps.fields, nextProps.fields) &&
    lodashIsEqual(prevProps.containerWidth, nextProps.containerWidth)
  ) {
    return true; // do not re-render
  }
  return false; // will re-render
};
export default memo(PivotTable, areEqual);
