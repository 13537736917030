import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { message, notification } from 'antd';
import { DOWNLOADREPORT, DOWNLOAD_UNDERLYING_DATA_REPORT } from '../visualisationPaneCompponents/graphqlQuery';
import { useSubmissionData } from './dataSubmissionHook';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_DOWNLOAD_MODAL_STATE } from 'src/reduxActions/actionNameEnums';
import { dbTypes } from 'src/constants';
import { isManualQueryReport } from 'src/components/common/utils/utils';

const canRestrictDownload = (downloadRowLimitConfig, columnsLength) => {
  for (const configObject of downloadRowLimitConfig) {
    const { minCols, maxCols, rows } = configObject;

    if (((minCols <= columnsLength && maxCols >= columnsLength) || (minCols <= columnsLength && !maxCols)) && rows === 0) {
      message.error({ key: 'downloadReport', content: `Not allowed to download more than ${minCols-1} columns` });
      return true;
    }
  }

  return false;
};

const useDownloadReport = () => {
    const downloadRowLimitConfig = useSelector((state:any) => state?.masterData?.organisationConfig?.downloadRowLimitConfig);
    const enableRedshiftDump = useSelector((state:any) => state?.masterData?.organisationConfig?.enableRedshiftDump);
    const selectedReportType = useSelector((state: any) => state.reportMetaData.selectedReport);
    const selectedDbType = useSelector((state: any) => state.reportConfig?.reports[selectedReportType]?.dbType);
    const applyDownloadLimit = selectedDbType === dbTypes.REDSHIFT ? !enableRedshiftDump : true;
    const dataHook = useSubmissionData();
    const dispatch = useDispatch();
    const selectedDataMartType = useSelector((state: any) => state.reportMetaData.dataMartType);
    const [submitDownloadReport, { loading: downloadInProgress, data }] = useLazyQuery(DOWNLOADREPORT, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            notification['success']({
                message: 'Download Request Received',
                description:
                  <div style={{ cursor: 'pointer', color: '#006EC3'}}>
                      {'Click here to see the status of your download request.'}
                      </div>,
                onClick: () => {
                    dispatch({
                        type: CHANGE_DOWNLOAD_MODAL_STATE,
                        payload: true,
                    });
                },
              });
        },
        onError: data => {
            message.error({key:'downloadReport', content: 'Failed to Submit Download Requests'});
        },
    });

    const onClickDownload = () => {
        let data;
        if ( isManualQueryReport(selectedDataMartType)) {
          data = dataHook.getCustomReportSubmissionVariables();
        } else {
          data = dataHook.getSubmissionVariables();
        }
        const { metrices=[], customAggregateMetrices=[] } = data.variables;
        if (applyDownloadLimit && Array.isArray(downloadRowLimitConfig) && downloadRowLimitConfig.length) {
          const columnsLength = [...metrices, ...customAggregateMetrices].length;

          if (canRestrictDownload(downloadRowLimitConfig, columnsLength)) {
            return;
          }
        }
        if(data.validationPassed){
            const variables = {
                reportType: data.variables.reportType,
                metrices: data.variables.metrices,
                filters: data.variables.filters,
                havingFilters: data.variables.havingFilters,
                customMetrices: data.variables.customMetrices,
                customAggregateMetrices: data.variables.customAggregateMetrices,
                name: data.variables.name,
                dataMartType: data.variables.dataMartType,
                isAdminReport: data.variables.isAdminReport,
                queryString: data.variables.queryString,
                mongoCollectionName: data.variables.mongoCollectionName,
            };
            message.loading({key:'downloadReport', content: 'Submitting report Download Request'});
            submitDownloadReport({variables});
        }
    };

    const [
      submitUnderlyingDownloadReport,
      { loading: underlyingReportDownloadInProgress },
    ] = useLazyQuery(DOWNLOAD_UNDERLYING_DATA_REPORT, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        notification['success']({
          message: 'Download Request Received',
          description: (
            <div style={{ cursor: 'pointer', color: '#006EC3' }}>
              {'Click here to see the status of your download request.'}
            </div>
          ),
          onClick: () => {
            dispatch({
              type: CHANGE_DOWNLOAD_MODAL_STATE,
              payload: true,
            });
          },
        });
      },
      onError: (data) => {
        message.error({
          key: 'downloadUnderlyingReport',
          content: 'Failed to Submit Download Requests',
        });
      },
    });

  const onClickUnderlyingDataDownload = (appliedFilters = []) => {
      let data;
      if (isManualQueryReport(selectedDataMartType)) {
        data = dataHook.getCustomReportSubmissionVariables();
      } else {
        data = dataHook.getSubmissionVariables();
      }
      const { variables, validationPassed } = data;
      const {
        reportType,
        metrices = [],
        filters = [],
        customMetrices = [],
        name,
        underlyingMetrics = [],
        underlyingQueryString,
        dataMartType,
      } = variables;

      if (applyDownloadLimit && Array.isArray(downloadRowLimitConfig) && downloadRowLimitConfig.length) {
        const columnsLength = underlyingMetrics.length;

        if (canRestrictDownload(downloadRowLimitConfig, columnsLength)) {
          return;
        }
      }

      if (validationPassed) {
        const variables = {
          reportType,
          metrices,
          filters: appliedFilters.length ? appliedFilters : filters,
          customMetrices,
          name,
          underlyingMetrics,
          underlyingQueryString,
          dataMartType,
        };
        message.loading({
          key: 'downloadUnderlyingReport',
          content: 'Submitting report Download Request',
        });
        submitUnderlyingDownloadReport({ variables });
      }
    };

    return {
      onClickDownload,
      downloadInProgress,
      onClickUnderlyingDataDownload,
      underlyingReportDownloadInProgress,
    };
};
export default useDownloadReport;
