import React, { useEffect, useState } from 'react';
import { DELETEREPORT } from '../reportsQuery';
import { useLazyQuery } from '@apollo/client';
import lodashDebounce from 'lodash/debounce';
import { useReports } from 'src/components/common/hooks/useReports';
import { message } from 'antd';

export const useReportTable = (reportType: string | undefined = undefined) => {
    const [tableData, setTableData] = useState([]);
    const [reportCount,setReportCount] = useState(0);
    const onReportFetchCompleted = (data) => {
        setTableData(data.reports);
        setReportCount(data.reportCount);
    };

    const {
        getReports,
        loadingReports,
        data,
        offSet,
        setOffSet,
        type,
        setType,
        sortOrder,
        setSortOrder,
        setDateRange,
        dateRange,
        empDetailsArray,
        setCreatedBy,
        createdBy,
        searchText,
        setSearch,
        tab,
        setTab,
        sortByKey,
        setSortByKey,
        fetchReports,
        setEmpDetailsArray,
    } = useReports({ reportType, onCompletedCallBack:onReportFetchCompleted});
    const [deleteReport, { loading:deleteLoading }] = useLazyQuery(DELETEREPORT, {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        message.success({ key: 'deleteReport', content: 'Successfully Deleted' }),
        fetchReports();
      },
    });

    const onReportDelete = (id) => deleteReport({variables:{id}});
    const onChangeSearch = (event) => setSearch(event.target.value);

    return {
        tableData,
        onReportDelete,
        loadingReports: deleteLoading || loadingReports,
        getReports,
        onChangeSearch,
        offSet,
        setOffSet,
        reportCount,
        setType,
        setSortOrder,
        setDateRange,
        empDetailsArray,
        setCreatedBy,
        searchText,
        setSearch,
        createdBy,
        dateRange,
        tab,
        setTab,
        setSortByKey,
        fetchReports,
        setEmpDetailsArray,
    };
};
