import { DatePicker } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
    select: {
        width: '50%',
    },

});

const DateInput = (props) => {
    const classes = useStyle();
    const { metricsName, onChange, value, boolShowTime = false, dateFormat = 'YYYY-MM-DD', style, defaultValue, onReset } = props;
    useEffect(() => {
        if(defaultValue && !value){
            onChange(defaultValue);
        }
    },[onReset,defaultValue]);
    const date = value?.value ? moment(value.value): null;
    return <DatePicker
            style = {style}
            format = {dateFormat}
            showTime = {boolShowTime}
            value = {date}
            onChange={(date,dateString) => onChange(dateString)}
           />;
};

export default DateInput;
