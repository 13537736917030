import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { getPermissionType } from '../componentHelper';
import StatusDisplay from '../pagesv2/StatusDisplay';
import { ReactComponent as NotFoundImage } from 'src/assets/Unauthorized.svg';
import { Link } from 'react-router-dom';
import Layout from 'src/components/common/Layout';

const ProtectedRoute = (props) => {
  const { excludedPermissions = [], allowMobile } = props;
  const userType = useSelector(
    (state: any) => state?.masterData?.userConfig?.typeOfUser,
  );
  const isTabletOrMobile = useSelector(
    (state: any) => state.config.isTabletOrMobile,
  );
  const permissionType = getPermissionType(userType);

  if (excludedPermissions.includes(permissionType)) {
    return (
      <StatusDisplay
        image={<NotFoundImage />}
        title={'Sorry, you are not authorised to access this page.'}
        subTitle={
          <>
            Try starting from the{' '}
            <Link to="/dashboard" rel="noopener noreferrer">
              dashboard homepage
            </Link>
          </>
        }
      />
    );
  }

  if (!allowMobile && isTabletOrMobile) {
    return (
      <StatusDisplay
        image={<NotFoundImage />}
        title={'This page is not available!'}
        subTitle={
          <>
            Try starting from the{' '}
            <Link to="/" rel="noopener noreferrer">
              homepage
            </Link>
          </>
        }
      />
    );
  }

  return (
    <Layout>
      <Route {...props} />
    </Layout>
  );
};

export default ProtectedRoute;
