import lodashCloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';

import {
    AUTOMATION_STATUS_CHANGED,
    AUTOMATION_ACTION_SELECTED,
    AUTOMATION_ADDITIONAL_FIELDS_CHANGED,
    AUTOMATION_INITIALISE,
    AUTOMATION_SCHEDULE_CHANGED,
    RESET_AUTOMATION_DATA,
} from '../reduxActions/actionNameEnums';
import { frequencyTypes, intervals } from 'src/constants';

const initialState = {
    frequency: frequencyTypes.DAY,
    frequencyData: {},
    selectedInterval: intervals.DAILY,
    repeat: 1,
    startTimestamp: moment().add(1, 'day').add(1, 'hour'),
    isEnabled: false,
    fromToRange: {},
    automation: {},
};

const automationReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const clonedState = lodashCloneDeep(state);

    switch (type) {
        case AUTOMATION_STATUS_CHANGED:
            return {
                ...clonedState,
                isEnabled: payload,
            };

        case AUTOMATION_ACTION_SELECTED:
            return {
                ...clonedState,
                automation: {
                    ...clonedState.automation,
                    action: payload,
                },
            };

        case AUTOMATION_ADDITIONAL_FIELDS_CHANGED:
            return {
                ...clonedState,
                automation: {
                    ...clonedState.automation,
                    additionalFields: payload,
                },
            };

        case AUTOMATION_INITIALISE:
            const requiredPayload: any = {
                frequency: payload.frequency || frequencyTypes.DAY,
                frequencyData: payload.frequencyData || {},
                selectedInterval: payload.selectedInterval || intervals.DAILY,
                repeat: payload.repeat || 1,
                startTimestamp: payload.startTimestamp ? moment(payload.startTimestamp) : moment().add(1, 'day').add(1, 'hour'),
                isEnabled: payload.isEnabled || false,
                fromToRange: payload.fromToRange || {},
                automation: payload.automation || {},
                customInterval: payload.customInterval || null,
            };

            if (payload._id) {
                requiredPayload._id = payload._id;
            }

            return {
                ...clonedState,
                ...requiredPayload,
            };

        case AUTOMATION_SCHEDULE_CHANGED:
            return {
                ...clonedState,
                ...payload,
            };

        case RESET_AUTOMATION_DATA:
            return initialState;

        default:
            return clonedState;
    }
};

export default automationReducer;
