import React from 'react';
import { PieChartOutlined } from '@ant-design/icons';
import { DatePicker, Input, Radio, Select, Tag } from 'antd';
import { createUseStyles } from 'react-jss';
import { iconMap } from 'src/constants';
import LabelWrapper from 'src/components/common/LabelWrapper';
import moment from 'moment';
import LoadingComponent from 'src/components/common/LoadingComponent';
import lodashCapitalize from 'lodash/capitalize';

const useStyle = createUseStyles({
  tableHeader: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '10px',
    lineHeight: '22px',
    alignItems: 'center',
  },
  tableRows: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '22px',
    color: '#1890FF',
  },
  detailsFont: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '14px',
  },
  reportsContainer: {
    margin: '16px 0',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    padding: '4px',
  },
  reportsListContainer: {
    height: '300px',
    overflow: 'auto',
    width: '100%',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeaderContainer: {
    height: '32px',
    width: '100%',
    backgroundColor: '#FAFAFA',
    display: 'flex',
  },
  reportTableRowContainer: {
    height: '40px',
    width: '100%',
    display: 'flex',
  },
  reportDetailsContainer: {
    width: '25%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
});

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

export const ReportTableHeader = () => {
  const classes = useStyle();
  return (
    <div className={classes.tableHeaderContainer}>
      <div
        className={`${classes.tableHeader} ${classes.flexCenter}`}
        style={{
          width: '40%',
          paddingLeft: '5px',
        }}
      >
        TITLE
      </div>
      <div
        className={`${classes.tableHeader} ${classes.flexCenter}`}
        style={{ width: '20%' }}
      >
        TYPE
      </div>
      <div
        className={`${classes.tableHeader} ${classes.flexCenter}`}
        style={{ width: '15%' }}
      >
        DASHBOARD
      </div>
      <div
        className={`${classes.tableHeader} ${classes.flexCenter}`}
        style={{ width: '25%' }}
      >
        DETAILS
      </div>
    </div>
  );
};

export const ReportTableRow = (props) => {
  const classes = useStyle();
  const { title, name, date, chartType, record, type, numDashboard } = props;
  const ChartIcon = iconMap[chartType] || PieChartOutlined;
  return (
    <>
      <div className={classes.reportTableRowContainer}>
        <div className={classes.flexCenter} style={{ width: '5%' }}>
          <Radio value={record} />
        </div>
        <div className={classes.flexCenter} style={{ width: '35%' }}>
          <div className={classes.tableRows} style={{ cursor: 'pointer' }}>
            {title}
          </div>
          <ChartIcon style={{ color: '#8C8C8C' }} />
        </div>
        <div className={classes.flexCenter} style={{ width: '20%' }}>
          <Tag color="success">{type}</Tag>
        </div>
        <div
          className={`${classes.tableHeader} ${classes.flexCenter}`}
          style={{ width: '15%' }}
        >
          {numDashboard}
        </div>
        <div className={`${classes.tableHeader} ${classes.reportDetailsContainer}`}>
          <div
            className={classes.detailsFont}
            style={{ color: 'rgba(0, 0, 0, 0.85)' }}
          >
            {name}
          </div>
          <div className={classes.detailsFont} style={{ color: '#8C8C8C' }}>
            {date}
          </div>
        </div>
      </div>
    </>
  );
};

export const ReportTableFilters = (props) => {
  const { tableData, search, handleTypeChange, type } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Search
        value={search}
        loading={tableData.loadingReports}
        onChange={tableData.onChangeSearch}
        style={{ width: '200px' }}
        placeholder={'Search Reports'}
      />
      <LabelWrapper title="Created By">
        <Select
          value={tableData.createdBy}
          maxTagCount={0}
          style={{ width: '142px' }}
          mode="multiple"
          placeholder="Select User"
          onChange={tableData.setCreatedBy}
        >
          {tableData?.empDetailsArray.map((option) => (
            <Option value={option.empId}>{option.name}</Option>
          ))}
        </Select>
      </LabelWrapper>
      <LabelWrapper title="Updated Date">
        <RangePicker
          value={tableData.dateRange}
          onChange={tableData.setDateRange}
        />
      </LabelWrapper>
      <LabelWrapper title="Type">
        <Select value={type} style={{ width: 120 }} onChange={handleTypeChange}>
          <Option value="default">Default</Option>
          <Option value="user">User</Option>
          <Option value="shared">Shared</Option>
          <Option value="all">All</Option>
        </Select>
      </LabelWrapper>
    </div>
  );
};

export const ReportsTable = (props) => {
  const {
    tableData,
    setLinkedReport,
    reports,
    setSelectedReportId,
    selectedReportId,
  } = props;
  const classes = useStyle();
  return (
    <div className={classes.reportsContainer}>
      <ReportTableHeader />
      <div className={classes.reportsListContainer}>
        {tableData.loadingReports ? (
          <LoadingComponent style={{ fontSize: '40px' }} />
        ) : (
          <Radio.Group
            style={{ width: '100%' }}
            onChange={(e) => {
              setSelectedReportId(e.target.value);
              const report = reports.find(
                (report) => report._id === e.target.value,
              );
              setLinkedReport({
                reportId: report?._id,
                reportName: report?.name,
              });
            }}
            value={selectedReportId}
          >
            {reports.map((item) => {
              const time = moment(item.updationTime).format(
                'D MMM YYYY, hh:mm A',
              );
              return (
                <ReportTableRow
                  numDashboard={item.numDashboard}
                  type={item.type}
                  record={item._id}
                  title={item.name}
                  chartType={item.chartType}
                  name={item.createdByName}
                  date={time}
                  category={lodashCapitalize(item.category || '')}
                />
              );
            })}
          </Radio.Group>
        )}
      </div>
    </div>
  );
};
