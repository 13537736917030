import { SELECTED_REPORT_CHANGED, SET_IS_AGGREGATED, INITIALISE_REPORT_NAME, INITILISE_REPORT_DESCRIPTION,
    RESET_REPORT_NAME_AND_DESCRIPTION_AND_ID,SUGGESTED_FILTER,
    INITIALISE_REPORT_ID,COLUMN_NAME,
    SELECTED_DBTYPE_CHANGED,
    SET_OBJECT,
    INITIALISE_OBJECT,
    RESET_OBJECT,
    INITIALISE_REPORT_CATEGORY,
    UPDATE_REPORT_NAME,
    INITILISE_METRIC_TYPE,
    DUMMY_DATA_SWITCH,
    CUSTOM_QUERY_STRING,
    UNDERLYING_CUSTOM_QUERY_STRING,
    CHANGE_METRIC_STRING_MANUAL_QUERY,
    CHANGE_MONGO_COLLECTION_NAME,
    ADMIN_REPORT_SWITCH,
    SELECTED_DATA_MART_TYPE_CHANGED,
} from '../reduxActions/actionNameEnums';

const initialState = {
    isAggregated: false,
    reportName: '',
    reportDescription: '',
    metricType: '',
    queryString: '',
    underlyingQueryString: '',
    metricString: '',
    isAdminReport: false,
};

const reportReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type){
        case SELECTED_REPORT_CHANGED:
            return {
                ...state,
                selectedReport: payload,
            };
        case SELECTED_DBTYPE_CHANGED:
            return {
                ...state,
                selectedDbType: payload,
            };
        case SET_IS_AGGREGATED:
            return {
                ...state,
                isAggregated: payload,
            };
        case INITILISE_REPORT_DESCRIPTION:
            return {
                ...state,
                reportDescription: payload,
            };
        case INITIALISE_REPORT_NAME:
            return {
                ...state,
                reportName: payload,
            };
        case UPDATE_REPORT_NAME:
            return {
                ...state,
                reportName: payload,
            };
        case INITIALISE_REPORT_ID:
            return {
                ...state,
                reportID: payload,
            };
        case RESET_REPORT_NAME_AND_DESCRIPTION_AND_ID:
            return {
                ...state,
                reportID: '',
                reportName: '',
                reportDescription: '',
            };
        case SUGGESTED_FILTER:
            return {
                ...state,
                suggestedFilterStrings: payload,
            };
        case SET_OBJECT:
            return {
                ...state,
                object: {
                    ...state['object'],
                    ...payload,
                },
            };
        case RESET_OBJECT:
            return {
                ...state,
                object: {},
            };
        case INITIALISE_OBJECT:
            return {
                ...state,
                object: payload,
            };
        case COLUMN_NAME:
            return {
                ...state,
                columnName: payload,
            };
        case INITIALISE_REPORT_CATEGORY:
            return {
                ...state,
                reportCategory: payload,
            };
        case INITILISE_METRIC_TYPE:
            return {
                ...state,
                metricType: payload,
            };
        case DUMMY_DATA_SWITCH:
            return {
                ...state,
                dummyDataSwitch: payload.enableDummyData,
            };
        case CUSTOM_QUERY_STRING:
            return {
                ...state,
                queryString: payload.queryString,
            };
        case UNDERLYING_CUSTOM_QUERY_STRING:
            return {
                ...state,
                underlyingQueryString: payload.queryString,
            };
        case CHANGE_METRIC_STRING_MANUAL_QUERY:
            return {
                ...state,
                metricString: payload.metricString,
            };
        case ADMIN_REPORT_SWITCH:
            return {
                ...state,
                isAdminReport: payload.isAdminReport,
            };
        case SELECTED_DATA_MART_TYPE_CHANGED:
            return {
                ...state,
                dataMartType: payload,
            };
        case CHANGE_MONGO_COLLECTION_NAME:
            return {
                ...state,
                mongoCollectionName: payload,
            };
        default:
            return state;
    }
};

export default reportReducer;
