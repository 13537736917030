import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { SUGGESTED_FILTER } from 'src/reduxActions/actionNameEnums';
import CustomInput from 'src/components/common/customInput';

const SuggestedFilter = (props) => {
    const dispatch = useDispatch();
    const { classes, suggestionFilterVisible, setsuggestionFilterVisible,prettyNameOfMetric,initialSuggestedFilters=[],filterData={}} = props;
    const [enteredStrings, setEnteredStrings] = useState('');
    const [initialStrings, setInitialStrings] = useState('');
    const [finalStrings, setFinalStrings] = useState('');
    const editedFilters= () => {
      setEnteredStrings(finalStrings);
      let words = finalStrings.split('\n');
      if(words.length === 1 && words[0] === ''){
        words =[];
      }
      let foundColumn = false;
      const tempArray = initialSuggestedFilters?.map(x => x);
      initialSuggestedFilters?.forEach((element,index) => {
        if(element.key === prettyNameOfMetric)
        {
          tempArray[index] = {
            key:prettyNameOfMetric,
            value: words,
          };
        foundColumn = true;
        }
      });
      if(!foundColumn){
        tempArray.push({
          key: prettyNameOfMetric,
          value: words,
        });
      }
      setsuggestionFilterVisible(false);
      dispatch({type: SUGGESTED_FILTER, payload: tempArray});
    };
    let finalString='';
    let initialString='';
    const onChange = (newValue) => {
        finalString = newValue;
        setEnteredStrings(finalString);
        setFinalStrings(finalString);
    };
    const reqObj = {
      key:'',
      value:'',
    };
    useEffect(() => {
      if(initialSuggestedFilters?.length === 0){
        initialString='';
      }
      else{
        initialSuggestedFilters?.forEach((element) => {
          if(element.key === prettyNameOfMetric)
          {
          reqObj.key = element.key;
          reqObj.value = element.value;
        }
        });
        if(reqObj.key === '' || reqObj.key === undefined){
          initialString='';
        }
        else{
          for(let i=0; i<reqObj.value.length; i++){
            initialString+=reqObj.value[i]+'\n';
          }
        }
        initialString = initialString.replace(/^\s+|\s+$/g,'');
      }
      setInitialStrings(initialString);
      setEnteredStrings(initialString);
      },[suggestionFilterVisible]);
      return (
        <Modal
          title={'Add Suggested Filters'}
          visible={suggestionFilterVisible}
          onCancel={() => setsuggestionFilterVisible(false)}
          okText={'Save'}
          onOk={() => editedFilters()}
          className={classNames(classes.filterPane)}
        >
          <div>{prettyNameOfMetric}</div>
          <CustomInput
            isTextArea={true}
            textAreaRows={20}
            value={enteredStrings}
            onChange={(e) => onChange(e)}
          />
        </Modal>
      );
};

export default SuggestedFilter;
