import { Select } from 'antd';
import React from 'react';
import CustomSelect from 'src/components/common/UIComponents/CustomSelect';

const ColorPickerSelect = (props) => {
  const {
    selectedOption,
    setSelectedOption,
    options,
    size,
    style,
  } = props;

  const onChange = (value) => {
    setSelectedOption(options.find((opt) => opt.value === value));
  };

  return (
    <CustomSelect
      style={style}
      size={size}
      data={options}
      value={selectedOption.value}
      onChange={onChange}
    />
  );
};

export default ColorPickerSelect;
