import { transpileModule } from 'typescript';
import {
  RECIEVED_PARENT_CREDENTIAL,
  INITIALISE_REPORT_USER_TYPE,
  RESET_REPORT_USER_TYPE,
  LANDING_PAGE_LOADED,
  CHANGE_LEFT_PANE_STATE,
  CHANGE_LEFT_PANE_VISIBILITY,
  CHANGE_DOWNLOAD_MODAL_STATE,
  CHANGE_NAV_TAB,
  CHANGE_SELECTED_DASHBOARD_TYPE,
  CHANGE_SELECTED_REPORT_TYPE,
  CHANGE_LANDING_PAGE_ID,
  SET_USER_ROLES,
  SET_DEVICE_TYPE,
  SET_ANALYTICS_DASHBOARD_CRM,
} from '../reduxActions/actionNameEnums';

const initialState = {
  recievedParentCredential: process.env.REACT_APP_IS_CUSTOM === 'CUSTOM' ? true : false,
  recievedLandingPage: false,
  userTypeOfEditReport: null,
  leftPaneOpen: true,
  leftPaneVisibility: false,
  downloadModalVisibility: false,
  currentSelectedNavTab: ['home'],
  selectedDashboardType: 'all',
  selectedReportType: 'all',
  recievedLandingPageId: '',
  roleOptions: null,
  isTabletOrMobile: false,
  externalFilters: [],
  analyticsTopBar: false,
};

const configReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RECIEVED_PARENT_CREDENTIAL:
      return {
        ...state,
        recievedParentCredential: true,
      };
    case LANDING_PAGE_LOADED:
      return {
        ...state,
        recievedLandingPage: payload,
      };
    case INITIALISE_REPORT_USER_TYPE:
      return {
        ...state,
        userTypeOfEditReport: payload,
      };
    case RESET_REPORT_USER_TYPE:
      return {
        ...state,
        userTypeOfEditReport: null,
      };
    case CHANGE_LEFT_PANE_STATE:
      return {
        ...state,
        leftPaneOpen: payload,
      };
    case CHANGE_LEFT_PANE_VISIBILITY:
      return {
        ...state,
        leftPaneVisibility: payload,
      };
    case CHANGE_DOWNLOAD_MODAL_STATE:
      return {
        ...state,
        downloadModalVisibility: payload,
      };
    case CHANGE_NAV_TAB:
      return {
        ...state,
        currentSelectedNavTab: payload,
      };
    case CHANGE_SELECTED_DASHBOARD_TYPE:
      return {
        ...state,
        selectedDashboardType: payload,
      };
    case CHANGE_SELECTED_REPORT_TYPE:
      return {
        ...state,
        selectedReportType: payload,
      };
    case CHANGE_LANDING_PAGE_ID:
      return {
        ...state,
        recievedLandingPageId: payload,
      };
    case SET_USER_ROLES:
      return {
        ...state,
        roleOptions: payload,
      };
    case SET_DEVICE_TYPE:
      return {
        ...state,
        isTabletOrMobile: payload,
      };
    case SET_ANALYTICS_DASHBOARD_CRM:
      return {
        ...state,
        externalFilters: payload.externalFilters,
        analyticsTopBar: payload.analyticsTopBar,
      };
    default:
      return state;
  }
};

export default configReducer;
