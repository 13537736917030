import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import CustomInput from 'src/components/common/customInput';
import { CHANGE_MONGO_COLLECTION_NAME } from 'src/reduxActions/actionNameEnums';
import SectionPartContainer from 'src/components/common/sectionPartContainer';

const CustomQueryMongoCollectionSection = (props) => {
  const mongoCollectionName = useSelector(
    (state: any) => state.reportMetaData.mongoCollectionName,
  );
  const dispatch = useDispatch();
  const lazyUpdate = () => {
    let mongoCollectionName = '';
    let inside = false;
    return (value) => {
      mongoCollectionName = value;
      if (inside === false) {
        inside = true;
        setTimeout(() => {
          dispatch({
            type: CHANGE_MONGO_COLLECTION_NAME,
            payload: mongoCollectionName,
          });
          inside = false;
        });
      }
    };
  };

  return (
    <SectionPartContainer
      title={'Mongo Collection'}
      onTitleChanges={console.log}
      showAddButton={false}
    >
      <div style={{ marginTop: '16px' }}>
        <CustomInput
          onChange={lazyUpdate()}
          value={mongoCollectionName}
          placeholder={'Please Input Collection Name'}
          width={'100%'}
          highlightActiveLine={false}
        />
      </div>
    </SectionPartContainer>
  );
};

export default memo(CustomQueryMongoCollectionSection);
