import moment from 'moment';

export const startOfToday = moment().startOf('day');
export const endOfToday = moment().endOf('day');
export const startOfYesterday = moment().subtract(1, 'day').startOf('day');
export const endOfYesterday = moment().subtract(1, 'day').endOf('day');
export const startOfWeek = moment().startOf('week');
export const endOfWeek = moment().endOf('week');
export const startOfLastWeek = moment().subtract(1, 'week').startOf('week');
export const endOfLastWeek = moment().subtract(1, 'week').endOf('week');
export const startOfMonth = moment().startOf('month');
export const endOfMonth = moment().endOf('month');
export const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
export const endOfLastMonth = moment().subtract(1, 'month').endOf('month');
export const startOfYear = moment().startOf('year');
export const endOfYear = moment().endOf('year');
export const startOfFinancialYear = moment(startOfToday)
  .subtract(startOfToday.month() < 3 ? 1 : 0, 'years')
  .month(3)
  .startOf('month');
export const endOfFinancialYear = moment(startOfFinancialYear)
  .add(1, 'year')
  .subtract(1, 'day')
  .endOf('day');
