import { gql } from '@apollo/client';

export const GET_DASHBOARD_METADATA = gql`
query GET_DASHBOARD_METADATA( $dashboardId:String! ) {
  getDashboardVisualisations(dashboardId: $dashboardId, globalFilters: $globalFilters) {
    dashboard{
      name
      _id
      description
      type
    }
    reports{
      reportId
      formData
      chartData
      chartType
      position{
        x
        y
        w
        h
      }
      reportName
      reportDateRange
      type
      globalSuggestedStrings{
        key
        value
      }
  }
  }
  getDashboardGlobalFilters(dashboardId: $dashboardId){
    metric
    prettyName
    operation
    filter {
      name
      prettyName
      type
    }
    reportType
    caseInsensitive
  }
}
`;

export const CREATE_DASHBOARD = gql`
query CREATE_DASHBOARD($name: String!, $description: String, $category:String) {
  createDashboard(name: $name, description: $description,category: $category){
    _id
    name
    description
  }
}
`;

export const CREATE_DASHBOARD_FAST_CREATE = gql`
query CREATE_DASHBOARD($name: String!, $dashboardGroupId: String!) {
  createDashboard(name: $name, dashboardGroupId: $dashboardGroupId) {
    _id
    name
    description
  }
}
`;

export const MOVE_DASHBOARD_TO_DASHBOARDGROUP = gql`
query MOVE_DASHBOARD_TO_DASHBOARDGROUP($dashboardGroupId: String!, $dashboardId: String! ) {
  addDashboardToDashboardGroup(
    dashboardGroupId: $dashboardGroupId,
    dashboardId: $dashboardId,
  ) {
    success
    error
  }
}
`;

export const REMOVE_DASHBOARD_FROM_DASHBOARDGROUP = gql`
query REMOVE_DASHBOARD_FROM_DASHBOARDGROUP($dashboardGroupId: String!, $dashboardId: String! ) {
  removeDashboardFromGroupModel(
    dashboardGroupId: $dashboardGroupId,
    dashboardId: $dashboardId,
  ) {
    success
    error
  }
}
`;

export const UPDATE_DASHBOARD = gql`
query UPDATE_DASHBOARD($id:String!,$name: String, $description: String, $category:String) {
  updateDashboard(id: $id, name: $name, description: $description,category: $category){
    _id
    name
    description
  }
}
`;

export const GET_DASHBOARD_BY_NAME = gql`
query GET_DASHBOARD_BY_NAME($searchQuery: String!, $types: [String]!) {
  getDashboardByName(searchQuery: $searchQuery, types: $types) {
    _id
    name
    description
    creationTime
    updationTime
    numReports
    type
    category
  }
  getLandingDashboardPage {
    dashboardGroupId
  }
}
`;

export const SET_DASHBOARD_POSITION = gql`
query SET_DASHBOARD_POSITION($reportPositions: [reportPositionInput]!, $dashboardId: String!) {
  setReportsDashboardPosition(reportPositions: $reportPositions, dashboardId: $dashboardId) {
    success
    error
  }
}
`;

export const DOWNLOAD_REPORT = gql`
  query DOWNLOAD_REPORT(
    $reportId: String!
    $globalFilters: [whereHavingInput]
    $childReportFilters: [whereHavingInput]
    $externalFilters: [externalFilterMetaInput]
  ) {
    executeReport(
      id: $reportId
      globalFilters: $globalFilters
      childReportFilters: $childReportFilters
      externalFilters: $externalFilters
    )
  }
`;

export const REMOVE_DASHBOARD = gql`
query REMOVE_DASHBOARD($reportId: String!, $dashboardId: String!) {
  removeReportFromDashboard(reportId: $reportId, dashboardId: $dashboardId){
    success
    error
  }
}
`;

export const DELETE_DASHBOARD = gql`
query DELETE_DASHBOARD($dashboardId: String!) {
  deleteDashboard(id: $dashboardId){
    _id
    name
    description
  }
}
`;

export const IMPORT_DASHBOARD = gql`
query IMPORT_DASHBOARD($defaultDashboardId: String!, $name: String!, $description: String) {
  importDashboard(defaultDashboardId: $defaultDashboardId, name: $name, description: $description) {
    _id
  }
}
`;

export const GET_REPORT_BY_ID = gql`
  query getReportById($reportId: String!, $fetchAutomationSchedules: Boolean) {
    getReportById(
      id: $reportId
      fetchAutomationSchedules: $fetchAutomationSchedules
    ) {
      _id
      name
      description
      reportType
      customAggregateMetrices {
        metric
        sql
        prettyName
        type
        alias
        sortBy {
          type
          index
        }
        index
        linkedReport {
          reportName
          reportId
          linkedMetrices
        }
        aliasName
        savedMetric
        columnWidth
        dummyData
      }
      customMetrices {
        metric
        sql
        prettyName
        type
      }
      metrices {
        metric
        section
        alias
        operation
        operationPrettyName
        prettyName
        aliasName
        savedMetric
        columnWidth
        dummyData
        linkedReport {
          reportName
          reportId
          linkedMetrices
        }
        groupBy {
          toApply
          bucketValue
        }
        sortBy {
          type
          index
        }
        type
        index
        drilldown {
          type
          toApply
        }
        sql
      }
      underlyingMetrics {
        metric
        alias
        prettyName
        type
        index
      }
      filters {
        metric
        section
        filter {
          name
          prettyName
          data
          type
          isDefaultFilter
          sql
        }
        caseInsensitive
        dashboardFilterType
        filterId
        filterPriority
        overrideOtherFilters
      }
      havingFilters {
        metric
        section
        filter {
          name
          prettyName
          data
          type
          havingOperation
        }
      }
      reportTypePrettyName
      chartType
      chartOptions {
        section
        option
        data
      }
      chartConfig
      type
      tab
      category
      suggestedFilterStrings {
        key
        value
      }
      object {
        name
        field {
          metric
          prettyName
        }
      }
      pageSize
      createdByName
      isFavourite
      creationTime
      updationTime
      numAlerts
      numSchedules
      numDashboard
      formatting
      isAggregate
      metricType
      actions {
        selectedActionId
        additionalFields {
          key
          value
        }
      }
      dataMartType
      isAdminReport
      queryString
      underlyingQueryString
      automationSchedules {
        _id
        frequency
        intervalType
        repeat
        startTimestamp
        isEnabled
        frequencyData {
          dateMonth
          date
          dayOfWeek
        }
        fromToRange {
          timeRange
        }
        automation {
          action
          additionalFields {
            key
            type
            value
          }
        }
        customInterval
      }
      htmlData
      mongoCollectionName
    }
  }
`;

export const GET_SHARED_DASHBOARD_DETAILS = gql`
  query GET_SHARED_DASHBOARD_DETAILS(
    $id: String!,
  ) {
    getDashboardSharedEmpDetails(
      id: $id,
    ) {
      dashboardId
      ownerDetail {
        name
        empId
        email
      }
      sharedToEmployeeDetails {
        name
        empId
        email
      }
      isSharableToAll
    }
  }
`;

export const SAVE_SHARED_DASHBOARD_DETAILS = gql`
  query SAVE_SHARED_DASHBOARD_DETAILS(
    $id: String!,
    $employeeDetails: [userInput],
    $isSharableToAll: Boolean,
  ) {
    shareDashboardToEmployee(
      id: $id,
      employeeDetails: $employeeDetails,
      isSharableToAll: $isSharableToAll,
    ) {
      success
      error
    }
  }
`;

export const GET_REPORT_VISUALISATION = gql`
  query GET_REPORT_VISUALISATION(
    $reportId: String!
    $dashboardId: String!
    $globalFilters: [whereHavingInput]
    $isDummyDataRequest: Boolean,
    $externalFilters: [externalFilterMetaInput]
  ) {
    getReportVisualisation(
      reportId: $reportId,
      dashboardId: $dashboardId
      globalFilters: $globalFilters
      isDummyDataRequest: $isDummyDataRequest
      externalFilters: $externalFilters
    )  {
      formData
      chartData
      visualizeMetaData
      chartType
      pageSize
      chartConfig
      columnWidth
      chartOptions {
        section
        option
        data
      }
      reportDescription
      reportDateRange
      globalSuggestedStrings{
        key
        value
      }
      formatting
      htmlData
      reportType
      url
      lastRefreshTime
    }
  }
`;

export const SET_LANDING_DASHBOARD_PAGE = gql`
  query SET_LANDING_DASHBOARD_PAGE(
    $dashboardGroupId: String!,
  ) {
    setLandingDashboardPage(
      dashboardGroupId: $dashboardGroupId,
    ) {
      success
      error
    }
  }
`;

export const SET_FAVOURITE_DASHBOARD_GROUP = gql`
  query SET_FAVOURITE_DASHBOARD_GROUP($dashboardGroupId: String!, $value: Boolean!) {
    setFavouriteDashboardGroup(dashboardGroupId: $dashboardGroupId, value: $value) {
      success
      error
    }
  }
`;

export const RENAME_DASHBOARD_GROUP = gql`
query RENAME_DASHBOARD_GROUP($dashboardGroupId:String!,$name: String!) {
  renameDashboardGroup(dashboardGroupId: $dashboardGroupId, name: $name){
    success
    error
  }
}
`;

export const DELETE_DASHBOARD_GROUP = gql`
query DELETE_DASHBOARD_GROUP($dashboardGroupId:String!) {
  deleteDashboardGroup(dashboardGroupId: $dashboardGroupId){
    success
    error
  }
}
`;

export const MARK_DEFAULT_DASHBOARD_GROUP = gql`
query MARK_DEFAULT_DASHBOARD_GROUP($dashboardGroupId:String!) {
  markDashboardGroupAsDefault(dashboardGroupId: $dashboardGroupId){
    success
    error
  }
}
`;

export const SAVE_DASHBOARD_GROUP = gql`
query SAVE_DASHBOARD_GROUP(
        $id: String
        $isNew: Boolean!
        $name: String!
        $description: String
        $isActive: Boolean!
        $category: String
        $isFavourite: Boolean
        $dashboardGridColumns: Int
) {
  saveDashboardGroup(id: $id, isNew: $isNew, name: $name, description: $description, isActive: $isActive,
    category: $category, isFavourite: $isFavourite, dashboardGridColumns: $dashboardGridColumns){
    success
    error
  }
}
`;

export const GET_SHARED_DASHBOARD_GROUP_DETAILS = gql`
query GET_SHARED_DASHBOARD_GROUP_DETAILS(
  $id: String!,
) {
  getDashboardGroupSharedEmpDetails(
    id: $id,
  ) {
    dashboardGroupId
    ownerDetail {
      name
      empId
      email
    }
    sharedToEmployeeDetails {
      name
      empId
      email
    }
    isSharableToAll
    sharedToCustomerPortal
    sharedToVendorPortal
    isSharableToCustomer
    isSharableToVendor
  }
}
`;

export const SAVE_SHARED_DASHBOARD_GROUP_DETAILS = gql`
  query SAVE_SHARED_DASHBOARD_GROUP_DETAILS(
    $id: String!,
    $employeeDetails: [userInput],
    $isSharableToAll: Boolean,
    $sharedToCustomerPortal: Boolean
    $sharedToVendorPortal: Boolean
  ) {
    shareDashboardGroupToEmployee(
      id: $id,
      employeeDetails: $employeeDetails,
      isSharableToAll: $isSharableToAll,
      sharedToCustomerPortal: $sharedToCustomerPortal
      sharedToVendorPortal: $sharedToVendorPortal
    ) {
      success
      error
    }
  }
`;

export const GET_DEFAULT_DASHBOARD_CATEGORY = gql`
query GET_DEFAULT_DASHBOARD_CATEGORY{
  getDefaultDashboardCategory
}
`;

export const UPDATE_INCIDENT_ALERT_STATUS = gql`
query updateIncidentAlertStatus(
  $id: String!
  $status: String!
) {
  updateIncidentAlertStatus(
    id: $id
    status: $status
  ) {
    success
    error
  }
}
`;

export const SAVE_INCIDENT_ALERT = gql`
  query saveIncidentAlert(
    $id: String
    $name: String!
    $metricId: String
    $filters: [whereHavingInput]
    $criterias: criteriaInput
    $incidentGeneration: incidentGenerationLimitInput
    $scheduler: schedulerInput
    $assignee: assigneeWatcherInput!
    $watchers: [watcherInput]
    $suppressSubsequentAlerts: Boolean!
    $resolutionSLA: resolutionSLAInput
    $ticketDetails: ticketDetailInput!
    $emailNotification: Boolean
    $linkedDashboardGroups: String
    $type: String
  ) {
    saveIncidentAlert(
      id: $id
      name: $name
      metricId: $metricId
      filters: $filters
      criterias: $criterias
      incidentGeneration: $incidentGeneration
      scheduler: $scheduler
      assignee: $assignee
      watchers: $watchers 
      suppressSubsequentAlerts: $suppressSubsequentAlerts
      resolutionSLA: $resolutionSLA
      ticketDetails: $ticketDetails
      emailNotification: $emailNotification
      linkedDashboardGroups: $linkedDashboardGroups
      type: $type
    ) {
      success
      error
    }
  }
`;

export const DOWNLOAD_UNDERLYING_REPORT_DATA = gql`
  query DOWNLOAD_UNDERLYING_REPORT_DATA(
    $reportId: String!
    $globalFilters: [whereHavingInput]
    $childReportFilters: [whereHavingInput]
    $externalFilters: [externalFilterMetaInput]
  ) {
    executeReportUnderlyingData(
      id: $reportId
      globalFilters: $globalFilters
      childReportFilters: $childReportFilters
      externalFilters: $externalFilters
    )
  }
`;
