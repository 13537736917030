import {
  Button,
  Drawer,
  Card,
  DatePicker,
  InputNumber,
  Dropdown,
  Menu,
  Modal,
} from 'antd';
import { createUseStyles } from 'react-jss';
import { PlusCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_DUMMY_DATA } from 'src/reduxActions/actionNameEnums';
import moment from 'moment';
import { GET_TEXT_DUMPS } from 'src/api/routes';
import { urlDownload } from 'src/components/pages/chartBuilder/helpers';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { dummyDataConst } from 'src/constants';
import CustomInput from 'src/components/common/customInput';

const useStyle = createUseStyles({
  columnComponent: {
    display: 'flex',
    flexDirection: 'column',
  },
  rangeHeading: {
    fontSize: '12px',
  },
  inputNumber: {
    width: '5.5em',
  },
  datePicker: {
    width: '12em',
  },
  aceEditor: {
    border: '1px solid #D9D9D9',
    marginTop: '10px',
  },
  cardComponent: {
    backgroundColor: '#FAFAFA',
  },
  cardComponentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
  },
  cardComponentHeading: {
    fontWeight: '500',
  },
  textCardComponent: {
    display: 'flex',
    gap: '0.5em',
    alignItems: 'center',
  },
  rangeCardComponent: {
    display: 'flex',
    gap: '2em',
  },
  drawerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelButton: {
    marginRight: '10px',
  },
});

const DropDownComponent = ({ itemClicked, textDataDump }) => {
  const [visible, setVisible] = useState(false);
  const dropdownMenu = (
    <Menu>
      {Object.keys(textDataDump).map((dump) => (
        <Menu.Item key={dump} onClick={() => itemClicked(setVisible, dump)}>
          {dump}
        </Menu.Item>
      ))}
    </Menu>
  );

  const toggleDropdown = () => {
    setVisible(!visible);
  };

  return (
    <Dropdown
      overlay={dropdownMenu}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <PlusCircleOutlined onClick={toggleDropdown} />
    </Dropdown>
  );
};
const DataDumpDrawer = ({
  setRenderDataDumpDrawer,
  dataDumpRender,
  columnData,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const metrics = useSelector((state: any) => state.metrics);
  const textDataDump = useSelector(
    (state: any) => state.reportConfig.textDataDump,
  );
  const { columnName, columnDataType, columnOperation } = columnData;
  const dummyDataRangeFetched = metrics[columnName]?.dummyData;
  const [dummyDataRange, setDummyDataRange] = useState({
    min: dummyDataRangeFetched?.min ? dummyDataRangeFetched?.min : 1,
    max: dummyDataRangeFetched?.max ? dummyDataRangeFetched?.max : 100,
  });
  const updatedDummyDataList =
    dummyDataRangeFetched?.min === undefined ? dummyDataRangeFetched : [];
  const [dummyDataList, setDummyDataList] = useState<any>(
    updatedDummyDataList || [],
  );
  const dummyDataListEditor =
    dummyDataList && !('min' in dummyDataList)
      ? dummyDataList?.join('\n')
      : null;

  const [getTextDumps] = useLazyAPI(GET_TEXT_DUMPS, {
    type: 'GET',
    onCompleted: (url) => {
      urlDownload(url);
    },
  });

  const handleEditorChange = (value) => {
    if (value === '') {
      setDummyDataList([]);
      return;
    }
    setDummyDataList(value.split('\n'));
  };

  const itemClicked = (setVisible, value) => {
    setVisible(false);
    if (dummyDataList && dummyDataList.includes(value)) {
      const updatedDummyDataList = dummyDataList.filter(
        (item) => item !== value,
      );
      setDummyDataList(updatedDummyDataList);
    } else {
      setDummyDataList(dummyDataList ? [...dummyDataList, value] : [value]);
    }
  };
  const handleChange = (value, name) => {
    const updatedDataRange = {
      ...dummyDataRange,
      [name.toLowerCase()]: value,
    };
    setDummyDataRange(updatedDataRange);
  };
  const ColumnComponent = ({ name, type }) => {
    return (
      <div className={classes.columnComponent}>
        {type === 'text' ? null : (
          <div className={classes.rangeHeading}>{name}</div>
        )}
        {type === 'number' ? (
          <div>
            <InputNumber
              className={classes.inputNumber}
              value={
                name === dummyDataConst.Min
                  ? dummyDataRange.min
                  : dummyDataRange.max
              }
              onChange={(value) => handleChange(value, name)}
              defaultValue={'0'}
            />
          </div>
        ) : null}
        {type === 'date' ? (
          <DatePicker
            className={classes.datePicker}
            format="YYYY-MM-DD HH:mm:ss"
            showTime={true}
            value={
              name === dummyDataConst.Min
                ? dummyDataRange?.min === 1
                  ? null
                  : moment(dummyDataRange.min)
                : dummyDataRange?.max === 100
                ? null
                : moment(dummyDataRange.max)
            }
            onChange={(value) => handleChange(value, name)}
            allowClear={false}
          />
        ) : null}
        {type === 'text' ? (
          <CustomInput
            isTextArea={true}
            textAreaRows={20}
            className={classes.aceEditor}
            value={dummyDataListEditor}
            onChange={handleEditorChange}
          />
        ) : null}
      </div>
    );
  };

  const cardComponent = ({ name, heading }) => {
    return (
      <Card className={classes.cardComponent}>
        <div className={classes.cardComponentHeader}>
          <div className={classes.cardComponentHeading}>{heading}</div>
          {name === dummyDataConst.CATEGORICAL ? (
            <div className={classes.textCardComponent}>
              <DownloadOutlined onClick={() => getTextDumps()} />
              <DropDownComponent
                itemClicked={itemClicked}
                textDataDump={textDataDump}
              />
            </div>
          ) : null}
        </div>
        {name === dummyDataConst.NUMBERS ? (
          <div className={classes.rangeCardComponent}>
            {ColumnComponent({ name: dummyDataConst.Min, type: 'number' })}
            {ColumnComponent({ name: dummyDataConst.Max, type: 'number' })}
          </div>
        ) : null}
        {name === dummyDataConst.DATETIME ? (
          <div className={classes.rangeCardComponent}>
            {ColumnComponent({ name: dummyDataConst.Min, type: 'date' })}
            {ColumnComponent({ name: dummyDataConst.Max, type: 'date' })}
          </div>
        ) : null}
        {name === dummyDataConst.CATEGORICAL
          ? ColumnComponent({ name: 'List', type: 'text' })
          : null}
      </Card>
    );
  };
  const renderAppropriateDumpOptions = () => {
    if (
      columnDataType === 'text' &&
      (!columnOperation || columnOperation === 'any')
    ) {
      return cardComponent({
        name: dummyDataConst.CATEGORICAL,
        heading: 'LIST',
      });
    }
    if (
      columnDataType === 'timestampz' &&
      (!columnOperation || ['max', 'min', 'any'].includes(columnOperation))
    ) {
      return cardComponent({
        name: dummyDataConst.DATETIME,
        heading: dummyDataConst.RANGE,
      });
    }
    return cardComponent({
      name: dummyDataConst.NUMBERS,
      heading: dummyDataConst.RANGE,
    });
  };
  const cancelDummyDataPayload = () => {
    const payload = {
      isTextField:
        columnDataType === 'text' &&
        (!columnOperation || columnOperation === 'any'),
      dummyData:
        columnDataType === 'text' &&
        (!columnOperation || columnOperation === 'any')
          ? ['list_of_words']
          : { min: 1, max: 100 },
      columnName,
    };
    return payload;
  };
  const cancelDummyDataSave = () => {
    setRenderDataDumpDrawer(false);
    dispatch({
      type: UPDATE_DUMMY_DATA,
      payload: cancelDummyDataPayload(),
    });
  };
  const saveDummyDataPayload = () => {
    const payload = {
      isTextField:
        columnDataType === 'text' &&
        (!columnOperation || columnOperation === 'any'),
      dummyData:
        columnDataType === 'text' &&
        (!columnOperation || columnOperation === 'any')
          ? !dummyDataList.length
            ? Modal.warning({
                content: 'Select atleast one value from the list',
              })
            : dummyDataList
          : { min: dummyDataRange.min, max: dummyDataRange.max },
      columnName,
    };
    return payload;
  };
  const saveDummyData = () => {
    dispatch({
      type: UPDATE_DUMMY_DATA,
      payload: saveDummyDataPayload(),
    });
    if (
      columnDataType === 'text' &&
      (!columnOperation || columnOperation === 'any') &&
      !dummyDataList.length
    ) {
      setRenderDataDumpDrawer(true);
    } else {
      setRenderDataDumpDrawer(false);
    }
  };
  return (
    <Drawer
      title={
        <div className={classes.drawerTitle}>
          <div>Define Dummy Data</div>
          <div>
            <Button
              type="text"
              className={classes.cancelButton}
              onClick={cancelDummyDataSave}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={saveDummyData}>
              Save
            </Button>
          </div>
        </div>
      }
      closable={true}
      onClose={cancelDummyDataSave}
      placement="right"
      visible={dataDumpRender}
      size="large"
    >
      {renderAppropriateDumpOptions()}
    </Drawer>
  );
};
export default DataDumpDrawer;
