import { ApolloClient, InMemoryCache, ApolloLink, concat, HttpLink } from '@apollo/client';
import config from './settings';
import { getAuthenticationHeaders } from './components/common/utils/utils';
const httpLink = new HttpLink({ uri: config.BACKEND_URL });
const addHeaders = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      ...getAuthenticationHeaders(),
      'Access-Control-Allow-Origin': '*',
    },
  });
  return forward(operation);
});
const client = new ApolloClient({
  link: concat(addHeaders, httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});
export default client;
