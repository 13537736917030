import {
  intervals,
  schedulerIntervals,
  hourlyIntervals,
  frequencyTypes,
  weekdays,
} from '../../../constants';
import moment from 'moment-timezone';
import lodashCloneDeep from 'lodash/cloneDeep';

const {
  DAILY,
  WEEKLY_DAY,
  MONTHLY_DAY,
  YEARLY_DAY,
  WEEKLY_WEEKDAYS,
  HOURLY,
  MINUTELY,
  CUSTOM,
} = intervals;
const { ONE_HOUR, TWO_HOURS, FOUR_HOURS, SIX_HOURS, TWELVE_HOURS } =
  hourlyIntervals;
const { YEAR, MONTH, DAY, WEEK, HOUR, MINUTE } = frequencyTypes;

const generateIntervalOptions = (timestamp) => {
  const dayString = moment(timestamp).format('dddd');
  const dateString = moment(timestamp).format('Do');
  const monthString = moment(timestamp).format('MMMM');
  const schedulerIntervalsOptions = schedulerIntervals.map((intervalObj) => {
    const { value } = intervalObj;
    if (value === DAILY) return { value, prettyName: 'Daily' };
    if (value === WEEKLY_DAY) return { value, prettyName: `Weekly on ${dayString}` };
    if (value === MONTHLY_DAY) return { value, prettyName: `Monthly on ${dateString}` };
    if (value === YEARLY_DAY) return { value, prettyName: `Annually on ${monthString} ${dateString}` };
    if (value === WEEKLY_WEEKDAYS) return { value, prettyName: 'Every Weekday (Monday to Friday)' };
    return { value, prettyName: 'Custom' };
  });
  return schedulerIntervalsOptions;
};

const generateIntervalOptionsV2 = (timestamp, schedulerFrequency) => {
  const dayString = moment(timestamp).format('dddd');
  const dateString = moment(timestamp).format('Do');
  const monthString = moment(timestamp).format('MMMM');

  const hourlyIntervalOptions = [
    { value: ONE_HOUR, label: '1 Hour' },
    { value: TWO_HOURS, label: '2 Hours' },
    { value: FOUR_HOURS, label: '4 Hours' },
    { value: SIX_HOURS, label: '6 Hours' },
    { value: TWELVE_HOURS, label: '12 Hours' },
  ];

  const minuteIntervalOptions: any = [];

  const schedulerIntervalsOptions = [
    { value: DAILY, label: 'Daily' },
    { value: WEEKLY_DAY, label: `Weekly on ${dayString}` },
    { value: MONTHLY_DAY, label: `Monthly on ${dateString}` },
    { value: YEARLY_DAY, label: `Annually on ${monthString} ${dateString}` },
    { value: WEEKLY_WEEKDAYS, label: 'Every Weekday (Monday to Friday)' },
    { value: HOURLY, label: 'Hour', children: hourlyIntervalOptions },
  ];

  if (
    Array.isArray(schedulerFrequency?.minutes) &&
    schedulerFrequency.minutes.length
  ) {
      schedulerFrequency.minutes.forEach((minuteOption) => {
        minuteIntervalOptions.push({
          value: `${minuteOption}_minutes`,
          label: `${minuteOption} Minutes`,
        });
      });

    if (minuteIntervalOptions.length) {
      schedulerIntervalsOptions.push({
        value: MINUTELY,
        label: 'Minute',
        children: minuteIntervalOptions,
      });
    }
  }

  schedulerIntervalsOptions.push({
    value: CUSTOM,
    label: 'Custom',
  });

  return schedulerIntervalsOptions;
};

const getUpdatedSchedulerData = (params) => {
  const {
    intervalType,
    selectedSubOption,
    schedulerData,
    updatedStartTimestamp,
    timeRange,
  } = params;

  // taking a deep copy as the props are read only
  const schedulerDataCopy = lodashCloneDeep(schedulerData);

  if (updatedStartTimestamp) {
    schedulerDataCopy.startTimestamp = updatedStartTimestamp;
  }
  if (intervalType) {
    schedulerDataCopy.selectedInterval = intervalType;
  }

  const { startTimestamp, selectedInterval } = schedulerDataCopy;
  const dayString = moment(startTimestamp).format('dddd');
  const dateString = moment(startTimestamp).date().toString();
  const dateMonthString = moment(startTimestamp).format('DD/MM');

  switch (selectedInterval) {
    case DAILY:
      schedulerDataCopy.frequencyData = {};
      schedulerDataCopy.frequency = DAY;
      schedulerDataCopy.repeat = 1;
      schedulerDataCopy.fromToRange = {};
      schedulerDataCopy.customInterval = null;
      break;

    case WEEKLY_DAY:
      schedulerDataCopy.frequencyData = { dayOfWeek: [dayString] };
      schedulerDataCopy.frequency = WEEK;
      schedulerDataCopy.repeat = 1;
      schedulerDataCopy.fromToRange = {};
      schedulerDataCopy.customInterval = null;
      break;

    case MONTHLY_DAY:
      schedulerDataCopy.frequencyData = { date: [dateString] };
      schedulerDataCopy.frequency = MONTH;
      schedulerDataCopy.repeat = 1;
      schedulerDataCopy.fromToRange = {};
      schedulerDataCopy.customInterval = null;
      break;

    case YEARLY_DAY:
      schedulerDataCopy.frequencyData = { dateMonth: [dateMonthString] };
      schedulerDataCopy.frequency = YEAR;
      schedulerDataCopy.repeat = 1;
      schedulerDataCopy.fromToRange = {};
      schedulerDataCopy.customInterval = null;
      break;

    case WEEKLY_WEEKDAYS:
      schedulerDataCopy.frequencyData = { dayOfWeek: weekdays };
      schedulerDataCopy.frequency = WEEK;
      schedulerDataCopy.repeat = 1;
      schedulerDataCopy.fromToRange = {};
      schedulerDataCopy.customInterval = null;
      break;

    case HOURLY:
      let hourlyRepeat = schedulerDataCopy.repeat || 1;
      const hourlyFromToRange = schedulerDataCopy.fromToRange || {};

      if (selectedSubOption) {
        const hourlyInterval = selectedSubOption;

        switch (hourlyInterval) {
          case ONE_HOUR:
            hourlyRepeat = 1;
            break;

          case TWO_HOURS:
            hourlyRepeat = 2;
            break;

          case FOUR_HOURS:
            hourlyRepeat = 4;
            break;

          case SIX_HOURS:
            hourlyRepeat = 6;
            break;

          case TWELVE_HOURS:
            hourlyRepeat = 12;
            break;

          default:
            hourlyRepeat = 1;
        }
      }

      if (timeRange && timeRange.length) {
        hourlyFromToRange.timeRange = timeRange.map((value) =>
          value.format('HH:mm'),
        );
      }

      schedulerDataCopy.frequencyData = {};
      schedulerDataCopy.frequency = HOUR;
      schedulerDataCopy.repeat = hourlyRepeat;
      schedulerDataCopy.fromToRange = hourlyFromToRange;
      schedulerDataCopy.customInterval = null;
      break;

    case MINUTELY:
      let minutelyRepeat = schedulerDataCopy.repeat || 1;
      const minutelyFromToRange = schedulerDataCopy.fromToRange || {};

      if (selectedSubOption) {
        const minuteInterval = selectedSubOption;
        const minutes = parseInt(minuteInterval.split('_')[0], 10) || 1;
        minutelyRepeat = minutes;

      }

      if (timeRange && timeRange.length) {
        minutelyFromToRange.timeRange = timeRange.map((value) =>
          value.format('HH:mm'),
        );
      }

      schedulerDataCopy.frequencyData = {};
      schedulerDataCopy.frequency = MINUTE;
      schedulerDataCopy.repeat = minutelyRepeat;
      schedulerDataCopy.fromToRange = minutelyFromToRange;
      schedulerDataCopy.customInterval = null;
      break;

    case CUSTOM:
      schedulerDataCopy.frequencyData = {};
      schedulerDataCopy.frequency = CUSTOM;
      schedulerDataCopy.repeat = 1;
      schedulerDataCopy.fromToRange = {};
      schedulerDataCopy.customInterval = schedulerData.customInterval || null;
      break;

    default:
      break;
  }

  return schedulerDataCopy;
};

export {
  generateIntervalOptions,
  generateIntervalOptionsV2,
  getUpdatedSchedulerData,
};
