import { Select } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
const { Option } = Select;

const MultiInputFilter = (props) => {
    const { value, buttonGroupActive,  onChange, prettyName, style = {width:'100%'}, size='small' } = props;
    const [open, setOpen] = useState<boolean>(false);
    const suggestedFilterStringsForDashboard = useSelector((state:any) => state.reportMetaData.suggestedFilterStrings) || [];
    const mappedFilterObj= suggestedFilterStringsForDashboard?.filter(filterObj => filterObj.key === prettyName);
    const mappedFilterStrings = mappedFilterObj.length >0 ? mappedFilterObj[0].value.map(x => x):[];
    return <Select
            showSearch
            showArrow={true}
            open={open && buttonGroupActive}
            onBlur={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
            }}
            onClick={(event) => {
                event.preventDefault();
                setOpen(true);
                const element = (event.target);
                const inputElement = (element as any).querySelector('input');
                if(!open){
                    inputElement?.focus();
                }
                else{
                    inputElement?.blur();
                }
            }}
            maxTagCount="responsive"
            style={style}
            value={value?.value}
            mode="tags"
            size={size}
            placeholder=""
            tokenSeparators={[',', '\n', '\r']}
            onChange={(e) => onChange(e)}>
                {
                mappedFilterStrings?.length > 0 ?
                mappedFilterStrings?.map(item => <Option key={item} value={item}>{item}</Option>): null
                }
        </Select>;
};

export default MultiInputFilter;
