import React, { memo, useState, useMemo, useRef } from 'react';
import { Bar, Column } from '@ant-design/charts';
import lodashUniqBy from 'lodash/uniqBy';
import BarConfigs from './ConfigCharts/configBar';
import { useSelector } from 'react-redux';

const BarViz = (props) => {
  const {
    formData,
    chartData,
    chartConfig,
    containerWidth,
    updateChartRef,
    visualizeMetaData,
    chartInteractionHook,
    showUnderlyingDataDownload,
    chartsFromDashboard,
    reportId,
    isStacked,
  } = props;
  const plotRef = useRef<any>(null);
  const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
  const chartOptions = useSelector(
    (state: any) => state.chartsMetaData.chartOptions,
  );
  const isStackedReport = chartOptions?.xAxis?.isStacked?.data;
  const [dimensions, setDimensions] = useState({
    height: 1000,
    width: 1000,
  });
  const XAxisGroups = lodashUniqBy(chartData, 'x');

  const barConfig = BarConfigs({
    dimensions,
    XAxisGroups,
    chartConfig,
    formData,
    chartsFromDashboard,
    chartData,
    isStacked: isStackedReport || isStacked,
});
  React.useEffect(() => {
    // caching dimensions because the dimensions from the props
    // is updated to 0 when the parent component is unmounted
    if (containerWidth) setDimensions({ width: containerWidth, height: 1000 });
  }, [containerWidth]);

  React.useEffect(() => {
    if (plotRef.current) {
      plotRef.current.on('element:click', async (...args) => {
        const columnData = args[0]?.data?.data;
        const { x, y } = args[0]?.gEvent;
        chartInteractionHook.setPopOverPosition({ x, y });
        const namesArray = (columnData?.name || '')
          ?.split(',')
          .map(item => item.trim());
        if (namesArray.length > 1) {
          columnData.name = namesArray[0];
        }
        const showChildReport = visualizeMetaData?.columnsData[columnData.name]
          ?.linkedReport?.reportId
          ? true
          : false;
        const urlPath = visualizeMetaData?.summarizeData?.find(item => item.name === columnData.name)?.urlPath ||
          visualizeMetaData?.columnsData[columnData.name]?.urlPath;
        const showViewDetails = !!urlPath;
        await chartInteractionHook.onChartColumnClick({
          showChildReport,
          showUnderlyingDataDownload,
          showViewDetails,
          reportData: { visualizeMetaData, columnData },
          reportId,
          enableDummyDataSwitch,
        });
      });
    }
  }, [enableDummyDataSwitch, reportId]);

  const onReady = async (plot) => {
    plotRef.current = plot;
    updateChartRef(plot);
  };

  const config: any = {
    ...formData,
    ...barConfig,
    onReady,
  };

  return formData.bar_type === 'horizontal' ? (
    <Bar {...config} />
  ) : (
    <Column {...config} />
  );
};

const areEqual = (prevProps, nextProps) => {
  // additional props in parent like classes and chartOptions
  // were causing a re-render
  if (
    prevProps.formData === nextProps.formData &&
    prevProps.chartData === nextProps.chartData &&
    prevProps.containerWidth === nextProps.containerWidth
  ) {
    return true; // do not re-render
  }
  return false; // will re-render
};

export default memo(BarViz, areEqual);
