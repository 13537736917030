import { SET_MASTER_DATA } from '../reduxActions/actionNameEnums';

const initialState = {};

const masterDataReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type){
        case SET_MASTER_DATA:
            return {...payload};
        default:
            return state;

    }
};

export default masterDataReducer;
