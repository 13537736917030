import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Affix, Drawer } from 'antd';
import { useRouteMatch } from 'react-router-dom';

import { ReactComponent as FilterIcon } from 'src/assets/Filter.svg';
import FilterMenu from 'src/components/commonv2/FilterMenu';
import { useDashboard } from '../pages/dashboard/hooks/dashboardHook';
import {
  DASHBOARD_FILTER_RADIO_SELECTED,
  DASHBOARD_FILTER_SELECTED_VALUE_CHANGED,
} from 'src/reduxActions/actionNameEnums';

const useStyle = createUseStyles({
  filterCount: {
    position: 'absolute',
    top: '-0.3rem',
    fontSize: '0.5rem',
    right: '-0.4rem',
    color: 'white',
    padding: '0.1rem 0.3rem',
    borderRadius: '100%',
    backgroundColor: '#1890FF',
  },
  filterContainer: {
    height: '31px',
    display: 'flex',
    position: 'relative',
    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.015)',
    marginLeft: 'auto',
    padding: '5px',
    marginRight: '10px',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #e4dede',
  },
  drawer: {
    '& .ant-drawer-content-wrapper': {
      height: '23.625rem !important',
    },
  },
});

const MobileNavBarFilters = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const dashboardGroupMetaData = useSelector(
    (state: any) => state.dashboardGroupMetaData,
  );
  const {
    dashboardGroupName,
    metadata: dashboardMetaData,
    currentDashboardGroupTab,
  } = dashboardGroupMetaData;
  const dashboardFilter = useSelector((state: any) => state.dashboardFilter);
  const match = useRouteMatch({
    path: '/dashboard/:dashboardId',
    exact: true,
  }) || { params: { dashboardId: '' } };
  const { params } = match;
  const { dashboardId: dashboardGroupId } = params;
  const dashboard = useDashboard(dashboardGroupId);
  const { dashboardTab, filters, dashboards } = dashboard;
  const dashboardId =
    dashboards?.[dashboardTab]?.dashboardVisualisations?.dashboard?._id;
  const filtersMetaData = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const filterData = useSelector(
    (state: any) => state.dashboardFilter[dashboardTab],
  );
  const boolInitialState = {};
  for (const key in filterData) {
    if (filterData.hasOwnProperty(key) && filterData[key].type === 'boolean') {
      boolInitialState[filterData[key].metric] = [];
    }
  }
  const [checkedValues, setCheckedValues] = useState(boolInitialState);
  const [filterApplied, setFilterApplied] = useState(true);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  let filterColorInitialState = {};
  if (filters[Number(dashboardTab)]) {
    const filterMetrics = filters[Number(dashboardTab)].map(
      (item) => item.metric,
    );
    filterColorInitialState = filterMetrics.reduce((acc, item) => {
      return { ...acc, [item]: false };
    }, {});
  }
  const [filterColor, setFilterColor] = useState(filterColorInitialState);
  const handleFilterClick = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const getDashboardFilterCount = () => {
    let count = 0;
    for (const key in filterColor) {
      if (filterColor[key]) {
        if (filterColor[key]) {
          count++;
        }
      }
    }
    return count;
  };

  const getCachedDashboards = () => {
    let cachedDashboards: any = [];
    const localCachedDashboard = localStorage.getItem('activeDashboard');
    if (localCachedDashboard) {
      cachedDashboards = JSON.parse(localCachedDashboard);
    }
    return cachedDashboards;
  };

  const handleChange = (key) => {
    setFilterColor((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const handleBoolChange = (key, checkedValues) => {
    setCheckedValues((prevState) => ({
      ...prevState,
      [key]: checkedValues,
    }));
  };

  useEffect(() => {
    if (filters[Number(dashboardTab)]) {
      const filterMetrics = filters[Number(dashboardTab)]?.map(
        (item) => item.metric,
      );
      const filterColorInitialState = filterMetrics?.reduce((acc, item) => {
        return { ...acc, [item]: false };
      }, {});
      setFilterColor(filterColorInitialState);
    }
    const cachedDashboards = getCachedDashboards();
    const cachedCurrentDashboard = cachedDashboards?.find(
      (cachedDashboard) =>
        cachedDashboard.dashboardGroupId === dashboardGroupId,
    );
    if (cachedCurrentDashboard) {
      const localFilter =
        cachedCurrentDashboard.tabs[dashboardId]?.filters ?? {};
      const valuesArray = Object.values(localFilter);
      valuesArray.forEach((value: any) => {
        if (value.metric && value.name) {
          onChangeRadio(value.metric, value.type)(value.name);
          handleChange(value.metric);
        }
        if (value.metric && value.data) {
          const dataObject = JSON.parse(value.data);
          onChangeFilterData(value.metric)(dataObject.value);
        }
        switch (value.name) {
          case 'booleanIsEqualTo':
            const dataObject = JSON.parse(value.data);
            handleBoolChange(value.metric, [JSON.stringify(dataObject.value)]);
            break;
          case 'booleanIsNull':
            handleBoolChange(value.metric, ['null']);
            break;
          case 'booleanIsNotTrue':
            handleBoolChange(value.metric, ['false', 'null']);
            break;
          case 'booleanIsNotFalse':
            handleBoolChange(value.metric, ['true', 'null']);
            break;
          case 'booleanIsNotNull':
            handleBoolChange(value.metric, ['true', 'false']);
            break;
          default:
            break;
        }
      });
      setFilterApplied(true);
    }
  }, [dashboardTab, dashboards]);

  const onChangeRadio = (metricName, filterType) => (value) => {
    let filter;
    if (filtersMetaData) {
      filter = filtersMetaData[filterType]?.find((obj) => obj.name === value);
    }
    const filterInputType = filter?.type;
    if (filterInputType === 'noInput') {
      setFilterApplied(false);
    }
    dispatch({
      type: DASHBOARD_FILTER_RADIO_SELECTED,
      payload: {
        metricName,
        selectedFilter: value,
        dashboardTab,
        filterInputType,
        setFilterApplied,
        filterType,
      },
    });
  };

  const onChangeFilterData = (metricName) => (data) => {
    setFilterApplied(false);
    dispatch({
      type: DASHBOARD_FILTER_SELECTED_VALUE_CHANGED,
      payload: {
        metricName,
        dashboardTab,
        data: {
          value: data,
        },
      },
    });
  };

  return (
    <>
      <Affix>
        {dashboardGroupName && filters?.[dashboardTab]?.length > 0 && (
          <div className={classes.filterContainer}>
            <FilterIcon onClick={handleFilterClick} style={{ height: '1.2rem', width: 'auto' }} />
            <div className={classes.filterCount}>
              {getDashboardFilterCount().toString()}
            </div>
          </div>
        )}
      </Affix>
      <Drawer
        title="Filters"
        visible={isFilterDrawerOpen}
        onClose={handleFilterClick}
        placement="bottom"
        bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}
        className={classes.drawer}
      >
        <FilterMenu
          dashboard={dashboard}
          dashboardId={dashboardId}
          onClose={handleFilterClick}
          filterApplied={filterApplied}
          setFilterApplied={setFilterApplied}
          checkedValues={checkedValues}
          setCheckedValues={setCheckedValues}
          boolInitialState={boolInitialState}
          filterColorInitialState={filterColorInitialState}
          filterColor={filterColor}
          setFilterColor={setFilterColor}
          isTabletOrMobile={true}
        />
      </Drawer>
    </>
  );
};

export default MobileNavBarFilters;
