import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { useReport, useSetInitialReportData } from './createQueryComponents/BodyParts/createQueryBodyHooks';
import CreateReportSection from './createQueryComponents/createReportSection';
import {
  useAggregate,
  useFilters,
  useHavingFilters,
  useSortBy,
  useUnderlyingMetrics,
} from './createQueryComponents/dataSetupContainerPart/useMetricsHook';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const useStyles = createUseStyles({
    baseCardStyle: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '30%',
    },
    scrollable: {
        overflow: 'auto',
        height: '100%',
    },
});

const modifyLocationState = (locationState) => {
    if(!locationState) return;
    const customMetrices = locationState.customMetrices.map(item => {
        return {
            ...item,
            id: uuidv4(),
            name: item.metric,
        };
    });
    const customMetricesObj = {};
    customMetrices.forEach(element => customMetricesObj[element.id] = element);
    const customAggregateMetrices = locationState.customAggregateMetrices.map(item => {
        return {
            ...item,
            id: uuidv4(),
            name: item.metric,
        };
    });
    const customAggregateMetricesObj = {};
    customAggregateMetrices.forEach(element => customAggregateMetricesObj[element.id] = element);
    locationState.customAggregateMetricesObj = customAggregateMetricesObj;
    locationState.customMetricesObj = customMetricesObj;
    return locationState;
};

const CreateQuery = (props) => {
    const {submitReport, reportData} = props;
    const classes = useStyles();
    const locationState =  modifyLocationState(reportData);
    const setReportSelectedData:any = useSetInitialReportData(locationState);
    const useReportObject = useReport(setReportSelectedData);
    const aggregate = useAggregate(useReportObject.metrics);
    const filters = useFilters(useReportObject.metrics);
    const havingFilters = useHavingFilters(useReportObject.metrics);
    const sortBy = useSortBy(aggregate.metricsMetaData.selectedMetrics);
    const underlyingMetrics = useUnderlyingMetrics(useReportObject.metrics);
    const initialSuggestedFilters = useSelector((state: any) => state.reportMetaData?.suggestedFilterStrings);
    return (
      <>
        <div className={classes.baseCardStyle}>
          <div className={classes.scrollable}>
            <CreateReportSection
              reportObject={useReportObject}
              submitReport={submitReport}
              setReset={useReportObject.resetEverything}
              aggregate={aggregate}
              filters={filters}
              havingFilters={havingFilters}
              sortBy={sortBy}
              underlyingMetrics={underlyingMetrics}
              initialSuggestedFilters={initialSuggestedFilters}
              isEditing={locationState ? true : false}
            />
          </div>
        </div>
      </>
    );
};
export default memo(CreateQuery);
