import {
    REMOVE_CUSTOM_COLUMNS_GLOBAL,
    REMOVE_CUSTOM_COLUMNS_LOCAL,
    RESET_CUSTOM_COLUMNS_GLOBAL,
    RESET_CUSTOM_COLUMNS_LOCAL,
    SET_CUSTOM_COLUMNS_GLOBAL,
    SET_CUSTOM_COLUMNS_LOCAL,
} from 'src/reduxActions/actionNameEnums';
import lodashOmit from 'lodash/omit';

const initialState = {
    global: {},
    local: {},
};

const filterReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type){
        case SET_CUSTOM_COLUMNS_GLOBAL:
            return {
                ...state,
                global: {
                    ...state.global,
                    [payload.name]: payload.body,
                },
            };
        case SET_CUSTOM_COLUMNS_LOCAL:
            return {
                ...state,
                local: {
                    ...state.local,
                    [payload.name]: payload.body,
                },
            };
        case REMOVE_CUSTOM_COLUMNS_GLOBAL:
            return lodashOmit(state, `global.${payload.name}`);
        case REMOVE_CUSTOM_COLUMNS_LOCAL:
            return lodashOmit(state, `local.${payload.name}`);
        case RESET_CUSTOM_COLUMNS_GLOBAL:
            return {
                ...state,
                global: payload,
            };
        case RESET_CUSTOM_COLUMNS_LOCAL:
            return {
                ...state,
                local: payload,
            };
        default:
            return state;
    }
};

export default filterReducer;
