import lodashOmit from 'lodash/omit';
import _ from 'lodash';
import lodashCloneDeep from 'lodash/cloneDeep';
import { DEFAULT_HTML_VALUE } from 'src/constants';
import {
  SET_CHARTS_METADATA,
  SET_CHART_TYPE,
  CHART_OPTION_VALUE_CHANGED,
  CHART_CONFIG_VALUE_CHANGED,
  CHART_OPTION_REMOVED,
  INITIALIZE_CHARTOPTIONS,
  INITIALIZE_CHARTCONFIG,
  SET_TABLE_PAGE_SIZE,
  SET_FIELD_VALUE,
  RESET_CHART,
  RESET_CHART_OPTIONS,
  FORMATTING_ACTION_COLUMN_CHANGE,
  FORMATTING_ACTION_OPTION_CHANGE,
  FORMATTING_ACTION_VALUE_CHANGE,
  FORMATTING_ACTION_COLOR_CHANGE,
  FORMATTING_CONDITION_DATA_CHANGE,
  FORMATTING_CONDITION_COLUMN_CHANGE,
  FORMATTING_CONDITION_OPERATOR_CHANGE,
  FORMATTING_SELECT_METRIC,
  FORMATTING_DELETE_METRIC,
  FORMATTING_RESET_METRIC,
  FORMATTING_CONDITION_DELETE,
  FORMATTING_CONDITION_ADD,
  INITIALIZE_CONDITIONAL_FORMATTING,
  RESET_FORMATTING,
  UPDATE_CHART_DATA_PIVOT,
  SET_HTML_TEXT,
} from '../reduxActions/actionNameEnums';

const initialState = {
  chartConfig: {},
  pageSize: 10,
  formatting: [],
  chartType: 'table',
  chartData: null,
  chartOptions: {},
  fieldValue: '',
  htmlText: DEFAULT_HTML_VALUE,
};

const chartMetaDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const cloneChartMetaData = _.cloneDeep(state);
  switch (type) {
    case SET_CHARTS_METADATA:
      return {
        ...state,
        chartData: payload,
      };
    case SET_CHART_TYPE:
      return {
        ...state,
        chartType: payload.chartType,
        chartConfig:
          state.chartType !== payload.chartType
            ? initialState.chartConfig
            : state.chartConfig,
      };
    case CHART_OPTION_VALUE_CHANGED:
      return {
        ...state,
        chartOptions: {
          ...state.chartOptions,
          [payload.section]: {
            ...state.chartOptions[payload.section],
            [payload.optionName]: {
              data: payload.value,
              section: payload.section,
            },
          },
        },
      };
    case CHART_CONFIG_VALUE_CHANGED:
      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          [payload.section]: {
            ...state?.chartConfig?.[payload.section],
            [payload.optionName]: {
              data: payload.value,
              section: payload.section,
            },
          },
        },
      };
    case CHART_OPTION_REMOVED:
      return {
        ...state,
        chartOptions: lodashOmit(state.chartOptions, [
          payload.optionName,
        ]),
      };
    case INITIALIZE_CHARTOPTIONS:
      const chartOptionsObject = {};
      payload?.forEach((option) => {
        chartOptionsObject[option.section] = {};
      });
      payload?.forEach((option) => {
        chartOptionsObject[option.section][option.option] = {
          data: JSON.parse(option.data).value,
          section: option.section,
        };
      });
      return {
        ...state,
        chartOptions: chartOptionsObject,
      };
    case FORMATTING_CONDITION_COLUMN_CHANGE:
    case FORMATTING_ACTION_OPTION_CHANGE:
    case FORMATTING_ACTION_VALUE_CHANGE:
    case FORMATTING_ACTION_COLOR_CHANGE:
    case FORMATTING_CONDITION_DATA_CHANGE:
    case FORMATTING_CONDITION_OPERATOR_CHANGE:
    case FORMATTING_SELECT_METRIC:
    case FORMATTING_DELETE_METRIC:
    case FORMATTING_RESET_METRIC:
    case FORMATTING_CONDITION_DELETE:
    case FORMATTING_CONDITION_ADD:
      const payloadCopy = lodashCloneDeep(payload);
      return {
        ...state,
        formatting: payloadCopy,
      };
    case RESET_FORMATTING:
      return {
        ...state,
        formatting: [],
      };
    case INITIALIZE_CHARTCONFIG:
      return {
        ...state,
        chartConfig: JSON.parse(payload || '{}'),
      };
    case INITIALIZE_CONDITIONAL_FORMATTING:
      return {
        ...state,
        formatting: JSON.parse(payload || '[]'),
      };
    case SET_TABLE_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload || 10,
      };
    case SET_FIELD_VALUE:
      return {
        ...state,
        fieldValue: payload,
      };
    case RESET_CHART_OPTIONS:
      return {
        ...state,
        chartOptions: initialState.chartOptions,
        chartData: initialState.chartData,
        pageSize: initialState.pageSize,
      };
    case RESET_CHART:
      return {
        ...state,
        chartData: initialState.chartData,
      };
    case UPDATE_CHART_DATA_PIVOT:
      cloneChartMetaData.chartData.formData = payload.formData;
      return cloneChartMetaData;
    case SET_HTML_TEXT:
      return {
        ...state,
        htmlText: payload,
      };
    default:
      return state;
  }
};

export default chartMetaDataReducer;
