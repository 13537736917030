import { useState } from 'react';
import moment from 'moment';
import lodashKeyBy from 'lodash/keyBy';
import copy from 'copy-to-clipboard';
import { chartInteractionsList, filterTimeFormats } from 'src/constants';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_CHILD_REPORT } from 'src/reduxActions/actionNameEnums';
import { getFilterColumns, getParentWindowBaseURL } from '../utils/utils';
import { useDownloadReport } from 'src/components/pages/dashboard/hooks/downloadReport';
import useVisualiseDownloadReport from 'src/components/pages/chartBuilder/hooks/downloadReport';
import { getToFromTimeStringObject } from '../utils/timeTransformer';

const getFilterFillers = (filterData) => {
  const { type, value, bucketValue } = filterData;
  const filterFillers: any = {};
  switch (type) {
    case 'text':
      filterFillers.selectedFilter = 'textIsEqualTo';
      filterFillers.data = { value: [value] };
      return filterFillers;
    case 'boolean':
      filterFillers.selectedFilter = 'booleanIsEqualTo';
      filterFillers.data = { value };
      return filterFillers;
    case 'number':
      filterFillers.selectedFilter = 'numberIsEqualTo';
      filterFillers.data = { value };
      return filterFillers;
    case 'timestamp':
    case 'timestampz':
    case 'date':
      filterFillers.selectedFilter = 'timestampzBetween';
      filterFillers.data = {
        value: getToFromTimeStringObject(bucketValue, value),
      };
      return filterFillers;
    case 'textSingleSelect':
      filterFillers.selectedFilter = 'testSingleSelectIsEqualTo';
      filterFillers.data = { value: getToFromTimeStringObject(bucketValue, value) };
      return filterFillers;
    default:
      return filterFillers;
  }
};

const getChildReportFilters = (metaData, linkedMetrices, summarizeData, filterData) => {
  const childReportFilters: any = [];
  summarizeData.forEach(({ name: parentMetricName, type: parentMetricType, metric: parentMetric, bucketValue }) => {
    const childMetricObj = linkedMetrices[parentMetric];
    if (childMetricObj) {
      const value = metaData[parentMetricName];
      if (value === undefined || value === null || value === 'Aggregate') {
        return;
      }
      const { data, selectedFilter } = getFilterFillers({ type: parentMetricType, value, bucketValue });
      const childFilterObject = {
        metric: childMetricObj.metric,
        filter: {
          prettyName: childMetricObj.prettyName,
          data: JSON.stringify(data),
          type: childMetricObj.type,
          name: selectedFilter,
          sql: filterData.find(filter => filter.metric === parentMetric)?.filter?.sql,
        },
      };
      childReportFilters.push(childFilterObject);
    }
  });
  filterData.forEach(({ filter: parentFilter, metric: parentMetric }) => {
    const childMetricObj = linkedMetrices[parentMetric];
    if (childMetricObj && !childReportFilters.some(filter => filter.metric === childMetricObj.metric)) {
      const childFilterObject = {
        metric: childMetricObj.metric,
        filter: {
          prettyName: childMetricObj.prettyName,
          data: parentFilter.data,
          type: parentFilter.type,
          name: parentFilter.name,
          sql: parentFilter.sql,
        },
      };
      childReportFilters.push(childFilterObject);
    }
  });
  return childReportFilters;
};

const getCurrentReportUnderlyingFilters = (
  metaData,
  summarizeData,
  filterData,
  drilldownData,
  columnsData,
  clickedColumnName,
  dataMartType,
) => {
  const currentReportUnderlyingFilters: any = [];
  summarizeData.forEach(
    ({
      name: parentMetricName,
      type: parentMetricType,
      metric: parentMetric,
      bucketValue,
      prettyName: parentPrettyName,
      sql,
    }) => {
      let value = metaData[parentMetricName];
      if (value === undefined || value === null || value === 'Aggregate') {
        return;
      }
      let currentBucketValue = bucketValue;
      if (drilldownData?.metric === parentMetric) {
        value = metaData[drilldownData.currentDrillDownPrettyName];
        if (drilldownData.drilldown.type === 'timestamp') {
          currentBucketValue = drilldownData.currentBucketValue;
        }
      }
      const { data, selectedFilter } = getFilterFillers({
        type: parentMetricType,
        value,
        bucketValue: currentBucketValue,
      });
      const underlyingFilterObject = {
        metric: parentMetric,
        filter: {
          prettyName: parentPrettyName,
          data: JSON.stringify(data),
          type: parentMetricType,
          name: selectedFilter,
          sql,
        },
      };
      currentReportUnderlyingFilters.push(underlyingFilterObject);
    },
  );
  filterData.forEach(({ filter: parentFilter, metric: parentMetric }) => {
    {
      const underlyingFilterObject = {
        metric: parentMetric,
        filter: {
          prettyName: parentFilter.prettyName,
          data: parentFilter.data,
          type: parentFilter.type,
          name: parentFilter.name,
          isDefaultFilter: parentFilter.isDefaultFilter,
          sql: parentFilter.sql,
        },
      };
      // not adding hub filters for drill down only using hub which are coming from sumaarise by
      if (
        !(
          drilldownData?.metric === parentMetric &&
          drilldownData?.drilldown?.type === 'hub'
        )
      ) {
        currentReportUnderlyingFilters.push(underlyingFilterObject);
      }
    }
  });
  // adding filters for column which is clicked if aggregate operation applied on it is count_true or count_not_true
  if (
    ['count_true', 'count_not_true'].includes(
      columnsData[clickedColumnName]?.operation?.name,
    )
  ) {
    const clickedColumnData = columnsData[clickedColumnName];
    const operationName = clickedColumnData.operation.name;
    const { data, selectedFilter } = getFilterFillers({
      type: 'boolean',
      value: operationName === 'count_true' ? true : false,
    });
    const underlyingFilterObject = {
      metric: clickedColumnData.metric,
      filter: {
        prettyName: clickedColumnData.prettyName,
        data: JSON.stringify(data),
        type: 'boolean',
        name: selectedFilter,
        sql: clickedColumnData.sql,
      },
    };
    currentReportUnderlyingFilters.push(underlyingFilterObject);
  }
  return currentReportUnderlyingFilters;
};

export const useChartInteractions = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state: any) => state.reportConfig.reports);
  const isTabletOrMobile = useSelector((state: any) => state.config.isTabletOrMobile);
  const chartsMetaData = useSelector((state: any) => state.chartsMetaData);
  const [popOverVisible, setPopOverVisible] = useState(false);
  const [popOverContentList, setPopOverContentList]: any = useState([]);
  const [columnData, setColumnData]: any = useState({});
  const [visualizeMetaData, setVisualizeMetaData]: any = useState({});
  const [dataMartType, setDataMartType] = useState('');
  const [currentReportId, setCurrentReportId] = useState(undefined);
  const [popOverPosition, setPopOverPosition] = useState({ x: 0, y: 0 });
  const [contentList, setContentList] = useState([]);
  const { onDownloadUnderlyingData } = useDownloadReport();
  const { onClickUnderlyingDataDownload: onDownloadVisualiseUnderlyingData } =
    useVisualiseDownloadReport();

  const resetChartInteractionsData = () => {
    setPopOverVisible(false);
    setPopOverContentList([]);
    setColumnData({});
    setVisualizeMetaData({});
    setCurrentReportId(undefined);
    setDataMartType('');
  };
  const copyChartInteractionsData = () => {
    const updatedContentList:any = contentList.filter(
      (content:any) => content.key !== 'COPY_VALUE');
      updatedContentList.push(chartInteractionsList.copied);
    setPopOverContentList(updatedContentList);
    setTimeout(() => {
      resetChartInteractionsData();
    }, 1000);
  };
  const { fieldValue, chartType } = chartsMetaData;
  const onPopOverContentClick = async (key) => {
    if (chartInteractionsList.childReport.key === key) {
      await onChildReportClick();
    }
    if (chartInteractionsList.underlyingDataDownload.key === key) {
      await onDownloadUnderlyingDataClick();
    }
    if (chartInteractionsList.copyToClipBoard.key === key){
      copy(fieldValue);
    }
    if (chartInteractionsList.viewDetails.key === key) {
      const redirectionLink = getRedirectionLink();
      window.open(redirectionLink, '_blank');
    }
    key !== 'COPY_VALUE' ? resetChartInteractionsData() : copyChartInteractionsData();
  };

  const getRedirectionLink = () => {
    const { columnsData, summarizeData } = visualizeMetaData;
    const { name } = columnData;
    const urlPath = summarizeData?.find(item => item.name === name)?.urlPath || columnsData?.[name]?.urlPath;
    const redirectionLink = `${getParentWindowBaseURL()}${urlPath?.replace('{id}', fieldValue)}`;
    return redirectionLink;
  };

  const onPopOverVisibleChange = (visibility) => {
    if (!visibility) {
      resetChartInteractionsData();
    }
  };

  const onChartColumnClick = (chartColumnData) => {
    const {
      showChildReport,
      reportData,
      reportId,
      showUnderlyingDataDownload,
      enableDummyDataSwitch,
      showViewDetails,
    } = chartColumnData;
    setColumnData(reportData?.columnData);
    setVisualizeMetaData(reportData?.visualizeMetaData);
    setDataMartType(reportData?.dataMartType);
    setCurrentReportId(reportId);
    const contentListArray: any = [];
    const enableChildReport = showChildReport && !isTabletOrMobile;
    const enableUnderlyingData = showUnderlyingDataDownload && !enableDummyDataSwitch && !isTabletOrMobile;
    const enableViewDetails = showViewDetails && !isTabletOrMobile;
    if (enableViewDetails) {
      contentListArray.push(chartInteractionsList.viewDetails);
    }
    if (enableChildReport) {
      contentListArray.push(chartInteractionsList.childReport);
    }
    if (enableUnderlyingData) {
      contentListArray.push(chartInteractionsList.underlyingDataDownload);
    }
    if(['pivotTable', 'table'].includes(chartType)){
      contentListArray.push(chartInteractionsList.copyToClipBoard);
    }
    if (contentListArray.length) {
      setPopOverContentList(contentListArray);
      setPopOverVisible(true);
      setContentList(contentListArray);
    }
  };

  const getChildReportFilterData = () => {
    const { summarizeData, filterData, columnsData, reportType } = visualizeMetaData;
    const { name, rawData } = columnData;
    const linkedMetrices = JSON.parse(
      columnsData[name].linkedReport.linkedMetrices,
    );
    const childReportFilters = getChildReportFilters(
      rawData,
      linkedMetrices,
      summarizeData,
      filterData,
    );
    const globalFilters = getFilterColumns(
      childReportFilters,
      reportType,
      reports,
    );
    return {
      childReportFilters,
      childReportGlobalFilters: lodashKeyBy(globalFilters, 'value'),
      reportId: columnsData[name].linkedReport.reportId,
    };
  };

  const getCurrentReportUnderlyingFilterData = () => {
    const { summarizeData, filterData, columnsData } = visualizeMetaData;
    const { rawData, drilldownData, name: clickedColumnName } = columnData;
    const underlyingFilters = getCurrentReportUnderlyingFilters(
      rawData,
      summarizeData,
      filterData,
      drilldownData,
      columnsData,
      clickedColumnName,
      dataMartType,
    );
    return {
      underlyingFilters,
    };
  };

  const onChildReportClick = async () => {
    const { childReportFilters, reportId, childReportGlobalFilters } = getChildReportFilterData();
    dispatch({
      type: ADD_CHILD_REPORT,
      payload: {
        reportId,
        childReportFilters,
        childReportGlobalFilters,
        globalFiltersApplied: false,
      },
    });
  };

  const onDownloadUnderlyingDataClick = async () => {
    const { underlyingFilters } = getCurrentReportUnderlyingFilterData();
    if (currentReportId) {
      await onDownloadUnderlyingData(currentReportId, [], underlyingFilters);
    } else {
      await onDownloadVisualiseUnderlyingData(underlyingFilters);
    }
  };

  return {
    onChildReportClick,
    popOverVisible,
    setPopOverVisible,
    onPopOverVisibleChange,
    onChartColumnClick,
    popOverContentList,
    onPopOverContentClick,
    setPopOverPosition,
    popOverPosition,
  };
};
