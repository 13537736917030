import React from 'react';
import { numberFilter } from 'src/constants';
import { createUseStyles } from 'react-jss';
import { InputNumber, Select } from 'antd';

const useStyle = createUseStyles({
  baseNumberBox: {
    width: '15rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.7rem 1rem',
  },
  operator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
    width: '50%',
  },
  label: {
    textAlign: 'left',
    width: '100%',
  },
});

const NumberFilter = (props) => {
  const { selectedValue, onChangeRadio, onChangeFilterData } = props;
  const classes = useStyle();
  const numberFilterArray = Object.entries(numberFilter).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );
  return (
    <div className={classes.baseNumberBox}>
      <div className={classes.operator}>
        <div className={classes.label}>Operator</div>
        <Select
          style={{ width: '100%' }}
          value={selectedValue?.selectedFilter}
          onChange={(e) => onChangeRadio(e)}
          options={numberFilterArray}
        />
      </div>
      <div className={classes.operator}>
        <div className={classes.label}>Value</div>
        <InputNumber
          placeholder="Enter Value"
          disabled={['numberIsNull', 'numberIsNotNull'].includes(
            selectedValue?.selectedFilter,
          )}
          value={selectedValue?.data?.value}
          onChange={(e) => {
            onChangeFilterData(e);
          }}
        />
      </div>
    </div>
  );
};

export default NumberFilter;
