import React from 'react';
import SingleSelectList from './SingleSelectionMenu';
import Select from './selectFilters';
import Input from './Input';
import BetweenNumberInput from './betweenNumberInput';
import DateInput from './dateInput';
import RangePicker from './rangePicker';
import DatePreviousInput from './datePreviousInput';
import InputOrSelectFilter from './inputOrMultiSelect';
import SerchableSelect from './SearchableSelect';
import DayDateTimeBetween from './dayDateTime';

const FilterComponentMap = {
    listSingleSelect: SingleSelectList,
    text: Select,
    singleSelect: Select,
    numberInput: Input,
    betweenNumberInput: BetweenNumberInput,
    textInput: Input,
    multiSelect: Select,
    dateInput: DateInput,
    dateBetweenInput: RangePicker,
    datePreviousInput: DatePreviousInput,
    inputOrMultiSelect: InputOrSelectFilter,
    searchableSelect: SerchableSelect,
    dateLastInput : DatePreviousInput,
};

export default FilterComponentMap;
