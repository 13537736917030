import * as React from 'react';
import { useSelector } from 'react-redux';
import { Line } from '@ant-design/charts';
import LineConfigs from './ConfigCharts/configLine';

const LineViz = (props) => {
  const {
      formData,
      chartData,
      updateChartRef,
      chartConfig,
      visualizeMetaData,
      chartInteractionHook,
      showUnderlyingDataDownload,
      reportId,
      chartsFromDashboard,
    } = props;
  const lineConfig = LineConfigs({chartConfig, formData, chartsFromDashboard, chartData});
  const plotRef = React.useRef<any>(null);
  const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);

  React.useEffect(() => {
    if (plotRef.current) {
      plotRef.current.on('element:click', async (...args) => {
        const { points, data } = args[0]?.data;
        const namesArray = (data?.name || '')?.split(',').map((item) => item.trim());
        if (namesArray.length > 1) {
          data.name = namesArray[0];
        }
        const { x, y } = args[0]?.gEvent;
        chartInteractionHook.setPopOverPosition({ x, y });
        if (points && points.length === 1) {
          const showChildReport = visualizeMetaData?.columnsData[data.name]
            ?.linkedReport?.reportId
            ? true
            : false;
          const urlPath = visualizeMetaData?.summarizeData?.find(item => item.name === data.name)?.urlPath ||
            visualizeMetaData?.columnsData[data.name]?.urlPath;
          const showViewDetails = !!urlPath;
          await chartInteractionHook.onChartColumnClick({
            showChildReport,
            showUnderlyingDataDownload,
            showViewDetails,
            reportData: { visualizeMetaData, columnData: data },
            reportId,
            enableDummyDataSwitch,
          });
        }
      });
    }
  }, [enableDummyDataSwitch, reportId]);

  const onReady = async (plot) => {
    plotRef.current = plot;
    updateChartRef(plot);
  };
  const config: any = {
    data: chartData,
    ...formData,
    ...lineConfig,
    onReady,
  };
  return (
    <Line
      {...config}
    />
  );
};

export default LineViz;
