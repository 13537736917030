import { Button, Popover, Badge, Divider } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { CaretDownOutlined, FilterOutlined,PushpinFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_FILTER_RADIO_SELECTED,
  DASHBOARD_FILTER_SELECTED_VALUE_CHANGED,
  GENERIC_DASHBOARD_FILTER_DATA_RESET,
  DASHBOARD_FILTER_SELECTED_BOOL_VALUE_CHANGED,
  SET_APPLIED_FILTER,
} from 'src/reduxActions/actionNameEnums';
import GeneralFilter from 'src/components/common/GeneralFilterDashboard';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { numberFilter, dashboardFilterTypes, filterType } from 'src/constants';
import capitalize from 'lodash/capitalize';

const useStyles = createUseStyles({
  base: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0.2rem 0.5rem',
    justifyContent: 'space-between',
  },
  textStyle: {
    color: '#262626',
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '24px',
    cursor: 'pointer',
    padding: '8px',
    '&:hover': {
      background: '#efefef',
    },
  },
  addReportButton: {
    borderRadius: '4px',
    margin: 'auto',
    height:'80%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.7rem',
  },
  createDashboard: {
    borderRadius: '4px',
    backgroundColor: '#1890FF',
    marginLeft: '16px',
    marginRight: '4px',
  },
  downOutlinedIcon: {
    marginLeft: '8px',
    cursor: 'pointer',
  },
  lock: {
    fontSize: '20px',
    margin: 'auto 10px',
  },
  filterPane: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: 'auto 10px',
    '&:hover': {
        background: '#efefef',
    },
    padding: '8px',
  },
  filterText: {
    marginLeft: '6px',
  },
  popoverTime:{
    '& .ant-popover-inner-content':{
        padding: '0px 0px',
    },
  },
  arrowPosition1:{
    '& .ant-popover-arrow':{
      left:'15%',
    },
  },
  arrowPosition2:{
    '& .ant-popover-arrow':{
      left:'33%',
    },
  },
  blinkingDot:{
    animation: '$blink 1s infinite',
    position: 'absolute',
    bottom: '3%',
    right: '12%',
  },
  '@keyframes blink': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.3,
    },
    '100%' :{
      opacity: 1,
    },
  },
  filterContainer: {
    display: 'flex',
    maxWidth: '100%',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    alignItems: 'center',
    gap: '0.375rem',
    height: '100%',
  },
  dashboardFilter: {
    display: 'flex',
    alignItems: 'center',
    height: '80%',
    cursor: 'pointer',
    border: '0px solid #D9D9D9',
    borderRadius: '4px',
    justifyContent: 'center',
    fontSize: '0.8rem',
    color:'#000000D9',
  },
});

const TitleBar = (props) => {
  const {
    dashboardId,
    filters = [],
    dashboardTab,
    dashboardGroupId,
    onApplyFilter,
    removeFromLocalStorage,
    getCachedDashboards,
    onResetFilter,
    isSticky,
    setIsSticky,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterApplied = useSelector((state:any) => state.dashboardGroupMetaData.isFilterApplied);
  const [selectedFilter, setSelectedFilter] = useState<String>('');
  const [visiblePopOver, setVisiblePopOver] = useState<Boolean>(false);
  const [onReset, setOnReset] = useState(true);
  const filtersMetaData = useSelector((state:any) => state?.masterData?.supportedFilters);
  const filterData = useSelector((state:any) => state.dashboardFilter[dashboardTab]);
  const boolInitialState = {};
  for (const key in filterData) {
    if (filterData.hasOwnProperty(key) && filterData[key].type === 'boolean') {
      if (filterData[key].selectedFilter === 'booleanIsEqualTo') {
        boolInitialState[filterData[key].metric] = [JSON.stringify(filterData[key].data.value)];
      } else if (filterData[key].selectedFilter === 'booleanIsNull') {
        boolInitialState[filterData[key].metric] = ['null'];
      } else if (filterData[key].selectedFilter === 'booleanIsNotTrue') {
        boolInitialState[filterData[key].metric] = ['false', 'null'];
      } else if (filterData[key].selectedFilter === 'booleanIsNotFalse') {
        boolInitialState[filterData[key].metric] = ['true', 'null'];
      } else if (filterData[key].selectedFilter === 'booleanIsNotNull') {
        boolInitialState[filterData[key].metric] = ['true', 'false'];
      } else {
        boolInitialState[filterData[key].metric] = [];
      }
    }
  }

  const setFilterApplied = (value) => {
    dispatch({
      type: SET_APPLIED_FILTER,
      payload: value,
    });
  };

  const [checkedValues, setCheckedValues] = useState(boolInitialState);
  const handleBoolChange = (key,checkedValues) => {
    setCheckedValues((prevState) => ({
      ...prevState,
      [key]: checkedValues,
    }));
  };

  const onChangeRadio = (metricName, filterType) => value => {
    let filter;
    if(filtersMetaData){
    filter = filtersMetaData[filterType]?.find((obj) => obj.name === value);
    }
    const filterInputType = filter?.type;
    if(filterInputType==='noInput'){
      setFilterApplied(false);
    }
    dispatch({
    type: DASHBOARD_FILTER_RADIO_SELECTED,
    payload: {
        metricName,
        selectedFilter: value,
        dashboardTab,
        filterInputType,
        setFilterApplied,
        filterType,
    },
  });
};

const onChangeFilterData = (metricName) => data => {
  setFilterApplied(false);
  dispatch({
  type: DASHBOARD_FILTER_SELECTED_VALUE_CHANGED,
  payload: {
      metricName,
      dashboardTab,
      data: {
          value: data,
      },
  },
});
};

const onChangeFilterDataForSimple = (metricName, type) => (data) => {
  if(type === filterType.TEXT){
    onChangeRadio(metricName, type)('textIsEqualTo');
  }
  else if(type === filterType.TIMESTAMPZ){
  onChangeRadio(metricName, type)('timestampzBetween');
  }
  else if(type === filterType.DATE) {
    onChangeRadio(metricName, type)('timestampzBetween');
  }
  onChangeFilterData(metricName)(data);
};

  const onChangeBoolData = (metricName) => (checkedValues: CheckboxValueType[],selectedFilter,data) => {
    setFilterApplied(false);
    handleBoolChange(metricName, checkedValues);
    dispatch({
      type: DASHBOARD_FILTER_SELECTED_BOOL_VALUE_CHANGED,
      payload: {
        metricName,
        selectedFilter,
        dashboardTab,
        data: {
          value: data,
        },
      },
    });
  };
 //we update the dashboard tab filters in an existing dashboardGroup or adding new dashboardGroup
  const setLocalStorage = (appliedFilterData) => {
    appliedFilterData.forEach((filterData) => {
      const cachedDashboards = getCachedDashboards();
      const newTabFilters = {
        [filterData.metric]: {
          name: filterData.filter.name,
          data: filterData.filter.data,
          metric: filterData.metric,
          type: filterData.filter.type,
        },
      };
      const newLocalStorageChange = {
        dashboardGroupId,
        activeTabId: dashboardId,
        tabs: {
          [dashboardId]: {
            filters: newTabFilters,
          },
        },
      };
      const cachedCurrentDashboardIndex = cachedDashboards?.findIndex(
        (activeDashboard) =>
          activeDashboard.dashboardGroupId === dashboardGroupId,
      );//searching if the dashboardGroup is already present in localstorage
      const cachedCurrentDashboard =
        cachedDashboards[cachedCurrentDashboardIndex];
      const updateLocalStorageChange = {
        ...cachedCurrentDashboard,
        dashboardGroupId: newLocalStorageChange.dashboardGroupId,
        activeTabId: newLocalStorageChange.activeTabId,
        tabs: {
          ...cachedCurrentDashboard?.tabs,
          [dashboardId]: {
            filters: {
              ...(cachedCurrentDashboard?.tabs[dashboardId]?.filters ?? {}),
              ...newTabFilters,
            },
          },
        },
      };
      if (cachedCurrentDashboardIndex !== -1) {
        cachedDashboards.splice(cachedCurrentDashboardIndex, 1);
        //We need to remove the old dashboardGroup so that we can add the updated one.
      }
      cachedDashboards.push(updateLocalStorageChange);
      localStorage.setItem('activeDashboard', JSON.stringify(cachedDashboards));
    });
  };

  const metricValues = filters.map((item) => item.metric);
  const initialState = metricValues.reduce((acc, metric) => {
    // true for applied filters
    const filterData = filters.find((filter) => filter.metric === metric);
    const filterMeta = filtersMetaData[filterData.type]?.find(
      (obj) => obj.name === filterData.selectedFilter,
    );
    // when no selected filter radio button is selected
    if (!filterMeta) {
      return { ...acc, [metric]: false };
    }
    const filterInputType = filterMeta?.type;
    if (filterInputType === 'noInput' || filterData?.data) {
      return { ...acc, [metric]: true };
    }
    // when no data selected for filter
    return { ...acc, [metric]: false };

  }, {});
  const [filterColor, setFilterColor] = useState(initialState);

  const handleChange = (key) => {
    setFilterColor((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const resetFilter = () => {
    dispatch({
      type: GENERIC_DASHBOARD_FILTER_DATA_RESET,
      payload: {
        dashboardTab,
      },
    });
    removeFromLocalStorage(dashboardId);
    onResetFilter(dashboardTab);
    const filterColorInitialState = metricValues.reduce((acc, metric) => {
      return { ...acc, [metric]: false };
    }, {});
    const boolInitialState = {};
    for (const key in filterData) {
      if (filterData.hasOwnProperty(key) && filterData[key].type === 'boolean') {
        boolInitialState[filterData[key].metric] = [];
      }
    }
    setFilterColor(filterColorInitialState);
    setCheckedValues(boolInitialState);
    setFilterApplied(true);
    setOnReset(!onReset);
  };

  const applyingFilters = (dashboardTab) => {
    const applied = onApplyFilter(dashboardTab);
    setFilterColor(initialState);
    applied.map((item) => {
        handleChange(item.metric);
    });
    setLocalStorage(applied);
    setFilterApplied(true);
  };

  const filterDataSelectedName = (prettyName, metric, type) => {
    const data = filterData[metric]?.data?.value;
    const filterName = filterData[metric]?.selectedFilter;

    const getTypeText = () => {
      switch (type) {
        case filterType.TEXT:
          if (!data || data.length === 0 || filterName !=='textIsEqualTo') {
            return prettyName;
          }
          if(data.length === 1 || data.length === 2){
            return `${prettyName}: ${data.join(', ')}`;
          }
          const itemsToShow = data.length > 2 ? [data[0], data[1], `${data.length - 2}+other`] : [data[0], `${data.length - 1}+other`];
          return `${prettyName}: ${itemsToShow.join(', ')}`;

        case filterType.NUMBER:
          if(filterName === 'numberIsNull'){
            return `${prettyName}: Null`;
          }
          if(filterName === 'numberIsNotNull'){
            return `${prettyName}: Not Null`;
          }
          if(filterData[metric]?.data?.value != null && numberFilter[filterData[metric]?.selectedFilter]){
            return `${prettyName} ${numberFilter[filterData[metric]?.selectedFilter]} ${filterData[metric]?.data?.value}`;
          }
          return prettyName;

        case filterType.BOOLEAN:
          const arr = checkedValues[metric];
          if (!arr || arr.length === 0) {
            return prettyName;
          }
          if (arr.length === 1) {
            return `${prettyName}: ${capitalize(arr[0])}`;
          }
          if (arr.length === 3) {
            return `${prettyName}: All`;
          }
          const mapping = {
            booleanIsNotNull: 'Not Null',
            booleanIsNotTrue: 'Not True',
            booleanIsNotFalse: 'Not False',
          };
          return `${prettyName}: ${mapping[filterName]}`;

        case filterType.TIMESTAMPZ:
        case filterType.DATE:
          const { from, to } = filterData[metric]?.data?.value || {};
          if(from && to && filterName === 'timestampzBetween'){
            return `${prettyName}: ${from} - ${to}`;
          }
          return prettyName;
      }
    };

    return getTypeText() || prettyName;
  };

  return (
    <>
    <div
      style={{
        position: isSticky ? 'sticky' : 'relative',
        top: isSticky ? '3.2rem' : '0px',
        zIndex: '1',
        height: '2.5rem',
        margin: '0 0.5rem 0.1rem',
      }}
    >
    <div className={classes.base}>
      <FilterOutlined style={{ fontSize: '14px', marginRight: '10px' }}/>
      <div className={classes.filterContainer}>
            {filters.map((row, i) => {

        const filterRadioChangeHandler = onChangeRadio(row.metric,row.type);
        const filterListRadio = filtersMetaData[row.type] || [];
        const filterProps = {
          type: row.type,
          searchOptions: row.searchOptions,
          metricsName: row.metric,
          filterMetaData:row.filterMetaData,
          filterListRadio,
          onChangeBoolData:onChangeBoolData(row.metric),
          onChangeRadio: filterRadioChangeHandler,
          onChangeFilterData: onChangeFilterData(row.metric),
          onChangeFilterDataForSimple: onChangeFilterDataForSimple(row.metric,row.type),
          dashboardFilterType: row.dashboardFilterType,
          visiblePopOver,
          filterData,
          showfreezeOption: false,
          selectedFilterReportType: row.reportType,
          prettyName:row.prettyName,
          onApplyFilter,
          dashboardTab,
          checkedValues,
          setFilterApplied,
          onReset,
        };

        return (
          <Popover
            key={`${row.metric}_${dashboardTab}`}
            trigger="click"
            destroyTooltipOnHide={true}
            placement="bottom"
            arrowPointAtCenter={false}
            overlayClassName={`${
              i === 0
                ? classes.arrowPosition1
                : i === 1
                ? classes.arrowPosition2
                : ''
            }
        ${row.type !== 'boolean' ? classes.popoverTime : ''}`}
            content={<GeneralFilter {...filterProps} />}
            onVisibleChange={(visible) => {
              if(row.type === 'timestampz'){
                setVisiblePopOver(visible);
              }
              else if(row.type === 'date'){
                setVisiblePopOver(visible);
              }
              visible ? setSelectedFilter(row.metric) : setSelectedFilter('');
            }}
          >
          <Button
          data-gtm-id="dashboard-filter"
          type="text"
          className={classes.dashboardFilter}
          style={{
            backgroundColor:filterColor[row.metric] || selectedFilter === row.metric? '#C5E3FF':undefined,
           }}>{row.dashboardFilterType === dashboardFilterTypes.SIMPLE
              ? filterDataSelectedName(row.prettyName, row.metric, row.type)
              : row.prettyName}
           <CaretDownOutlined style={{ display: 'flex', color: '#000000D9' }} />
        </Button>
          </Popover>);
      })}
      </div>
      <div style={{ marginLeft: 'auto', display: 'flex', height: '100%' }}>
      <Divider style={{ height: '100%' }} type="vertical" />
      <Button
        data-gtm-id="dashboard-apply-filter"
          style={{
            borderColor: !filterApplied ? '#1890FF' : '#d9d9d9',
            color: !filterApplied ? '#1890FF' : '#d9d9d9',
          }}
          disabled={filterApplied}
          onClick={() => applyingFilters(dashboardTab)}
          className={classes.addReportButton}>
              Apply Filter
              {!filterApplied ? (
              <Badge
                dot={true}
                offset={[5, -5]}
                className={classes.blinkingDot}
                style={{ backgroundColor: '#FF0000' }}
              />
               ) : null}
      </Button>
      <Button
        data-gtm-id="dashboard-reset-filter"
        type="link" style={{ color: '#FF4D4F', fontSize: '0.7rem' }}
        onClick={resetFilter}>
        Reset Filters
      </Button>
      <Button
          data-gtm-id="dashboard-pin-filter"
          onClick={() => setIsSticky(!isSticky)}
          style={{
            color: isSticky ? '#1890FF' : '#000000D9',
            borderColor: isSticky ? '#1890FF' : '#C8C8C8',
            borderRadius: '4px',
          }}
       >
          <PushpinFilled />
      </Button>
      </div>
    </div>
    </div>
    </>
  );
};

export default memo(TitleBar);
