import lodashCloneDeep from 'lodash/cloneDeep';

import {
  ACTION_CHANGED,
  ACTION_INITIALISE,
} from 'src/reduxActions/actionNameEnums';

const initialState = [];

const actionReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const actionsClone = lodashCloneDeep(state);
    switch (type) {
      case ACTION_INITIALISE:
        return [];
      case ACTION_CHANGED:
        return lodashCloneDeep(payload);
      default:
        return actionsClone;
    }
};

export default actionReducer;
