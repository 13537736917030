import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { notification, message } from 'antd';
import { DOWNLOAD_REPORT, DOWNLOAD_UNDERLYING_REPORT_DATA } from './dashboardQueries';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_DOWNLOAD_MODAL_STATE } from 'src/reduxActions/actionNameEnums';

export const useDownloadReport = () => {
    const dispatch = useDispatch();
    const externalFilters = useSelector((state: any) => state.config.externalFilters);
    const [downloadReport, { loading: downloadRequestInProgress }] = useLazyQuery(DOWNLOAD_REPORT, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            notification['success']({
                message: 'Download Request Recieved',
                description:
                  <div style={{ cursor: 'pointer', color: '#006EC3'}}>
                      {'Click here to see the status of your download request.'}
                      </div>,
                onClick: () => {
                    dispatch({
                        type: CHANGE_DOWNLOAD_MODAL_STATE,
                        payload: true,
                    });
                },
              });
        },
        onError: (data: any) => {
          const errorMessage = data?.networkError?.result?.errors[0]?.message || 'Failed to Submit Download Requests';
          message.error({ key:'downloadReport', content: errorMessage, duration: 2 });
        },
    });
    const onDownload = (reportId, globalFilters, childReportFilters) => {
      downloadReport({
        variables: {
          reportId,
          globalFilters,
          childReportFilters,
          externalFilters,
        },
      });
    };

    const [
      downloadUnderlyingDataReport,
      { loading: downloadUnderlyingDataRequestInProgress },
    ] = useLazyQuery(DOWNLOAD_UNDERLYING_REPORT_DATA, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        notification['success']({
          message: 'Download Request Received',
          description: (
            <div style={{ cursor: 'pointer', color: '#006EC3' }}>
              {'Click here to see the status of your download request.'}
            </div>
          ),
          onClick: () => {
            dispatch({
              type: CHANGE_DOWNLOAD_MODAL_STATE,
              payload: true,
            });
          },
        });
      },
      onError: (data: any) => {
        // const errorMessage = data?.networkError?.result?.errors[0]?.message || 'Failed to Submit Download Requests';
        const errorMessage = 'Failed to Submit Download Requests';
        message.error({ key:'downloadReport', content: errorMessage, duration: 2 });
      },
    });

  const onDownloadUnderlyingData = (reportId, globalFilters, childReportFilters) => {
      downloadUnderlyingDataReport({
        variables: {
          reportId,
          globalFilters,
          childReportFilters,
          externalFilters,
        },
      });
    };

    return {
        onDownload,
        downloadRequestInProgress,
        onDownloadUnderlyingData,
        downloadUnderlyingDataRequestInProgress,
    };
};
