import { Popover, PopoverProps, Tooltip } from 'antd';
import React, { FC, ReactNode, useState, isValidElement } from 'react';
import { SwitcherContent, SwitcherContentProps } from './content';
import { getSwitcherClassName } from './util';
import { SwapOutlined } from '@ant-design/icons';

export interface SwitcherProps
  extends Omit<SwitcherContentProps, 'onToggleVisible'> {
  title?: ReactNode;
  // ref: https://ant.design/components/popover-cn/#API
  popover?: PopoverProps;
  disabled?: boolean;
}

export const Switcher: FC<SwitcherProps> = ({
  title,
  popover,
  disabled,
  style = {},
  ...otherProps
}) => {
  const [visible, setVisible] = useState(false);
  const onToggleVisible = () => {
    setVisible((prev) => !prev);
  };

  return (
    <Popover
      visible={!disabled && visible}
      content={
        <SwitcherContent {...otherProps} onToggleVisible={onToggleVisible} />
      }
      onVisibleChange={onToggleVisible}
      trigger="click"
      placement="bottomLeft"
      destroyTooltipOnHide
      {...popover}
    >
      {isValidElement(title) ? (
        title
      ) : (
        <Tooltip title={'Switch Dimensions'}>
          <div
            className={getSwitcherClassName('entry-button')}
            style={{ justifyContent: 'center', ...style }}
          >
            <SwapOutlined
              style={{
                rotate: '90deg',
                fontSize: '18px',
                marginRight: '16px',
                fontWeight: 'bold',
              }}
            />
            {title}
          </div>
        </Tooltip>
      )}
    </Popover>
  );
};

Switcher.defaultProps = {
  title: '',
  popover: {},
  disabled: false,
};
