import React from 'react';
import { useDispatch } from 'react-redux';
import { Divider, InputNumber, Switch } from 'antd';
import CustomColorPicker from 'src/components/common/UIComponents/CustomColorPicker';
import {
  FORMATTING_ACTION_VALUE_CHANGE,
  FORMATTING_ACTION_COLOR_CHANGE,
} from 'src/reduxActions/actionNameEnums';
import { useStyles } from './styles';
import lodashCloneDeep from 'lodash/cloneDeep';

const ActionForm = (props) => {
  const { rules, index, ruleIndex } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const rule = rules[ruleIndex].rules[index];
  const { actions } = rule;

  const onBucketNumberChange = (value, index, actionIdx) => {
    const rulesCopy = lodashCloneDeep(rules);
    rulesCopy[ruleIndex].rules[index].actions[actionIdx] = {
      ...rulesCopy[ruleIndex].rules[index].actions[actionIdx],
      noOfBuckets: value,
    };

    dispatch({
      type: FORMATTING_ACTION_VALUE_CHANGE,
      payload: rulesCopy,
    });
  };

  const onActionValueChange = (value, index, actionIdx) => {
    const rulesCopy = lodashCloneDeep(rules);
    rulesCopy[ruleIndex].rules[index].actions[actionIdx] = {
      ...rulesCopy[ruleIndex].rules[index].actions[actionIdx],
      value: value ? 'custom' : 'automatic',
    };

    dispatch({
      type: FORMATTING_ACTION_VALUE_CHANGE,
      payload: rulesCopy,
    });
  };

  const onActionColorChange = (color, index, actionIdx, key) => {
    const rulesCopy = lodashCloneDeep(rules);
    rulesCopy[ruleIndex].rules[index].actions[actionIdx] = {
      ...rulesCopy[ruleIndex].rules[index].actions[actionIdx],
      [key]: color.hex,
    };

    dispatch({
      type: FORMATTING_ACTION_COLOR_CHANGE,
      payload: rulesCopy,
    });
  };

  return (
    <div className={classes.actionForm}>
      <div className={classes.actionTitle}>
        {index === rules[ruleIndex].rules.length - 1 ? 'Else Then' : 'Then'}
      </div>
      <div className={classes.actionsContainer}>
        {actions.map((action, actionIdx) => {
          return (
            <div>
              <div className={classes.actionContainer}>
                {action && (
                  <div className={classes.actionWrapper}>
                    <div className={classes.actionTypeContainer}>
                      {
                        {
                          backgroundColor: 'Background',
                          textColor: 'Text',
                        }[action.actionType]
                      }
                    </div>
                    <div className={classes.customFieldContainer}>
                      <p className={classes.fieldName}>Custom</p>
                      <Switch
                        size={'small'}
                        checked={action.value === 'custom'}
                        onChange={(val) =>
                          onActionValueChange(val, index, actionIdx)
                        }
                      />
                    </div>
                    {action.value === 'custom' && (
                      <div className={classes.fieldContainer}>
                        <p className={classes.fieldName}>Color</p>
                        <CustomColorPicker
                          onChangeColor={(color) =>
                            onActionColorChange(
                              color,
                              index,
                              actionIdx,
                              'color',
                            )
                          }
                          selectedOption={{ color: action.color }}
                        />
                      </div>
                    )}
                    {action.value === 'automatic' && (
                      <div className={classes.fieldContainer}>
                        <p className={classes.fieldName}>Buckets</p>
                        <InputNumber
                          min={2}
                          defaultValue={6}
                          style={{ width: '3rem' }}
                          value={action.noOfBuckets}
                          onChange={(value) =>
                            onBucketNumberChange(value, index, actionIdx)
                          }
                        ></InputNumber>
                      </div>
                    )}
                    {action.value === 'automatic' && (
                      <div className={classes.fieldContainer}>
                        <div className={classes.fieldName}>Gradient</div>
                        <CustomColorPicker
                          onChangeColor={(color) =>
                            onActionColorChange(
                              color,
                              index,
                              actionIdx,
                              'gradientStart',
                            )
                          }
                          selectedOption={{
                            color: action.gradientStart,
                          }}
                        />
                        <div style={{ color: 'rgba(0, 0, 0, 0.85)' }}>-</div>
                        <CustomColorPicker
                          onChangeColor={(color) =>
                            onActionColorChange(
                              color,
                              index,
                              actionIdx,
                              'gradientEnd',
                            )
                          }
                          selectedOption={{
                            color: action.gradientEnd,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {actionIdx !== actions.length - 1 && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Divider className={classes.actionDivider} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActionForm;
