import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import UnderlyingColumnsSection from './dataSetupContainerPart/UnderlyingColumns';
import { isManualQueryReport } from 'src/components/common/utils/utils';
import UnderlyingCustomQuery from './dataSetupContainerPart/UnderlyingCustomQuery';

const useStyle = createUseStyles({
  sectionContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '12px',
  },
  containerHeading: {
    fontSize: '10px',
    fontWeight: '400',
    color: '#8C8C8C',
  },
});

const UnderlyingDataSection = (props) => {
  const { underlyingMetrics, reportObject } = props;
  const classes = useStyle();
  return (
    <>
      <div style={{ width: '100%' }}>
        <div className={classes.sectionContainer}>
          <div className={classes.containerHeading}>
            Pick and choose the data elements here
          </div>
        </div>
        {isManualQueryReport(reportObject.selectedDataMartType) ? (
          <UnderlyingCustomQuery />
        ) : (
          <UnderlyingColumnsSection underlyingMetrics={underlyingMetrics} />
        )}
      </div>
    </>
  );
};

export default memo(UnderlyingDataSection);
