import React, { memo, useEffect } from 'react';
import { GetChartConfigData } from 'src/components/common/utils/utils';
import { tranformPieChartData } from 'src/components/utils/utils';

const PieConfigs = (props) => {
  const { chartConfig, formData, chartsFromDashboard, chartData } = props;
  const configObject = {
    chartConfig,
    configType: 'pie',
    formData,
    chartsFromDashboard,
  };
  const configData = GetChartConfigData(configObject);
  if (!configData) {
    return null;
  }
  const { graphicLabel: label, legend } = configData['pie'] || {};
  const { type, offset, offsetX, offsetY, labelSize } =
    configData['graphicLabel'] || {};
  const {
    location,
    flipPage,
    offsetX: legendOffsetX,
    offsetY: legendOffsetY,
  } = configData['legend'] || {};
  const { tooltip, color, radius, percentageThreshold, colorMap } =
    configData['graphic'] || {};
  const finalData = tranformPieChartData(
    chartData,
    percentageThreshold?.data ?? 2,
  );
  let colorArray: any = [];
  finalData.forEach((item, index) => {
    if (colorMap) {
      const color = colorMap.data[item.x];
      if (color) {
        colorArray.push(color);
      }
    }
  });
  colorArray = [...colorArray, ...color?.data];
  const pieConfig = {
    label: label?.data
      ? {
          type: type?.data,
          offset: offset?.data,
          offsetX: offsetX?.data,
          offsetY: offsetY?.data,
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: labelSize?.data,
            textAlign: 'center',
          },
        }
      : undefined,
    legend: legend?.data
      ? {
          position: location?.data,
          flipPage: flipPage?.data,
          offsetX: legendOffsetX?.data,
          offsetY: legendOffsetY?.data,
        }
      : false,
    radius: radius?.data / 100,
    tooltip: tooltip?.data,
    color: colorArray,
    theme: {
      background: '#FFFFFF',
    },
    othersThreshold: percentageThreshold?.data,
    data: finalData,
  };
  return pieConfig;
};

export default PieConfigs;
