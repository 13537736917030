import React, { memo, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import {
  useAggregate,
  useFilters,
  useGenericAggregate,
  useGenericFilters,
  useHavingFilters,
  useSortBy,
} from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/useMetricsHook';
import CreateMetricSection from './CreateMetricSection';
import { SET_IS_AGGREGATED } from 'src/reduxActions/actionNameEnums';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useReport, useSetInitialReportData } from '../../../pages/chartBuilder/createQueryComponents/BodyParts/createQueryBodyHooks';

const useStyles = createUseStyles({
  baseCardStyle: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '30%',
  },
  scrollable: {
    overflow: 'auto',
    height: '100%',
  },
});

const modifyLocationState = (locationState) => {
  if (!locationState) return;

  const customMetrices = locationState.customMetrices.map((item) => {
    return {
      ...item,
      id: uuidv4(),
      name: item.metric,
    };
  });
  const customMetricesObj = {};
  customMetrices.forEach(
    (element) => (customMetricesObj[element.id] = element),
  );

  const customAggregateMetrices = locationState.customAggregateMetrices.map(
    (item) => {
      return {
        ...item,
        id: uuidv4(),
        name: item.metric,
      };
    },
  );

  const customAggregateMetricesObj = {};
  customAggregateMetrices.forEach(
    (element) => (customAggregateMetricesObj[element.id] = element),
  );

  locationState.customAggregateMetricesObj = customAggregateMetricesObj;
  locationState.customMetricesObj = customMetricesObj;

  return locationState;
};

const CreateMetricQuery = (props) => {
  const classes = useStyles();
  const { submitMetric, reportData } = props;
  const locationState =  modifyLocationState(reportData);
  const setReportSelectedData:any = useSetInitialReportData(locationState);
  const useReportObject = useReport({
    ...setReportSelectedData,
    options: { defaultChartType: 'line' },
  }, true);

  const dispatch = useDispatch();
  const aggregate = useAggregate(useReportObject.metrics);
  const timeline = useGenericAggregate(useReportObject.metrics, 'timeline');
  const summariseBy = useGenericAggregate(
    useReportObject.metrics,
    'summariseBy',
  );
  const filters = useFilters(useReportObject.metrics);
  const coverage = useGenericFilters(useReportObject.metrics, 'coverage');
  const havingFilters = useHavingFilters(useReportObject.metrics);
  const sortBy = useSortBy(aggregate.metricsMetaData.selectedMetrics);
  const initialSuggestedFilters = useSelector(
    (state: any) => state.reportMetaData?.suggestedFilterStrings,
  );

  const setChecked = (data) =>
    dispatch({ type: SET_IS_AGGREGATED, payload: data });

  useEffect(() => {
    setChecked(true);
  }, []);

  return (
    <>
      <div className={classes.baseCardStyle}>
        <div className={classes.scrollable}>
          <CreateMetricSection
            reportObject={useReportObject}
            submitReport={submitMetric}
            setReset={useReportObject.resetEverything}
            aggregate={aggregate}
            filters={filters}
            coverage={coverage}
            timeline={timeline}
            summariseBy={summariseBy}
            havingFilters={havingFilters}
            sortBy={sortBy}
            initialSuggestedFilters={initialSuggestedFilters}
          />
        </div>
      </div>
    </>
  );
};

export default memo(CreateMetricQuery);
