import * as React from 'react';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Col, Input, Row, Tooltip } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  overflowScroll: {
    maxHeight: '250px',
    overflow: 'scroll',
    fontSize: '12px',
    padding: '0px 10px 10px 10px',
    backgroundColor: '#FFF',
    border: '1px solid #D9D9D9',
    marginTop: '1rem',
  },
  paddingTop10px: {
    paddingTop: '10px',
  },
  hideCopy: {
    display: 'none',
  },
  tagTextDecoration: {
    textDecoration: 'underline',
  },
  tableHeader: {
    opacity: '0.8',
    fontSize: '12px',
  },
  infoCircleOutlined: {
    marginLeft: 5,
  },
  handlersContainer: {
    width: '100%',
    background: '#f9f9f9',
    padding: '0.75rem 1rem',
    borderRadius: '4px',
  },
  handlersTitle: {
    letterSpacing: '0.08em',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
});

const Handlers = ({ handlers }) => {
  const classes = useStyle();
  const [handlerSearch, setHandlerSearch] = React.useState<string>('');
  const [tagHover, setTagHover] = React.useState('');
  const [copyElement, setCopyElement] = React.useState('');

  const handleCopyPlaceholder = (key) => {
    setCopyElement(key);
  };

  const filteredHandlers = () => {
    const keyword = handlerSearch.toLowerCase();
    const filteredList = handlers?.filter((handler) => {
      let { name } = handler;
      name = name.toLowerCase();
      return name.indexOf(keyword) > -1;
    });
    return filteredList || [];
  };

  const handleTagHover = (key) => {
    setTagHover(key);
  };

  return (
    <div className={classes.handlersContainer}>
      <Row>
        <Col span={10} className={classes.handlersTitle}>
          HANDLERS
        </Col>
        <Col span={14}>
          <Input.Search
            placeholder="Search Handlers"
            onChange={(e) => setHandlerSearch(e.target.value)}
          />
        </Col>
      </Row>
      <div className={classes.overflowScroll}>
        <Row
          className={classNames(classes.paddingTop10px, classes.tableHeader)}
        >
          <Col span={12}>Type</Col>
          <Col>Handler</Col>
        </Row>
        {filteredHandlers().map((elem) => (
          <Row key={elem.name} className={classes.paddingTop10px}>
            <Col span={12}>
              {elem.name}
              <Tooltip title={elem.description}>
                <InfoCircleOutlined className={classes.infoCircleOutlined} />
              </Tooltip>
            </Col>
            <Col
              onMouseOver={() => handleTagHover(elem.name)}
              onMouseLeave={() => handleTagHover('')}
            >
              <span
                className={classNames({
                  [classes.tagTextDecoration]: elem.name === tagHover,
                })}
              >
                &#123;&#123;{elem.name}&#125;&#125;
              </span>
              &nbsp;&nbsp;
              <Tooltip title={elem.example}>
                <InfoCircleOutlined className={classes.infoCircleOutlined} />
              </Tooltip>
              <CopyToClipboard text={'{{' + copyElement + '}}'}>
                <CopyOutlined
                  className={classNames({
                    [classes.hideCopy]: elem.name !== tagHover,
                  })}
                  onMouseOver={() => handleCopyPlaceholder(elem.name)}
                />
              </CopyToClipboard>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default Handlers;
