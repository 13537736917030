import { Divider } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    divider: {
        margin: '0px',
    },
});

const CustomDivider = (props) => {
    const { className, style } = props;
    const classes = useStyles();

    return <Divider className={`${classes.divider} ${className}`} style={style}/>;
};

export default CustomDivider;
