import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { GET_REPORTS_BY_NAME } from 'src/api/routes';
import useLazyAPI from './useLazyAPI';
import lodash from 'lodash';

export const useReports = (params,isPagination=true,canEditDefaultDashboard=false, isDashboard=false) => {
    const { onCompletedCallBack, reportType } = params;
    const [offSet,setOffSet] = useState(1);
    const [sortOrder,setSortOrder] = useState('desc');
    const [sortByKey, setSortByKey] = useState('updationTime');
    const selectedReportType = useSelector((state:any) => state.config.selectedReportType);
    const [type, setType] = useState(selectedReportType || ['user','default', 'shared']);
    const [dateRange, setDateRange] = useState<any>([]);
    const [category, setCategory] = useState(['all']);
    const [createdBy, setCreatedBy] = useState([]);
    const [empDetailsArray, setEmpDetailsArray] = useState([]);
    const [searchText, setSearch] = useState('');
    const [tab, setTab] = useState(reportType || 'default');

    const [getReports, { loading: loadingReports, data }] = useLazyAPI(GET_REPORTS_BY_NAME, {
        type: 'POST',
        onCompleted: onCompletedCallBack,
    });

    const debouncedSearch = useCallback(
        lodash.debounce((nextValue) => getReports(nextValue), 800),
        [],
    );

    const fetchReports = () => {
        const filters: any = [];
        if(dateRange?.length === 2) {
            filters.push({
                key: sortByKey,
                data: {
                        from: dateRange[0].format('YYYY-MM-DD'),
                        to: dateRange[1].format('YYYY-MM-DD'),
                    },
            });
        }

        if(createdBy?.length) {
            filters.push({
                key: 'userId',
                data: createdBy,
            });
        }

        let typeFilter = canEditDefaultDashboard ?
        ['default'] :
        !isDashboard ? type : ['user', 'shared']; // isDashboard check is used for Adding report in Dashboard

        if(typeFilter === 'all') {
            typeFilter = ['user','default', 'shared'];
        }

        if(typeof typeFilter === 'string') {
            typeFilter = [typeFilter];
        }
        filters.push({
            key: 'type',
            data: tab === 'default' ?
               typeFilter :
            ['user', 'shared'],
        });

        if(searchText.length === 0 || searchText.length > 2) {
            debouncedSearch({
                variables: {
                    searchQuery: searchText,
                    offSet,
                    isPagination,
                    tab,
                    sortBy: {
                        key: sortByKey,
                        order: sortOrder,
                    },
                    filters,
                },
            });
        }
    };

    useEffect(() => {
        fetchReports();
    },[offSet, type, sortOrder, dateRange, category, createdBy, searchText, tab]);

    return {
        getReports,
        loadingReports,
        data: data || {},
        offSet,
        setOffSet,
        setType,
        type,
        sortOrder,
        setSortOrder,
        setDateRange,
        dateRange,
        setCategory,
        empDetailsArray,
        setEmpDetailsArray,
        setCreatedBy,
        createdBy,
        searchText,
        setSearch,
        tab,
        setTab,
        sortByKey,
        setSortByKey,
        fetchReports,
    };
};
