import React, { memo,useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import CreateQuery from './createQuery';
import { useDispatch, useSelector } from 'react-redux';
import { cleanObject, processData } from 'src/components/pages/reports/utils';
import {message,Spin } from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import lodashIsEmpty from 'lodash/isEmpty';
import { useLazyQuery } from '@apollo/client';
import { GET_REPORT_BY_ID } from '../dashboard/hooks/dashboardQueries';
import { useParams } from 'react-router-dom';
import VisualisationPane from './visualisationPane';
import './chartBuilder.style.css';
import { useSubmitReport } from './hooks/submitReportHook';
import { SET_REPORT_MASTER_DATA, UPDATE_REPORT_NAME } from 'src/reduxActions/actionNameEnums';
import ChildReportModal from 'src/components/common/ChildReportModal';
import { useRemoveReportData } from './createQueryComponents/BodyParts/createQueryBodyHooks';
import { selectNumCompletedTodos } from 'src/components/utils/utils';
import { processDataForVisualisation } from 'src/components/pages/reports/utils';
import { AUTO_RUN_REPORT_DISABLE } from 'src/constants';

const useStyle = createUseStyles({
    layout: {
        display: 'flex',
        margin: '16px 16px 0px 16px',
        width: '100%',
    },
});
const ChartBuilder = (props) => {
    const { reportId } = useParams();
    const classes = useStyle();
    const dispatch = useDispatch();
    const chartData = useSelector(selectNumCompletedTodos);
    const submitReport = useSubmitReport({ chartData });
    const removeReportData = useRemoveReportData();
    const masterDataInState = useSelector((state:any) => state.masterData);
    const reportConfig = useSelector((state: any) => state.reportConfig);
    const { isLoading: reportConfigLoading, reports } = reportConfig;

    const [reportData, setReportData] = React.useState<any>(null);
    const [reportLoading, setReportLoading] = React.useState(true);
    const [getReportById] = useLazyQuery(
        GET_REPORT_BY_ID,
        {
            fetchPolicy: 'network-only',
            onCompleted: data => {
                message.success({ key: 'ReportData', duration: 0.5, content: 'Data Fetched Successfully' });
                const response = data.getReportById;
                setReportData(processData(response));
                dispatch({type: UPDATE_REPORT_NAME, payload: response?.name});
                setReportLoading(false);
                if(!AUTO_RUN_REPORT_DISABLE.includes(response.reportType)) {
                  const visualisationVariables = processDataForVisualisation(response);
                  submitReport.submitQuery({variables: cleanObject(visualisationVariables)});
                }
            },
            onError: data => {
                message.error({key:'ReportStatus', duration: 0.5, content: 'Failed fetching Report Meta Data'});
            },
        },
      );
    useEffect(() => {
      return () => {
        // Perform cleanup for report data
        removeReportData.removeData();
      };
    }, []);

    useEffect(() => {
        // create new from reports page
        if (!reportId) {
            setReportData(null);
            setReportLoading(false);
        }
        // reportId directly from url or from reports section
        if (reportId) {
            getReportById({ variables: { reportId, fetchAutomationSchedules: true }});
        }
    },[reportId]);

    if (
        reportLoading ||
        reportConfigLoading ||
        lodashIsEmpty(masterDataInState) ||
        lodashIsEmpty(reports)
      ) {
        const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
        return (
          <Spin
            indicator={antIcon}
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />
        );
      }

    return <div className={classes.layout}>
        <CreateQuery submitReport={submitReport} reportData={reportData}/>
        <div style={{ display: 'flex', flex: 1 }}>
            <VisualisationPane
                fields={submitReport.fields}
                setFields={submitReport.setFields}
                switcherFields={submitReport.switcherFields}
                setSwitcherFields={submitReport.setSwitcherFields}
                chartData={chartData}
                submitQueryloading={submitReport.submitQueryloading}
                stopQuery={submitReport.stopQuery}
                reportData={reportData}
            />
        </div>
        <ChildReportModal />
    </div>;
};
export default memo(ChartBuilder);
