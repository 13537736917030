import moment from 'moment';
import { filterTimeFormats, buckets } from 'src/constants';

export const getToFromTimeString = (momentTime, bucketValue) => {
  return {
    from: moment(momentTime).startOf(bucketValue).format('YYYY-MM-DD'),
    to: moment(momentTime).endOf(bucketValue).format('YYYY-MM-DD'),
  };
};

export const getToFromTimeStringObject = (bucketValue, value) => {
  let toFromObject: any = {};
  const momentTime = moment(value, filterTimeFormats[bucketValue]);
  switch (bucketValue) {
    case 'year':
    case 'quarter':
    case 'month':
    case 'day':
      toFromObject = getToFromTimeString(momentTime, bucketValue);
      return toFromObject;
    case 'week':
      toFromObject = {
        to: moment(momentTime).endOf(bucketValue).format('YYYY-MM-DD'),
        from: moment(momentTime)
          .startOf(bucketValue)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      };
      return toFromObject;
    case 'hour':
      toFromObject = {
        to: moment(momentTime).startOf(bucketValue).format('YYYY-MM-DD H:mm'),
        from: moment(momentTime).endOf(bucketValue).format('YYYY-MM-DD H:mm'),
      };
      return toFromObject;
    default:
      return toFromObject;
  }
};

export const getCurrentBucketValue = (initialbucket, level) => {
  const bucketIndex = buckets.indexOf(initialbucket);
  if (bucketIndex + level - 1 > buckets.length) {
    return null;
  }
  return buckets[bucketIndex + level - 1];
};
