import React, { useEffect } from 'react';
import { Select, Avatar, Popover, Tooltip } from 'antd';
import { createUseStyles } from 'react-jss';
const { Option } = Select;

const useStyle = createUseStyles({
    basePane: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '4px',
        marginBottom: '20px',
    },
    prettyName: {
        lineHeight: '14px',
        letterSpacing: '0px',
        color: '#303030',
        height: '14px',
        marginBottom: '0.5rem',
        fontSize: '12px',
    },
});

const SelectFilter = (props) => {
    const classes = useStyle();
    const { onChange, prettyName, value, type='singleSelect',data, placeHolder='Select', size = 'small', showSearch = false, style, ...rest} = props;
    const isMultiSelect = type !== 'singleSelect' ? true : false;
    const onChangeSelect = (value) => onChange(value);
    const options = data?.options || [];

    useEffect(() => {
        if(!value && (options.length > 0)){
            if(!isMultiSelect){
                onChange(options[0].value);
            }
        }
    },[value, options]);

    return <>
            <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                showSearch={showSearch}
                style={style}
                size={size}
                placeholder={placeHolder}
                mode={isMultiSelect ? 'tags' as const : undefined}
                maxTagCount={isMultiSelect ? 1 : undefined}
                onChange={onChangeSelect}
                value={value}
                filterOption={(input, option) =>
                    JSON.stringify(option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                maxTagPlaceholder={isMultiSelect ? (arr) => <>+{arr.length} More</> : null}
                {...rest}>
                {options.map((singleOption: any, i) => <Option label={singleOption.prettyName} value={singleOption.value} key={i}>
                        <Tooltip placement="right" title={singleOption.description || singleOption.prettyName}>
                        {singleOption.prettyName}
                        </Tooltip>
                    </Option>)}
            </Select>
    </>;
};

const LabelHandlerComponent = (props) => {
    const classes = useStyle();
    const { prettyName, noLabel = false, mandatory, ...rest } = props;
    const selectProps = { ...props, ...rest };
    return noLabel ? <SelectFilter {...selectProps} /> : <div className={classes.basePane}>
    <span className={classes.prettyName}>{prettyName} {mandatory && <span style={{ color: 'red' }}>*</span>}</span>
        <SelectFilter {...selectProps}/>
    </div>;
};

export default LabelHandlerComponent;
