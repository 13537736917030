import React, { memo, useEffect } from 'react';
import InputMap from 'src/components/common/UIComponents/mapping';
import { configObjects } from './configObjects';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { AnyColorFormat } from 'antd-colorpicker';
import { colors } from 'src/components/utils/colors';

const useStyle = createUseStyles({
  switchStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  infoTip: {
    marginLeft: '0.3rem',
  },
  theme: {
    '& .ant-select-item-option-content': {
      height: '2.25rem',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Inputbox = (props) => {
  const classes = useStyle();
  const {
    onChange,
    rowContainer,
    parent,
    rowProperties, //object which constains data,name,prettyName
    selectedChart,
    defaultValue,
    colorOptions,
    setColorOptions,
    colorSelectedOption,
    setColorSelectedOption,
    disabled,
  } = props;

  const reducerVal = useSelector(
    (state: any) => state.chartsMetaData.chartConfig,
  );
  const value = reducerVal?.[parent]?.[rowProperties.name]?.data;
  const InputComponent = InputMap[rowProperties.type];

  useEffect(() => {
    if (value === undefined) {
      onChange(defaultValue, rowProperties.name, parent);
    }
  }, [rowProperties, selectedChart]);

  const onChangeValue = (value) => {
    if (
      rowProperties.name === 'color' &&
      (['pie', 'bar', 'line']).includes(selectedChart)
    ) {
      onChange(value, 'colorMap', parent);
      return;
    }
    onChange(value, rowProperties.name, parent);
  };

  const onThemeChange = (value) => {
    onChangeValue(value);
    onChange(colors[value], 'color', 'graphic');
    const updatecolorOptions = colorOptions.map((option, index) => {
      return { ...option, color: colors[value][index % 20] };
    });
    setColorOptions(updatecolorOptions);
    setColorSelectedOption(updatecolorOptions[0]);
  };

  const onChangeColor = (color: AnyColorFormat) => {
    const hexColor = color.hex;
    const updatedOptions = [...colorOptions];
    const index = updatedOptions.findIndex(
      (opt) => opt.value === colorSelectedOption.value,
    );
    updatedOptions[index].color = hexColor;
    setColorOptions(updatedOptions);
    setColorSelectedOption(updatedOptions[index]);
    if ((['pie', 'bar', 'line']).includes(selectedChart)) {
      const colorsMap = colorOptions.reduce((acc, { value, color }) => {
        acc[value] = color;
        return acc;
      }, {});
      onChangeValue(colorsMap);
      return;
    }
    const colors = colorOptions.map((option) => option.color);
    onChangeValue(colors);
  };

  const sizeProperty = () => {
    return rowProperties.name === 'themeCategory' ? 'middle' : 'small';
  };
  const selectDropDownClass = () => {
    return rowProperties.name === 'themeCategory' ? classes.theme : '';
  };
  const selectOnChange = () => {
    return rowProperties.name === 'themeCategory'
      ? onThemeChange
      : onChangeValue;
  };

  return (
    <div className={rowContainer}>
      {rowProperties.prettyName ? (
        <div
          className={classes.label}
          style={{
            width: rowProperties.type === 'switch' ? '60%' : '35%',
          }}
        >
          {rowProperties.prettyName}
          {rowProperties.tooltip ? (
            <Tooltip placement="right" title={rowProperties.tooltip}>
              <InfoCircleOutlined className={classes.infoTip} />
            </Tooltip>
          ) : null}
        </div>
      ) : null}
      <div
        style={{
          width:
            rowProperties.type === 'switch'
              ? '40%'
              : rowProperties.prettyName
              ? '57%'
              : '100%',
        }}
      >
        <div>
          <InputComponent
            value={value}
            data={rowProperties.data}
            onChange={selectOnChange()}
            name={rowProperties.name}
            size={sizeProperty()}
            disabled={disabled}
            style={{ width: '100%', height: '100%' }}
            placeholder={'Select a value'}
            onChangeColor={onChangeColor}
            switchStyle={classes.switchStyle}
            minValue={rowProperties.minValue}
            maxValue={rowProperties.maxValue}
            onChangeCustom={onChange}
            child={configObjects[rowProperties.child]}
            selectedChart={selectedChart}
            options={colorOptions}
            setOptions={setColorOptions}
            selectedOption={colorSelectedOption}
            setSelectedOption={setColorSelectedOption}
            dropDownClass={selectDropDownClass()}
          />
        </div>
      </div>
    </div>
  );
};
export default memo(Inputbox);
