import React, { useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { message, Popconfirm, Drawer, Space, Button, Radio } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { GET_SHARED_DASHBOARD_GROUP_DETAILS } from '../hooks/dashboardQueries';
import ExportDashboardModal from '../dashboardParts/exportDashboardModal';
import {
  DeleteOutlined,
  ShareAltOutlined,
  ExportOutlined,
  EditOutlined,
  SettingOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import UpdateModel from '../dashboardParts/updateDashboardModel';
import lodashDebounce from 'lodash/debounce';
import { useUpdateDashboard } from '../hooks/dashboardHook';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as DashboardSVG } from '../../../../assets/Dashboard.svg';
import { ReactComponent as RenameSVG } from '../../../../assets/Rename.svg';
import SchedulerAction from '../../reports/actions/schedulerAction';
import { entityTypes, permissionTypes } from 'src/constants';
const { DASHBOARD_GROUP, CXO } = entityTypes;
import { getPermissionType } from 'src/components/componentHelper';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import {
  GET_DASHBOARD_GROUP,
  SAVE_DASHBOARD_GROUP_SETTINGS,
  GET_OWNER_DETAILS,
  SEARCH_EMPLOYEE_BY_EMAIL,
} from 'src/api/routes';
import ShareDashboardGroupModal from './shareDashboardGroupModal';
import TransferOwnershipModal from 'src/components/common/TransferOwnershipModal';
import { SET_AUTO_RELOAD_FREQUENCY } from 'src/reduxActions/actionNameEnums';
import {
  autoReloadFrequencyOptionsSec,
  autoReloadFrequencyOptionsMin,
  DEFAULT_AUTO_RELOAD_FREQUENCY,
} from 'src/constants';

const useStyle = createUseStyles({
  basePane: {
    display: 'flex',
    flexDirection: 'column',
  },
  menuItem: {
    display: 'flex',
    margin: '3px 0',
    gap: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E9FBFF',
    },
  },
  menuIcon: {
    fontSize: '14px',
    marginRight: '4px',
  },
  autoReloadRadioGroup: {
    '& .ant-radio-button-checked': {
      backgroundColor: '#E6F7FF',
    },
    '& .ant-radio-group .ant-radio-button-wrapper': {
      width: '65px',
      textAlign: 'center',
    },
  },
  autoReloadSetting: {
    display: 'flex',
    gap: '0.4rem',
    flexDirection: 'column',
  },
});

const exportDashboardFormat = (dashboards) => {
  const dashboardInfo = dashboards.map((entry) => {
    const dashboard = entry.dashboardVisualisations.dashboard;
    return {
      name: dashboard.name,
      _id: dashboard._id,
    };
  });
  return dashboardInfo;
};

const DashboardOptions = (props: any) => {
  const classes = useStyle();
  const {
    id,
    name,
    description,
    category,
    refetch,
    scheduler,
    type,
    numSchedules,
    setOpenPopOver,
    setInEditMode,
    downloadButton,
    isTabletOrMobile,
    fromDashboardListingPage = true,
    dashboards,
    onDeleteDashboard,
    owner,
    onOpenScheduler,
    autoReloadFrequency = DEFAULT_AUTO_RELOAD_FREQUENCY,
  } = props;

  const { onSetLandingPage, onDelete, landingPageLoading } = useUpdateDashboard(
    {
      dashboardGroupId: id,
      refetch,
      refetchForDashboardGroup: onDeleteDashboard,
    },
  );
  const landingDashboardId = useSelector(
    (state: any) => state.config.recievedLandingPageId,
  );
  const userType = useSelector(
    (state: any) => state?.masterData?.userConfig?.typeOfUser,
  );
  const isDefaultEditable = useSelector(
    (state: any) =>
      state.masterData?.organisationConfig?.isDefaultEditable || false,
  );
  const enableAutoReloadinDashboardInSec = useSelector((state: any) => state.config.enableAutoReloadinDashboardInSec) || false;
  const dispatch = useDispatch();
  const [updateModelVisibilty, setupdateModelVisibilty] = useState(false);
  const [exportDashboardModal, setExportDashboardModal] = useState(false);
  const [exportDashboardTabList, setExportDashboardTabList] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [
    transferOwnershipModalVisibility,
    setTransferOwnershipModalVisibility,
  ] = useState(false);
  const [sharedDashboardGroupData, setSharedDashboardGroupData] = useState({});
  const [openSettingDrawer, setOpenSettingDrawer] = useState(false);
  const [ownerDetails, setOwnerDetails] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [autoReloadFrequencyState, setAutoReloadFrequencyState] =
    useState(autoReloadFrequency);
  let permissionType = permissionTypes.USER;
  if (userType !== undefined) {
    permissionType = getPermissionType(userType);
  }

  const removeDashboardFromLocalStorage = () => {
    const cachedDashboardsString = localStorage.getItem('activeDashboard');
    if (cachedDashboardsString) {
      const cachedDashboards = JSON.parse(cachedDashboardsString);
      const indexToRemove = cachedDashboards.findIndex(
        (activeDashboard) => activeDashboard.dashboardGroupId === id,
      );
      if (indexToRemove !== -1) {
        if (cachedDashboards.length === 1) {
          localStorage.removeItem('activeDashboard');
          //If only one dashboardGroup is present in local storage and we are deleting that dashboardGroup,
          //we need to remove activeDashboard from local storage as well.
        } else {
          cachedDashboards?.splice(indexToRemove, 1); //removing one out of many dashboardGroup
          localStorage.setItem(
            'activeDashboard',
            JSON.stringify(cachedDashboards),
          );
        }
      }
    }
  };

  const [getDashboardGroupSharedData] = useLazyQuery(
    GET_SHARED_DASHBOARD_GROUP_DETAILS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setSharedDashboardGroupData(data.getDashboardGroupSharedEmpDetails);
      },
      onError: (data) => {
        message.error({
          key: 'sharedDashboardData',
          duration: 0.5,
          content: 'Failed Fetching Shared Dashboard Meta Data',
        });
      },
    },
  );

  const [getOwnerDetail, { loading: loadingOwnerDetails }] = useLazyAPI(
    GET_OWNER_DETAILS,
    {
      type: 'POST',
      onCompleted: (data) => {
        setOwnerDetails(data);
      },
      onError: () => {
        message.error('Failed to fetch Owner Details');
      },
    },
  );

  const [getEmailsForEmps, { loading: loadingUserDetails }] = useLazyAPI(
    SEARCH_EMPLOYEE_BY_EMAIL,
    {
      type: 'GET',
      onCompleted: (data) => {
        setAllUsers(data);
      },
      onError: () => {
        message.error({
          key: 'getEmailsForEmps',
          duration: 0.5,
          content: 'Failed fetching employees data',
        });
      },
    },
  );

  const [
    getDashboardGroupMetaData,
    { loading: dashboardGroupfetchInProgress, data: dashboardGroupData },
  ] = useLazyAPI(GET_DASHBOARD_GROUP, {
    type: 'GET',
    onCompleted: (data) => {
      const { dashboards } = data;
      const dashboardInfo = exportDashboardFormat(dashboards);
      setExportDashboardTabList(dashboardInfo);
    },
    onError: (data) => {
      message.error({
        key: 'dashboardMetaDataStatus',
        duration: 0.5,
        content: 'Failed fetching Dashboard Meta Data',
      });
    },
  });

  const [saveDashboardGroupSettings] = useLazyAPI(
    SAVE_DASHBOARD_GROUP_SETTINGS,
    {
      type: 'POST',
      onCompleted: (data) => {
        if (!fromDashboardListingPage) {
          refetch();
        }
        message.success({
          key: 'SaveDashboardStatus',
          duration: 2,
          content: 'Dashboard Settings Saved',
        });
      },
      onError: (data) => {
        message.error({
          key: 'SaveDashboardStatus',
          duration: 2,
          content: 'Failed to save Dashboard Settings',
        });
      },
    },
  );
  const onShare = (id) => {
    getDashboardGroupSharedData({ variables: { id } });
    setModalVisibility(true);
  };

  const onExport = (id) => {
    if (!dashboards) {
      getDashboardGroupMetaData({
        URLQueryPath: id,
      });
    } else {
      const dashboardInfo = exportDashboardFormat(dashboards);
      setExportDashboardTabList(dashboardInfo);
    }
    setExportDashboardModal(true);
  };

  const onClickExportDashboard = (e) => {
    e.stopPropagation();
    onExport(id);
    setOpenPopOver(false);
  };

  const debouncedSearch = useCallback(
    lodashDebounce((nextValue) => getEmailsForEmps(nextValue), 500),
    [],
  );
  const onSearch = (val) => {
    debouncedSearch({ variables: { searchQuery: val } });
  };

  const handleTransferOwnership = () => {
    getOwnerDetail({ variables: { entityId: id, entity: DASHBOARD_GROUP } });
    getEmailsForEmps({ variables: { searchQuery: '' } });
    setTransferOwnershipModalVisibility(true);
    setOpenPopOver(false);
  };

  const onClickEditDashboard = () => {
    setInEditMode(true);
    setOpenPopOver(false);
  };

  const onCloseSettingDrawer = () => {
    setOpenSettingDrawer(false);
  };

  const updatedDashboardGroupSettings = () => {
    setOpenSettingDrawer(false);

    saveDashboardGroupSettings({
      variables: {
        dashboardGroupId: id,
        autoReloadFrequency: autoReloadFrequencyState,
      },
    });
  };

  const onChangeAutoReloadFrequency = (value) => {
    setAutoReloadFrequencyState(value);
    dispatch({ type: SET_AUTO_RELOAD_FREQUENCY, payload: value });
  };

  const onClickSettting = () => {
    setOpenSettingDrawer(true);
    setOpenPopOver(false);
  };

  const isLandingDashboard = id === landingDashboardId;
  return (
    <div className={classes.basePane}>
      {!fromDashboardListingPage &&
        (type === 'user' || isDefaultEditable) &&
        !downloadButton &&
        !isTabletOrMobile && ( // Edit
          <div onClick={onClickEditDashboard} className={classes.menuItem}>
            <div>
              <EditOutlined style={{ fontSize: '14px' }} />
            </div>
            <div>Edit</div>
          </div>
        )}
      <Popconfirm
        title={`Are you sure you want to set ${name} as Landing Page`}
        okText="Yes"
        cancelText="Cancel"
        onConfirm={() => onSetLandingPage(id)}
        okButtonProps={{ loading: landingPageLoading }}
        disabled={isLandingDashboard}
      >
        <div
          className={classes.menuItem}
          style={{
            pointerEvents: type !== 'cxo' ? 'auto' : 'none',
            opacity: type !== 'cxo' ? '1' : '0.5',
            margin: '2px 0',
            cursor: isLandingDashboard ? 'not-allowed' : 'pointer',
          }}
        >
          <div style={{ paddingTop: '2px' }}>
            <DashboardSVG
              style={{ fontSize: '20px' }}
              fill={isLandingDashboard ? 'grey' : '#000'}
            />
          </div>
          <div
            style={{
              opacity: isLandingDashboard ? '0.5' : '1',
              color: isLandingDashboard ? '#666' : '',
              userSelect: isLandingDashboard ? 'none' : 'auto',
            }}
          >
            Set as Landing Page
          </div>
        </div>
      </Popconfirm>
      {permissionType !== permissionTypes.USER && ( // Rename, Export, Scheduler, Share, Delete
        <>
          <div
            className={classes.menuItem}
            style={{
              pointerEvents:
                type === 'user' || isDefaultEditable ? 'auto' : 'none',
              opacity: type === 'user' || isDefaultEditable ? '1' : '0.5',
              margin: '2px 0',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setupdateModelVisibilty(true);
              setOpenPopOver(false);
            }}
          >
            <div style={{ paddingTop: '2px' }}>
              <RenameSVG style={{ fontSize: '20px' }} fill="#000" />
            </div>
            <div>Rename</div>
          </div>
          <div className={classes.menuItem} onClick={onClickExportDashboard}>
            <div>
              <ExportOutlined
                style={{ fontSize: '14px', marginRight: '4px' }}
              />{' '}
              Export
            </div>
          </div>
          <div onClick={() => setOpenPopOver(false)}>
            <SchedulerAction
              record={{ _id: id, numSchedules }}
              scheduler={scheduler}
              entityType={type === 'cxo' ? CXO : DASHBOARD_GROUP}
              onOpenScheduler={onOpenScheduler}
              fromDashboardListingPage={fromDashboardListingPage}
            />
          </div>
          <div
            style={{
              pointerEvents: type === 'user' ? 'auto' : 'none',
              opacity: type === 'user' ? '1' : '0.5',
            }}
            className={classes.menuItem}
            onClick={(e) => {
              e.stopPropagation();
              onShare(id);
              setOpenPopOver(false);
            }}
          >
            <div>
              <ShareAltOutlined
                style={{ fontSize: '14px', marginRight: '7px' }}
              />
              Share
            </div>
          </div>
          {type === 'user' && permissionType !== permissionTypes.USER ? (
            <div className={classes.menuItem} onClick={handleTransferOwnership}>
              <div>
                <SwapOutlined className={classes.menuIcon} /> Transfer Ownership
              </div>
            </div>
          ) : null}
          <div className={classes.menuItem} onClick={onClickSettting}>
            <div>
              <SettingOutlined />
            </div>
            Settings
          </div>
          <Popconfirm
            title="Are you sure to delete this Dashboard?"
            onConfirm={(e: any) => {
              removeDashboardFromLocalStorage();
              onDelete(id, name, category, description);
              e.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
          >
            <div
              style={{
                pointerEvents:
                  type === 'user' || isDefaultEditable ? 'auto' : 'none',
                opacity: type === 'user' || isDefaultEditable ? '1' : '0.5',
              }}
              onClick={(err) => {
                err.stopPropagation();
              }}
            >
              <div className={classes.menuItem} style={{ color: 'red' }}>
                <div>
                  <DeleteOutlined />
                </div>
                <div>Delete</div>
              </div>
            </div>
          </Popconfirm>
        </>
      )}
      <Drawer
        title="Settings"
        placement="right"
        onClose={onCloseSettingDrawer}
        visible={openSettingDrawer}
        extra={
          <Space>
            <Button onClick={onCloseSettingDrawer}>Cancel</Button>
            <Button type="primary" onClick={updatedDashboardGroupSettings}>
              Update
            </Button>
          </Space>
        }
      >
        <div className={classes.autoReloadSetting}>
          <div>Auto - Reload Frequency</div>
          <div className={classes.autoReloadRadioGroup}>
            <Radio.Group
              options={autoReloadFrequencyOptionsMin}
              onChange={(e) => onChangeAutoReloadFrequency(e.target.value)}
              value={autoReloadFrequencyState}
              optionType="button"
            />
          </div>
          {enableAutoReloadinDashboardInSec && <div className={classes.autoReloadRadioGroup}>
            <Radio.Group
              options={autoReloadFrequencyOptionsSec}
              onChange={(e) => onChangeAutoReloadFrequency(e.target.value)}
              value={autoReloadFrequencyState}
              optionType="button"
            />
          </div>}
          {/* <div>
            <Radio.Group
              className={classes.autoReloadRadioGroup}
              options={autoReloadFrequencyOptions}
              onChange={(e) => onChangeAutoReloadFrequency(e.target.value)}
              value={autoReloadFrequencyState}
              optionType="button"
            />
          </div> */}
        </div>
      </Drawer>
      <UpdateModel
        updateModelVisibilty={updateModelVisibilty}
        setupdateModelVisibilty={setupdateModelVisibilty}
        id={id}
        name={name}
        description={description}
        category={category}
        onFind={refetch}
      />
      <ExportDashboardModal
        exportDashboardModal={exportDashboardModal}
        setExportDashboardModal={setExportDashboardModal}
        exportDashboardTabList={exportDashboardTabList}
        dashboardGroupfetchInProgress={dashboardGroupfetchInProgress}
        dashboardGroupId={id}
        dashboardGroupName={name}
      />
      <ShareDashboardGroupModal
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        sharedDashboardGroupData={sharedDashboardGroupData}
        dashboardName={name}
        owner={owner}
      />
      <TransferOwnershipModal
        transferOwnershipModalVisibility={transferOwnershipModalVisibility}
        setTransferOwnershipModalVisibility={
          setTransferOwnershipModalVisibility
        }
        dashboardGroupId={id}
        dashboardName={name}
        ownerDetails={ownerDetails}
        loadingOwnerDetails={loadingOwnerDetails}
        allUsers={allUsers}
        entityType={DASHBOARD_GROUP}
        refetch={refetch}
        loadingUserDetails={loadingUserDetails}
        onSearch={onSearch}
      />
    </div>
  );
};

export default DashboardOptions;
