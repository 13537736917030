import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import GeneralFilter from 'src/components/common/GeneralFilter';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import PopOverPane from './popOverPane';
import lodashValues from 'lodash/values';
import SuggestedFilter from './suggestedFilter';
import CustomInput from 'src/components/common/customInput';
import CustomDivider from 'src/components/common/CustomDivider';
import { SettingOutlined } from '@ant-design/icons';

const useStyles = createUseStyles({
  switchStyle: {
    marginRight: '10px',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  aggregate: {
    width: '50px',
  },
  rightPane: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
  },
  dropDownArrow: {
    marginLeft: '4px',
    fontSize: '0.7rem',
  },
});

const useStyleOptions = createUseStyles({
  option: {
    '&:hover': {
      backgroundColor: '#E7F7FE',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
    height: '30px',
    cursor: 'pointer',
  },
  optionBox: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const ListOptions = props => {
  const classes = useStyleOptions();
  const { options, onChange} = props;
  return (
    <div className={classes.optionBox}>
      {options.map((item) => (
        <div
          onClick={() => onChange(item.name)}
          className={classes.option}
        >
          {item.prettyName}
        </div>
      ))}
    </div>
  );
};

const CustomQueryFilters = props => {
  const classes = useStyles();
  const {
    onChangeRadio,
    onChangeFilterData,
    isFilterListMutable = true,
    onChangeFilterQuery,
    onChangeFilterType,
    showfreezeOption = true,
    initialSuggestedFilters = [],
  } = props;
  const filterData = useSelector((state: any) => state.filterData);
  const selectedData = lodashValues(filterData);
  const filtersMetaData = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const selectedReport = useSelector((state:any) => state.reportMetaData.selectedReport);
  const allReports = useSelector((state: any) => state.reportConfig.reports);
  const dbType = allReports[selectedReport]?.dbType;

  return (
    <SectionPartContainer
      title={'Filters'}
      onTitleChanges={console.log}
      showAddButton={false}
    >
      {selectedData.map(row => {
        let filterListRadio = filtersMetaData[row.type] || [];
        if (dbType === 'elasticSearch' && row.type === 'timestamp') {
          filterListRadio = filterListRadio.filter(
            item =>
              item.name !== 'timestampzCurrent' &&
              item.name !== 'timestampzPrevious',
          );
        }
        const defaultSelection = filterListRadio[0];
        const filterRadioChangeHandler = onChangeRadio(row.value);
        const onChangeFilterQueryHandler = onChangeFilterQuery(row.value);
        const onChangeFilterTypeHandler = onChangeFilterType(
          row.value,
          filtersMetaData,
        );
        const filterProps = {
          type: row.type,
          metricsName: row.value,
          filterMetaData: row.filterMetaData,
          filterListRadio,
          customDivider: true,
          onChangeRadio: filterRadioChangeHandler,
          onChangeFilterData: onChangeFilterData(row.value),
          filterData,
          showfreezeOption,
          selectedFilterReportType: row.reportType,
          showAdditionalFilters: true,
          prettyName: row.prettyName,
        };
        const selectedRadio = filterData[row.value].selectedFilter;
        const selectedRadioMetaData = filterListRadio.find(
          filter => filter.name === selectedRadio,
        );
        const isRequired = row.filterMetaData?.required || false;
        const filterTypeSelection = row.type
          ? { name: row.type, prettyName: row.type }
          : { name: 'text', prettyName: 'text' };
        return (
          <>
            {!['organisationId', 'hubId'].includes(row.value) ? (
              <RowContainer
                key={`${row.value}-${JSON.stringify(row.data)}`}
                showLeftPane={true}
                showLeftPaneFilterComponent={true}
                showPopOverComponent={true}
                hideRemove={true}
                customDivider={false}
                title={row.prettyName}
                description={row.description}
                LeftPaneComponent={PopOverPane}
                filterData={row.filterData}
                metricsName={row.value}
                rowType={row.type}
                LeftPaneFilterComponent={SuggestedFilter}
                initialSuggestedFilters={initialSuggestedFilters}
                PopOverContent={
                  <>
                    <div style={{ width: '400px' }}>
                      <PopOverPane
                        classes={classes}
                        value={filterTypeSelection}
                        Content={
                          <ListOptions
                            options={Object.keys(filtersMetaData).map((key) => {
                              return {
                                name: key,
                                prettyName: key,
                              };
                            })}
                            onChange={(type) => {
                              onChangeFilterTypeHandler(type);
                            }}
                            value={filterTypeSelection}
                            {...filterProps}
                          />
                        }
                      />
                      <CustomDivider style={{ margin: '10px 0' }} />
                      <CustomInput
                        onChange={onChangeFilterQueryHandler}
                        value={row.sql || ''}
                        placeholder={'Please Input Query'}
                        isCodeEditor={true}
                        height={'100px'}
                        theme={'monokai'}
                      />
                    </div>
                  </>
                }
                PopOverButton={SettingOutlined}
                leftPaneProps={{
                  classes,
                  value: selectedRadioMetaData || defaultSelection,
                  Content: (
                    <ListOptions
                      options={
                        isRequired
                          ? filterListRadio.filter(
                              (filterElement) =>
                                filterElement.name !== 'doNotApplyThisFilter',
                            )
                          : filterListRadio
                      }
                      onChange={(k) => {
                        filterRadioChangeHandler(k);
                      }}
                      value={selectedRadioMetaData || defaultSelection}
                      {...filterProps}
                    />
                  ),
                }}
              />
            ) : (
              <>
                <RowContainer
                  key={`${row.value}-${JSON.stringify(row.data)}`}
                  title={row.prettyName}
                  description={row.description}
                  metricsName={row.value}
                  customDivider={false}
                  rowType={row.type}
                  showPopOverComponent={true}
                  showSuggestionFilter={false}
                  hideRemove={true}
                  PopOverContent={
                    <div style={{ width: '400px' }}>
                      <CustomInput
                        onChange={onChangeFilterQueryHandler}
                        value={row.sql || ''}
                        placeholder={'Please Input Query'}
                        isCodeEditor={true}
                        height={'100px'}
                        theme={'monokai'}
                      />
                    </div>
                  }
                  PopOverButton={SettingOutlined}
                />
                <CustomDivider style={{ marginTop: '0.8rem' }} />
              </>
            )}
            {!['organisationId', 'hubId'].includes(row.value) && (
              <GeneralFilter {...filterProps} />
            )}
          </>
        );
      })}
      <div className={classes.switchContainer}></div>
    </SectionPartContainer>
  );
};
export default memo(CustomQueryFilters);
