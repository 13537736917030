import { gql } from '@apollo/client';

export const GETDATADICTIONARY = gql`
query GET_DATA_DICT
{
  getDataDictionary {
    success
    url
    error
  }
}`;
