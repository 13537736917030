import { useLazyQuery } from '@apollo/client';
import { message } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { SAVE_SHARED_DASHBOARD_GROUP_DETAILS } from '../hooks/dashboardQueries';
import { SEARCH_EMPLOYEE_BY_EMAIL } from '../../../../api/routes';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashDebounce from 'lodash/debounce';

export const useShareDashboardGroup = (sharedDashboardGroupData, setModalVisibility, modalVisibility) => {

    const [sharedGroupDashboard, setSharedGroupDashboard] = useState(sharedDashboardGroupData);
    const [empDetailsArray, setEmpDetailsArray] = useState([]);

    const [shareDashboardGroupToEmployee] = useLazyQuery(SAVE_SHARED_DASHBOARD_GROUP_DETAILS, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
          message.success({key:'shareDashboardGroupToEmployee', duration: 0.5, content: 'Successfully Shared Dashboard Group'});
          setModalVisibility(false);
          setSharedGroupDashboard({
            sharedToEmployeeDetails: [],
            isSharableToAll: false,
            dashboardGroupId: '',
          });
        },
        onError: data => {
            message.error({key:'shareDashboardGroupToEmployee', duration: 0.5, content: 'Failed Saving Shared Dashboard Group Meta Data'});
        },
    });
    const [getEmailsForEmps] = useLazyAPI(SEARCH_EMPLOYEE_BY_EMAIL, {
        type: 'GET',
        onCompleted: data => {
          setEmpDetailsArray(data);
        },
        onError: data => {
            message.error({key:'getEmailsForEmps', duration: 0.5, content: 'Failed fetching employees data'});
        },
    });
    useEffect(() => {
        if(lodashIsEmpty(sharedDashboardGroupData)){
            setSharedGroupDashboard({
            sharedToEmployeeDetails: [],
            isSharableToAll: false,
            dashboardGroupId: '',
          });
        }else{
            setSharedGroupDashboard(sharedDashboardGroupData);
        }
    }, [sharedDashboardGroupData, modalVisibility]);

    useEffect(() => {
      if(modalVisibility && !empDetailsArray.length) {
        getEmailsForEmps({variables: { searchQuery: ''}});
      }
    }, [modalVisibility]);

    const onSelect = (value) => {
        const [empId, email, name] = value.split('_&_');
        setSharedGroupDashboard({
          ...sharedGroupDashboard,
          sharedToEmployeeDetails: [...sharedGroupDashboard.sharedToEmployeeDetails, {empId, email, name}],
        });
    };

    const onDeselect = (value) => {
        const filteredEmps = sharedGroupDashboard.sharedToEmployeeDetails.filter(({empId, email, name}) => `${empId}_&_${email}_&_${name}` !== value);
        setSharedGroupDashboard({
          ...sharedGroupDashboard,
          sharedToEmployeeDetails: filteredEmps,
        });
    };

    const toggleOrgShare = () => {
        setSharedGroupDashboard({
          ...sharedGroupDashboard,
          isSharableToAll: !sharedGroupDashboard.isSharableToAll,
        });
    };
    const toggleCustomerPortalSharing = () => {
      setSharedGroupDashboard({
        ...sharedGroupDashboard,
        sharedToCustomerPortal: !sharedGroupDashboard.sharedToCustomerPortal,
      });
  };

  const toggleVendorPortalSharing = () => {
    setSharedGroupDashboard({
      ...sharedGroupDashboard,
      sharedToVendorPortal: !sharedGroupDashboard.sharedToVendorPortal,
    });
  };

  const debouncedSearch = useCallback(
    lodashDebounce((nextValue) => getEmailsForEmps(nextValue), 500),
    [],
);
    const onSearch = (val) => {
      debouncedSearch({variables: { searchQuery: val}});
    };

    const onShare = () => {
      const {
        dashboardGroupId,
        sharedToEmployeeDetails,
        isSharableToAll,
        sharedToCustomerPortal,
        sharedToVendorPortal,
      } = sharedGroupDashboard;
      shareDashboardGroupToEmployee({
        variables: {
          id: dashboardGroupId,
          employeeDetails: sharedToEmployeeDetails,
          isSharableToAll,
          sharedToCustomerPortal,
          sharedToVendorPortal,
        },
      });
    };
    const onCancelShare = () => {
      setModalVisibility(false);
    };

    return {
        shareDashboardGroupToEmployee,
        sharedGroupDashboard,
        empDetailsArray,
        onSelect,
        onDeselect,
        onSearch,
        toggleOrgShare,
        onShare,
        onCancelShare,
        toggleCustomerPortalSharing,
        toggleVendorPortalSharing,
    };
};
