import { useLazyQuery } from '@apollo/client';
import { AutoComplete, Input, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useUpdateDashboard } from '../hooks/dashboardHook';
import { GET_DEFAULT_DASHBOARD_CATEGORY } from '../hooks/dashboardQueries';

const { Option } = Select;
const useStyle = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  inputStyle: {
    width: '300px',
  },
});

const UpdateModel = (props) => {
  const classes = useStyle();
  const {
    id,
    title = 'Update Dashboard',
    updateModelVisibilty,
    setupdateModelVisibilty,
    name,
    description,
    category,
    onFind,
  } = props;

  const [dashboardName, setDashboardName] = useState('');
  const [dashboardCategory, setDashboardCategory] = useState('');
  const [dashboardDescription, setDashboardDescription] = useState('');

  const [getDefaultDashboardCategory] = useLazyQuery(
    GET_DEFAULT_DASHBOARD_CATEGORY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setCategoryList(data.getDefaultDashboardCategory);
      },
      onError: (data) => {},
    },
  );

  useEffect(() => {
    if (updateModelVisibilty) {
      setDashboardName(name);
      setDashboardCategory(category);
      setDashboardDescription(description);
      getDefaultDashboardCategory();
    }
  }, [updateModelVisibilty]);
  const find = () => onFind(search);
  const [search, setSearch] = useState('');
  const { onClickOk } = useUpdateDashboard({
    dashboardGroupId: id,
    dashboardName,
    setupdateModelVisibilty,
    dashboardCategory,
    dashboardDescription,
    refetch: find,
  });
  const isDefaultEditable = useSelector(
    (state: any) => state.masterData?.organisationConfig?.isDefaultEditable || false,
  );
  const [categorylist, setCategoryList] = useState(['test']);
  return (
    <Modal
      maskClosable={false}
      title={title}
      visible={updateModelVisibilty}
      onOk={() => onClickOk()}
      okText={title}
      onCancel={() => setupdateModelVisibilty(false)}
    >
      <div className={classes.container}>
        <span>Dashboard Name</span>
        <Input
          value={dashboardName}
          onChange={(event) => setDashboardName(event.target.value)}
          className={classes.inputStyle}
        />
      </div>
      {isDefaultEditable ? (
        <div className={classes.container}>
          <span>Dashboard Description</span>
          <TextArea
            value={dashboardDescription}
            onChange={(event) => setDashboardDescription(event.target.value)}
            className={classes.inputStyle}
          />
        </div>
      ) : null}
      {isDefaultEditable ? (
        <div className={classes.container}>
          <span>Dashboard Category</span>
          <AutoComplete
            placeholder={'Select Category'}
            value={dashboardCategory}
            onChange={(e) => setDashboardCategory(e)}
            className={classes.inputStyle}
            onSelect={(value) => setDashboardCategory(value)}
          >
            {categorylist.map((option) => {
              return <Option value={option}>{option}</Option>;
            })}
          </AutoComplete>
        </div>
      ) : null}
    </Modal>
  );
};
export default UpdateModel;
