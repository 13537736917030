import React from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { GET_SCHEDULER_METADATA } from './queries';
import { useScheduler } from '../../reports/hooks/schduler';
import { useReport } from '../../chartBuilder/createQueryComponents/BodyParts/createQueryBodyHooks';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Switch ,Tooltip} from 'antd';
import lodashCapitalize from 'lodash/capitalize';
import { statusOptions } from '../utils';
import { entityTypes } from 'src/constants';
import { DeleteModal } from 'src/components/common/deleteModal';
const { CXO } = entityTypes;

export const useSchedulerPage = (entityId, entityType, onRefetchDashboardGroupData:any = null) => {
    const [schedules, setSchedules] = useState([]);
    const useReportObject = useReport();
    const [editMetaData, setEditMetaData] = useState<any>(undefined);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [deleteReport, setDeleteReport] = useState<any>({});
    const onUpdateCallback = async () => {
        setEditMetaData(undefined);
        await getScheduler();
    };
    const schedulerDrawer = useScheduler(editMetaData, onRefetchDashboardGroupData? onRefetchDashboardGroupData : onUpdateCallback);
    const onOpen = (record) => schedulerDrawer.openDrawer(record);
    const { visible, data } = schedulerDrawer;
    const onClose = () => {
        setEditMetaData(undefined);
        schedulerDrawer.onClose();
    };

    const [getScheduler, { loading:loadingScheduler }] = useLazyQuery(GET_SCHEDULER_METADATA, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setSchedules(data.getAllSchedules.schedules);
        },
        variables: { entityId, entityType },
    });

    useEffect(() => getScheduler(), [entityId, entityType]);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (data, record) => <>{lodashCapitalize(data)}</>,
        },
        {
            title: 'Frequency',
            dataIndex: 'name',
            key: 'name',
            render: (data, record) => {
                return <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {record.frequency}
                    </div>
                    <div style={{fontSize: '12px', color: '#595959'}}>
                        {record.executionTime}
                    </div>
                </div>;
            },
        },
        {
            title: 'Creation Date',
            dataIndex: 'creationTime',
            key: 'creationDate',
            render: (text) => (
                <>{moment(parseFloat(text)).format('D MMM YYYY, hh:mm A')}</>
              ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (data, record) => <div>
                <Switch
                    checked={record.status==='on'}
                    onChange={async (value) => {
                        const { entityType } = record;
                        const emailMessage = entityType === CXO ? undefined : record.emailMessage;
                        const updatedRecord = {...record, status: statusOptions[value.toString()], emailMessage};
                        await schedulerDrawer.data.onUpdateScheduler(updatedRecord);
                    }}
                    size="small"/>
            </div>,
        },
        {
            title: 'Actions',
            dataIndex: 'name',
            render: (data, record) => {
                return <div>
                    <Tooltip title={'Edit Schedule'}>
                    <EditOutlined onClick={() => {
                        setEditMetaData(record);
                        onOpen(record);
                    }}/>
                    </Tooltip>
                    <DeleteModal
                        title={'Delete Schedule'}
                        visible={deleteVisible}
                        name={deleteReport?.title}
                        onCancel={() => {
                            setDeleteVisible(false);
                        }}
                        onConfirm={async () => {
                            const { entityType } = deleteReport;
                            const emailMessage = entityType === CXO ? undefined : deleteReport.emailMessage;
                            const updatedRecord = {...deleteReport, isActive: false, emailMessage};
                            await schedulerDrawer.data.onUpdateScheduler(updatedRecord);
                            setDeleteVisible(false);
                        }}
                    />
                    <Tooltip title={'Delete Schedule'}>
                    <DeleteOutlined onClick={() => {
                        setDeleteReport(record);
                        setDeleteVisible(true);
                    }} style={{marginLeft: '12px'}} />
                    </Tooltip>
                </div>;
            },
        }];

    return {
        onOpen,
        loadingScheduler,
        getScheduler,
        columns,
        onClose,
        schedules,
        visible,
        data,
        editMetaData,
    };
};
