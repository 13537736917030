import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import GeneralFilter from 'src/components/common/GeneralFilter';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import { FILTER_RADIO_SELECTED, FILTER_SELECTED_VALUE_CHANGED } from 'src/reduxActions/actionNameEnums';
import PopOverPane from './popOverPane';

const rowData = {
    title: 'Container',
    isTitleEditable: true,
    onTitleChange: console.log,
};

const useStyles = createUseStyles({
    switchStyle:{
        marginRight: '10px',
    },
    switchContainer: {
        marginTop: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    aggregate: {
        width: '50px',
    },
});

const useStyleOptions = createUseStyles({
    option: {
        '&:hover': {
            backgroundColor: '#E7F7FE',
        },
    },
});

const ListOptions = (props) => {
    const classes = useStyleOptions();
    const { options, onChange } = props;
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        {options.map(item => <div onClick={() => onChange(item.name)}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px', height: '30px'}} className={classes.option}>
            {item.prettyName}
        </div>)}
    </div>;
};

const HavingSelection = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // eslint-disable-next-line max-len
    const { havingFilters, havingFilterData, onChangeHavingFilterData, onChangeHavingRadio, isFilterListMutable = true, showfreezeOption=true, onChangeHavingAggregate} = props;
    const { enableCustomColumnDrawer } = props;
    const { havingFiltersOptions, onSelectHavingFilterMetrics, selectedData, onRemoveHavingFilter} = havingFilters;
    const { title } = props;
    const filtersMetaData = useSelector((state:any) => state?.masterData?.supportedFilters);
    return <SectionPartContainer
                enableCustomColumnDrawer={enableCustomColumnDrawer}
                showTitle={isFilterListMutable}
                title={ title || 'Having'}
                showCount
                onChangeSelect={onSelectHavingFilterMetrics}
                options={havingFiltersOptions}
                initialValue={selectedData.map(element => element.value)}
                onTitleChanges={console.log}>
                {selectedData
                    .map(row => {
                    const defaultSelection = {prettyValue: undefined, prettyName: 'Select'};
                    const filterListRadio = filtersMetaData['number'] || [];
                    const filterRadioChangeHandler = onChangeHavingRadio(row.value);
                    const currentOperation = havingFiltersOptions.filter((operation) => operation.value === row.value);
                    const havingOperations = currentOperation?.[0]?.havingOperations || [];
                    const filterProps = {
                        type: row.type,
                        searchOptions: row.searchOptions,
                        metricsName: row.value,
                        filterMetaData:row.filterMetaData,
                        filterListRadio,
                        onChangeRadio: onChangeHavingRadio(row.value),
                        onChangeFilterData: onChangeHavingFilterData(row.value),
                        filterData: havingFilterData,
                        showfreezeOption: false,
                        selectedFilterReportType: row.reportType,
                        havingOperations,
                        onChangeHavingAggregate,
                        selectedHavingOperation: row.havingOperation,
                    };
                    const selectedRadio = havingFilterData[row.value].selectedFilter;
                    const selectedRadioMetaData = filterListRadio.find((filter) => filter.name === selectedRadio);
                    const isRequired = row.filterMetaData?.required || false;
                    const updatedFilterListRadio = filterListRadio.filter((filterElement) => filterProps.havingOperations?.length
                     ? filterElement?.name !== 'doNotApplyThisFilter'
                     : true);
                    return <RowContainer
                                onRemove={() => {
                                    onRemoveHavingFilter(row.value);
                                }}
                                isTitleRequired={isRequired}
                                title={row.prettyName}
                                description={row.description}
                                showLeftPane={true}
                                aggregate={row.aggregatePrettyName}
                                isFilterListMutable={isFilterListMutable}
                                LeftPaneComponent={PopOverPane}
                                leftPaneProps = {{
                                    classes,
                                    value: selectedRadioMetaData || defaultSelection,
                                    Content: <ListOptions
                                        options={updatedFilterListRadio}
                                        onChange={(k) => {
                                            filterRadioChangeHandler(k);
                                        }}
                                        value={selectedRadioMetaData || defaultSelection}
                                    {...filterProps}/>,
                                }}
                            >
                            <GeneralFilter {...filterProps}/>
                            </RowContainer>;
                            })}
                <div className={classes.switchContainer}>
    </div>
    </SectionPartContainer>;
};

export default memo(HavingSelection);
