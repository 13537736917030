/* eslint-disable no-cond-assign */
import React, { memo } from 'react';
import CustomInput from 'src/components/common/customInput';
import { useDispatch, useSelector } from 'react-redux';
import { UNDERLYING_CUSTOM_QUERY_STRING } from 'src/reduxActions/actionNameEnums';
import SectionPartContainer from 'src/components/common/sectionPartContainer';

const UnderlyingCustomQuery = () => {
  const dispatch = useDispatch();
  const underlyingQueryString = useSelector(
    (state: any) => state.reportMetaData.underlyingQueryString,
  );
  const onChange = (customQuery) => {
    dispatch({
      type: UNDERLYING_CUSTOM_QUERY_STRING,
      payload: {
        queryString: customQuery,
      },
    });
  };
  // if you select any text in sqlInputEditor and paste after selection then onChange will be called
  // twice once for cut and second for paste and setState will stuck in a infinite loop
  // to prevent this problem
  const lazyUpdate = () => {
    let query = '';
    let inside = false;
    return (value) => {
      query = value;
      if (inside === false) {
        inside = true;
        setTimeout(() => {
          onChange(query);
          inside = false;
        });
      }
    };
  };

  return (
    <SectionPartContainer title={'Underlying Query'} showAddButton={false}>
      <div style={{ marginTop: '16px' }}>
        <CustomInput
          onChange={lazyUpdate()}
          value={underlyingQueryString}
          placeholder={'Please Input Query'}
          isCodeEditor={true}
          width={'100%'}
          highlightActiveLine={false}
          theme={'monokai'}
        />
      </div>
    </SectionPartContainer>
  );
};

export default memo(UnderlyingCustomQuery);
