import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Spinner = (props) => {
  const { fontSize = 36, styles } = props;

  const spinnerStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    ...styles,
  };
  const antIcon = <LoadingOutlined style={{ fontSize }} spin />;
  return <Spin indicator={antIcon} style={spinnerStyles} />;
};

export default Spinner;
