import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ADD_REPORT_TO_DASHBOARD } from './queries';
import { getReportIdsFromReportData } from '../../pages/reports/utils';
import { message } from 'antd';
import lodash from 'lodash';

export const useReportDashboard = (params) => {
    const {
        onCompletedCallBack = () => {},
        onErrorCallBack =  () => {},
    } = params;

    const strPath = lodash.get(window, 'location.pathname', '');
    const dashboardGroupId = strPath.split('/').slice(-1)[0];

    const [addReports, { loading:addingReport }] = useLazyQuery(ADD_REPORT_TO_DASHBOARD, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onCompleted: () => {
            message.success({ key: 'addreportTodashboard', content: 'Report added to the dashboard successfully' }),
            onCompletedCallBack();
        },
        onError: (error) => {
            if(error.message.includes('401')) {
                message.error({ key: 'addreportTodashboard', content: 'This Report is unauthorized to share with customer' }),
                onErrorCallBack();
            }
            else{
            message.error({ key: 'addreportTodashboard', content: 'Failed to add Report to the Dashboard' }),
            onErrorCallBack();
            }
        },
      });
    const addReportsToDashboard = (reports, dashboardId) => {
        addReports({ variables:{ reportIds: getReportIdsFromReportData(reports), dashboardId, dashboardGroupId } });
    };

    return {
        addingReport,
        addReportsToDashboard,
    };
};
