import React from 'react';
import { Popover } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  popOverItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  popOverItemTitle: {
    padding: '4px',
  },
  popOverDisplay: {
    position: 'relative',
    minHeight: '0.5px',
  },
});

const ChartInteractionPopOver = (props) => {
  const { chartInteractionHook, isChartFromDashboard } = props;
  const {
    popOverPosition,
    popOverContentList,
    onPopOverContentClick,
    popOverVisible,
    onPopOverVisibleChange,
  } = chartInteractionHook;
  const classes = useStyle();
  return (
    <Popover
      content={
        <div>
          {popOverContentList.map((content) => {
            const Icon = content.icon;
            return (
              <div
                className={classes.popOverItem}
                onClick={() => onPopOverContentClick(content.key)}
              >
                <Icon />
                <span className={classes.popOverItemTitle}>
                  {content.title}
                </span>
              </div>
            );
          })}
        </div>
      }
      placement="left"
      trigger="click"
      visible={popOverVisible}
      onVisibleChange={onPopOverVisibleChange}
    >
      <div
        style={{
          left: popOverPosition.x,
          top: popOverPosition.y,
          position: isChartFromDashboard ? 'absolute' : 'relative',
        }}
        className={classes.popOverDisplay}
      ></div>
    </Popover>
  );
};

export default ChartInteractionPopOver;
