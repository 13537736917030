import { gql } from '@apollo/client';

export const BUILDQUERY = gql`
  query BUILDQUERY(
    $organisationId: String!
    $reportName: String!
    $aggegationType: String!
    $metrices: [String]!
    $filters: [filterInput]!
    $suggestedFilterStrings: [suggestedFilterStringsKeyValueInput]
  ) {
    buildQuery(
      organisationId: $organisationId
      reportName: $reportName
      aggegationType: $aggegationType
      metrices: $metrices
      filters: $filters
      suggestedFilterStrings: $suggestedFilterStrings
    )
  }
`;

export const GETVISUALISATION = gql`
query GETVISUALISATION (
  $reportId: String
  $reportType: String
  $dataMartType: String
  $isAdminReport: Boolean
  $queryString: String
  $customAggregateMetrices: [customAggregateMetricInput]
  $customMetrices: [customMetricInput]
  $metrices: [metricInput]!
  $filters: [whereHavingInput]!
  $havingFilters: [havingClauseInput]
  $chartType: String!
  $chartOptions: [chartOptionInput]
  $suggestedFilterStrings: [suggestedFilterStringsKeyValueInput]
  $isDummyDataRequest: Boolean
  $mongoCollectionName: String
) {
  getVisualisation(
    reportId: $reportId
    dataMartType: $dataMartType
    isAdminReport: $isAdminReport
    queryString: $queryString
    reportType: $reportType
    customAggregateMetrices: $customAggregateMetrices
    customMetrices: $customMetrices
    metrices: $metrices
    filters: $filters
    havingFilters: $havingFilters
    chartType: $chartType
    chartOptions: $chartOptions
    suggestedFilterStrings: $suggestedFilterStrings
    isDummyDataRequest: $isDummyDataRequest
    mongoCollectionName: $mongoCollectionName
  ) {
    formData
    chartData
    chartType
    url
    visualizeMetaData
    metrices {
      metric
      prettyName
      aliasName
      columnWidth
      type
      operation
      groupBy {
        toApply
        bucketValue
      }
    }
    customAggregateMetrices {
        metric
        prettyName
        aliasName
        columnWidth
      }
    position {
      x
      y
      w
      h
    }
    reportName
    reportId
    reportDescription
    reportDateRange
    type
    globalSuggestedStrings {
      key
      value
    }
    lastRefreshTime
  }
}
`;

export const GET_REPORT_DRILLDOWN_DATA = gql`
  query GET_REPORT_DRILLDOWN_DATA(
    $reportId: String
    $drilldownData: drilldownDataInput!
    $parentData: String!
    $level: Int!
    $reportData: reportDataInput
    $initialBucketValue: String
    $isDummyDataRequest: Boolean
    $globalFilters: [whereHavingInput]
  ) {
    getReportDrilldownData(
      reportId: $reportId
      drilldownData: $drilldownData
      parentData: $parentData
      level: $level
      reportData: $reportData
      initialBucketValue: $initialBucketValue
      isDummyDataRequest: $isDummyDataRequest
      globalFilters: $globalFilters
    ) {
      chartData
      nextLevelFieldName
    }
  }
`;
