import { Steps } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';
import '../chartBuilder.style.css';

const useStyle = createUseStyles({
  stepsMargin: {
    margin: '0 24px 24px 24px',
  },
});

const { Step } = Steps;

const steps = [
  {
    title: 'Step 1',
    description: 'Select the Report to be opened when the link is clicked',
  },
  {
    title: 'Step 2',
    description: 'Map filters from Parent Report to fields in the Child Report',
  },
];

const CreateLinkReportWizardContent = (props) => {
  const { currentStep } = props;
  const classes = useStyle();
  return (
    <div className={classes.stepsMargin}>
      <Steps current={currentStep}>
      {steps.map(({ title, description }) => {
        return <Step title={title} description={description} />;
      })}
    </Steps>
    </div>
  );
};

export default CreateLinkReportWizardContent;
