import {
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  startOfLastWeek,
  endOfLastWeek,
  startOfMonth,
  endOfMonth,
  startOfLastMonth,
  endOfLastMonth,
  startOfYear,
  endOfYear,
  startOfFinancialYear,
  endOfFinancialYear,
} from 'src/components/utils/timeFilterConstant';

export const handleQuickSelection = (type, onRangeChange, setSelectedDateRange) => {
  switch (type) {
    case 'today':
      onRangeChange([startOfToday, endOfToday]);
      break;
    case 'yesterday':
      onRangeChange([startOfYesterday, endOfYesterday]);
      break;
    case 'this-week':
      onRangeChange([startOfWeek, endOfWeek]);
      break;
    case 'last-week':
      onRangeChange([startOfLastWeek, endOfLastWeek]);
      break;
    case 'this-month':
      onRangeChange([startOfMonth, endOfMonth]);
      break;
    case 'last-month':
      onRangeChange([startOfLastMonth, endOfLastMonth]);
      break;
    case 'this-year':
      onRangeChange([startOfYear, endOfYear]);
      break;
    case 'this-financial-year':
      onRangeChange([startOfFinancialYear, endOfFinancialYear]);
      break;
    default:
      setSelectedDateRange([]);
      break;
  }
};

export const quickSelectionsOptions: any = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'This Week', value: 'this-week' },
  { label: 'Last Week', value: 'last-week' },
  { label: 'This Month', value: 'this-month' },
  { label: 'Last Month', value: 'last-month' },
  { label: 'This Calendar Year', value: 'this-year' },
  { label: 'This Financial Year', value: 'this-financial-year' },
];
