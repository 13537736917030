import { List, Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import CustomDivider from 'src/components/common/CustomDivider';
import lodashCapitalize from 'lodash/capitalize';
import { useHistory } from 'react-router-dom';
import Search from 'antd/lib/input/Search';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { GET_DASHBOARDS_BY_NAME } from 'src/api/routes';
import { useSelector } from 'react-redux';
import Spinner from 'src/components/commonv2/Spinner';

const useStyle = createUseStyles({
    basePane: {
        position: 'relative',
    },
    viewAll: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#fff',
        zIndex: 100,
        padding: '1rem',
    },
    viewAllTextStyle: {
        color: '#1890FF',
        fontSize: '14px',
        fontWeight: '600',
        textAlign: 'center',
        cursor: 'pointer',
        padding: '0.5rem',
    },
    listItem:{
        cursor: 'pointer',
    },
    list: {
        '&:hover': {
            background: '#efefef',
            color: '#2a69b9',
        },
    },
    searchInput: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        zIndex: 100,
        padding: '1rem',
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'scroll',
        padding: '0 1rem 1rem 1rem',
        scrollbarWidth: 'none',
    },
    sectionTitle: {
        display: 'flex',
        fontSize: '0.8rem',
        color: '#666666',
        gap: '0.5rem',
        marginBottom: '0.3rem',
    },
});

const NavigationPane = (props: any) => {
    const { hidePopover, searchText, setSearchText } = props;
    const classes = useStyle();
    const isTabletOrMobile = useSelector(
        (state: any) => state.config.isTabletOrMobile,
    );
    const [favDashboardList, setFavDashboardList] = useState([]);
    const [dashboardGroupList, setDashboardGroupList] = useState([]);
    const [getFavDashboardQuery, { loading: loadingFavDashboards }] = useLazyAPI(GET_DASHBOARDS_BY_NAME, {
        type: 'POST',
        onCompleted: (data) => {
            setFavDashboardList(data?.dashboardGroups || []);
        },
    });

    const [getDashboardListQuery, { loading: loadingDashboardGroupList}] = useLazyAPI(GET_DASHBOARDS_BY_NAME, {
        type: 'POST',
        onCompleted: (data) => {
            setDashboardGroupList(data?.dashboardGroups || []);
        },
    });

    useEffect(() => {
        getFavDashboardQuery({
          variables: {
            searchQuery: searchText,
            isPagination: false,
            filters: [
              { key: 'type', data: ['user', 'default', 'shared', 'cxo'] },
              {
                key: 'isFavouriteForUserId',
                data: true,
              },
            ],
          },
        });

        getDashboardListQuery({
            variables: {
              searchQuery: searchText,
              filters: [
                { key: 'type', data: ['user', 'default', 'shared', 'cxo'] },
                {
                  key: 'isFavouriteForUserId',
                  data: false,
                },
              ],
            },
          });
    }, [searchText]);

    const history = useHistory();
    const onListItemClickHandler = (dashboardId) => () => {
        history.push(`/dashboard/${dashboardId}`);
        hidePopover();
    };
    const onClickViewAllHAndler = () => {
        history.push('/dashboard');
        hidePopover();
    };

    const onSearchTextChange = (event) => setSearchText(event.target.value);

    return <div className={classes.basePane}>
        <Search
            placeholder="Search Dashboards"
            onChange={onSearchTextChange}
            loading={loadingDashboardGroupList || loadingFavDashboards}
            allowClear
            value={searchText}
            className={classes.searchInput}
        />
        <div className={classes.sectionContainer}>
            <div className={classes.sectionTitle}>
                FAVOURITES
            <Badge showZero count={favDashboardList.length} style={{ backgroundColor:'#1890FF'}} />
            {loadingFavDashboards && <Spinner fontSize={14} styles={{ position: 'static' }} />}</div>
        <List
        size="small"
        dataSource={favDashboardList}
        locale={{ emptyText: <div /> }}
        renderItem={(item:any) => <List.Item className={classes.list} onClick={onListItemClickHandler(item._id)}>
            <div className={classes.listItem}>
                            {lodashCapitalize(item.name)}
                        </div>
            </List.Item>}
        />
        </div>
        <div className={classes.sectionContainer}>
            <div className={classes.sectionTitle}>
            OTHER DASHBOARDS
        <Badge showZero count={dashboardGroupList.length} style={{ backgroundColor:'#1890FF'}} />
        {loadingDashboardGroupList && <Spinner fontSize={14} styles={{ position: 'static' }} />}</div>
        <List
        size="small"
        dataSource={dashboardGroupList.slice(0, 15)}
        locale={{ emptyText: <div /> }}
        renderItem={(item:any) => <List.Item className={classes.list} onClick={onListItemClickHandler(item._id)}>
            <div className={classes.listItem}>
                            {lodashCapitalize(item.name)}
                        </div>
            </List.Item>}
        />
        </div>
        {!isTabletOrMobile &&
        <div className={classes.viewAll}>
            <CustomDivider/>
            <div className={classes.viewAllTextStyle} onClick={onClickViewAllHAndler}>
            View All Dashboard
            </div>
        </div>
        }
    </div>;
};

export default NavigationPane;
