import { useLazyQuery } from '@apollo/client';
import { message } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SAVE_DASHBOARD_GROUP, SET_LANDING_DASHBOARD_PAGE, SET_DASHBOARD_POSITION } from './dashboardQueries';
import { useHistory } from 'react-router-dom';
import lodashValues from 'lodash/values';
import {
    SET_DASHBOARD_METADATA,
    SET_DASHBOARD_GROUP_TAB,
    SUGGESTED_FILTER,
    SET_FILTER_REMOVE_FROM_LOCAL,
    SET_AUTO_RELOAD_FREQUENCY,
    SET_REPORT_LIST,
  } from 'src/reduxActions/actionNameEnums';
import {
    getfilterWithUnSelectedValues,
    transformFilters,
    transformFilterWithSelectedValue,
} from 'src/components/common/utils/filter';
import {
    CHANGE_DASHBOARD_GROUP,
    INITIALIZE_DASHBOARD_FILTERS,
    DUMMY_DATA_SWITCH,
} from 'src/reduxActions/actionNameEnums';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { GET_DASHBOARD_GROUP } from 'src/api/routes';
import { dashboardFilterTypes, getDashboardChartSizes } from 'src/constants';
import config from 'src/settings';

const listenerFuncs = () => {
    const listeners:any  = {};
    const listen = (listenerFunc, dashboardTabId) => {
        if(!(dashboardTabId in listeners)){
            listeners[dashboardTabId] = [];
        }
        listeners[dashboardTabId].push(listenerFunc);
    };
    const sendSignal = (args, dashboardTabId) => {
        if(!(dashboardTabId in listeners)){
            listeners[dashboardTabId] = [];
        }
        listeners[dashboardTabId].forEach(listener => {
            listener(args);
        });
    };
    return {
        listen,
        sendSignal,
    };
};

export const useDashboard = (id) => {
    const dispatch = useDispatch();
    const reports = useSelector((state:any) => state.reportConfig.reports);
    const dashboardFilter = useSelector((state: any) => state.dashboardFilter);
    const supportedFilters = useSelector((state:any) => state.masterData?.supportedFilters);
    const dashboardGroupMetaData = useSelector(
        (state: any) => state.dashboardGroupMetaData,
    );
    const {
      currentDashboardGroupTab: dashboardTab,
      filterRemoveFromLocal,
      metadata: dashboardMetaData,
      externalParams,
      dashboardGridColumns = 12,
    } = dashboardGroupMetaData;
    const canEditDefaultDashboard = useSelector((state:any) => state.masterData?.organisationConfig?.isDefaultEditable || false);
    const listeners = useRef(null);
    const [dashboardError, setDashboardError] = useState(false);
    const [numSchedules, setNumSchedules] = useState(false);
    const dashboardGroupId = id;
    const cachedDashboardString = localStorage.getItem('activeDashboard');
    const cachedDashboards = cachedDashboardString ? JSON.parse(cachedDashboardString) : null;
    let activeCachedDashboard = cachedDashboards?.find(activeDashboard => activeDashboard.dashboardGroupId === id) ?? null;

    const setDashboardTab = (dashboardTabId) => {
        dispatch({
          type: SET_DASHBOARD_GROUP_TAB,
          payload: dashboardTabId,
        });
    };

    const [editPositions, { loading: editingPosition }] = useLazyQuery(SET_DASHBOARD_POSITION, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            getDashboardGroupMetaData({
                URLQueryPath: id,
            });
        },
        onError: () => {
            message.error({ key: 'editReportPosition', content: 'Failed to change Report position' });
        },
    });

    useEffect(() => {
        listeners.current = listenerFuncs() as any;
    },[]);

    const listenForRefresh = (listenFunction, dashboardTab) => {
        if(listeners.current){
            (listeners.current as any).listen(listenFunction, dashboardTab);
        }
    };

    const setFilterRemoveFromLocal = (payload) => {
        dispatch({ type: SET_FILTER_REMOVE_FROM_LOCAL, payload });
    };

    const setActiveTabId = (dashboard) => {
        if (activeCachedDashboard) {
          const index = dashboard.findIndex(
            (d) =>
              d.dashboardVisualisations.dashboard._id ===
              activeCachedDashboard.activeTabId,
          );
          if (index !== -1) {
            setDashboardTab(index.toString());
            suggestedFilter(dashboard[index]);
            return index;
          }
          setDashboardTab('0');
          return 0;
        }
        setDashboardTab('0');
        suggestedFilter(dashboard[0]);
        return 0;
      };
    const deleteFilterLocalStorage = (dashboardFilter) => {
    const tabId = activeCachedDashboard?.activeTabId;
    const filterIsPresent = activeCachedDashboard?.tabs[tabId]?.filters ?? false;
    if (activeCachedDashboard && filterIsPresent) {
        for (const metric in activeCachedDashboard.tabs[tabId].filters) {
        if (activeCachedDashboard.tabs[tabId].filters.hasOwnProperty(metric)) {
            const matchingMetric = dashboardFilter.find(
            (filter) => filter.metric === metric,
            );
            if (!matchingMetric) {
            delete activeCachedDashboard.tabs[tabId].filters[metric];
            }
            if (!Object.keys(activeCachedDashboard.tabs[tabId].filters).length) {
            break;
            }
        }
        }
        if (!Object.entries(activeCachedDashboard.tabs[tabId].filters).length) {
        delete activeCachedDashboard.tabs[tabId];
        //We should be deleting the entire tab if it only has the specific filter that is being deleted
        }
        localStorage.setItem('activeDashboard', JSON.stringify(cachedDashboards));
    }
    setFilterRemoveFromLocal(false);
    };

    const updateGlobalFilters = (dashboards, globalFilters, externalLinkedFilters) => {
        if (externalParams?.externalSource) {
            activeCachedDashboard = externalLinkedFilters?.find(activeDashboard => activeDashboard.dashboardGroupId === id) ?? null;
        }
        if (activeCachedDashboard) {
            Object.keys(activeCachedDashboard.tabs).forEach((dashboardId) => {
                const index =
                    dashboards.findIndex((d) =>
                    d.dashboardVisualisations.dashboard._id === dashboardId,
                );
                if (index !== -1) {
                    const dashboardData = dashboards[index];
                    const currentDashboardGlobalFilters = globalFilters[index];
                    if (dashboardData) {
                        const localFilter =
                            activeCachedDashboard.tabs[dashboardId]?.filters ?? {};
                        const valuesArray = Object.values(localFilter) || [];
                        const updatedGlobalFilters = currentDashboardGlobalFilters.map((cachedGlobalFilter) => {
                            const matchingFilter: any = valuesArray.find(
                                (filter: any) => filter.metric === cachedGlobalFilter.metric,
                            );
                            if (matchingFilter) {
                                const selectedFilter = matchingFilter.name;
                                const filterInput = selectedFilter ? supportedFilters[cachedGlobalFilter.type]?.
                                    find((obj) => obj.name === matchingFilter.name) : undefined;
                                return {
                                    ...cachedGlobalFilter,
                                    data: matchingFilter.data
                                        ? JSON.parse(matchingFilter.data)
                                        : undefined,
                                    selectedFilter,
                                    filterInputType: filterInput?.type,
                                };
                            }
                            return cachedGlobalFilter;
                        });
                        globalFilters[index] = updatedGlobalFilters;
                    }
                }
            });
        }
        return globalFilters;
    };

    const getGlobalFiltersWithUniqueFilterIds = (globalFilters, dashboard) => {
      const finalGlobalFiltersList: any = [];
      for (let index = 0; index < dashboard.length; index++) {
        const { dashboardVisualisations } = dashboard[index];
        const dashboardGlobalFilters = globalFilters[index];

        const groupedFilters = {};
        let hasFilterID = true;
        dashboardGlobalFilters.forEach((filter) => {
          // if even a single report in dashboardGroup has no filterId, then use globalFilters only
          if (!filter.filterId) {
            hasFilterID = false;
          }
          if (!groupedFilters[filter.filterId]) {
            groupedFilters[filter.filterId] = [];
          }
          groupedFilters[filter.filterId].push(filter);
        });

        if (!hasFilterID) {
          return globalFilters;
        }

        const finalGlobalFilters: any = [];
        for (const filterId in groupedFilters) {
          if (groupedFilters.hasOwnProperty(filterId)) {
            const filters = groupedFilters[filterId];
            let preferredFilters = filters.filter(
              (filter) => filter.overrideOtherFilters,
            );
            if (preferredFilters.length === 0) {
              preferredFilters = filters;
            }

            let selectedFilter = preferredFilters[0];
            if (preferredFilters.length > 1) {
              selectedFilter = preferredFilters.reduce((acc, filter) => {
                const accReport = dashboardVisualisations.reports.find(
                  (report) => report.reportId === acc.reportId,
                );
                const filterReport = dashboardVisualisations.reports.find(
                  (report) =>
                    accReport.reportId !== report.reportId &&
                    report.reportId === filter.reportId,
                );
                const filterReportWinsInPosition =
                  filterReport.position.y < accReport.position.y ||
                  (filterReport.position.y === accReport.position.y &&
                    filterReport.position.x < accReport.position.x);
                if (
                  filterReport.filtersIdOverRideValue &&
                  filterReport.filtersIdOverRideValue[filterId] &&
                  accReport.filtersIdOverRideValue &&
                  accReport.filtersIdOverRideValue[filterId]
                ) {
                  return filterReportWinsInPosition ? filter : acc;
                }
                if (
                  filterReport.filtersIdOverRideValue &&
                  filterReport.filtersIdOverRideValue[filterId]
                ) {
                  return filter;
                }
                if (
                  accReport.filtersIdOverRideValue &&
                  accReport.filtersIdOverRideValue[filterId]
                ) {
                  return acc;
                }
                return filterReportWinsInPosition ? filter : acc;
              });
            }

            const sameFilterId = filters
              .map((filter) => ({
                type: filter.filter.type,
                metricName: filter.metric,
                isCustomReportFilter: filter.isCustomReportFilter,
                sql: filter.sql || '',
                reportType: filter.reportType,
              }));

            finalGlobalFilters.push({
              ...selectedFilter,
              sameFilterId,
            });
          }
        }
        finalGlobalFilters.sort((a, b) => a.filterPriority - b.filterPriority);
        finalGlobalFiltersList.push(finalGlobalFilters);
      }
      return finalGlobalFiltersList;
    };

    const [getDashboardGroupMetaData, { loading:dashboardGroupfetchInProgress }] = useLazyAPI(GET_DASHBOARD_GROUP, {
        type: 'GET',
        onCompleted: (data) => {
            let externalLinkedFilters:any = {};
            const externalSourceLinking = externalParams?.externalSource;
            if (externalSourceLinking === 'true') {
                const linkedDashboardGroups = JSON.parse(externalParams?.linkedDashboardGroup) || {};
                const objectId = externalParams?.objectId || '';
                const linkedFiltersMapping = linkedDashboardGroups[data?.id]?.linkedFilters || {};
                Object.values(linkedFiltersMapping).forEach((value:any) => {
                    if(value?.data === undefined) {
                        value.data = JSON.stringify({value: [objectId]});
                        value.name = 'textIsEqualTo';
                    }
                });
                externalLinkedFilters = {
                    activeTabId: data?.dashboards[0]?.dashboardVisualisations.dashboard._id,
                    dashboardGroupId: data?.id,
                    tabs: {},
                };
                data?.dashboards.forEach(dashboard => {
                    Object.entries(linkedFiltersMapping).forEach(([key, value]: [string, any]) => {
                        const dashboardTabId = dashboard?.dashboardVisualisations.dashboard._id;
                        externalLinkedFilters.tabs[dashboardTabId] = {
                            filters: {
                                ...externalLinkedFilters.tabs[dashboardTabId]?.filters,
                                [key]: {
                                    data: value?.data,
                                    name: value?.name,
                                    type: value?.type,
                                    metric: value?.metric,
                                    linkedMetric: value?.linkedMetric,
                                },
                            },
                        };
                    });
                });
                const activeDashboard = localStorage.getItem('activeDashboard');
                const activeDashboards = activeDashboard ? JSON.parse(activeDashboard) : [];
                const index = activeDashboards.findIndex(item => item.dashboardGroupId === data?.id);
                if (index !== -1) {
                    activeDashboards.splice(index, 1);
                }
                activeDashboards.push(externalLinkedFilters);
                localStorage.setItem('activeDashboard', JSON.stringify(activeDashboards));
            }
            dispatch({
                type: DUMMY_DATA_SWITCH,
                payload: {
                    enableDummyData: false,
                },
            });
            dispatch({
                type: CHANGE_DASHBOARD_GROUP,
                payload: {
                    dashboardGroupName: data?.name,
                    dashboardGroupId: data?.id,
                    dashboardGroupType: data?.type,
                    dashboardType: data?.type,
                    dashboardDescription: data?.description,
                    dashboardCategory: data?.category,
                    reportsData: {},
                    dashboardGridColumns: data?.dashboardGridColumns || 12,
                },
            });
            dispatch({ type: SET_AUTO_RELOAD_FREQUENCY, payload: data.autoReloadFrequency });
            const globalFilters = data.dashboards.map((datapoint) =>
              getFilterColumns(datapoint),
            );
            const dashboard = data?.dashboards;
            const updatedGlobalFilters = updateGlobalFilters(
              dashboard,
              globalFilters,
              [externalLinkedFilters],
            );
            const globalFiltersWithUniqueFilterIds = getGlobalFiltersWithUniqueFilterIds(updatedGlobalFilters, dashboard);
            dispatch({
              type: INITIALIZE_DASHBOARD_FILTERS,
              payload: globalFiltersWithUniqueFilterIds,
            });
            setDashboardMetaData(data);
            const dashboardTabIndex = setActiveTabId(dashboard);
            const reportIds = dashboard[dashboardTabIndex].dashboardVisualisations.reports.map((reports: any) => reports.reportId);
            dispatch({
                type: SET_REPORT_LIST,
                payload: reportIds,
              });

            if(filterRemoveFromLocal){
                const dashboardFilter = data?.dashboards[dashboardTab].dashboardGlobalFilters;
                deleteFilterLocalStorage(dashboardFilter);
                //If a report is deleted and the filter related to it was in localStorage, we need to remove it
            }
            setDashboardError(false);
            setNumSchedules(data?.numSchedules);
        },
        onError: data => {
            console.log('error', data);
            setDashboardError(true);
        },
    });

    const setDashboardMetaData = (payload) => {
        dispatch({
            type: SET_DASHBOARD_METADATA,
            payload,
        });
    };

  const expandFilters = (filters) => {
    const expandedFilters = {};
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const filter = filters[key];
        if (!filter.filterId || !filter.sameFilterId) {
          // if even a single report in dashboardGroup has no filterId, then use filters only
          return filters;
        }
        const { sameFilterId = [], ...mainFilter } = filter;

        const mainFilterType = mainFilter.filter.type;
        const inconsistentFilterMapping = sameFilterId.some(
          (sameFilter) => sameFilter.type !== mainFilterType,
        );

        if (inconsistentFilterMapping) {
          message.error({
            key: 'incorrectFilterSetup',
            duration: 1,
            content: 'Incorrect filter setup. Please check again!',
          });
          return null;
        }

        sameFilterId.forEach((sameFilter) => {
          expandedFilters[`${sameFilter.metricName}.${sameFilter.reportType}`] =
            {
              ...mainFilter,
              metric: sameFilter.metricName,
              filter: { ...mainFilter.filter, type: sameFilter.type },
              isCustomReportFilter: sameFilter.isCustomReportFilter,
              sql: sameFilter.sql || '',
            };
        });
      }
    }
    return expandedFilters;
  };

  const onClickApplyFilters = (dashboardTab) => {
    const expandedFilters = expandFilters(dashboardFilter[dashboardTab]);
    const filters = lodashValues(expandedFilters);
    const filtersWithUnselectedValues = getfilterWithUnSelectedValues(
      filters,
      supportedFilters,
    ) as Array<any>;
    const filterWithSelectedValue = filters.filter(
      (filter) => !filtersWithUnselectedValues.includes(filter.metric),
    );
    const refinedFilterWithSelectedValue = filterWithSelectedValue.filter(
      transformFilterWithSelectedValue,
    );
    const filterTransformed = transformFilters(refinedFilterWithSelectedValue);
    return filterTransformed;
  };

    const setNewPositions = (addedReports, nextYPositionDesktop, nextYPositionMobile) => {
        const newReportPositions: any[] = [];
        let startXDesktop = 0;
        let startYDesktop = nextYPositionDesktop;
        let startXMobile = 0;
        let startYMobile = nextYPositionMobile;
        const dashboardChartSizesObj = getDashboardChartSizes(dashboardGridColumns);
        Object.keys(dashboardChartSizesObj).forEach((chartType) => {
            let newRowJump = false;
            let chartTypePresent = false;
            const chartHeight = dashboardChartSizesObj[chartType].h;
            const chartWidth = dashboardChartSizesObj[chartType].w;
            addedReports.forEach((report) => {
                if(report.type === chartType) {
                    newRowJump = false;
                    chartTypePresent = true;
                    newReportPositions.push(
                        {
                            reportId: report.id,
                            position: {
                                x: startXDesktop,
                                y: startYDesktop,
                                w: chartWidth,
                                h: chartHeight,
                            },
                            mobilePosition: {
                                x: startXMobile,
                                y: startYMobile,
                                w: chartWidth,
                                h: chartHeight,
                            },
                        },
                    );
                    startXDesktop += chartWidth;
                    startXMobile += chartWidth;
                    if(startXDesktop + chartWidth > dashboardGridColumns) {
                        startXDesktop = 0;
                        startYDesktop += chartHeight;
                        startXMobile = 0;
                        startYMobile += chartHeight;
                        newRowJump = true;
                    }
                }
            });
            if(!newRowJump && chartTypePresent) {
                startYDesktop += chartHeight;
                startXDesktop = 0;
                startYMobile += chartHeight;
                startXMobile = 0;
            }
        });
        return newReportPositions;
    };
    const getReportPositions = (addedReports) => {
        const reportPositions: any = [];
        // existing reports
        dashboardMetaData?.dashboards[dashboardTab].dashboardVisualisations.reports.map((report) => {
            reportPositions.push({
              reportId: report.reportId,
              position: report.position,
              mobilePosition: report.mobilePosition,
            });
        });

        const lastAddedReport = reportPositions[reportPositions.length - 1];
        // new reports position for desktop
        const nextYPositionDesktop =
          reportPositions.length === 0
            ? 0
            : lastAddedReport?.position
            ? lastAddedReport.position.h + lastAddedReport.position.y
            : reportPositions.length * 10;
        // new reports position for mobile
        const nextYPositionMobile =
          reportPositions.length === 0
            ? 0
            : lastAddedReport?.mobilePosition
            ? lastAddedReport.mobilePosition.h +
              lastAddedReport.mobilePosition.y
            : reportPositions.length * 10;

        const newReportPositions = setNewPositions(
          addedReports,
          nextYPositionDesktop,
          nextYPositionMobile,
        );
        return reportPositions.concat(newReportPositions);
      };

    const updateReportPositions = (addedReports) => {
        editPositions({
            variables: {
                dashboardId: dashboardMetaData?.dashboards[dashboardTab].dashboardVisualisations.dashboard._id,
                reportPositions: getReportPositions(addedReports),
            },
        });
    };

    const onRefetchDashboardData = () => {
        getDashboardGroupMetaData({
            URLQueryPath: id,
        });
    };

    const onApplyFilter = (dashboardTab) => {
        const filters = onClickApplyFilters(dashboardTab);
        if(filters){
            if(listeners.current){
                (listeners.current as any).sendSignal(filters, dashboardTab);
            }
        }
        return filters;
    };

    const onResetFilter = (dashboardTab) => {
        const filters = [];
            if(listeners.current){
                (listeners.current as any).sendSignal(filters, dashboardTab);
            }
        return filters;
    };

    const getFilterColumns = (data) => {
        const filterList = data?.dashboardGlobalFilters || [];
        const newList = filterList.map((filter) => {
            if(filter.dashboardFilterType !== dashboardFilterTypes.FROZEN ){
            return {
                ...filter,
                type: filter.filter.type,
                name: filter.metric,
                prettyName: filter.filter.prettyName,
                sql: filter.filter.sql || '',
                isDefaultFilter: filter.filter.isDefaultFilter || false,
            };
        }}).filter(element => element !== undefined);
        return newList;
    };

    const suggestedFilter = (data) => {
        const globalSuggestedFilterStrings = data?.dashboardVisualisations?.reports[0]?.globalSuggestedStrings || [];
        const arrayOfGlobalSuggestedFilters = globalSuggestedFilterStrings.map(x => x);
        dispatch({type: SUGGESTED_FILTER, payload: arrayOfGlobalSuggestedFilters});
    };
    return {
        listenForRefresh,
        fetchInProgress: dashboardGroupfetchInProgress,
        // dashboardFilter: dashboardGroupData?.dashboardGlobalFilters || [],
        filters: dashboardMetaData?.dashboards.map(datapoint => getFilterColumns(datapoint)) || [],
        submitableFilter: onClickApplyFilters(dashboardTab),
        onApplyFilter,
        onResetFilter,
        // reportName: dashboardGroupData?.dashboardVisualisations?.dashboard.name,
        refetchMetaData: onApplyFilter,
        // reports: dashboardGroupData?.dashboardVisualisations.reports || [],
        // type: dashboardGroupData?.dashboardVisualisations.dashboard.type,
        canEditDefaultDashboard,
        dashboards: dashboardMetaData?.dashboards,
        setDashboardTab,
        dashboardError,
        setFilterRemoveFromLocal,
        getFilterColumns,
        suggestedFilter,
        dashboardTab,
        dashboardGroupId,
        dashboardGroupType: dashboardMetaData?.type,
        onRefetchDashboardData,
        updateReportPositions,
        numSchedules,
        activeCachedDashboard,
    };
};

export const useCreateNewDashboard = (params) => {
    const { refetch } = params;

    const [showModal, setShowModal] = useState(false);
    const [dashboardName, setDashboardName] = useState('');
    const [dashboardDescription, setDashboardDescription] = useState('');
    const history = useHistory();
    const [dashboardCategory,setDashboardCategory] = useState<any>(null);
    const canEditDefaultDashboard = useSelector((state:any) => state.masterData?.organisationConfig?.isDefaultEditable || false);
    const [createDashboard, { loading: creationInProgress, data: createdDashboardMetaData }] = useLazyQuery(SAVE_DASHBOARD_GROUP, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            message.success({key:'creatingDashboard', content: 'Dashboard created successfully'});
            setShowModal(false);
            refetch();
        },
        onError: data => {
            message.error({key:'creatingDashboard', content: 'Failed Creating Dashboard'});
        },
    });
    const onCreateDashboard = () => {
        if(!dashboardName){
            return message.error('Please Provide Dashboard Name to proceed');
        }
        if(!dashboardDescription){
            return message.error('Please Provide Dashboard Description to proceed');
        }
        if(canEditDefaultDashboard){
            if(!dashboardCategory){
                return message.error('Please Provide Dashboard Category to proceed');
            }
        }
        message.loading({key:'creatingDashboard', content: 'Creating New Dashboard'});

        return createDashboard(
            { variables: {
                isFavourite: false,
                name: dashboardName,
                description: dashboardDescription,
                isActive: true,
                isNew: true,
                category: dashboardCategory,
                dashboardGridColumns: config.DASHBOARD_GRID_COLUMNS,
            }},
        );
    };

    return {
        showModal,
        setShowModal,
        dashboardName,
        setDashboardName,
        dashboardDescription,
        setDashboardDescription,
        creationInProgress,
        createdDashboardMetaData,
        dashboardCategory,
        setDashboardCategory,
        onClickOk: onCreateDashboard,
    };
};

export const useAddReport = () => {
    const [visiblility, setVisibility] = useState(false);
    return {
        visiblility,
        setVisibility,
    };
};

export const useUpdateDashboard = (params) => {
    const {
        dashboardGroupId,
        dashboardName,
        dashboardDescription,
        dashboardCategory,
        setupdateModelVisibilty,
        refetch,
        refetchForDashboardGroup,
      } = params;
    const canEditDefaultDashboard = useSelector((state:any) => state.masterData?.organisationConfig?.isDefaultEditable || false);
    const dashboardGroupMetadata = useSelector(
      (state: any) => state.dashboardGroupMetaData,
    );
    const { dashboardGridColumns = 12 } = dashboardGroupMetadata || {};
    const [setLandingDashboardPage, {loading: landingPageLoading}] = useLazyQuery(SET_LANDING_DASHBOARD_PAGE, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            message.success({key:'landingPage', duration: 0.5, content: 'Landing page updated successfully'});
            refetch();
        },
        onError: data => {
            message.error({key:'landingPage', duration: 0.5, content: 'Landing page update failed'});
        },
    });

    const [updateDashboard] = useLazyQuery(SAVE_DASHBOARD_GROUP, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            message.success({key:'updatingDashboard', content: 'Dashboard updated successfully'});
            setupdateModelVisibilty(false);
            refetch();
        },
        onError: data => {
            message.error({key:'updatingDashboard', content: 'Dashboard Update Failed'});
        },
    });

    const onUpdateDashboard = () => {
        if(!dashboardName){
            return message.error('Please Provide Dashboard Name to proceed');
        }
        if(canEditDefaultDashboard){
            if(!dashboardCategory){
                return message.error('Please Provide Dashboard Category to proceed');
            }
        }
        message.loading({key:'updatingDashboard', content: 'Updating Dashboard'});
        return updateDashboard({variables: {
            id: dashboardGroupId,
            name: dashboardName,
            isNew: false,
            isActive: true,
            category: dashboardCategory,
            description: dashboardDescription,
            dashboardGridColumns,
        }});
    };

    const [deleteDashboard, { loading: deleteInProgress, data: deleted }] = useLazyQuery(SAVE_DASHBOARD_GROUP, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            message.success({key:'deleteDashboard', duration: 0.5, content: 'Dashboard deleted successfully'});
            if(refetchForDashboardGroup){
                refetchForDashboardGroup();
            } else {
                refetch();
            }
        },
        onError: data => {
            message.error({key:'deleteDashboard', duration: 0.5, content: 'Failed deleting Dashboard'});
        },
    });

    const onDelete = (dashboardGroupId, name, category ='', description ='') => {
        message.loading({key: 'deleteDashboard', duration: 0, content: 'Deleting Dashboard'});
        deleteDashboard({variables:{
            id: dashboardGroupId,
            name,
            isActive: false,
            isNew: false,
            category,
            description,
        }});
    };

    const onSetLandingPage = (dashboardGroupId) => {
        setLandingDashboardPage({variables:{
            dashboardGroupId,
        }});
    };

    return {
        onClickOk: onUpdateDashboard,
        onSetLandingPage,
        onDelete,
        landingPageLoading,
    };
};
