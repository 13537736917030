import React from 'react';
import { Tooltip } from 'antd';
import { createUseStyles } from 'react-jss';
import { ReactComponent as AscendingIcon } from 'src/assets/AscendingIcon.svg';
import { ReactComponent as DescendingIcon } from 'src/assets/DescendingIcon.svg';
const useStylesSortBySelector = createUseStyles({
  basePane: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '0.3rem',
    marginTop: '0.3rem',
  },
  icon: {
    margin: '0.1rem',
    fontSize: '1.25rem',
  },
  active: {
    color: '#1890FF',
  },
  childChartSelector: {
    width: '1.8rem',
    height: '1.8rem',
    display: 'flex',
    border: '1px solid #D9D9D9',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '0.2rem',
  },
  selectedChart: {
    borderColor: '#1890FF',
  },
});
const SortBySelector = (props) => {
  const classes = useStylesSortBySelector();
  const { value, onChange } = props;
  return (
    <div className={classes.basePane}>
      <Tooltip title="Ascending">
        <div
          onClick={() => onChange('ASC')}
          className={`${classes.childChartSelector}${
            value === 'ASC' ? ' ' + classes.selectedChart : ''
          }`}
        >
          <AscendingIcon stroke={value === 'ASC' ? '#1890FF' : '#8C8C8C'} />
        </div>
      </Tooltip>
      <Tooltip title="Descending">
        <div
          onClick={() => onChange('DESC')}
          className={`${classes.childChartSelector}${
            value === 'DESC' ? ' ' + classes.selectedChart : ''
          }`}
        >
          <DescendingIcon stroke={value === 'DESC' ? '#1890FF' : '#8C8C8C'} />
        </div>
      </Tooltip>
    </div>
  );
};
export default SortBySelector;
