import { Switch } from 'antd';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_REPORT_SWITCH } from 'src/reduxActions/actionNameEnums';

const useStyles = createUseStyles({
  topQueryBarContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0.8rem 0.5rem',
    justifyContent: 'space-between',
    borderRadius: '4px 4px 0px 0px',
    border: '1px solid rgba(0, 0, 0, 0.06)',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    alignItems: 'center',
    zIndex: 1,
  },
});

const CustomReportSwitch = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isAdminReport = useSelector(
    (state: any) => state.reportMetaData.isAdminReport,
  );
  const onChangeAdminReportHandle = checked => {
    dispatch({
      type: ADMIN_REPORT_SWITCH,
      payload: { isAdminReport: checked },
    });
  };
    return (
      <>
        <div className={classes.topQueryBarContainer}>
          <div style={{ fontSize: '0.9rem' }}>Admin Report</div>
          <Switch
            size={'small'}
            checked={isAdminReport}
            onChange={onChangeAdminReportHandle}
          />
        </div>
      </>
    );
};
export default CustomReportSwitch;
