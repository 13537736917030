import {
    RESET_DASHBOARD_GROUP_NAME_AND_ID,
    INITIALISE_DASHBOARD_GROUP,
    CHANGE_DASHBOARD_GROUP,
    GET_DASHBOARD_GROUP_LIST,
    SET_DASHBOARD_GROUP_TAB,
    SET_DASHBOARD_METADATA,
    SET_VISIBLE_CHARTS,
    DASHBOARD_FILTER_UPDATED,
    SET_FILTER_REMOVE_FROM_LOCAL,
    SET_FULL_SCREEN,
    SET_LAST_UPDATED_TIME,
    SET_AUTO_REFRESH,
    SET_REFRESH_REPORTS_DATA,
    CHANGE_DASHBOARD_TAB,
    SET_AUTO_RELOAD_FREQUENCY,
    SET_APPLIED_FILTER,
    SET_REPORT_LIST,
    SET_MOBILE_REPORT_PANE_HEIGHT,
    DASHBOARD_REPORT_CHART_CONFIG,
    SET_EXTERNAL_META_DATA,
} from '../reduxActions/actionNameEnums';
import lodashCloneDeep from 'lodash/cloneDeep';
import { DEFAULT_AUTO_RELOAD_FREQUENCY } from 'src/constants';

const initialState = {
    dashboardGroupName: '',
    dashboardGroupId: '',
    dashboardGroupType: '',
    dashboardGroupList: [],
    currentDashboardGroupTab: '0',
    metadata: undefined,
    filterRemoveFromLocal: false,
    currentDashboards: [],
    visibleCharts: {},
    filterUpdated: false,
    isFullScreen: false,
    dashboardType: '',
    dashboardDescription: '',
    dashboardCategory: null,
    isAutoRefresh: false,
    lastUpdatedTime: 0,
    reportList: [],
    reportsData: {},
    autoReloadFrequency: DEFAULT_AUTO_RELOAD_FREQUENCY,
    isFilterApplied: true,
    reportPaneHeights: {},
    dashboardReportsChartConfigs: {},
    externalParams: {},
    dashboardGridColumns: 12,
};

const initialStateDashboardGroupList = [];

const dashboardGroupMetaData = (state = initialState, action) => {
    const { type, payload } = action;
    const copyDashboardMetaData = lodashCloneDeep(state);
    switch(type){
        case INITIALISE_DASHBOARD_GROUP:
        case CHANGE_DASHBOARD_GROUP:
            const newObject = Object.assign(copyDashboardMetaData, payload);
            return newObject;
        case RESET_DASHBOARD_GROUP_NAME_AND_ID:
            return {
                ...copyDashboardMetaData,
                dashboardGroupName: '',
                dashboardGroupId: '',
                dashboardGroupType: '',
                currentDashboardGroupTab: '0',
                metadata: undefined,
            };
        case GET_DASHBOARD_GROUP_LIST:
            return{
                ...copyDashboardMetaData,
                dashboardGroupList:payload,
            };
        case SET_DASHBOARD_GROUP_TAB: {
            return {
                ...copyDashboardMetaData,
                currentDashboardGroupTab: payload,
            };
        }
        case SET_DASHBOARD_METADATA:
            return {
                ...copyDashboardMetaData,
                metadata: payload,
            };
        case SET_VISIBLE_CHARTS:
            return {
                ...copyDashboardMetaData,
                visibleCharts: {
                    ...state?.visibleCharts,
                    [payload]: true,
                },
            };
        case DASHBOARD_FILTER_UPDATED:
            return {
                ...copyDashboardMetaData,
                filterUpdated: payload,
            };
        case SET_MOBILE_REPORT_PANE_HEIGHT:
            return {
                ...copyDashboardMetaData,
                reportPaneHeights: {
                    ...copyDashboardMetaData.reportPaneHeights,
                    [payload.reportId]: payload.bigNumberCount,
                },
            };
        case DASHBOARD_REPORT_CHART_CONFIG:
            return {
                ...copyDashboardMetaData,
                dashboardReportsChartConfigs: {
                    ...copyDashboardMetaData.dashboardReportsChartConfigs,
                    [payload.reportId]: payload.chartConfig,
                },
            };
        case SET_FILTER_REMOVE_FROM_LOCAL:
            return {
                ...copyDashboardMetaData,
                filterRemoveFromLocal: payload,
            };
        case SET_FULL_SCREEN:
            return {
                ...copyDashboardMetaData,
                isFullScreen: payload,
            };
        case SET_LAST_UPDATED_TIME:
            return {
                ...copyDashboardMetaData,
                lastUpdatedTime: payload,
            };
        case SET_AUTO_REFRESH:
            return {
                ...copyDashboardMetaData,
                isAutoRefresh: payload,
            };
        case SET_REFRESH_REPORTS_DATA:
            return {
                ...copyDashboardMetaData,
                reportsData: payload,
            };
        case SET_AUTO_RELOAD_FREQUENCY:
            return {
                ...copyDashboardMetaData,
                autoReloadFrequency: payload,
            };
        case CHANGE_DASHBOARD_TAB:
            const objectChange = Object.assign(copyDashboardMetaData, payload);
            return objectChange;
        case SET_APPLIED_FILTER:
            return {
                ...copyDashboardMetaData,
                isFilterApplied: payload,
            };
        case SET_REPORT_LIST:
            return {
                ...copyDashboardMetaData,
                reportList: payload,
            };
        case SET_EXTERNAL_META_DATA:
            return {
                ...copyDashboardMetaData,
                externalParams: payload,
            };
        default:
            return copyDashboardMetaData;
    }
};

export default dashboardGroupMetaData;
