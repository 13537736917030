import React from 'react';
import { InputNumber } from 'antd';

const CustomNumberInput = (props) => {
  const {
    size,
    style,
    onChange,
    value,
    maxValue = Number.POSITIVE_INFINITY,
    minValue = Number.NEGATIVE_INFINITY,
  } = props;

  return (
    <InputNumber
      style={style}
      size={size}
      value={value}
      onChange={onChange}
      min={minValue}
      max={maxValue}
    />
  );
};

export default CustomNumberInput;
