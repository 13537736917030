import React, { useState} from 'react';
import { useLazyQuery, ServerError } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { METRICS_CHANGED, SET_CHARTS_METADATA, SET_COLUMN_WIDTH_ALIAS_NAME } from 'src/reduxActions/actionNameEnums';
import { GETVISUALISATION } from '../createQueryComponents/graphqlQuery';
import { useSubmissionData } from './dataSubmissionHook';
import { isManualQueryReport } from 'src/components/common/utils/utils';
import { message } from 'antd';

export const useSubmitReport = (options: any = {}) => {
    const dispatch = useDispatch();
    const [stopQuery, setStopQuery] = useState(false);
    const submissionData = useSubmissionData(options);
    const selectedDataMartType = useSelector((state: any) => state.reportMetaData.dataMartType);
    const metrics = useSelector((state: any) => state.metrics);

    const getSwitcherFields = (data: any = {}) => {
        if (!Object.keys(data).length) return;
        return {
          rows: {
            items: data.fields?.rows.map((row) => {
              return { id: row };
            }),
          },
          columns: {
            items: data.fields?.columns.map((column) => {
              return { id: column };
            }),
          },
          values: {
            selectable: true,
            items: data.fields?.values.map((value) => {
              return { id: value };
            }),
          },
        };
      };

    const [switcherFields, setSwitcherFields] = useState(
        getSwitcherFields(options.chartData?.formData),
      );
    const [fields, setFields] = useState(options.chartData?.formData?.fields);

    const [submitQuery, { loading:submitQueryloading, data }] = useLazyQuery(GETVISUALISATION, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if(!stopQuery){
                dispatch({
                    type: SET_CHARTS_METADATA,
                    payload: data.getVisualisation,
                });
                if(isManualQueryReport(selectedDataMartType)) {
                  const updatedMetrices = data.getVisualisation.metrices.map(metric => {
                    return {
                      ...metric,
                      value: metric.metric,
                      savedMetric: metrics[metric.metric]?.savedMetric || false,
                    };
                  });
                  dispatch({
                    type:METRICS_CHANGED,
                    payload: updatedMetrices,
                  });
                }
                const combinedMetric = data.getVisualisation.metrices.concat(data.getVisualisation.customAggregateMetrices);
                dispatch({
                    type:SET_COLUMN_WIDTH_ALIAS_NAME,
                    payload:combinedMetric,
                });
            }
        },
        onError: (error) => {
          if((error?.networkError as ServerError)?.statusCode === 403) {
            message.error({
              duration: 3,
              content: 'You do not have access to view this report. Please contact admin',
            });
          }
        },
    });

    const onClickSubmit = () => {
        const getSubmissionVariables = isManualQueryReport(selectedDataMartType)
          ? submissionData.getCustomReportSubmissionVariables
          : submissionData.getSubmissionVariables;
        const variables = getSubmissionVariables();
        if(variables.validationPassed){
            setStopQuery(false);
            submitQuery({variables: variables.variables});
        }
        if(options.chartData) {
            setFields(options.chartData.formData?.fields);
            setSwitcherFields(getSwitcherFields(options.chartData.formData));
        }
    };
    const onClickStop = () => {
        setStopQuery(true);
    };
    return {
      submitQuery,
      onClickSubmit,
      submitQueryloading,
      fields,
      setFields,
      switcherFields,
      setSwitcherFields,
      stopQuery,
      setStopQuery,
      onClickStop,
    };
};
