import { gql } from '@apollo/client';

export const ADD_REPORT_TO_DASHBOARD = gql`
  query ADD_REPORT_TO_DASHBOARD($reportIds: [String]!, $dashboardId: String!, $dashboardGroupId: String!) {
    moveReportsToDashboard(reportIds: $reportIds, dashboardId: $dashboardId, dashboardGroupId: $dashboardGroupId) {
      success
      error
    }
  }
`;

export const GET_ALL_DASHBOARD = gql`
  query GET_DASHBOARD_BY_NAME($searchQuery: String!, $types: [String]!) {
    getDashboardByName(searchQuery: $searchQuery, types: $types) {
      _id
      name
      type
      description
      creationTime
      updationTime
      category
    }
  }
`;

export const SEARCH_FILTER_SUGGESTION = gql`
  query getSearchFilterSuggestion(
    $searchQuery: String
    $metricName: String!
    $reportName: String!
  ) {
    getSearchFilterSuggestion(
      searchQuery: $searchQuery
      metricName: $metricName
      reportName: $reportName
    ) {
        searchResults {
          value
          prettyName
        }
        total
    }
  }
`;
