import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Affix, Drawer } from 'antd';
import NavigationPane from '../pages/dashboard/dashboardParts/NavigationPane';
import { ReactComponent as DashboardSwitcherIcon } from 'src/assets/DashboardSwitcher.svg';
import { getNavBarTheme } from 'src/components/common/utils/utils';

const useStyle = createUseStyles({
  basePane: {
    width: '100%',
    height: '56px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: (navBarTheme) =>
      navBarTheme['navbar-background'] ?? '#FFFFFF',
    boxShadow: 'inset 0px -1px 0px #F0F0F0',
    paddingRight: '16px',
  },
  dashboardTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.9rem',
    color: (navBarTheme) => navBarTheme['text-color'] ?? undefined,
  },
  dashboardTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '50px',
    gap: '0.5rem',
    width: '80%',
  },
});

const MobileNavBar = (props) => {
  const dashboardGroupMetaData = useSelector(
    (state: any) => state.dashboardGroupMetaData,
  );
  const {
    dashboardGroupName,
    metadata: dashboardMetaData,
    currentDashboardGroupTab,
  } = dashboardGroupMetaData;
  const [isDashboardSwitchDrawerOpen, setIsDashboardSwitchDrawerOpen] =
    useState(false);
  const [dashboardSearchText, setDashboardSearchText] = useState('');

  const handleDashboardSwitcherClick = () => {
    setIsDashboardSwitchDrawerOpen(!isDashboardSwitchDrawerOpen);
  };

  const onHideDashboardPopOver = () => {
    setIsDashboardSwitchDrawerOpen(false);
    setDashboardSearchText('');
  };
  const navBarTheme = getNavBarTheme();
  const classes = useStyle(navBarTheme);

  return (
    <>
      <Affix>
        <div className={classes.basePane}>
          <div
            className={classes.dashboardTitleContainer}
            onClick={handleDashboardSwitcherClick}
          >
            <div className={classes.dashboardTitle}>{dashboardGroupName}</div>
            {dashboardGroupName && (
              <DashboardSwitcherIcon
                fill={navBarTheme['text-color'] ?? '111111'}
                style={{
                  height: '1rem',
                  width: 'auto',
                  color: navBarTheme['text-color'] ?? undefined,
                }}
              />
            )}
          </div>
        </div>
      </Affix>
      <Drawer
        title="Switch to"
        visible={isDashboardSwitchDrawerOpen}
        onClose={handleDashboardSwitcherClick}
        placement="bottom"
        bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}
      >
        <NavigationPane
          searchText={dashboardSearchText}
          setSearchText={setDashboardSearchText}
          hidePopover={onHideDashboardPopOver}
        />
      </Drawer>
    </>
  );
};

export default MobileNavBar;
