import React, { memo } from 'react';
import { Radio, Space } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
    basePane: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '4px',
        marginBottom: '20px',
    },
    prettyName: {
        lineHeight: '14px',
        letterSpacing: '0px',
        color: '#303030',
        height: '14px',
        marginBottom: '0.5rem',
        fontSize: '12px',
    },
    spaceGroup: {
        display: 'flex',
        gap: '3px !important',
        flexWrap: 'wrap',
    },
});

const CustomRadioGroup = (props) => {
    const classes = useStyle();
    const {onChange, prettyName, value, data, direction='horizontal'} = props;
    const options = data?.options || [];
    const onChangeRadio = (event) => onChange(event.target.value);
    return <>
    <div className={classes.basePane}>
        <span className={classes.prettyName}>{prettyName}</span>
        <Radio.Group onChange={onChangeRadio} value={value}>
            <Space direction={direction} className={classes.spaceGroup}>
                {options.map(option => <Radio value={option.value}>{option.prettyName}</Radio>)}
            </Space>
        </Radio.Group>
    </div>
    </>;
};

export default memo(CustomRadioGroup);
