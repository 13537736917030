import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { message, Select } from 'antd';
import { useSelector } from 'react-redux';
import { GET_REPORT_BY_ID } from '../../dashboard/hooks/dashboardQueries';
import lodashUnion from 'lodash/union';
import { createUseStyles } from 'react-jss';
import lodashMap from 'lodash/map';

const useStyle = createUseStyles({
  columnListContainer: {
    margin: '24px 24px 0 24px',
  },
  listHeader: {
    display: 'flex',
    width: '60%',
  },
  headerColumn: {
    width: '50%',
    color: '#595959',
    lineHeight: '22px',
  },
  listRow: {
    display: 'flex',
    width: '60%',
  },
  rowColumn: {
    width: '50%',
    color: 'rgba(0, 0, 0, 0.85)',
    lineHeight: '24px',
    fontWeight: 600,
    margin: '10px 0',
  },
});

const StepTwo = (props) => {
  const { linkedReport, setLinkedReport, selectedReportId, parentReportType } = props;
  const classes = useStyle();
  const { filterData, summarization, metrics, reportConfig, customMetrices } = useSelector((state: any) => state);
  const allmetrics = { ...summarization, ...metrics, ...filterData };
  const filterDataWithoutDefaultFilters = {};
  for (const key in filterData) {
      if (filterData.hasOwnProperty(key) && !filterData[key].isDefaultFilter) {
        filterDataWithoutDefaultFilters[key] = filterData[key];
      }
  }
  const parentFilterKeysForChildReport = lodashUnion(Object.keys(filterDataWithoutDefaultFilters), Object.keys(summarization));
  const [childReportMetrices, setChildReportMetrices] = useState([]);
  const {
    global: parentCustomMetricObjects,
    local: parentCustomFieldObjects,
  } = customMetrices;

  const appendModalData = (data) => {
    const { reportType: childReportType, customMetrices, customAggregateMetrices, filters } = data.getReportById;
    const filterChildReportWithoutDefaultFilters = filters.filter(filter => !filter.filter.isDefaultFilter);
    const extraMetricesListFromChildReport = [...customMetrices, ...customAggregateMetrices];
    let metricesFromChildReport = reportConfig.reports[childReportType].metrics
      ?.length
      ? reportConfig.reports[childReportType].metrics
      : filterChildReportWithoutDefaultFilters;
    if (!reportConfig.reports[childReportType].metrics?.length) {
      metricesFromChildReport = metricesFromChildReport.map((metric) => {
        const { filter, ...restMetric } = metric;
        return { ...restMetric, ...filter, value: restMetric.metric };
      });
    }
    // remove custom metrics which are in parent report
    const parentCustomMetricsAndFields = lodashMap(
      { ...parentCustomMetricObjects, ...parentCustomFieldObjects },
      'name',
    );
    const filteredMetricesFromChildReport = metricesFromChildReport.filter(
      metricData => !parentCustomMetricsAndFields.includes(metricData.metric),
    );
    // add all custom metrics from child report
    extraMetricesListFromChildReport.forEach((metricData) => {
      const { metric, prettyName, sql, type } = metricData;
      if (
        filteredMetricesFromChildReport.findIndex(
          childReportMetric => childReportMetric.value === metric,
        ) < 0
      ) {
        filteredMetricesFromChildReport.push({
          value: metric,
          prettyName,
          sql,
          type,
        });
      }
    });
    setChildReportMetrices(filteredMetricesFromChildReport);
    if (
      !Object.keys(linkedReport?.linkedMetrices || {})?.length &&
      parentReportType === childReportType
    ) {
      const updatedLinkedReport = JSON.parse(JSON.stringify(linkedReport));
      parentFilterKeysForChildReport.forEach(filterKey => {
        const childReportMetric: any =
          filteredMetricesFromChildReport.find(
            (childMetric: any) => childMetric.value === filterKey,
          ) || {};
        if (childReportMetric) {
          updatedLinkedReport.linkedMetrices = {
            ...updatedLinkedReport.linkedMetrices,
            [filterKey]: {
              metric: childReportMetric?.value,
              type: childReportMetric?.type,
              prettyName: childReportMetric?.prettyName,
            },
          };
        }
      });
      setLinkedReport(updatedLinkedReport);
    }
  };

  const [getReportById] = useLazyQuery(GET_REPORT_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: appendModalData,
    onError: (data) => {
      message.error({
        key: 'ReportStatus',
        duration: 0.5,
        content: 'Failed fetching Report Meta Data',
      });
    },
  });

  const onSelectMetric = (childMetricKey, parentMetricKey) => {
    const updatedLinkedReport = JSON.parse(JSON.stringify(linkedReport));
    const childReportMetric: any = childReportMetrices.find((childMetric: any) => childMetric.value === childMetricKey) || {};
    if (childReportMetric) {
      updatedLinkedReport.linkedMetrices = {
        ...updatedLinkedReport.linkedMetrices,
        [parentMetricKey]: {
          metric: childReportMetric?.value,
          type: childReportMetric?.type,
          prettyName: childReportMetric?.prettyName,
        },
      };
      setLinkedReport(updatedLinkedReport);
    }
  };

  useEffect(() => {
    if (selectedReportId) {
      getReportById({ variables: { reportId: selectedReportId }});
    }
  }, [selectedReportId]);

  return (
    <>
      <div className={classes.columnListContainer}>
        <div className={classes.listHeader}>
          <div className={classes.headerColumn}>Filters from Parent Report</div>
          <div className={classes.headerColumn}>
            Select Child Report Mappings
          </div>
        </div>
        <div style={{ overflow: 'auto' }}>
          {parentFilterKeysForChildReport.map((filterKey) => {
            const dropDownValue =
              linkedReport?.linkedMetrices?.[filterKey]?.metric || undefined;
            return (
              <>
                <div className={classes.listRow}>
                  <div className={classes.rowColumn}>
                    {allmetrics?.[filterKey]?.prettyName}
                  </div>
                  <div className={classes.rowColumn}>
                    <Select
                      showSearch
                      filterOption={(a, b: any) => {
                        if (
                          b !== undefined &&
                          b?.label !== undefined &&
                          typeof b?.label === 'string'
                        ) {
                          const dataInLowerCase = (b?.label + '').toLowerCase();
                          const pos = dataInLowerCase.search(a.toLowerCase());
                          return pos !== -1;
                        }
                        return true;
                      }}
                      style={{ width: '100%' }}
                      placeholder="Select"
                      value={dropDownValue}
                      onSelect={(childMetricKey) =>
                        onSelectMetric(childMetricKey, filterKey)
                      }
                      options={childReportMetrices
                        .filter(
                          (metricData: any) =>
                            metricData.type === allmetrics?.[filterKey]?.type,
                        )
                        .map(({ value, prettyName }) => {
                          return { value, label: prettyName };
                        })}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default StepTwo;
