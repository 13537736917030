import React, { lazy } from 'react';
import { Input } from 'antd';
import { createUseStyles } from 'react-jss';
const AceEditor = lazy(() => import('react-ace'));
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-pgsql';

const useStyle = createUseStyles({
    basePane: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '4px',
        marginBottom: '20px',
    },
    prettyName: {
        lineHeight: '14px',
        letterSpacing: '0px',
        color: '#303030',
        height: '14px',
        marginBottom: '0.5rem',
        fontSize: '12px',
    },
});
const { TextArea } = Input;
const CustomInput = (props) => {
    const classes = useStyle();
    const {
        onChange,
        prettyName,
        value,
        placeholder = '',
        isTextArea = false,
        isCodeEditor = false,
        disabled = false,
        textAreaRows = 4,
        highlightActiveLine = true,
        width = '375px',
        height = '300px',
        mode,
        theme,
    } = props;
    const onChangeInput = (event) => {
        onChange(event.target.value);
    };

    return <>
    <div className={classes.basePane}>
        {prettyName && <span className={classes.prettyName}>{prettyName}</span>}
            { isCodeEditor ?
                <AceEditor
                    style={{zIndex: 0}}
                    width={width}
                    height={height}
                    placeholder={placeholder}
                    mode={mode || 'pgsql'}
                    theme={theme || 'chrome'}
                    name="blah2"
                    onLoad={() => {}}
                    onChange={onChange}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={highlightActiveLine}
                    value={value}
                    setOptions={{
                        showLineNumbers: true,
                        tabSize: 2,
                    }}/> :
            isTextArea ?
                <TextArea
                    rows={textAreaRows}
                    placeholder={placeholder}
                    onChange={onChangeInput}
                    value={value}
                    disabled={disabled}
                /> :
                <Input
                    placeholder={placeholder}
                    onChange={onChangeInput}
                    value={value}
                    disabled={disabled}>
                </Input>
            }
    </div>
    </>;
};

export default CustomInput;
