import { Button } from 'antd';
import React, { useState, memo } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTER_RADIO_SELECTED,
  FILTER_SELECTED_QUERY_CHANGED,
  FILTER_SELECTED_TYPE_CHANGED,
  FILTER_SELECTED_VALUE_CHANGED,
} from 'src/reduxActions/actionNameEnums';
import CustomQueryModal from './CustomQueryModal';
import CustomQueryColumns from './dataSetupContainerPart/CustomQueryColumns';
import CustomQueryFilters from './dataSetupContainerPart/CustomQueryFilters';
import { PlusOutlined } from '@ant-design/icons';
import CustomQueryMongoCollectionSection from './dataSetupContainerPart/CustomQueryMongoCollectionSection';
import { dbTypes } from 'src/constants';
import CustomQuery from './dataSetupContainerPart/CustomQuery';

const useStyles = createUseStyles({
  createReportSection: {
    padding: '0px',
    margin: '16px 0px 0px 0px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
  },
  title: {
    height: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '16px',
  },
  moreOutlined: {
    zIndex: 0,
    cursor: 'pointer',
    right: '0',
  },
  sectionContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '12px',
    position: 'relative',
  },
  containerHeading: {
    fontSize: '10px',
    fontWeight: '400',
    color: '#8C8C8C',
  },
  dummyDataSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
  },
});

const CustomQueryDataSetupSection = props => {
  const [customQueryVisible, setCustomQueryVisible] = useState(false);
  const selectedDbType = useSelector((state:any) => state.reportMetaData.selectedDbType);
  const classes = useStyles();
  const dispatch = useDispatch();

  const onChangeRadio = metricsName => value =>
    dispatch({
      type: FILTER_RADIO_SELECTED,
      payload: {
        metricsName,
        selectedFilter: value,
      },
    });
  const onChangeFilterData = metricsName => (
    data,
    additionalFiterData = null,
  ) => {
    const payload = { metricsName, data: { value: data } };
    if (additionalFiterData) {
      payload.data['additionalFiterData'] = additionalFiterData;
    }
    return dispatch({
      type: FILTER_SELECTED_VALUE_CHANGED,
      payload,
    });
  };

  const onChangeFilterType = (metricsName, filtersMetaData) => (type) => {
    const payload = { metricsName, type, filtersMetaData };
    return dispatch({
      type: FILTER_SELECTED_TYPE_CHANGED,
      payload,
    });
  };

  const onChangeFilterQuery = metricsName => sql => {
    const payload = { metricsName, sql };
    return dispatch({
      type: FILTER_SELECTED_QUERY_CHANGED,
      payload,
    });
  };

  const showCustomQuery = () => {
      setCustomQueryVisible(true);
  };
  return (
    <>
      <div style={{ width: '100%' }}>
        <div className={classes.sectionContainer}>
          <div className={classes.containerHeading}>
            Pick and choose the data elements here
          </div>
        </div>
        {selectedDbType === dbTypes.MONGO && (
          <CustomQueryMongoCollectionSection />
        )}
        <CustomQuery />
        {/* <Button
          style={{ marginBottom: '12px' }}
          type="primary"
          onClick={showCustomQuery}
          icon={<PlusOutlined />}
        >
          Custom Query
        </Button>
        <CustomQueryModal
          visible={customQueryVisible}
          setVisible={setCustomQueryVisible}
        /> */}
        <CustomQueryFilters
          onChangeRadio={onChangeRadio}
          onChangeFilterData={onChangeFilterData}
          onChangeFilterQuery={onChangeFilterQuery}
          onChangeFilterType={onChangeFilterType}
        />
        <CustomQueryColumns />
      </div>
    </>
  );
};

export default memo(CustomQueryDataSetupSection);
