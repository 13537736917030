import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import FilterMenu from 'src/components/common/FilterComponent/SingleSelectionMenu';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import PopOverPane from './popOverPane';
import { useAggregateSwitch } from './useMetricsHook';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

const rowData = {
  title: 'Container',
  isTitleEditable: true,
  onTitleChange: () => {},
};

const useStyles = createUseStyles({
  switchStyle: {
    marginRight: '10px',
  },
  switchContainer: {
    // marginTop: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  aggregate: {
    width: '50px',
  },
});

const MetricColumns = (props) => {
  const classes = useStyles();
  const {
    aggregate,
    setCustomColumnType,
    maximumSelection,
    description,
    summariseBy,
    timeline,
  } = props;
  const { metricsMetaData, selectedAggregate } = aggregate;
  const filteredColumns = metricsMetaData.selectedMetrics.filter(
    (metric) => metric.section == null,
  );
  const initialValues = filteredColumns.map((data) => data.value);
  const titleChangeHandlerGenerator = (name) => (value) =>
    metricsMetaData.onChangeAlias(name, value);
  const aggregateHandlerGenerator = (name) => (data) =>
    metricsMetaData.onChangeAggregate(name, data?.[0].key);
  const selectedReport = useSelector(
    (state: any) => state.reportMetaData.selectedReport,
  );
  const metrics =
    useSelector(
      (state: any) => state.reportConfig.reports?.[selectedReport]?.metrics,
    ) || [];

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...metricsMetaData.selectedMetrics];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    metricsMetaData.reorderMetrices(newItems);
  };

  return (
    <SectionPartContainer
      setCustomColumnType={setCustomColumnType}
      showToAddCustomColumnforLocal={
        timeline.selectedAggregate.length > 0 ||
        summariseBy.selectedAggregate.length > 0
      }
      title={'Columns'}
      description={description}
      onChangeSelect={(values) =>
        metricsMetaData.onSelect(values, { showErrorOnDuplicate: true })
      }
      options={metricsMetaData.selectableMetrics}
      initialValue={initialValues}
      onTitleChanges={() => {}}
      showAddButton={
        maximumSelection ? filteredColumns.length < maximumSelection : true
      }
      optionDisabled={
        maximumSelection
          ? filteredColumns.length < maximumSelection
            ? false
            : true
          : false
      }
      showCount
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {filteredColumns.map((row, index) => {
                const shouldShowRightPane =
                  row?.customColumnType === 'local' ? false : true;
                const currentMetric = metrics.find(
                  (metric) => metric.value === row.value,
                );
                const operations = currentMetric
                  ? currentMetric.operations
                  : [];
                const optionsValue = operations.length
                  ? operations.find(
                      (element) => element.value === row.operation,
                    )
                  : [];
                const defaultSelection = { value: null, prettyName: 'Select' };
                return (
                  <Draggable
                    key={row.value}
                    draggableId={row.value}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <RowContainer
                          onRemove={() =>
                            metricsMetaData.onRemoveMetrics(row.value)
                          }
                          title={row.alias || row.prettyName}
                          prettyName={row.prettyName}
                          description={row.description}
                          isTitleEditable={true}
                          showLeftPane={shouldShowRightPane}
                          onTitleChange={titleChangeHandlerGenerator(row.value)}
                          LeftPaneComponent={PopOverPane}
                          leftPaneProps={{
                            Content: (
                              <FilterMenu
                                options={operations}
                                value={row.operation}
                                onChange={aggregateHandlerGenerator(row.value)}
                              />
                            ),
                            value: optionsValue || defaultSelection,
                          }}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </SectionPartContainer>
  );
};

export default memo(MetricColumns);
