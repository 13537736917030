import lodashMapKeys from 'lodash/mapKeys';

import {
  INITIALIZE_UNDERLYING_METRICS,
  UNDERLYING_METRICS_CHANGED,
  UNDERLYING_METRICS_RESET,
  CHANGE_UNDERLYING_METRIC_ALIAS,
} from '../reduxActions/actionNameEnums';

const initialState = {};

const underlyingMetricReducer = (state: any = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INITIALIZE_UNDERLYING_METRICS:
    case UNDERLYING_METRICS_CHANGED:
      const newObject = Object.assign({}, payload);
      const metricObject = lodashMapKeys(newObject, (data, keys) => data.value);
      return metricObject;
    case UNDERLYING_METRICS_RESET:
      return initialState;
    case CHANGE_UNDERLYING_METRIC_ALIAS:
      const aliasAddObject = {
        [payload.name]: {
          ...state[payload.name],
          alias: payload.value,
        },
      };
      return Object.assign({}, state, aliasAddObject);
    default:
      return state;
  }
};

export default underlyingMetricReducer;
