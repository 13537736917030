import { Tooltip } from 'antd';
import { ReactComponent as AscendingIcon } from 'src/assets/AscendingIcon.svg';
import { ReactComponent as DescendingIcon } from 'src/assets/DescendingIcon.svg';
import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import FilterMenu from 'src/components/common/FilterComponent';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SortBySelector from 'src/components/common/sortBySelector';

const useStyles = createUseStyles({
    switchStyle:{
        marginRight: '10px',
    },
    switchContainer: {
        // marginTop: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    aggregate: {
        width: '50px',
    },
    thenText: {
        fontSize: '10px',
        color: '#595959',
    },
});

const SortBySection = (props) => {
    const classes = useStyles();
    const { sortBy, hideRemove, onSelectSortMetric, showAddButton } = props;
    const { selectedSortBy, sortableMetrics, onSelectSortMetrics, onChangeSortBy, onRemoveSortBy, reorderSortMetrics } = sortBy;

    const onSelectSortByMetric = (val) => {
        if(onSelectSortMetric) {
            onSelectSortMetric(onSelectSortMetrics, val);
            return;
        }
        onSelectSortMetrics(val);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
        reorderSortMetrics(result);
      };

      return (
        <SectionPartContainer
          title={'Sort By'}
          showAddButton={showAddButton}
          onChangeSelect={(a, b) => onSelectSortByMetric(a)}
          options={sortableMetrics}
          initialValue={selectedSortBy.map((element) => element.value)}
          onTitleChanges={console.log}
          showCount
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedSortBy.map((row, j) => {
                    const hideRemoveSortBy = hideRemove
                      ? hideRemove(row.value)
                      : false;
                    return (
                      <>
                        <Draggable
                          key={row.value}
                          draggableId={row.value}
                          index={j}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              snapshot={snapshot}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <RowContainer
                                onRemove={() => onRemoveSortBy(row.value)}
                                title={row.prettyName}
                                description={row.description}
                                isTitleEditable={false}
                                showLeftPane={true}
                                onTitleChange={console.log}
                                hideRemove={hideRemoveSortBy}
                                LeftPaneComponent={SortBySelector}
                                leftPaneProps={{
                                  onChange: onChangeSortBy(row.value),
                                  value: row.sortBy.type,
                                  ContentComponent: FilterMenu[row.type],
                                  type: row.type,
                                  options: row.operations,
                                  metricsName: row.value,
                                }}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                        {selectedSortBy.length - 1 !== j && (
                          <span className={classes.thenText}>Then</span>
                        )}
                      </>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className={classes.switchContainer}></div>
        </SectionPartContainer>
      );
};

export default memo(SortBySection);
