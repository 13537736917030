import React from 'react';
import { InputNumber } from 'antd';

const CustomNumberInput = (props) => {
  const { onChange, prettyName, value, data, basePane, stylePrettyName, styleInputNumber } = props;
  return (
    <>
      <div className={basePane}>
        <div className={stylePrettyName}>{prettyName}</div>
        <InputNumber
          className={styleInputNumber}
          size="small"
          min={data.min}
          max={data.max}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default CustomNumberInput;
