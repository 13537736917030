import React from 'react';
import { Switch } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
    basePane: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '4px',
        marginBottom: '20px',
        alignItems: 'center',
    },
    prettyName: {
        display: 'flex',
        lineHeight: '14px',
        letterSpacing: '0px',
        color: '#303030',
        height: '14px',
        fontSize: '12px',
        marginLeft: '16px',
    },
});

const CustomSwitch = (props) => {
    const classes = useStyle();
    const { onChange, prettyName, value = false, disabled = false } = props;
    const onChangeSwitch = (value) => onChange(value);

    return (
        <>
            <div className={classes.basePane}>
                <Switch size="small" checked={value} onChange={onChangeSwitch} disabled={disabled} />
                <div className={classes.prettyName}>{prettyName}</div>
            </div>
        </>
    );
};

export default CustomSwitch;
