import React, { memo } from 'react';
import { Radio } from 'antd';
import { createUseStyles } from 'react-jss';
import Inputbox from 'src/components/pages/chartBuilder/createQueryComponents/chartConfig/InputBox';

const useStyles = createUseStyles({
  styling: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '2%',
    padding: '2% 0% 2% 0%',
  },
  radio: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const ChartConfigRadio = (props) => {
  const classes = useStyles();
  const { onChange, value, data, name, child, onChangeCustom } = props;
  return (
    <>
      <div className={classes.styling}>
        <div className={classes.radio}>
          <Radio.Group onChange={(e) => onChange(e.target.value)} value={value}>
            {data.map((option, index) => (
              <Radio key={index} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>
      {value === 'customize' && child ? (
        <Inputbox
          onChange={onChangeCustom}
          styling={classes.styling}
          parent={name}
          rowProperties={child}
          defaultValue={child.defaultValue}
        />
      ) : null}
    </>
  );
};

export default memo(ChartConfigRadio);
