import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import FilterMenu from 'src/components/common/FilterComponent/SingleSelectionMenu';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import {
  GENERIC_BUCKET_VALUE_CHANGED,
  GENERIC_SUMMARISATION_LIST_CHANGED,
} from 'src/reduxActions/actionNameEnums';
import PopOverPane from './popOverPane';

const useStyles = createUseStyles({
  switchStyle: {
    marginRight: '10px',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  aggregate: {
    width: '50px',
  },
  rightPane: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
  },
});

const GenericSummariseBy = (props) => {
  const classes = useStyles();
  const {
    aggregate,
    type,
    onChange,
    onRemove,
    title,
    options,
    description,
    isMandatory,
    maximumSelection,
  } = props;
  const dispatch = useDispatch();
  const filteredAggregate = aggregate.selectedAggregate.filter(
    (aggregate) => aggregate.section === type,
  );
  const initialValues = filteredAggregate.map((data) => data.value);
  const filterData = useSelector((state: any) => state.genericSummarisation);
  const bucketValueHandlerGenerator = (metricName) => (value) => {
    dispatch({
      type: GENERIC_BUCKET_VALUE_CHANGED,
      payload: { metricName, bucketValue: value[0].key, type },
    });
  };
  return (
    <SectionPartContainer
      title={title}
      description={description}
      onChangeSelect={(a, b) => {
        onChange(a, b);
      }}
      options={options}
      initialValue={initialValues}
      isMandatory={isMandatory}
      showAddButton={
        maximumSelection ? filteredAggregate.length < maximumSelection : true
      }
      showCount
    >
      {filteredAggregate.map((row) => {
        const aggregateBuckets = aggregate.aggregateOptions.filter(
          (option) => option.value === row.value,
        );
        const buckets =
          row.groupBy.buckets || aggregateBuckets?.[0]?.groupBy?.buckets || [];
        const bucketValue = filterData[type][row.value]?.groupBy.bucketValue;
        const bucketOption = buckets.find(
          (bucket) => bucket.value === bucketValue,
        );
        const defaultSelection = { value: '', prettyName: 'Select' };
        return (
          <RowContainer
            onRemove={() => onRemove(row.value)}
            title={row.prettyName}
            description={row.description}
            showLeftPane={buckets.length !== 0}
            LeftPaneComponent={PopOverPane}
            leftPaneProps={{
              Content: (
                <FilterMenu
                  options={buckets}
                  value={bucketOption}
                  onChange={bucketValueHandlerGenerator(row.value)}
                />
              ),
              value: bucketOption || defaultSelection,
            }}
          />
        );
      })}
      <div className={classes.switchContainer}></div>
    </SectionPartContainer>
  );
};

export default memo(GenericSummariseBy);
