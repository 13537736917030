export const viewType = {
  tile: 'tile',
  list: 'list',
};

export const countOfErrors = (filters, summariseBy, errorsInReports, filterName, dbType) => {
  let errorCount = 0;
  const summariseByLength = errorsInReports ? (summariseBy ? summariseBy.length : 0) : Object.keys(summariseBy).length;
  if(summariseByLength !== 0 || dbType !== 'postgresql') return errorCount;
  filters.forEach(filter => {
    const updatedFilter = errorsInReports ? filter.filter : filter;
    if (updatedFilter.name === 'timestampzIsNull' || updatedFilter.type !== 'timestampz') return 0;
    const data = errorsInReports ? JSON.parse(updatedFilter.data) : updatedFilter.data;
    switch(updatedFilter[filterName]) {
      case 'timestampzBetween': {
        const dateFrom = new Date(data.value.from);
        const dateTo = new Date(data.value.to);
        const diffTime = Math.abs(dateTo.getTime() - dateFrom.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 30) {
          errorCount += 1;
        }
        break;
      }
      case 'timestampzCurrent': {
        if (data.value === 'quarter' || data.value === 'year') {
          errorCount += 1;
        }
        break;
      }
      case 'timestampzPrevious':
      case 'timestampzPast': {
        const dataValueUnit = data.value.unit;
        const dataValueQuantity = data.value.quantity;
        if (
          dataValueUnit === 'quarters' ||
          dataValueUnit === 'years' ||
          (dataValueUnit === 'months' && Number(dataValueQuantity) > 1) ||
          (dataValueUnit === 'weeks' && Number(dataValueQuantity) > 4) ||
          (dataValueUnit === 'days' && Number(dataValueQuantity) > 31)
        ) {
          errorCount += 1;
        }
        break;
      }
      case 'timestampzIsBefore': {
        errorCount += 1;
        break;
      }
      case 'timestampzIsAfter': {
        const currDateTime = new Date();
        const choosenDate = new Date(data.value);
        const diffTime = Math.abs(choosenDate.getTime() - currDateTime.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 31) {
          errorCount += 1;
        }
        break;
      }
      default:
    }
    return;
  });
  return errorCount;
};

export const TszError = (type) => {
  if (type === 'single') {
    return 'For Data Dumps, only a range of 31 days is supported.';
  }
  return 'For Data Dumps, only a range of 31 days is supported. At least one timestamp should have a range <= 31 days.';
};
