import React from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { SET_HTML_TEXT } from 'src/reduxActions/actionNameEnums';
import CustomInput from 'src/components/common/customInput';
import debounce from 'lodash/debounce';

const useStyles = createUseStyles({
  sectionPartContainer: {
    width: '100%',
    marginBottom: '1rem',
    border: '1px solid #F1F2F6',
    borderRadius: '4px 4px 0px 0px',
  },
  title: {
    height: '14px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '14px',
    letterSpacing: '0',
    color: '#303030',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '2.375rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    backgroundColor: '#F1F2F6',
  },
});

const HtmlReportType = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const htmlValue: string = useSelector(
    (state: any) => state.chartsMetaData.htmlText,
  );

  const onChangeHtmlData = (value) => {
    dispatch({ type: SET_HTML_TEXT, payload: value });
  };

  return (
    <>
      <div className={classes.sectionPartContainer}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>HTML Editor</span>
        </div>
        <CustomInput
          onChange={debounce(onChangeHtmlData, 500)}
          width={'100%'}
          placeholder={'Enter your HTML here'}
          mode={'html'}
          isCodeEditor={true}
          value={htmlValue}
          theme={'monokai'}
        />
      </div>
    </>
  );
};

export default HtmlReportType;
