export const customSort = (type: string, order: string) => {
  const sortOrder = order === 'desc' ? -1 : 1;

  if (['number', 'epoch'].includes(type)) {
    return (a, b) => {
      return sortOrder * (Number(a) - Number(b));
    };
  }
  if (['timestamp', 'timestampz', 'date'].includes(type)) {
    return (a, b) => {
      const dateA = new Date(a).getTime();
      const dateB = new Date(b).getTime();
      return sortOrder * (dateA - dateB);
    };
  }
  if (type === 'boolean') {
    return (a, b) => {
      return sortOrder * (a === b ? 0 : a ? 1 : -1);
    };
  }
  return (a, b) => {
    return sortOrder * a.localeCompare(b);
  };

};
