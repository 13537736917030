import { gql } from '@apollo/client';

// export const GET_ALL_SCHEDULER = gql`
// query($reportId: String){
//   getAllSchedules(reportId: $reportId){
//     numSchedules
//     schedules {
//       title
//       creationTime
//       title
//       alertFilters {
//         metric
//         prettyName
//         operation
//         freeze
//         filter {
//           name
//           prettyName
//           data
//           type
//         }
//       }
//       status
//     }
//   }
// }
// `;

export const GET_SCHEDULER_METADATA = gql`
  query GET_SCHEDULER_METADATA($entityId: String!, $entityType: String!) {
    getAllSchedules(entityId: $entityId, entityType: $entityType) {
      numSchedules
      schedules {
        _id
        entityType
        entityId
        title
        isProcessing
        status
        isActive
        timeZone
        mailType
        toEmails {
          name
          email
          empId
        }
        ccEmails {
          name
          email
          empId
        }
        emailMessage
        emailSubject
        frequency
        frequencyData {
          dateMonth
          date
          dayOfWeek
        }
        fromToRange {
          timeRange
        }
        startTimestamp
        endTimestamp
        repeat
        messageTemplate
        updationTime
        creationTime
        intervalType
        selectedEntityList {
          entityType
          entityId
        }
        summary
        recipientsRoles
        selectedEntityListForSubjectAndSummary {
          entityType
          entityId
        }
      }
    }
  }
`;
