import { message } from 'antd';
import lodashKeys from 'lodash/keys';
import lodashDifference from 'lodash/difference';

export const getNamesOfFilter = (filter) => {
    const keys: Array<string> = [];
    switch (filter.type) {
      case 'hubAndRider':
        keys.push(filter.hubName);
        keys.push(filter.riderName);
        break;
      default:
        keys.push(filter.name);
        break;
    }
    return keys;
  };

  export const getValue = (value, filterType) => {
    switch (filterType) {
      case 'createdAtFilter':
      case 'consignmentCreatedAtFilter':
        return value.isRecurring
          ? `Period of ${value.recurValue}`
          : `From: ${value.from}      To: ${value.to}`;
      case 'isComplete':
      case 'isCOD':
      case 'statusFilter':
        return value;
      case 'consignmentType':
      case 'eventType':
      case 'currentHubFilter':
      case 'currentRiderFilter':
      case 'eventHubFilter':
      case 'eventRiderFilter':
      case 'zoneFilter':
        return value
          .map((s) => {
            if (typeof s !== 'string') return '';
            return s.charAt(0).toUpperCase() + s.slice(1);
          })
          .join(', ');
      default:
        const warningMessage = `Filter Type ${filterType} not Found`;
        message.warning(warningMessage);
        return warningMessage;
    }
  };

const validator = (supportedFilters, selectedReport, filterData, selectedColumns) => {
    const requiredFilters = supportedFilters.filter((filter) => filter.required);
    const requiredFiltersNames = requiredFilters.map(filter => filter.name);
    const namesOfSelectedFilters = lodashKeys(filterData);
    const differenceInSelectedAndRequiredFilters = lodashDifference(requiredFiltersNames, namesOfSelectedFilters);
    const mandatoryFiltersNotSelected = !(differenceInSelectedAndRequiredFilters.length === 0);
    const prettyNameOfFiltersWhichDifferes = differenceInSelectedAndRequiredFilters
                                              //So we have FilterName and we need PrettyName
                                              //So For Every required Filter Name
                                                    .map(filterName => requiredFilters
                                              // We are finding its object in the requiredFilters array and then plucking the prettyName property
                                                                            .find(filter => filter.name === filterName).prettyName);

    if (mandatoryFiltersNotSelected) {
      const warning = `Required Filters Not Selected ${prettyNameOfFiltersWhichDifferes.toString()}`;
      message.warning(warning);
      return false;
    }

    if (selectedColumns.length === 0) {
      message.warning('Please select at Least one Column to Proceed');
      return false;
    }

    return true;
  };

  export const urlDownload = (url) => {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = url;
    hiddenElement.click();
  };

const exportedObject = {
  validator,
};

export default exportedObject;
