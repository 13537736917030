import lodashCloneDeep from 'lodash/cloneDeep';
import lodashUnset from 'lodash/unset';
import lodashOmit from 'lodash/omit';
import lodashForOwn from 'lodash/forOwn';

import {
  RESET_CHILD_REPORT_LIST,
  ADD_CHILD_REPORT,
  REMOVE_CHILD_REPORT,
  CHILD_GLOBAL_FILTER_RADIO_SELECTED,
  CHILD_GLOBAL_FILTER_SELECTED_VALUE_CHANGED,
  CHILD_GLOBAL_FILTER_RESET,
  CHILD_GLOBAL_FILTER_APPLY,
  CHILD_GLOBAL_SELECTED_BOOL_VALUE_CHANGED,
} from '../reduxActions/actionNameEnums';

const initialState = {
  childReportDataList: [],
  currentIndex: -1,
};

const childReportModalDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const copyCurrentState = lodashCloneDeep(state);
  switch (type) {
    case RESET_CHILD_REPORT_LIST:
      return initialState;
    case ADD_CHILD_REPORT:
      return {
        ...copyCurrentState,
        childReportDataList: [...copyCurrentState.childReportDataList, payload],
        currentIndex: copyCurrentState.currentIndex + 1,
      };
    case REMOVE_CHILD_REPORT:
      if (copyCurrentState.childReportDataList.length > 1) {
        const newChildReportDataList =
          copyCurrentState.childReportDataList.slice(0, -1);
        return {
          ...copyCurrentState,
          childReportDataList: newChildReportDataList,
          currentIndex: newChildReportDataList.length - 1,
        };
      }
      return initialState;
    case CHILD_GLOBAL_FILTER_RADIO_SELECTED:
      const childReportGlobalFilters = lodashCloneDeep(
        copyCurrentState.childReportDataList?.[payload.childReportIndex]
          ?.childReportGlobalFilters,
      );
      childReportGlobalFilters[payload.metricName] = {
        ...childReportGlobalFilters[payload.metricName],
        selectedFilter: payload.selectedFilter,
        filterInputType: payload.filterInputType,
      };
      if (
        childReportGlobalFilters[payload.metricName]?.filterInputType !==
          payload.filterInputType ||
        !['text', 'number', 'timestampz', 'timestampz'].includes(
          payload.filterType,
        )
      ) {
        lodashUnset(childReportGlobalFilters, `${payload.metricName}.data`);
      }
      copyCurrentState.childReportDataList[
        payload.childReportIndex
      ].childReportGlobalFilters = childReportGlobalFilters;
      if (childReportGlobalFilters[payload.metricName].data?.value) {
        payload.setFilterApplied(false);
      }
      return copyCurrentState;
    case CHILD_GLOBAL_FILTER_SELECTED_VALUE_CHANGED:
      const globalFiltersWithData = lodashCloneDeep(
        copyCurrentState.childReportDataList?.[payload.childReportIndex]
          ?.childReportGlobalFilters,
      );
      globalFiltersWithData[payload.metricName].data = payload.data;
      copyCurrentState.childReportDataList[
        payload.childReportIndex
      ].childReportGlobalFilters = globalFiltersWithData;
      return copyCurrentState;
    case CHILD_GLOBAL_FILTER_RESET:
      const globalFiltersWithoutData = lodashCloneDeep(
        copyCurrentState.childReportDataList?.[payload.childReportIndex]
          ?.childReportGlobalFilters,
      );
      const resetState = {};
      lodashForOwn(globalFiltersWithoutData, (value, key) => {
        const newValue = lodashOmit(value, ['data', 'selectedFilter']);
        resetState[key] = newValue;
      });
      copyCurrentState.childReportDataList[payload.childReportIndex] = {
        ...copyCurrentState.childReportDataList[payload.childReportIndex],
        childReportGlobalFilters: resetState,
        globalFiltersApplied: false,
      };
      return copyCurrentState;
    case CHILD_GLOBAL_FILTER_APPLY:
      copyCurrentState.childReportDataList[payload.childReportIndex] = {
        ...copyCurrentState.childReportDataList[payload.childReportIndex],
        globalFiltersApplied: true,
      };
      return copyCurrentState;
    case CHILD_GLOBAL_SELECTED_BOOL_VALUE_CHANGED:
      const { childReportIndex, metricName, selectedFilter, data } = payload;
      copyCurrentState.childReportDataList[
        childReportIndex
      ].childReportGlobalFilters[metricName].data =
        data.value !== undefined ? data : undefined;

      copyCurrentState.childReportDataList[
        childReportIndex
      ].childReportGlobalFilters[metricName].selectedFilter = selectedFilter;
      return copyCurrentState;
    default:
      return state;
  }
};

export default childReportModalDataReducer;
