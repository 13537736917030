import lodashUnset from 'lodash/unset';
import lodashForOwn from 'lodash/forOwn';
import lodashMapKeys from 'lodash/mapKeys';
import lodashOmit from 'lodash/omit';

import { NAME_CHANGED } from '../reduxActions/actionNameEnums';

const initialState: any = {
    dashboardAdded:  [],
    nameChange: '',
};

const editDashboard = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {
        case NAME_CHANGED:
            return {
                ...state,
                nameChange:payload.name,
            };
        default:
            return state;
    }
};

export default editDashboard;
