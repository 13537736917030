import { Input } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
    select: {
        width: '50%',
    },

});

const InputFilter = (props) => {
    const classes = useStyle();
    const { data, value, type, isVisible , onChange, size = 'small', style = { height: '100%' } } = props;
    const onchangeWithValidator = (event) => {
        const value = event.target.value;
        const isNumberInput = type === 'numberInput';
        if(isNumberInput){
            const parsedValue = parseInt(event.target.value, 10);
            if(parsedValue){
                onChange(parsedValue);
            }
        }
        else {
            onChange(value);
        }
    };
    return (
        <Input
          style={style}
          size={size}
          value={value?.value}
          onChange={onchangeWithValidator}
        />
    );
};

export default InputFilter;
