import React, {memo} from 'react';
import { Colorpicker } from 'antd-colorpicker';

const CustomColorPicker = (props) => {
  const {
    style,
    onChangeColor,
    selectedOption,
  } = props;

  return (
    <Colorpicker
      style={style}
      popup
      value={selectedOption.color}
      onChange={onChangeColor}
    />
  );
};
export default memo(CustomColorPicker);
