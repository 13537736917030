import React, { memo, Suspense, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import values from 'lodash/values';
import Layout from './components/common/Layout';
import { useLocation } from 'react-router-dom';
import { messageType } from './components/common/utils/utils';
import LoadingComponent from './components/common/LoadingComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  CHANGE_LEFT_PANE_STATE,
  CHANGE_LEFT_PANE_VISIBILITY,
  SET_MASTER_DATA,
  SET_REPORT_MASTER_DATA,
  SET_ANALYTICS_DASHBOARD_CRM,
} from './reduxActions/actionNameEnums';
import lodashIsEmpty from 'lodash/isEmpty';
import useLazyAPI from './components/common/hooks/useLazyAPI';
import { GET_METADATA, GET_REPORT_CONFIG, GET_USER_ROLES } from './api/routes';
import ProtectedRoute from './components/common/ProtectedRoute';
import { message } from 'antd';

const Routes = (props) => {
  const { routeMetaData } = props;
  const routeLists = values(routeMetaData);
  const masterDataInState = useSelector((state: any) => state.masterData);

  const [getMetaData, { loading: metaDataLoading }] = useLazyAPI(GET_METADATA, {
    type: 'GET',
    onCompleted: (data) => {
      dispatch({ type: SET_MASTER_DATA, payload: data });
    },
  });

  const [getReportMetaData] = useLazyAPI(GET_REPORT_CONFIG, {
    type: 'GET',
    onCompleted: (data) => {
      dispatch({ type: SET_REPORT_MASTER_DATA, payload: data });
    },
    onError: (data) => {
      message.error({
        key: 'ReportStatus',
        duration: 0.5,
        content: 'Failed fetching report config',
      });
    },
  });

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    getMetaData();
    getReportMetaData();
    const url = new URL(window.location.href);
    localStorage.setItem('pageSource', url.searchParams.get('pageSource')??'');
    dispatch({ type: SET_ANALYTICS_DASHBOARD_CRM, payload: {
      externalFilters: JSON.parse(url.searchParams.get('filters')?? '[]'),
      analyticsTopBar: Boolean(url.searchParams.get('analyticsTopBar')),
    } });
  }, []);

  React.useEffect(() => {
    window.parent.postMessage({loaderZIndex:100}, '*');
    if (location.pathname !== '/') {
      const message = JSON.stringify({
        type: messageType.ROUTING,
        applicationId: 'GENERIC_ANALYTICS',
        pathname: location.pathname,
      });
      window.parent.postMessage(message, '*');
    }
    if (
      location.pathname === '/dashboard' ||
      location.pathname === '/reports' ||
      location.pathname === '/metrics' ||
      location.pathname === '/alerts' ||
      location.pathname === '/scheduled-emails'
    ) {
      dispatch({
        type: CHANGE_LEFT_PANE_STATE,
        payload: true,
      });
      dispatch({
        type: CHANGE_LEFT_PANE_VISIBILITY,
        payload: true,
      });
    } else {
      dispatch({
        type: CHANGE_LEFT_PANE_STATE,
        payload: false,
      });
      dispatch({
        type: CHANGE_LEFT_PANE_VISIBILITY,
        payload: false,
      });
    }
  }, [location.pathname]);

  if (metaDataLoading || lodashIsEmpty(masterDataInState)) {
    return <LoadingComponent style={{ fontSize: '40px' }} />;
  }

  return (
    <>
        <Suspense
          fallback={
            <LoadingComponent style={{ fontSize: '40px', marginTop: '50ox' }} />
          }
        >
          <Switch>
            {routeLists.map((routeMetaData) => {
              const Component = routeMetaData.component;
              return (
                <ProtectedRoute
                  key={routeMetaData.route}
                  exact={routeMetaData.exact ?? true}
                  path={routeMetaData.route}
                  render={(props) => <Component />}
                  excludedPermissions={routeMetaData.excludedPermissions}
                  allowMobile={routeMetaData.allowMobile}
                />
              );
            })}
          </Switch>
        </Suspense>
    </>
  );
};
export default memo(Routes);
