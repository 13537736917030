import config from './config';

const defaultValues = {
    API_BASE_URL: 'https://api.shipsy.in:4000/api',
    DOWNLOAD_PAGE_POLL_INTERVAL: 3000,
    TABLE_ROWS_COUNT: 10,
    REPORT_TABLE_ROWS_COUNT: 15,
    DOWNLOAD_STATUS_ROW_COUNT: 9,
    MAX_TAGS_ALLOWED: 100,
    DASHBOARD_TABLE_ROWS_COUNT: 15,
    GOOGLE_TAG_MANAGER_ID: '',
    DASHBOARD_GRID_COLUMNS: 256,
};

export default Object.assign({}, defaultValues, config);
