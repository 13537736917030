import lodashMapKeys from 'lodash/mapKeys';
import lodashOmit from 'lodash/omit';
import lodashForOwn from 'lodash/forOwn';
import lodashUnset from 'lodash/unset';
import lodashCloneDeep from 'lodash/cloneDeep';

import {
    DASHBOARD_FILTER_SELECTED_VALUE_CHANGED,
    DASHBOARD_FILTER_DATA_RESET,
    DASHBOARD_FILTER_RADIO_SELECTED,
    INITIALIZE_DASHBOARD_FILTERS,
    GENERIC_DASHBOARD_FILTER_DATA_RESET,
    DASHBOARD_FILTER_SELECTED_BOOL_VALUE_CHANGED,
} from '../reduxActions/actionNameEnums';

const initialState = {};

const filterReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const dashboardFiltersClone = lodashCloneDeep(state);
    switch(type){
        case INITIALIZE_DASHBOARD_FILTERS:
            const newMap = {};
            payload.forEach((item, index) => {
                newMap[index] = lodashMapKeys(item, (data, keys) => data.metric);
            });
            return newMap;
        case DASHBOARD_FILTER_DATA_RESET:
            const baseState = {};
            lodashForOwn(dashboardFiltersClone[payload.dashboardTab], (value, key) => {
                if(value.filterMetaData?.required){
                    const newValue = lodashOmit(value, ['data', 'selectedFilter', 'filterInputType']);
                    baseState[key] = newValue;
                }
            });
            return baseState;
        case DASHBOARD_FILTER_RADIO_SELECTED:
            const radioChange = {
                ...dashboardFiltersClone[payload.dashboardTab],
                [payload.metricName]: {
                    ...dashboardFiltersClone[payload.dashboardTab][payload.metricName],
                    selectedFilter: payload.selectedFilter,
                    filterInputType: payload.filterInputType,
                    },
            };
            const metricName = payload.metricName;
            const newState = Object.assign({}, dashboardFiltersClone, { [payload.dashboardTab]: radioChange });
            if (
                dashboardFiltersClone[payload.dashboardTab][metricName]?.filterInputType !== payload.filterInputType ||
                (payload.filterType !== 'text' && payload.filterType !== 'number' && payload.filterType!=='timestampz')
              ) {
                lodashUnset(
                  newState,
                  `${payload.dashboardTab}.${payload.metricName}.data`,
                );
              }
            return newState;
        case DASHBOARD_FILTER_SELECTED_VALUE_CHANGED:
            const changeData = {
                ...dashboardFiltersClone[payload.dashboardTab],
                [payload.metricName]: {
                    ...dashboardFiltersClone[payload.dashboardTab][payload.metricName],
                    data: payload.data,
                },
            };
            return Object.assign({}, dashboardFiltersClone, { [payload.dashboardTab]: changeData });
        case DASHBOARD_FILTER_SELECTED_BOOL_VALUE_CHANGED:
            const changeSelectedData = {
                    ...dashboardFiltersClone[payload.dashboardTab],
                    [payload.metricName]: {
                        ...dashboardFiltersClone[payload.dashboardTab][payload.metricName],
                        selectedFilter: payload.selectedFilter,
                        data: payload.data.value !== undefined ? payload.data : undefined,
                    },
                };
                return Object.assign({}, dashboardFiltersClone, { [payload.dashboardTab]: changeSelectedData });
        case GENERIC_DASHBOARD_FILTER_DATA_RESET:
                const resetState = {};
                lodashForOwn(dashboardFiltersClone[payload.dashboardTab], (value, key) => {
                        const newValue = lodashOmit(value, ['data', 'selectedFilter', 'filterInputType']);
                        resetState[key] = newValue;
                });
                return Object.assign({}, dashboardFiltersClone, { [payload.dashboardTab]: resetState });
        default:
            return dashboardFiltersClone;
    }
};

export default filterReducer;
