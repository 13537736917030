import {
  SET_REPORT_MASTER_DATA,
  SET_REPORT_MASTER_DATA_LOADING,
} from '../reduxActions/actionNameEnums';

const initialState = {
  isLoading: true,
  reports: {},
};

const reportConfigReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_REPORT_MASTER_DATA:
      return { ...state, ...payload, isLoading: false };
    case SET_REPORT_MASTER_DATA_LOADING:
      return { ...state, isLoading: payload };
    default:
      return state;
  }
};

export default reportConfigReducer;
