import { gql } from '@apollo/client';

export const DELETEREPORT = gql`
query deleteReport (
  $id: String!
){
  deleteReport(
    id: $id
  ) {
    success
    error
  }
}
`;

export const SAVE_ALERT = gql`
query SAVE_ALERT(
  $title: String!,
  $reportId: String!,
  $alertFilters: [whereHavingInput]!,
  $frequencyIntervalMillisec: Float!,
  $emailRecipients: [String]!,
  $executionTime: String!,
  $emailMessage: String!,
  $frequency: String!,
  $timeZone: String!,
  $emailSubject: String!){
  saveAlert(
    title: $title,
    reportId: $reportId,
    alertFilters: $alertFilters,
    frequencyIntervalMillisec: $frequencyIntervalMillisec,
    emailRecipients: $emailRecipients,
    emailMessage: $emailMessage,
    frequency: $frequency,
    timeZone: $timeZone,
    executionTime: $executionTime,
    emailSubject: $emailSubject){
      success
      error
  }
}
`;

export const SAVE_SCHEDULER = gql`
query SAVE_SCHEDULER(
  $_id: String,
  $entityId: String!,
  $entityType: String!,
  $title: String!,
  $isProcessing: Boolean,
  $status: String!,
  $isActive: Boolean!,
  $timeZone: String!,
  $mailType: String!,
  $toEmails: [inputUser],
  $ccEmails: [inputUser],
  $bccEmails: [inputUser],
  $emailMessage: String,
  $emailSubject: String!,
  $frequency: String!,
  $frequencyData: frequencyDataInput,
  $startTimestamp: String!,
  $endTimestamp: String
  $repeat: Int!,
  $messageTemplate: String!,
  $attachmentType: String,
  $intervalType: String!,
  $fromToRange: fromToRangeInput,
  $selectedEntityList: [selectedEntityListInput],
  $summary: String,
  $recipientsRoles: [String],
  $selectedEntityListForSubjectAndSummary: [selectedEntityListInput],
  ){
saveSchedule(
  _id: $_id,
  entityId: $entityId,
  entityType: $entityType,
  title: $title,
  isProcessing: $isProcessing,
  status: $status,
  isActive: $isActive,
  timeZone: $timeZone,
  mailType: $mailType,
  toEmails: $toEmails,
  ccEmails: $ccEmails,
  bccEmails: $bccEmails,
  emailMessage: $emailMessage,
  emailSubject: $emailSubject,
  frequency: $frequency,
  frequencyData: $frequencyData,
  startTimestamp: $startTimestamp,
  endTimestamp: $endTimestamp,
  repeat: $repeat,
  messageTemplate: $messageTemplate,
  attachmentType: $attachmentType,
  fromToRange: $fromToRange,
  intervalType: $intervalType,
  selectedEntityList: $selectedEntityList,
  summary: $summary,
  recipientsRoles: $recipientsRoles,
  selectedEntityListForSubjectAndSummary: $selectedEntityListForSubjectAndSummary,
  ){
      success
      error
  }
}
`;

export const SAVE_SHARED_REPORT_DETAILS = gql`
  query SAVE_SHARED_REPORT_DETAILS(
    $id: String!,
    $employeeDetails: [userInput],
    $isSharableToAll: Boolean,
    $sharedToCustomerPortal: Boolean
    $sharedToVendorPortal: Boolean
  ) {
    shareReportToEmployee(
      id: $id,
      employeeDetails: $employeeDetails,
      isSharableToAll: $isSharableToAll,
      sharedToCustomerPortal: $sharedToCustomerPortal,
      sharedToVendorPortal: $sharedToVendorPortal,
    ) {
      success
      error
    }
  }
`;

export const SEARCH_EMPLOYEE_BY_EMAIL = gql`
  query SEARCH_EMPLOYEE_BY_EMAIL(
    $searchQuery: String,
  ) {
    searchEmplyoeeEmails(
      searchQuery: $searchQuery,
    ) {
      name
      email
      empId
      empCode
    }
  }
`;

export const GET_SHARED_REPORT_DETAILS = gql`
  query GET_SHARED_REPORT_DETAILS(
    $id: String!,
  ) {
    getSharedEmpDetails(
      id: $id,
    ) {
      reportId
      sharedToEmployeeDetails {
        name
        empId
        email
      }
      ownerDetail {
        name
        empId
        email
      }
      isSharableToAll
      sharedToCustomerPortal
      sharedToVendorPortal
      isSharableToCustomer
      isSharableToVendor
    }
  }
`;

export const SET_FAVOURITE = gql`
  query SET_FAVOURITE($reportId: String!, $value: Boolean!) {
    setFavouriteReport(reportId: $reportId, value: $value) {
      success
      error
    }
  }
`;

export const GET_DASHBOARD_GROUP_REPORTS_FOR_EMAIL = gql`
  query GET_DASHBOARD_GROUP_REPORTS_FOR_EMAIL($dashboardGroupId: String!) {
    getDashboardGroupReportsForEmail(dashboardGroupId: $dashboardGroupId) {
      reports {
        id
        name
        chartType
        templateKey
      }
      tags
      handlers {
        name
        description
        example
      }
    }
  }
`;

export const SEND_TEST_EMAIL = gql`
  query SEND_TEST_EMAIL(
    $entityId: String!
    $entityType: String!
    $title: String!
    $mailType: String!
    $toEmails: [inputUser]!
    $bccEmails: [inputUser]
    $ccEmails: [inputUser]
    $emailMessage: String
    $emailSubject: String!
    $messageTemplate: String!
    $attachmentType: String
    $selectedEntityList: [selectedEntityListInput]
    $selectedEntityListForSubjectAndSummary: [selectedEntityListInput]
    $summary: String
    $recipientsRoles: [String]
    $endTimestamp: String
  ) {
    testScheduleEmail(
      entityId: $entityId
      entityType: $entityType
      title: $title
      mailType: $mailType
      toEmails: $toEmails
      bccEmails: $bccEmails
      ccEmails: $ccEmails
      emailMessage: $emailMessage
      emailSubject: $emailSubject
      messageTemplate: $messageTemplate
      attachmentType: $attachmentType
      selectedEntityList: $selectedEntityList
      summary: $summary
      recipientsRoles: $recipientsRoles
      selectedEntityListForSubjectAndSummary: $selectedEntityListForSubjectAndSummary
      endTimestamp: $endTimestamp
    ) {
      success
      error
    }
  }
`;
