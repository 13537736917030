import { DownOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const PopOverPane = (props) => {
    const { value, classes, Content,title} = props;
    const [isVisible, setIsVisible] = useState(false);
    const { prettyName, prettyValue = ''} = value;

    useEffect(() => {
      setIsVisible(false);
    },[value]);

    return <Popover
    content={Content}
    placement="bottom"
    trigger={'click'}
    visible={isVisible}
    onVisibleChange={setIsVisible}
    getPopupContainer={(trigger: any) => trigger.parentNode}
    className={classNames(classes.rightPane, 'rowLeftPanePopOver')}>
                <div className={classes.selectFilter}>
                  <a style={{ fontSize: '0.8rem' }}>
                    {prettyName}
                  </a>{' ' + prettyValue}<DownOutlined className={classes.dropDownArrow}/>
                </div>
           </Popover>;
};

export default PopOverPane;
