import { Menu, Select } from 'antd';
import React, { useState,useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

const useStyle = createUseStyles({
    select: {
        width: '200px',
    },
    dropDown: {
        width: '200px !important',
    },
});

const SelectFilters = (props) => {
    const { data, type, onChange, value, defaultValue, size = 'small',onReset, style = { width: '100%', height: '100%' } } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyle();
    const selectMode = type === 'multiSelect' ? 'tags' : undefined;
    const options = data?.options || [];
    useEffect(() => {
        if (defaultValue && !value) {
            onChange(defaultValue);
        }
    }, [onReset]);
    return <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                style={style}
                size={size}
                showArrow={true}
                placeholder="Select a value"
                maxTagCount="responsive"
                mode={selectMode}
                open={open}
                onBlur={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(false);
                }}
                onClick={(event) => {
                    event.preventDefault();
                    setOpen(!open);
                }}
                value={value?.value}
                dropdownClassName={classes.dropDown}
                className={classes.select}
                onChange={(value) => onChange(value)} >
                    {options.map(option => <Option value={option.value}>{option.prettyName}</Option >)}
           </Select>;
};

export default SelectFilters;
