import React from 'react';
import { ReactComponent as ThemeOne } from 'src/assets/theme/theme1.svg';
import { ReactComponent as ThemeTwo } from 'src/assets/theme/theme2.svg';
import { ReactComponent as ThemeThree } from 'src/assets/theme/theme3.svg';
import { ReactComponent as ThemeFour } from 'src/assets/theme/theme4.svg';

const svgOptions = [
  { value: 'theme_1', label: <ThemeOne /> },
  { value: 'theme_2', label: <ThemeTwo /> },
  { value: 'theme_3', label: <ThemeThree /> },
  { value: 'theme_4', label: <ThemeFour /> },
];

export const configObjects: any = {
  radius: {
    prettyName: 'Radius',
    name: 'radius',
    type: 'numberInput',
    minValue: 1,
    maxValue: 100,
  },
  category: {
    prettyName: 'Category',
    name: 'category',
    type: 'ColorpickerSelect',
    data: [{ value: 'Default', label: 'Default' }],
  },
  tableCategory: {
    prettyName: 'Category',
    name: 'tableCategory',
    type: 'ColorpickerSelect',
  },
  switchDimensions: {
    prettyName: 'Switch Dimensions',
    name: 'switchDimensions',
    tooltip:
      'Switch rows and columns, and disable/enable values. Aggregate should be disabled to enable this config.',
    type: 'switch',
    disabled: (chartOptions) => {
      return chartOptions['global']?.['showAggregate']?.['data'];
    },
  },
  color: {
    prettyName: 'Color',
    name: 'color',
    type: 'Colorpicker',
  },
  columnSizeBar: {
    prettyName: 'Thickness',
    name: 'columnSize',
    type: 'customRadio',
    data: [
      { value: 'default', label: 'Default' },
      { value: 'customize', label: 'Custom' },
    ],
    child: 'thicknessValue',
  },
  thicknessValue: {
    prettyName: '',
    name: 'thicknessValue',
    type: 'numberInput',
    defaultValue: 10,
    minValue: 1,
  },
  columnSizeLine: {
    prettyName: 'Thickness',
    name: 'columnSize',
    type: 'numberInput',
    minValue: 1,
  },
  align: {
    prettyName: 'Align',
    name: 'align',
    type: 'singleSelect',
    data: [
      { value: 'top', label: 'Top' },
      { value: 'middle', label: 'Middle' },
      { value: 'bottom', label: 'Bottom' },
    ],
  },
  labelType: {
    prettyName: 'Type',
    name: 'labelType',
    type: 'singleSelect',
    data: [
      { value: 'internal', label: 'Internal' },
      { value: 'external', label: 'External' },
      { value: 'cobweb', label: 'Cobweb' },
    ],
  },
  showPoint: {
    prettyName: 'Show Point',
    name: 'showPoint',
    type: 'numberInput',
  },
  smooth: {
    prettyName: 'Smooth',
    name: 'smooth',
    type: 'switch',
  },
  locationPie: {
    prettyName: 'Location',
    name: 'location',
    type: 'singleSelect',
    data: [
      { value: 'left-top', label: 'Left Top' },
      { value: 'left', label: 'Left Center' },
      { value: 'left-bottom', label: 'Left Bottom' },
      { value: 'right-top', label: 'Right Top' },
      { value: 'right', label: 'Right Center' },
      { value: 'right-bottom', label: 'Right Bottom' },
      { value: 'top-left', label: 'Top Left' },
      { value: 'top', label: 'Top Center' },
      { value: 'top-right', label: 'Top Right' },
      { value: 'bottom-left', label: 'Bottom Left' },
      { value: 'bottom', label: 'Bottom Center' },
      { value: 'bottom-right', label: 'Bottom Right' },
    ],
  },
  progressBarPosition: {
    prettyName: 'Location',
    name: 'location',
    type: 'singleSelect',
    data: [
      { value: 'top-left', label: 'Top Left' },
      { value: 'top', label: 'Top Center' },
      { value: 'top-right', label: 'Top Right' },
    ],
  },
  barPosition: {
    prettyName: 'Location',
    name: 'position',
    type: 'singleSelect',
    data: [
      { value: 'left', label: 'Left' },
      { value: 'middle', label: 'Middle' },
      { value: 'right', label: 'Right' },
    ],
  },
  columnPosition: {
    prettyName: 'Location',
    name: 'position',
    type: 'singleSelect',
    data: [
      { value: 'top', label: 'Top' },
      { value: 'middle', label: 'Middle' },
      { value: 'bottom', label: 'Bottom' },
    ],
  },
  type: {
    prettyName: 'Type',
    name: 'type',
    type: 'singleSelect',
    data: [
      { value: 'inner', label: 'Internal' },
      { value: 'outer', label: 'Outside' },
      { value: 'spider', label: 'Spider' },
    ],
  },
  offset: {
    prettyName: 'Overall offset',
    name: 'offset',
    type: 'numberInput',
  },
  offsetX: {
    prettyName: 'X offset',
    name: 'offsetX',
    type: 'numberInput',
  },
  offsetY: {
    prettyName: 'Y offset',
    name: 'offsetY',
    type: 'numberInput',
  },
  xTitle: {
    prettyName: 'Title',
    name: 'xTitle',
    type: 'switchCollapse',
  },
  yTitle: {
    prettyName: 'Title',
    name: 'yTitle',
    type: 'switchCollapse',
  },
  axisRulers: {
    prettyName: 'Axis rulers',
    name: 'axisRulers',
    type: 'switch',
  },
  xAxisLabel: {
    prettyName: 'Axis label',
    name: 'xAxisLabel',
    type: 'switchCollapse',
  },
  yAxisLabel: {
    prettyName: 'Axis label',
    name: 'yAxisLabel',
    type: 'switchCollapse',
  },
  tickLine: {
    prettyName: 'Tick line',
    name: 'tickLine',
    type: 'switch',
  },
  grid: {
    prettyName: 'Grid',
    name: 'grid',
    type: 'switch',
  },
  autoRotateLabel: {
    prettyName: 'Auto rotate label',
    name: 'autoRotateLabel',
    type: 'switch',
  },
  autoHideLabel: {
    prettyName: 'Auto hide label',
    name: 'autoHideLabel',
    type: 'switch',
    defaultValue: false,
  },
  text: {
    prettyName: 'Text',
    name: 'text',
    type: 'input',
  },
  pieLegend: {
    prettyName: 'Legend',
    name: 'pieLegend',
    type: 'legend',
  },
  size: {
    prettyName: 'Size',
    name: 'size',
    type: 'numberInput',
    minValue: 0,
  },
  shape: {
    prettyName: 'Shape',
    name: 'shape',
    type: 'singleSelect',
    data: [
      { value: 'circle', label: 'Circle' },
      { value: 'point', label: 'Point' },
      { value: 'square', label: 'Square' },
      { value: 'diamond', label: 'Diamond' },
      { value: 'hexagon', label: 'Hexagon' },
      { value: 'triangle', label: 'Triangle' },
      { value: 'cross', label: 'Cross' },
    ],
  },
  toolTip: {
    prettyName: 'Tooltip',
    name: 'tooltip',
    type: 'switch',
  },
  flipPage: {
    prettyName: 'FlipPage',
    name: 'flipPage',
    type: 'switch',
  },
  textAlign: {
    prettyName: 'Text Align',
    name: 'textAlign',
    type: 'singleSelect',
    data: [
      { value: 'left', label: 'Left' },
      { value: 'center', label: 'Center' },
      { value: 'right', label: 'Right' },
    ],
  },
  numberAlign: {
    prettyName: 'Number Align',
    name: 'numberAlign',
    type: 'singleSelect',
    data: [
      { value: 'left', label: 'Left' },
      { value: 'center', label: 'Center' },
      { value: 'right', label: 'Right' },
    ],
  },
  commaSeparator: {
    prettyName: 'Comma Separator',
    name: 'commaSeparator',
    type: 'singleSelect',
    data: [
      { value: 'international', label: 'International' },
      { value: 'indian', label: 'Indian' },
      { value: 'none', label: 'None' },
    ],
  },
  dateRangeVisible: {
    prettyName: 'Date Range',
    tooltip: 'Date Range Visiblity in Dashboard.',
    name: 'dateRangeVisible',
    type: 'switch',
  },
  label: {
    prettyName: 'Label',
    name: 'label',
    type: 'switch',
  },
  labelSize: {
    prettyName: 'Size',
    name: 'labelSize',
    type: 'numberInput',
  },
  percentageThreshold: {
    prettyName: 'Threshold for others',
    name: 'percentageThreshold',
    type: 'numberInput',
    minValue: 0,
    maxValue: 100,
    tooltip: 'Below the threshold, the data will be aggregated into "Others".',
  },
  themeCategory: {
    prettyName: 'Theme',
    name: 'themeCategory',
    type: 'singleSelect',
    data: svgOptions,
  },
  verticalRepresentation: {
    prettyName: 'Vertical Representation',
    tooltip: 'representing numbers in vertical format like tables',
    name: 'verticalRepresentation',
    type: 'switch',
  },
  chartPaddingCollapse: {
    prettyName: 'Chart padding',
    name: 'chartPadding',
    type: 'switchCollapse',
  },
  chartBorderRadiusCollapse: {
    prettyName: 'Round Border',
    name: 'chartBorderRadius',
    type: 'switchCollapse',
  },
  chartPaddingLeft: {
    prettyName: 'Left',
    name: 'chartPaddingLeft',
    type: 'numberInput',
  },
  chartPaddingRight: {
    prettyName: 'Right',
    name: 'chartPaddingRight',
    type: 'numberInput',
  },
  chartPaddingTop: {
    prettyName: 'Top',
    name: 'chartPaddingTop',
    type: 'numberInput',
  },
  chartPaddingBottom: {
    prettyName: 'Bottom',
    name: 'chartPaddingBottom',
    type: 'numberInput',
  },
  chartBorderRadiusTopLeft: {
    prettyName: 'Top-Left',
    name: 'chartBorderRadiusTopLeft',
    type: 'numberInput',
  },
  chartBorderRadiusTopRight: {
    prettyName: 'Top-Right',
    name: 'chartBorderRadiusTopRight',
    type: 'numberInput',
  },
  chartBorderRadiusBottomLeft: {
    prettyName: 'Bottom-Left',
    name: 'chartBorderRadiusBottomLeft',
    type: 'numberInput',
  },
  chartBorderRadiusBottomRight: {
    prettyName: 'Bottom-Right',
    name: 'chartBorderRadiusBottomRight',
    type: 'numberInput',
  },
  isReportNameVisibleOnDashboard: {
    prettyName: 'Dashboard Report Name',
    name: 'isReportNameVisibleOnDashboard',
    type: 'switch',
    tooltip: 'If disabled, the report name will not be displayed in the dashboard.',
  },
  timePicker: {
    prettyName: 'Time Picker',
    name: 'timePicker',
    type: 'switch',
  },
  scrollable: {
    prettyName: 'Scrollable',
    name: 'scrollable',
    type: 'switch',
  },
};
export const tableData = [
  { value: 'columnHeaderBackground', label: 'Column header background' },
  { value: 'columnHeaderFont', label: 'Column header font' },
  {
    value: 'rowsBackground',
    label: 'Rows background',
  },
  {
    value: 'alternateRowsColor',
    label: 'Alternate rows color',
  },
  { value: 'rowsFontColor', label: 'Rows font color' },
  {
    value: 'columnsHeaderBorderColor',
    label: 'Columns header border color',
  },
  {
    value: 'cellBorderColor',
    label: 'Cell border color',
  },
];
