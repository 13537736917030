import { CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';
import CustomDivider from 'src/components/common/CustomDivider';
const useStyle = createUseStyles({
    basePane: {
        width: '100%',
        height: '56px',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        padding: '16px',
        backgroundColor: 'white',
    },
    leftPartStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
    },
    crossIcon: {
        fontSize: '16px',
    },
    title:{
        marginLeft: '16px',
        height: '22px',
        width: '131px',
        color: '#424242',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0',
        lineHeight: '22px',
    },
    rightPartStyle: {
        marginLeft: 'auto',
        display: 'flex',
        gap: '1rem',
    },
});
const DrawerTitleBar = (props) => {
    const classes = useStyle();
    const {onClose, title, buttonText, onClickButton, secondaryButtonText, onClickSecondaryButton, secondaryButtonLoading,
        disableSecondaryButton, secondaryButtonHoverTitle } = props;
    return <><div className={classes.basePane}>
        <div className={classes.leftPartStyle}>
            <CloseOutlined className={classes.crossIcon} onClick={onClose}/>
            <div className={classes.title}>{title}</div>
        </div>
        <div className={classes.rightPartStyle}>
            {secondaryButtonText &&
                <Tooltip placement="bottom" title={secondaryButtonHoverTitle}>
                    <Button
                    type={'default'}
                    onClick={onClickSecondaryButton}
                    loading={secondaryButtonLoading}
                    disabled={disableSecondaryButton}
                >
                    {secondaryButtonText}
                </Button>
                </Tooltip>
            }
            <Button
                type={'primary'}
                onClick={onClickButton}>
                {buttonText}
            </Button>
        </div>
    </div>
    <CustomDivider/>
    </>;
};
export default DrawerTitleBar;
