import lodashMapKeys from 'lodash/mapKeys';
import lodashOmit from 'lodash/omit';

import { SORT_SELECTED_LIST_CHANGED, SORT_DATA_REMOVED, SORT_DATA_RESET, SORT_VALUE_CHANGED, INITIALIZE_SORT } from '../reduxActions/actionNameEnums';

const initialState = {};

const sortByReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type){
        case INITIALIZE_SORT:
            const initialStateSortBy = lodashMapKeys(payload, (data, keys) => data.value);
            return initialStateSortBy;
        case SORT_SELECTED_LIST_CHANGED:
            const payloadWithIndex = payload.map((element, index) => ({
                ...element,
                sortBy: { index, type: element.sortBy ? element.sortBy.type : 'ASC' },
              }));
            const metricObject = lodashMapKeys(payloadWithIndex, (data, keys) => data.value);
            return metricObject;
        case SORT_DATA_REMOVED:
            return lodashOmit(state, payload);
        case SORT_DATA_RESET:
            return initialState;
        case SORT_VALUE_CHANGED:
            const newData = {
                [payload.metricName]: {
                    ...state[payload.metricName],
                    sortBy:{
                        ...state[payload.metricName].sortBy,
                        type: payload.value,
                    },
                },
            };
            return Object.assign({}, state, newData);
        default:
            return state;
    }
};

export default sortByReducer;
