import { Select, Tooltip } from 'antd';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import CustomSelect from 'src/components/common/CustomSelect';
import { metricObjectTypeOptions } from 'src/components/pagesv2/incidentAlerts/utils';
import { ACTION_INITIALISE, SET_OBJECT } from 'src/reduxActions/actionNameEnums';

const { Option } = Select;

const ObjectSection = props => {
  const dispatch = useDispatch();
  const { filters, title, object, isMandatory } = props;
  const { filtersOptions } = filters;
  const filteredObjects = filtersOptions.filter(
    obj => obj.objectType === object?.name,
  );
  const items = metricObjectTypeOptions.map(option => ({
    prettyName: option,
    value: option,
  }));
  const handleChange = value => {
    const metric = filteredObjects.filter(filter => filter.value === value);
    dispatch({
      type: SET_OBJECT,
      payload: { field: { metric: value, prettyName: metric[0].prettyName } },
    });
  };

  const handleNameChange = value => {
    const payload: any = { name: value };
    dispatch({
      type: SET_OBJECT,
      payload,
    });
    dispatch({ type: ACTION_INITIALISE });
  };

  const selectedField = filteredObjects.filter(
    filter => filter.value === object?.field?.metric,
  );
  return (
    <SectionPartContainer
      title={title || 'Object'}
      showAddButton={false}
      showSelect={false}
      isMandatory={isMandatory}
    >
      <RowContainer title="Object Name" hideRemove>
        <div style={{ marginBottom: '1.5rem' }}>
          <CustomSelect
            size="middle"
            onChange={handleNameChange}
            prettyName={'Object'}
            noLabel={true}
            value={object?.name}
            data={{ options: items }}
            style={{ width: 300 }}
          />
        </div>
      </RowContainer>
      {object?.name !== 'Overall' && (
        <RowContainer title="Field" hideRemove>
          <div style={{ marginBottom: '1.5rem' }}>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              onChange={handleChange}
              style={{ width: 300 }}
              placeholder="Select field"
              value={selectedField?.[0]?.value}
              showSearch
              filterOption={(input, option: any) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {filteredObjects.map(option => (
                <Option
                  title={option.description}
                  label={option.prettyName}
                  value={option.value}
                >
                  <Tooltip
                    placement="right"
                    title={
                      option.description
                        ? option.description
                        : option.prettyName
                    }
                  >
                    {option.prettyName}
                  </Tooltip>
                </Option>
              ))}
            </Select>
          </div>
        </RowContainer>
      )}
    </SectionPartContainer>
  );
};

export default memo(ObjectSection);
