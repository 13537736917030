import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  option: {
    '&:hover': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: '#E7F7FE',
    },
  },
  noRulesMessage: {
    backgroundColor: '#e5f7ff',
    padding: '1rem',
    border: '0.5px solid #006EC3',
    borderRadius: '0.3rem',
    marginBottom: '1rem',
    textAlign: 'center',
    width: '100%',
    margin: '2rem',
    height: '3.5rem',
  },
  modalContainer: {
    '& .ant-modal-body': {
      padding: 0,
    },
  },
  sideMenu: {
    maxHeight: '100%',
    overflowY: 'scroll',
    height: '100%',
  },
  actionDivider: {
    margin: '0.5rem 0',
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  fieldName: {
    color: '#707070',
    marginBottom: '0em',
  },
  addButton: {
    position: 'absolute',
    bottom: '-7%',
    left: '45%',
  },
  deleteButton: {
    position: 'absolute',
    right: '-8px',
    top: '45%',
  },
  conditionContainer: {
    display: 'flex',
    gap: '0.5rem',
    backgroundColor: '#F1F5FA',
    border: '1px solid #CDE3FF',
    borderRadius: '0.3rem',
    padding: '0.5rem',
    flexDirection: 'column',
    height: '100%',
  },
  conditionTitle: {
    flex: 0.4,
    color: '#303030',
    fontWeight: 600,
    fontSize: '1rem',
  },
  conditionRow: {
    flex: 1,
    gap: '0.7rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  ruleContainer: {
    border: '1px solid #DADADA',
    borderRadius: '0.3rem',
    padding: '0.5rem',
    backgroundColor: '',
    margin: '0.5rem',
    position: 'relative',
    marginBottom: '1.5rem',
    display: 'flex',
  },
  ruleIndexWrapper: {
    width: '100%',
    marginTop: '0.5rem',
    overflow: 'scroll',
    height: '98%',
  },
  ruleIndexContainer: {
    width: '100%',
    margin: '0 0.5rem',
    maxHeight: '100%',
  },
  menuContainer: {
    maxHeight: '100%',
    height: '100%',
    width: '25%',
  },
  modalWrapper: {
    display: 'flex',
    maxHeight: '100%',
    height: '100%',
  },
  addMetricBtn: {
    border: 'none',
    color: '#1890ff',
  },
  customFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  actionTypeContainer: {
    display: 'flex',
    gap: '1.5rem',
    minWidth: '5rem',
    alignItems: 'center',
    fontWeight: 500,
  },
  actionWrapper: {
    display: 'flex',
    gap: '1rem',
    padding: '0.5rem',
    width: '100%',
  },
  actionContainer: {
    display: 'flex',
    gap: '0.5rem',
    width: '100%',
  },
  actionForm: {
    backgroundColor: '#F1F5FA',
    border: '1px solid #CDE3FF',
    borderRadius: '0.3rem',
    padding: '0.5rem',
    width: '60%',
    marginLeft: '0.5rem',
  },
  actionTitle: {
    color: '#303030',
    fontWeight: 600,
    fontSize: '1rem',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
  },
});
