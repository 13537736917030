import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, Checkbox, message } from 'antd';
import { createUseStyles } from 'react-jss';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { EXPORT_DASHBOARD } from '../../../../api/routes';
import LoadingComponent from 'src/components/common/LoadingComponent';
import { urlDownload } from 'src/components/pages/chartBuilder/helpers';

const useStyle = createUseStyles({
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
});
interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

const ExportDashboardModal = (props) => {
  const classes = useStyle();
  const CheckboxGroup = Checkbox.Group;
  const {
    exportDashboardModal,
    setExportDashboardModal,
    exportDashboardTabList,
    dashboardGroupfetchInProgress,
    dashboardGroupId,
    dashboardGroupName,
  } = props;

  const [checkedList, setCheckedList] = useState<any>([]);
  const plainOptions: Option[] = exportDashboardTabList.map(item => ({
    label: item.name,
    value: item._id,
  }));
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [getExportDashboard, { loading: loadingExportDashboard }] = useLazyAPI(
    EXPORT_DASHBOARD,
    {
      type: 'GET',
      onCompleted: (data) => {
        if (data.url) {
          urlDownload(data.url);
      }
      },
      onError: (data) => {
        message.error({
          key: 'getExportDashboard',
          duration: 2,
          content: 'Failed exporting dashboard data',
        });
      },
    },
  );

  const onClickExport = () => {
    setExportDashboardModal(false);
    getExportDashboard({
      variables: { dashboardGroupName, dashboardTabList: JSON.stringify(checkedList), dashboardGroupId },
    });
    message.loading({key:'exportDashboard', content: 'Exporting...'});
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions.map(option => option.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Modal
      title="Export Dashboard"
      visible={exportDashboardModal}
      onCancel={() => setExportDashboardModal(false)}
      footer={[
        <Button key="back" onClick={() => setExportDashboardModal(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onClickExport}>
          Export
        </Button>,
      ]}
    >
      <p>Choose the tabs that you want to export from this dashboard</p>
      <div className={classes.checkbox}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Check all
        </Checkbox>
        {dashboardGroupfetchInProgress ? <LoadingComponent /> : null}
        <CheckboxGroup
          options={plainOptions}
          value={checkedList}
          onChange={(ea) => onChange(ea)}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
        </CheckboxGroup>
      </div>
    </Modal>
  );
};

export default ExportDashboardModal;
