import { gql } from '@apollo/client';

export const GET_CHILD_REPORT_VISUALISATION = gql`
  query GET_CHILD_REPORT_VISUALISATION(
    $reportId: String!
    $childReportFilters: [whereHavingInput]
    $isDummyDataRequest: Boolean
  ) {
    getChildReportVisualisation(
      reportId: $reportId,
      childReportFilters: $childReportFilters
      isDummyDataRequest: $isDummyDataRequest
    ) {
      formData
      chartData
      visualizeMetaData
      chartType
      pageSize
      reportDateRange
      reportName
      reportId
      dataMartType
      chartConfig
      globalSuggestedStrings{
        key
        value
      }
    }
  }
`;
