import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { createUseStyles } from 'react-jss';

import CustomSwitch from '../../../common/customSwitch';
import {
    AUTOMATION_ACTION_SELECTED,
    AUTOMATION_ADDITIONAL_FIELDS_CHANGED,
    AUTOMATION_STATUS_CHANGED,
    AUTOMATION_SCHEDULE_CHANGED,
} from '../../../../reduxActions/actionNameEnums';
import CustomDivider from '../../../common/CustomDivider';
import AdditionalFields from './AdditionalFieldsSection';
import SpecialCharacter from 'src/components/commonv2/SpecialCharacter';
import Scheduler from 'src/components/commonv2/Scheduler';

const useStyles = createUseStyles({
    actionSelect: {
        width: '100%',
        marginBottom: '1em',
    },
    cronInput: {
        marginBottom: '1em',
    },
    customDivider: {
        marginBottom: '1em',
    },
});

const AutomationSection = (props) => {
    const { reportObject } = props;
    const classes = useStyles();
    const {
        frequency,
        frequencyData,
        repeat,
        selectedInterval,
        startTimestamp,
        fromToRange,
        automation = {},
        isEnabled = false,
        customInterval,
    } = useSelector((state: any) => state.automationData);
    const actionDropdownOptions = useSelector((state: any) => state.reportConfig?.reports[reportObject?.selectedReport]?.metricActions);
    const automationSchedulerFrequency = useSelector((state:any) => state?.masterData?.organisationConfig?.automationSchedulerFrequency);
    const schedulerData = {
        frequency,
        frequencyData,
        selectedInterval,
        repeat,
        startTimestamp,
        fromToRange,
        customInterval,
    };

    let disableAutomation = false;
    let sanitizedActionDropdownOptions: any = [];

    if (actionDropdownOptions?.length) {
        sanitizedActionDropdownOptions = actionDropdownOptions.filter((option) => {
            return option.objectType === 'Automation';
        }).map((option) => {
            return {
                value: option.id,
                label: option.prettyName,
            };
        });
    } else {
        disableAutomation = true;
    }

    const dispatch = useDispatch();

    const onAdditionalFieldsChange = (additionalFields) => {
        dispatch({
            type: AUTOMATION_ADDITIONAL_FIELDS_CHANGED,
            payload: additionalFields,
        });
    };

    const onStatusChange = (status: boolean) => {
        dispatch({
            type: AUTOMATION_STATUS_CHANGED,
            payload: status,
        });
    };

    const onActionChange = (action: string) => {
        dispatch({
            type: AUTOMATION_ACTION_SELECTED,
            payload: action,
        });
    };

    const onSchedulerDataChange = (data) => {
        dispatch({
            type: AUTOMATION_SCHEDULE_CHANGED,
            payload: data,
        });
    };

    return (
        <>
            <CustomSwitch
                prettyName={`Status (${isEnabled ? 'Enabled' : 'Disabled'})`}
                value={isEnabled}
                disabled={disableAutomation}
                onChange={onStatusChange}
            />

            <CustomDivider className={classes.customDivider} />

            <p>Action <SpecialCharacter character="*"/></p>
            <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                className={classes.actionSelect}
                options={sanitizedActionDropdownOptions}
                value={automation.action}
                onChange={onActionChange}
                dropdownStyle={{ minWidth: '25%' }}
                allowClear={true}
                disabled={!isEnabled || disableAutomation}
            />

            <CustomDivider className={classes.customDivider} />

            <p>Additional Fields for body</p>
            <AdditionalFields
                value={automation.additionalFields}
                onChange={onAdditionalFieldsChange}
                disabled={!isEnabled || disableAutomation}
            />

            <CustomDivider className={classes.customDivider} />

            <Scheduler
                schedulerData={schedulerData}
                onSchedulerDataChange={onSchedulerDataChange}
                disabled={!isEnabled || disableAutomation}
                type="Automation Frequency"
                schedulerFrequency={automationSchedulerFrequency}
                styles={{ headerFontWeight: 'normal', labelFontWeight: 'normal', headerLetterSpacing: 'normal', headerFontSize: '1em' }}
            />
        </>
    );
};

export default memo(AutomationSection);
