import { Input, InputNumber, message, Switch, Tooltip } from 'antd';
import { createUseStyles } from 'react-jss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_GEAR_DATA_CHANGED } from 'src/reduxActions/actionNameEnums';

const useStyle = createUseStyles({
  filterGearDiv: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
    marginTop: '0.8rem',
  },
  overRideSwitch: {
    margin: '0',
    gap: '0.25em',
    justifyContent: 'space-between',
  },
});

const FilterGearSection = (props) => {
  const { filterMetricName: metricsName, selectedReport } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const filterData = useSelector((state: any) => state.filterData);
  const filterGearData = filterData[metricsName] || {};

  const [filterIdValue, setFilterIdValue] = useState(
    filterGearData?.filterId || `${selectedReport}.${metricsName}`,
  );
  const [filterPriority, setFilterPriority] = useState(
    filterGearData.filterPriority || 10,
  );
  const [overrideOtherFilters, setOverrideOtherFilters] = useState(
    filterGearData.overrideOtherFilters,
  );

  const dispatchValues = (
    metricsName,
    filterIdValue,
    filterPriority,
    overrideOtherFilters,
  ) => {
    const hasDuplicateFilterID = Object.entries(filterData).some(
      ([key, value]: [any, any]) => {
        if (key !== metricsName && value?.filterId === filterIdValue) {
          message.error({
            key: 'duplicateFilterId',
            duration: 0.5,
            content: 'Duplicate Filter Id',
          });
          return true;
        }
        return false;
      },
    );
    if (!hasDuplicateFilterID) {
      dispatch({
        type: FILTER_GEAR_DATA_CHANGED,
        payload: {
          metricsName,
          filterIdValue,
          filterPriority,
          overrideOtherFilters,
        },
      });
    }
  };

  const handleInputChange = (e, type) => {
    switch (type) {
      case 'filterId':
        setFilterIdValue(e.target.value);
        break;
      case 'priority':
        setFilterPriority(e);
        break;
      case 'override':
        setOverrideOtherFilters(e);
        dispatchValues(metricsName, filterIdValue, filterPriority, e);
        break;
      default:
        break;
    }
  };

  const handleBlur = (e, type) => {
    switch (type) {
      case 'filterId':
        if (e.target.value === '') {
          setFilterIdValue(`${selectedReport}.${metricsName}`);
          dispatchValues(
            metricsName,
            `${selectedReport}.${metricsName}`,
            filterPriority,
            overrideOtherFilters,
          );
        }
        dispatchValues(
          metricsName,
          filterIdValue,
          filterPriority,
          overrideOtherFilters,
        );
        break;
      case 'priority':
        if (e.target.value === '') {
          setFilterPriority(10);
          dispatchValues(metricsName, filterIdValue, 10, overrideOtherFilters);
        }
        dispatchValues(
          metricsName,
          filterIdValue,
          filterPriority,
          overrideOtherFilters,
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setFilterIdValue(filterGearData?.filterId || `${selectedReport}.${metricsName}`);
    setFilterPriority(filterGearData.filterPriority || 10);
    setOverrideOtherFilters(filterGearData.overrideOtherFilters);
  }, [selectedReport]);

  return (
    <>
      <div className={classes.filterGearDiv}>
        <Tooltip title="Filter ID">
          <div style={{ flex: '4' }}>
            <Input
              value={filterIdValue}
              onChange={(e) => handleInputChange(e, 'filterId')}
              onBlur={(e) => handleBlur(e, 'filterId')}
            />
          </div>
        </Tooltip>
        <Tooltip title="Priority">
          <InputNumber
            defaultValue={'10'}
            value={filterPriority}
            onChange={(e) => handleInputChange(e, 'priority')}
            onBlur={(e) => handleBlur(e, 'priority')}
            style={{ flex: '1' }}
            min={1}
          />
        </Tooltip>
        <Tooltip title="Override other Filters">
          <Switch
            size="small"
            checked={overrideOtherFilters}
            onClick={(e) => handleInputChange(e, 'override')}
          />
        </Tooltip>
      </div>
    </>
  );
};
export default FilterGearSection;
