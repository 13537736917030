import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  title: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1.4rem',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  subTitle: {
    marginTop: '1%',
    textAlign: 'center',
    fontSize: '1.0rem',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
});

const StatusDisplay = (props) => {
  const classes = useStyle();
  const { image, title, subTitle } = props;
  return (
    <div className={classes.container}>
      {image}
      <div className={classes.title}>{title}</div>
      <div className={classes.subTitle}>
       {subTitle}
      </div>
    </div>
  );
};

export default StatusDisplay;
