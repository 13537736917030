import React from 'react';
import { isMobile } from 'react-device-detect';

const LabelWrapper = (props) => {
    const { children, title, style } = props;
    return <div style={{ display: 'flex', ...style }}>
        <div style={{
            height: '32px',
            border: '1px solid #d9d9d9',
            position: 'relative',
            left: '1px',
            backgroundColor: '#FFFFFF'}}>
            <div style={{ marginLeft: '16px', marginRight: '16px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                height: '100%',
                fontSize: '12px',
                display: 'flex',
                justifyContent: !isMobile ? 'center' : '',
                alignItems: 'center',
                 }}>
            {title}
            </div>
        </div>
        {children}
    </div>;
};

export default LabelWrapper;
