import { useLazyQuery, useQuery } from '@apollo/client';
import { useState } from 'react';
import { SEARCH_FILTER_SUGGESTION } from './queries';

const useSearchMetrics = (reportName, metricName, searchQuery) => {
  const [loadingError, setLoadingError] = useState({});
  const [optionsData, setOptionsData] = useState<any>([]);
  const [getSearchFilterSuggestion, { loading: optionsLoading }] = useLazyQuery(
    SEARCH_FILTER_SUGGESTION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setOptionsData(data.getSearchFilterSuggestion?.searchResults?.map((option) => {
            return {
                value: option.value,
                label: option.prettyName,
            };
        }));
      },
      onError: (error) => {
        setLoadingError(error);
      },
      variables: { searchQuery, metricName, reportName },
    },
  );
  return {
    getSearchFilterSuggestion,
    optionsLoading,
    loadingError,
    optionsData,
  };
};
export default useSearchMetrics;
