import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CHILD_GLOBAL_FILTER_APPLY, REMOVE_CHILD_REPORT, RESET_CHILD_REPORT_LIST } from 'src/reduxActions/actionNameEnums';
import { ServerError, throwServerError, useLazyQuery } from '@apollo/client';
import lodashIsEmpty from 'lodash/isEmpty';
import { GET_CHILD_REPORT_VISUALISATION } from '../pages/chartBuilder/chartBuilderQueries';
import ChartPane from 'src/components/common/Charts';
import { ArrowLeftOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ChartContainerTopBar from '../pages/dashboard/dashboardParts/chartsContainerParts/chartsContainerTopbar';
import { useDownloadReport } from '../pages/dashboard/hooks/downloadReport';
import GlobalFilterTopbar from './GlobalFilterTopbar';
import { getfilterWithUnSelectedValues, transformFilters } from './utils/filter';
import lodashValues from 'lodash/values';
import lodashOmit from 'lodash/omit';
import { useChartInteractions } from './hooks/useChartInterctions';
import ChartInteractionPopOver from './ChartInteractionPopOver';
import { createUseStyles } from 'react-jss';
import { isManualQueryReport } from './utils/utils';
import lodashCloneDeep from 'lodash/cloneDeep';

const useStyles = createUseStyles({
  modalContainer: {
    width: '100%',
    height: '100%',
  },
  childReportContainer: {
    height: '100%',
    width: '100%',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    backgroundColor: '#FAFAFA',
    overflow: 'hidden',
    position: 'relative',
  },
  chartContainer: {
    width: '100%',
    height: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  spinnerContainer: {
    height: 'calc(100% - 40px)',
    minHeight: '500px',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  contentDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    gap: '5px',
  },
  errorCircle: {
    color: 'white',
    background: 'red',
    borderRadius: '10px',
  },
});

const ChildReportModal = (props) => {
  const { childReportFromDashboard = false} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const chartRef: any = useRef();
  const firstRenderRef = useRef(false);
  const chartInteractionHook = useChartInteractions();
  const { onDownload, onDownloadUnderlyingData } = useDownloadReport();
  const updateChartRef = (plot) => {
    if (chartRef) {
      chartRef.current = plot;
    }
  };
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [currentReportData, setCurrentReportData]: any = useState({});
  const childReportModalData = useSelector((state: any) => state.childReportModalData);
  const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
  const supportedFilters = useSelector(
    (state: any) => state.masterData?.supportedFilters,
  );

  const [getChildReportVisualisation, { loading: loadingChildReport }] = useLazyQuery(GET_CHILD_REPORT_VISUALISATION, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getChildReportVisualisation: data }) => {
      const currentReportFilters = JSON.parse(data?.visualizeMetaData || '{}')?.filterData || [];
      let currentReportFiltersCopy  = lodashCloneDeep(currentReportFilters);
      currentReportFiltersCopy = currentReportFiltersCopy.map(currentReportFilter => {
        return lodashOmit(currentReportFilter, ['_id', 'filter._id']);
      });
      const visualizeMetaData = JSON.parse(data?.visualizeMetaData || '{}');
      setCurrentReportData({
        chartData: JSON.parse(data?.chartData || '{}'),
        formData: JSON.parse(data?.formData || '{}'),
        chartType: data?.chartType,
        visualizeMetaData,
        currentReportFilters: currentReportFiltersCopy,
        pageSize: data?.pageSize,
        reportName: data?.reportName,
        reportId: data?.reportId,
        reportDateRange: data?.reportDateRange,
        lastRefreshTime: data?.lastRefreshTime,
        dataMartType: data?.dataMartType,
        chartConfig: JSON.parse(data.chartConfig),
      });
    },
    onError: (error) => {
      if((error?.networkError as ServerError)?.statusCode === 403) {
        setError(true);
      }
    },
  });

  const onResetFilter = async () => {
    const { childReportDataList, currentIndex } = childReportModalData;
    const { reportId, childReportFilters } = childReportDataList[currentIndex];
    await getChildReportVisualisation({
      variables: {
        reportId,
        childReportFilters,
        isDummyDataRequest: enableDummyDataSwitch,
      },
    });
  };

  const checkAllValuesNotNull = (object) => {
    for (const value of Object.values(object)) {
      if (!value) {
        return false;
      }
    }
    return true;
  };
  const transformFilterWithSelectedValue = (element) => {
    //4 cases
    if ('data' in element) {
      if (element.data) {
        if (element.data?.value.length === undefined) {
          return checkAllValuesNotNull(element.data.value); //when data is of objects type
        }
        return element.data?.value.length; // //when data is of string or array type
      }
    }
    return true; //when there is no data
  };

  const getAppliedFilters = (filters) => {
    const filtersWithUnselectedValues = getfilterWithUnSelectedValues(
      filters,
      supportedFilters,
    ) as Array<any>;
    const filterWithSelectedValue = filters.filter(
      (filter) => !filtersWithUnselectedValues.includes(filter.value),
    );
    const refinedFilterWithSelectedValue = filterWithSelectedValue.filter(
      transformFilterWithSelectedValue,
    );
    const filterTransformed = transformFilters(refinedFilterWithSelectedValue);
    return filterTransformed;
  };

  const getUpdatedChildReportFilters = (
    globalFilledFilters,
    childReportFilters,
  ) => {
    const updatedFilters = childReportFilters.map((childFilter) => {
      const filledFilter = globalFilledFilters.find(
        (filledFilter) => (filledFilter.metric === childFilter.metric),
      );
      if (filledFilter) {
        return { metric: filledFilter.metric, filter: filledFilter.filter };
      }
      return childFilter;
    });
    return updatedFilters;
  };

  const onApplyFilter = async () => {
    dispatch({
      type: CHILD_GLOBAL_FILTER_APPLY,
      payload: {
        childReportIndex: childReportModalData.currentIndex,
      },
    });
    const { childReportDataList, currentIndex } = childReportModalData;
    const { reportId, childReportFilters, childReportGlobalFilters } = childReportDataList[currentIndex];
    const globalFilters = lodashValues(childReportGlobalFilters);
    const globalFilledFilters = getAppliedFilters(globalFilters);
    const updatedChildReportFilters = getUpdatedChildReportFilters(globalFilledFilters, childReportFilters);
    await getChildReportVisualisation({
      variables: {
        reportId,
        childReportFilters: updatedChildReportFilters,
        isDummyDataRequest: enableDummyDataSwitch,
      },
    });
    return globalFilledFilters;
  };

  const previousChildReport = () => {
    dispatch({ type: REMOVE_CHILD_REPORT });
  };

  const getCurrentChildReportData = async () => {
    const { childReportDataList, currentIndex } = childReportModalData;
    const {
      reportId,
      childReportFilters,
      childReportGlobalFilters,
      globalFiltersApplied,
    } = childReportDataList[currentIndex];
    let modifiedFilters = childReportFilters;
    if (globalFiltersApplied) {
      const globalFilters = lodashValues(childReportGlobalFilters);
      const globalFilledFilters = getAppliedFilters(globalFilters);
      modifiedFilters = getUpdatedChildReportFilters(
        globalFilledFilters,
        childReportFilters,
      );
    }
    await getChildReportVisualisation({
      variables: {
        reportId,
        childReportFilters: modifiedFilters,
        isDummyDataRequest: enableDummyDataSwitch,
      },
    });
  };

  useEffect(() => {
    if(!firstRenderRef.current) {
      firstRenderRef.current = true;
      return;
    }
    if (childReportModalData.childReportDataList.length) {
      setVisible(true);
      getCurrentChildReportData();
    } else {
      setVisible(false);
    }
  }, [childReportModalData.childReportDataList?.length]);

  const onCancleModal = () => {
    setVisible(false);
    dispatch({ type: RESET_CHILD_REPORT_LIST });
  };

  // keeping this constant for now, as later we disable download underlying data
  const showUnderlyingDataDownload = true;

  return (
    <>
      <Modal
        title={
          <div style={{ display: 'flex' }}>
            <ArrowLeftOutlined
              style={{ cursor: 'pointer', marginTop: '3px' }}
              onClick={previousChildReport}
            />
            <p style={{ margin: '0 10px' }}>Child Report</p>
          </div>
        }
        centered
        visible={visible}
        onCancel={onCancleModal}
        footer={[
          <Button key="back" onClick={onCancleModal}>
            Close
          </Button>,
        ]}
        style={{ minWidth: '80%', height: '95vh' }}
      >
        <div className={classes.modalContainer}>
          {error ? (
            <div className={classes.contentDisplay}>
              <CloseCircleOutlined className={classes.errorCircle} />
              <span>
                You do not have access to view this report. Please contact admin{' '}
              </span>
            </div>
          ) : !lodashIsEmpty(currentReportData) ? (
            <div className={classes.childReportContainer}>
              <GlobalFilterTopbar
                childReportIndex={childReportModalData.currentIndex}
                filterData={
                  childReportModalData?.childReportDataList[
                    childReportModalData?.currentIndex
                  ]?.childReportGlobalFilters
                }
                onResetFilter={onResetFilter}
                onApplyFilter={onApplyFilter}
                isSticky={false}
              />
              <ChartContainerTopBar
                onClickDownloadChartData={() =>
                  onDownload(
                    currentReportData.reportId,
                    [],
                    currentReportData.currentReportFilters,
                  )
                }
                onClickDownloadUnderlyingChartData={() => {
                  onDownloadUnderlyingData(
                    currentReportData.reportId,
                    [],
                    currentReportData.currentReportFilters,
                  );
                }}
                chartType={currentReportData.chartType}
                chartRef={chartRef}
                reportName={currentReportData.reportName}
                reportId={currentReportData.reportId}
                reportDateRange={currentReportData.reportDateRange}
                isChildReport={true}
                lastRefreshTime={currentReportData.lastRefreshTime}
                chartData={currentReportData.chartData}
                dataMartType={currentReportData.dataMartType}
              />
              <ChartInteractionPopOver
                chartInteractionHook={chartInteractionHook}
              />
              <div className={classes.chartContainer}>
                <ChartPane
                  updateChartRef={updateChartRef}
                  type={currentReportData.chartType}
                  chartInteractionHook={chartInteractionHook}
                  loaded={true}
                  fetching={loadingChildReport}
                  chartData={currentReportData.chartData}
                  pageSize={currentReportData.pageSize}
                  visualizeMetaData={currentReportData.visualizeMetaData}
                  formData={currentReportData.formData}
                  reportId={currentReportData.reportId}
                  showUnderlyingDataDownload={!showUnderlyingDataDownload}
                  chartConfig={currentReportData.chartConfig}
                  chartsFromDashboard={childReportFromDashboard}
                />
              </div>
            </div>
          ) : (
            <div className={classes.spinnerContainer}>
              <Spin spinning={loadingChildReport} className={classes.spinner} />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ChildReportModal;
