import lodashKeyBy from 'lodash/keyBy';
import lodashMapKeys from 'lodash/mapKeys';

import lodashCloneDeep from 'lodash/cloneDeep';
import {
  BUCKET_VALUE_CHANGED,
  DRILLDOWN_VALUE_CHANGED,
  INITIALIZE_AGGREGATE,
  SUMMARISATION_LIST_CHANGED,
  SUMMARISATION_LIST_RESET,
  REMOVE_DRILLDOWN,
} from '../reduxActions/actionNameEnums';

const initialState = {};

const summarisationReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const copySummarizations = lodashCloneDeep(state);
    switch (type) {
      case SUMMARISATION_LIST_CHANGED:
        const newObject = Object.assign({}, payload);
        const metricObject = lodashMapKeys(
          newObject,
          (data, keys) => data.value,
        );
        return metricObject;
      case INITIALIZE_AGGREGATE:
        return lodashKeyBy(payload, (metric) => metric.value);
      case SUMMARISATION_LIST_RESET:
        return initialState;
      case BUCKET_VALUE_CHANGED:
        const bucketValueChanged = {
          [payload.metricName]: {
            ...state[payload.metricName],
            groupBy: {
              ...state[payload.metricName].groupBy,
              bucketValue: payload.bucketValue,
            },
          },
        };
        const newState = Object.assign({}, state, bucketValueChanged);
        return newState;
      case DRILLDOWN_VALUE_CHANGED:
        const { metricName, drilldown } = payload;
        if (drilldown.toApply) {
          copySummarizations[metricName].drilldown = drilldown;
        } else {
          delete copySummarizations[metricName].drilldown;
        }
        return copySummarizations;
      case REMOVE_DRILLDOWN:
        Object.keys(copySummarizations).forEach((metricName) => {
          if (copySummarizations[metricName].drilldown) {
            delete copySummarizations[metricName].drilldown;
          }
        });
        return copySummarizations;
      default:
        return state;
    }
};

export default summarisationReducer;
