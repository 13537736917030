export const colors = {
  theme_4:[
    '#5B8FF9',
    '#CDDDFD',
    '#61DDAA',
    '#CDF3E4',
    '#65789B',
    '#CED4DE',
    '#F6BD16',
    '#FCEBB9',
    '#7262fd',
    '#D3CEFD',
    '#78D3F8',
    '#D3EEF9',
    '#9661BC',
    '#DECFEA',
    '#F6903D',
    '#FFE0C7',
    '#008685',
    '#BBDEDE',
    '#F08BB4',
    '#FFE0ED',
  ],
  theme_3:[
    '#FF6B3B',
    '#626681',
    '#FFC100',
    '#9FB40F',
    '#76523B',
    '#DAD5B5',
    '#0E8E89',
    '#E19348',
    '#F383A2',
    '#247FEA',
    '#2BCB95',
    '#B1ABF4',
    '#1D42C2',
    '#1D9ED1',
    '#D64BC0',
    '#255634',
    '#8C8C47',
    '#8CDAE5',
    '#8E283B',
    '#791DC9',
  ],
  theme_2:[
    '#025DF4',
    '#DB6BCF',
    '#2498D1',
    '#BBBDE6',
    '#4045B2',
    '#21A97A',
    '#FF745A',
    '#007E99',
    '#FFA8A8',
    '#2391FF',
    '#FFC328',
    '#A0DC2C',
    '#946DFF',
    '#626681',
    '#EB4185',
    '#CD8150',
    '#36BCCB',
    '#327039',
    '#803488',
    '#83BC99',
  ],
  theme_1:[
    '#1478FF',
    '#FF9B14',
    '#FF4500',
    '#1AAF8B',
    '#406C85',
    '#F6BD16',
    '#B40F0F',
    '#2FB8FC',
    '#4435FF',
    '#FF5CA2',
    '#BBE800',
    '#FE8A26',
    '#946DFF',
    '#6C3E00',
    '#6193FF',
    '#FF988E',
    '#36BCCB',
    '#004988',
    '#FFCF9D',
    '#CCDC8A',
    '#8D00A1',
    '#1CC25E',
  ],
};

export const tableColor = [
  '#DAECF7',
  '#000000',
  '#FFFFFF',
  '#F1F2F5',
  '#000000',
  '#AAA7A7',
  '#AAA7A7',
];
