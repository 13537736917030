/* eslint-disable no-lonely-if */
import { message } from 'antd';
import lodashOmit from 'lodash/omit';
import lodashTransform from 'lodash/transform';
import lodashIsObject from 'lodash/isObject';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsNil from 'lodash/isNil';
import moment from 'moment';
import { entityTypes, mailTypes } from '../../../constants';
import lodashUniqBy from 'lodash/uniqBy';
import lodashCloneDeep from 'lodash/cloneDeep';
import { getProjectName, isManualQueryReport } from 'src/components/common/utils/utils';
const { CXO: CXO_ENTITY } = entityTypes;
const { TEXT: TEXT_MAIL_TYPE, CXO: CXO_MAIL_TYPE, MJML: MJML_MAIL_TYPE} = mailTypes;

const processData = (record) => {
    const proceessedFilter = processFilters(record.filters);
    const proceessedHavingFilter = processFilters(record.havingFilters || [] );
    let recordSuggetedFilter = record.suggestedFilterStrings;
    if(record.suggestedFilterStrings === undefined || record.suggestedFilterStrings === null ){
        recordSuggetedFilter = [];
    }
    let processedMetrices = [];
    let metricString = '';
    processedMetrices = record.metrices.map((metric) => {
      const newMetric = lodashCloneDeep({ ...metric, value: metric.metric });
      const groupBy = { ...newMetric.groupBy };
      if (metric?.groupBy?.bucketValue) {
        groupBy.bucketValue = metric.groupBy.bucketValue;
      }
      if (newMetric?.groupBy?.toApply) {
        groupBy.toApply = metric.groupBy.toApply;
      }
      if (newMetric?.linkedReport?.linkedMetrices) {
        newMetric.linkedReport.linkedMetrices = JSON.parse(
          metric.linkedReport.linkedMetrices,
        );
      }
      if (newMetric?.dummyData) {
        newMetric.dummyData = JSON.parse(newMetric.dummyData);
      }
      return lodashOmit(newMetric, ['metric']);
    });
    if(isManualQueryReport(record.dataMartType)){
      metricString = JSON.stringify(
        record.metrices.map(
          ({ prettyName, type, metric, groupBy, operation, linkedReport, sql }) => {
            const metricObj: any = { prettyName, type, metric, sql };
            if (groupBy) {
              const groupByObj: any = {};
              if (groupBy?.bucketValue) {
                groupByObj.bucketValue = groupBy.bucketValue;
              }
              if (groupBy?.toApply) {
                groupByObj.toApply = groupBy.toApply;
              }
              metricObj.groupBy = groupByObj;
            }
            operation ? (metricObj.operation = operation) : null;
            if(linkedReport) {
              metricObj.linkedReport = {
                ...linkedReport,
                linkedMetrices: JSON.parse(linkedReport?.linkedMetrices || '{}'),
              };
            }
            return metricObj;
          },
        ),
        null,
        2,
      );
    }
    return {
      reportType: record.reportType || '',
      chartType: record.chartType,
      metrices: processedMetrices,
      chartOptions: record.chartOptions,
      chartConfig: record.chartConfig,
      formatting: record.formatting,
      filters: proceessedFilter,
      havingFilters: proceessedHavingFilter,
      customAggregateMetrices: record.customAggregateMetrices.map((metric) => {
        const newMetric = lodashCloneDeep(metric);
        if (newMetric?.linkedReport?.linkedMetrices) {
          newMetric.linkedReport.linkedMetrices = JSON.parse(
            newMetric.linkedReport.linkedMetrices,
          );
        }
        if (newMetric?.dummyData) {
          newMetric.dummyData = JSON.parse(newMetric.dummyData);
        }
        return newMetric;
      }),
      customMetrices: record.customMetrices,
      reportUserType: record.type,
      name: record.name,
      savedMetric: record.savedMetric,
      description: record.description,
      pageSize: record.pageSize,
      id: record._id,
      suggestedFilterStrings: recordSuggetedFilter,
      object: record.object,
      numAlerts: record.numAlerts,
      isAggregate: record.isAggregate,
      reportCategory: record.category,
      underlyingMetrics: (record.underlyingMetrics || []).map((metric) => {
        const newMetric = lodashCloneDeep({ ...metric, value: metric.metric });
        return lodashOmit(newMetric, 'metric');
      }),
      automationSchedules: record.automationSchedules,
      metricType: record.metricType,
      actions: record.actions,
      isAdminReport: record.isAdminReport || false,
      queryString: record.queryString ? record.queryString : undefined,
      metricString,
      htmlData: record.htmlData,
      dataMartType: record.dataMartType ? record.dataMartType : undefined,
      mongoCollectionName: record.mongoCollectionName ? record.mongoCollectionName : undefined,
      underlyingQueryString: record.underlyingQueryString ? record.underlyingQueryString : undefined,
    };
};

const processDataForVisualisation = (record) => {
    let metricString;
    if (!isManualQueryReport(record.dataMartType)) {
      metricString = JSON.stringify(
        record.metrices.map(({ prettyName, type, metric }) => {
          return { prettyName, type, metric };
        }),
        null,
        2,
      );
    }
  return {
    reportId: record._id,
    reportType: record.reportType || '',
    customAggregateMetrices: record.customAggregateMetrices.map(metric => {
      const { aliasName, savedMetric, ...rest } = metric;
      return rest;
    }),
    customMetrices: record.customMetrices,
    metrices: record.metrices.map(metric => {
      const { aliasName, savedMetric, ...rest } = metric;
      return rest;
    }) ?? [],
    filters: record.filters,
    havingFilters: record.havingFilters || [],
    chartType: record.chartType,
    chartOptions: record.chartOptions,
    suggestedFilterStrings: record.suggestedFilterStrings || [],
    isAdminReport: record.isAdminReport || false,
    dataMartType: record.dataMartType ? record.dataMartType : undefined,
    queryString: record.queryString ? record.queryString : undefined,
    metricString,
    mongoCollectionName: record.mongoCollectionName ? record.mongoCollectionName : undefined,
  };
};

const processFilters = (filters) => filters.map(filter => {
    return {
        value: filter.metric,
        prettyName: filter.filter.prettyName,
        type: filter.filter.type,
        selectedFilter: filter.filter.name,
        ...(filter.filter.data && {data: JSON.parse(filter.filter.data)}),
        searchOptions: filter.filter.searchOptions,
        havingOperation: filter.filter.havingOperation,
        havingOperations: filter.filter.havingOperations,
        caseInsensitive:filter.caseInsensitive,
        holidays: filter.filter.holidays,
        section: filter.section,
        sql: filter.filter.sql || '',
        isDefaultFilter: filter.filter.isDefaultFilter || false,
        dashboardFilterType: filter.dashboardFilterType,
        filterId: filter.filterId,
        filterPriority: filter.filterPriority,
        overrideOtherFilters: filter.overrideOtherFilters,
    };
});

const getFilterDataFromFiltersList = (filters) => {
    const filterData = {};
    filters.forEach(filter => {
        filterData[filter.value] = filter;
    });
    return filterData;
};

const getNextExecutionTime = (time, timezone) => {
    if(!time) return null;
    const timeObject = moment(time, timezone);
    const now = moment(new Date(), timezone);
    if(timeObject>now){
        return timeObject.toDate();
    }
    return timeObject.add(1, 'd').toDate();
};

const attachmentSchedulerOptions = [{
    value:'pdf',
    prettyName: 'PDF',
},
{
    value:'png',
    prettyName: 'PNG',
},
{
    value:'csv',
    prettyName: 'CSV',
},
{
    value:'excel',
    prettyName: 'Excel',
},
{
    value:'inlineWidget',
    prettyName: 'Widget',
}];

const frequencyOption = {
    3600000: {
        value: 3600000,
        prettyName: 'Hourly',
    },
    604800000: {
        value: 604800000,
        prettyName: 'Weekly',
    },
    86400000: {
        value: 86400000,
        prettyName: 'Daily',
    },
    2628000000: {
        value: 2628000000,
        prettyName: 'Monthly',
    },
};

const validateAlertsAndSchedulers = (data, isScheduler, sendNotification = true) => {
    let flag = true;
    const messages:Array<string> = [];
    if(!data.title){
        messages.push('Please Provide with the Title');
        flag = false;
    }
    if(data.emailRecipients.length === 0){
        messages.push('Please enter atleast one Recipient to Proceed');
        flag = false;
    }
    if(!data.emailMessage){
        messages.push('Please Provide with the Message');
        flag = false;
    }
    if(!data.emailSubject){
        messages.push('Please Provide with the Email Subject');
        flag = false;
    }
    if(!data.timeZone){
        messages.push('Please Select the Timezone');
        flag = false;
    }
    if(!data.frequencyIntervalMillisec){
        messages.push('Please Select the Frequency');
        flag = false;
    }
    if(!data.executionTime){
        messages.push('Please Select the Time of Execution');
        flag = false;
    }
    if(sendNotification){
        if(!flag){
            message.error('Please select all fields to proceed ');
        }
    }
    return flag;
};

const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

const validateAlertsAndSchedulersParams = (data, isUpdate = true) => {
    const projectName = getProjectName();
    let flag = true;
    if(!data.title){
        message.error('Please Provide with the Title');
        flag = false;
    }
    if(!data.mailType){
        message.error('Please Provide with the mailType');
        flag = false;
    }
    if (projectName === 'exim') {
      if (data.toEmails.length === 0) {
        message.error('Please enter atleast one Recipient to Proceed');
        flag = false;
      }
      if (data.toEmails.length > 0) {
        if (data.toEmails.some((user) => !user.email)) {
          message.error(
            'Please enter complete details of Recipient to Proceed',
          );
          flag = false;
        } else if (
          lodashUniqBy(data.toEmails, (obj) => obj.email).length !==
          data.toEmails.length
        ) {
          message.error('Please enter unique emails of Recipient to Proceed');
          flag = false;
        }
      }
      if (
        data.toEmails.length > 0 &&
        data.toEmails.some((user) => !validateEmail(user.email))
      ) {
        message.error('Please enter correct email of Recipient to Proceed');
        flag = false;
      }
    }
    if (projectName === 'logistics') {
      if (data.mailType === TEXT_MAIL_TYPE || data.mailType === CXO_MAIL_TYPE) {
        if(data.ccEmails.length === 0){
          message.error('Please enter atleast one Recipient to Proceed');
          flag = false;
        }
      } else {
        if(data.recipientsRoles?.length < 1 && !data.isTestEmail){
          message.error('Please select Recipient Roles to Proceed');
          flag = false;
        }
      }
    }

    if(moment.utc() > moment(new Date(data.startTimestamp)).utc() && !isUpdate){
        message.error('Start date and time should be greater than current date and time');
        flag = false;
    }
    if(data.endTimestamp && moment(new Date(data.startTimestamp)).utc() >= moment(new Date(data.endTimestamp)).utc()){
        message.error('End date and time should be greater than start date and time');
        flag = false;
    }
    if(!data.emailMessage && data.entityType !== CXO_ENTITY){
        message.error('Please Provide with the Message');
        flag = false;
    }
    if(!data.emailSubject){
        message.error('Please Provide with the Email Subject');
        flag = false;
    }
    if(!data.startDate){
        message.error('Please Provide with the Schedule Date');
        flag = false;
    }
    if(!data.startTime){
        message.error('Please Provide with the Schedule Time');
        flag = false;
    }
    return flag;
};

const getReportIdsFromReportData = (selectedReportsData = []) => {
  const reportIds = selectedReportsData.map(({id}) => id);
  return reportIds;
};

const ColorList = ['#74bbfb', '#d3b683', '#a58d7f', '#aefd6c'];

const getSingleNumberTemplate = (objPath) => {
    return `<mj-section padding="5px 20px">
    <mj-column background-color="rgba(229, 246, 255, 1);" border-radius="5px">
      <mj-text padding="0px 16px">
        <div style="display: block;">
          <p style="width: 50%; box-sizing: border-box; font-size: 14px; color: rgba(0, 0, 0, 0.85);
            font-weight: 600; padding: 5px; float: left;">
            {{${objPath}.name}}
          </p>
          {{#if ${objPath}.chartData.value}}
            <p style="width: 50%; box-sizing: border-box; font-size: 14px; color: rgba(0, 0, 0, 0.85);
              font-weight: 600; text-align: right; padding: 5px; float: right;">
              {{${objPath}.chartData.value}}
            </p>
          {{else}}
            <p style="width: 50%; box-sizing: border-box; font-size: 14px; color: rgba(0, 0, 0, 0.85);
              font-weight: 600; text-align: right; padding: 5px; float: right;">
              0
            </p>
          {{/if}}
        </div>
      </mj-text>
    </mj-column>
  </mj-section>`;
};

const getCustomNumberTemplate = (objPath) => {
  return `
  {{#if ${objPath}.chartData}} 
    <mj-section padding="5px 20px">
      <mj-column width="560px" background-color="rgba(229, 246, 255, 1);" border-radius="5px">
        <mj-text padding="17px 16px" font-weight='600' align="left" color="black" font-size="14px">{{${objPath}.name}}</mj-text>
        <mj-table padding="0px 12px 12px 12px" cellpadding="6px">
          <tbody>
            {{#each ${objPath}.chartData}}
              <tr>
                <td style="{{style}} white-space: nowrap; color: rgba(0, 0, 0, 0.45);">{{title}}</td>
                <td style="{{style}} white-space: nowrap; color: rgba(0, 0, 0, 0.85); font-weight: 600; text-align: right;">{{value}}</td>
              </tr>
            {{/each}}
          </tbody>
        </mj-table>
      </mj-column>
    </mj-section>
  {{/if}}`;
};

const getTableTemplate = (objPath) => {
  return `
  {{#if ${objPath}.chartData}}  
    <mj-section padding="15px 20px">
      <mj-column width="555px" background-color="rgba(229, 246, 255, 1);" border-radius="5px" css-class="overflow-scroll">
          <mj-text padding="17px 16px" font-weight='600' align="left" color="black" font-size="14px">{{${objPath}.name}}</mj-text>
          <mj-text padding="2px 16px" font-weight='400' align="left" color="rgba(0, 0, 0, 0.45)" font-size="11px">{{${objPath}.description}}</mj-text>
          <mj-table padding="12px 12px" cellpadding="6px">
            <tbody>
              <tr style="border-bottom: 2px solid #ecedee; text-align: left;">
                  {{#each ${objPath}.meta}}
                  <th style="{{{style}}} color: rgba(0, 0, 0, 0.45); font-weight: 400;">{{name}}</th>
                  {{/each}}
              </tr>
              {{#each ${objPath}.chartData}}
              <tr style="border-bottom: 1px solid #dcddde">
                  {{#each ../${objPath}.meta}}
                      {{#with (lookup ../this field)}}
                          <td style="{{{style}}}">{{value}}</td>
                      {{/with}}
                  {{/each}}
              </tr>
              {{/each}}
            </tbody>
          </mj-table>
          </mj-column>
    </mj-section>
  {{/if}}`;
};

const getThreeNumberTemplate = (objPath) => {
  return `
  {{#if ${objPath}.chartData}}  
    <mj-section padding="5px 20px">
      <mj-column background-color="#E5F6FF" border-radius="5px">
        <mj-text padding="17px 16px" font-weight="600" align="left" color="black" font-size="14px"
          >{{${objPath}.name}}
        </mj-text>
        <mj-text align="left" color="black">
          <div style="padding-right: 4%">
            <p style='float: left;'>
              <span style='color: black; font-weight: 600; font-size: 20px;'>{{${objPath}.chartData.firstData.value}}</span>
              <span style='padding: 5px; color: #00000073; font-weight: 400; font-size: 10px;'>
              {{${objPath}.chartData.secondData.title}} {{${objPath}.chartData.secondData.value}}
              </span>
            </p>
            <p style='float: right; height: 22px; display: flex; align-items: flex-end;'>
              <span style='padding: 2px; font-weight: 400; font-size: 10px;'>{{${objPath}.chartData.thirdData.value}}</span>
              <span style='padding: 2px; color: #00000073; font-weight: 400; font-size: 10px;'>{{${objPath}.chartData.thirdData.title}}</span>
            </p>
          </div>
        </mj-text>
      </mj-column>
    </mj-section>
  {{/if}}`;
};

const getSummary = () => {
    return `<mj-section padding="5px 0px">
    <mj-column>
      <mj-text padding="0px 20px">
        <p style="padding: 0px; margin: 0px 0px 8px 0px;">
          <span style="font-weight: 400; font-size: 16px; color: #000000;">Hi,</span>
        </p>
        <p style="padding: 0px; margin: 0px;">
          <span style="font-weight: 400; font-size: 16px; color: #000000;">{{summary}}</span>
        </p>
      </mj-text>
    </mj-column>
  </mj-section>`;
};

const getFooter = () => {
    return `<mj-section padding="5px 0px">
    <mj-column>
      <mj-text font-weight='400' align="left" color="black" font-size="16px">
        Note: Tables may be truncated to show the top 10 rows
      </mj-text>
      <mj-text font-weight='400' align="left" color="black" font-size="16px">
        Please visit
        <a href="{{dashboardLink}}" style="color: rgba(0, 110, 195, 1)">
        ShipsyBI dashboard
        </a>
        for additional details
      </mj-text>
      <mj-text font-weight='400' align="left" color="black" font-size="16px">Regards,</mj-text>
      <mj-text font-weight='400' align="left" color="black" font-size="16px">Shipsy</mj-text>
    </mj-column>
  </mj-section>`;
};

const getHeader = () => {
    return `<mj-head>
    <mj-style>
        .overflow-scroll { overflow: scroll; }
        @media only screen and (max-width: 480px) {
        .banner-heading {
          display: block !important;
          padding: 2px 0px;
          }
        }
    </mj-style>
  </mj-head>`;
};

const getTopBanner = () => {
    return `<mj-section padding="5px 0px" background-color="#E5F6FF" background-size="cover" background-repeat="no-repeat">
      <mj-column>
        <mj-text padding-bottom="5px" align="left">
          <div>
            <img style="vertical-align:middle; width: 150px;" src="https://query-builder-public-assets.s3.us-west-2.amazonaws.com/email/blueShipsyCompleteLogo.png" />
          </div>
        </mj-text>
        <mj-text align="left">
          <div>
            <span class="banner-heading" style="font-weight: 400; font-size: 1rem; color: #000000;">Analytics Daily Report for</span>
            <span class="banner-heading" style="font-weight: 400; font-size: 1rem; color: #000000;">{{todayDateString}}</span>
          </div>
          {{#if hub_name}}
            <div style="padding-top: 10px;">
              <span style="font-weight: 400; font-size: 1rem; color: #000000;">
                {{hub_name}}
              </span>
            </div>
          {{/if}}
        </mj-text>
      </mj-column>
    </mj-section>`;
};

const compulsoryKeys = ['metrices', 'filters'];
const cleanObject = obj => {
  return lodashTransform(obj, (result, value, key) => {
    if (!lodashIsNil(value)) {
      if (lodashIsObject(value)) {
        const cleanedValue = cleanObject(value);
        if (!lodashIsEmpty(cleanedValue) || compulsoryKeys.includes(key)) {
          result[key] = cleanedValue;
        }
      } else {
        result[key] = value;
      }
    }
  });
};

export {
    validateAlertsAndSchedulers,
    processData,
    processDataForVisualisation,
    getFilterDataFromFiltersList,
    processFilters,
    getNextExecutionTime,
    validateAlertsAndSchedulersParams,
    attachmentSchedulerOptions,
    frequencyOption,
    ColorList,
    getSingleNumberTemplate,
    getCustomNumberTemplate,
    getThreeNumberTemplate,
    getTableTemplate,
    getFooter,
    getHeader,
    getSummary,
    getTopBanner,
    getReportIdsFromReportData,
    cleanObject,
};
