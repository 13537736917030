import { Menu } from 'antd';
import React from 'react';

const FilterMenu = (props) => {
    const { options, onChange, value } = props;
    const menu = <Menu onClick={(value) => onChange([value])} selectedKeys={[value?.value]}>
                    {options.map(option => <Menu.Item key={option.value}>{option.prettyName}</Menu.Item >)}
                 </Menu>;
    return menu;
};

export default FilterMenu;
