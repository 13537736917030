import React, { memo, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import CreateQueryTopBar from 'src/components/pages/chartBuilder/createQueryComponents/createQueryTopBar';
import CreateQueryBottomBar from 'src/components/pages/chartBuilder/createQueryComponents/createQueryBottomBar';
import MetricSetupSection from './MetricSetupSection';
import { SET_CHART_TYPE } from 'src/reduxActions/actionNameEnums';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = createUseStyles({
  createReportSection: {
    padding: '0px',
    margin: '0px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  createReportBody: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    margin: '10px',
  },
});

const CreateMetricSection = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    reportObject,
    submitReport,
    setReset,
    aggregate,
    filters,
    sortBy,
    havingFilters,
    initialSuggestedFilters = [],
    coverage,
    timeline,
    summariseBy,
  } = props;

  useEffect(() => {
    dispatch({
      type: SET_CHART_TYPE,
      payload: {
        chartType: 'line',
      },
    });
  }, []);

  return (
    <div className={classes.createReportSection}>
      <CreateQueryTopBar
        setReset={setReset}
        reportObject={reportObject}
        submitReport={submitReport}
      />
      <MetricSetupSection
        setReset={setReset}
        reportObject={reportObject}
        aggregate={aggregate}
        filters={filters}
        sortBy={sortBy}
        havingFilters={havingFilters}
        initialSuggestedFilters={initialSuggestedFilters}
        coverage={coverage}
        timeline={timeline}
        summariseBy={summariseBy}
      />
      <div style={{ marginTop: 'auto' }}>
        <CreateQueryBottomBar
          defaultChartType="line"
          reportObject={reportObject}
          submitReport={submitReport}
        />
      </div>
    </div>
  );
};

export default memo(CreateMetricSection);
