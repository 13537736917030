import lodashMapKeys from 'lodash/mapKeys';
import lodashOmit from 'lodash/omit';
import lodashMapValues from 'lodash/mapValues';
import lodashCloneDeep from 'lodash/cloneDeep';
import { has } from 'lodash';
import {
    METRICS_CHANGED,
    METRICS_RESET,
    CHANGE_METRIC_ALIAS,
    CHANGE_AGGREGATE,
    ADD_METRIC_LINK_REPORT,
    REMOVE_METRIC_LINK_REPORT,
    RESET_ALL_AGGREGATE,
    REMOVE_LINKED_METRIC,
    REMOVE_ALL_LINKED_REPORTS,
    SET_COLUMN_WIDTH_METRICES,
    SET_COLUMN_WIDTH_ALIAS_NAME,
    SET_SAVED_WIDTHS,
    REMOVE_METRIC_KEY,
    UPDATE_DUMMY_DATA,
    INITIALIZE_METRICES } from '../reduxActions/actionNameEnums';

const initialState = {};

const metricReducer = (state: any = initialState, action) => {
    const { type, payload } = action;
    const copyMetrics = lodashCloneDeep(state);
    switch(type){
        case INITIALIZE_METRICES:
        case METRICS_CHANGED:
            const newObject = Object.assign({}, payload);
            const metricObject = lodashMapKeys(newObject, (data, keys) => data.value);
            return metricObject;
        case METRICS_RESET:
            return initialState;
        case CHANGE_METRIC_ALIAS:
            const aliasAddObject = {
                [payload.name]: {
                    ...state[payload.name],
                    alias: payload.value,
                },
            };
            return Object.assign({}, state, aliasAddObject);
        case REMOVE_METRIC_KEY:
            const metricKey = ['operation'];
            payload.forEach((item) => {
                if (state[item.value]) {
                    state[item.value] = lodashOmit({ ...state[item.value] }, metricKey);
                }
            });
            return state;
        case ADD_METRIC_LINK_REPORT:
            const linkedReportObject = {
                [payload.name]: {
                    ...state[payload.name],
                    linkedReport: {
                        reportId: payload.reportId,
                        reportName: payload.reportName,
                        linkedMetrices: payload.linkedMetrices,
                    },
                },
            };
            return Object.assign({}, state, linkedReportObject);
        case REMOVE_METRIC_LINK_REPORT:
            const linkedReportRemovedObject = {
                [payload.name]: lodashOmit({...state[payload.name]}, ['linkedReport']),
            };
            return Object.assign({}, state, linkedReportRemovedObject);
        case REMOVE_LINKED_METRIC:
            const { linkedMetricRemoved } = payload;
            Object.keys(copyMetrics).forEach((metric) => {
                if (copyMetrics[metric]?.linkedReport?.linkedMetrices) {
                    const linkedMetrices = lodashOmit(
                        lodashCloneDeep(copyMetrics[metric].linkedReport.linkedMetrices),
                        linkedMetricRemoved,
                    );
                    copyMetrics[metric].linkedReport.linkedMetrices = linkedMetrices;
                }
            });
            return copyMetrics;
        case REMOVE_ALL_LINKED_REPORTS:
            Object.keys(copyMetrics).forEach((metric) => {
                if (copyMetrics[metric]?.linkedReport) {
                    const updatedMetricObject = lodashOmit(
                        lodashCloneDeep(copyMetrics[metric]),
                        ['linkedReport'],
                    );
                    copyMetrics[metric] = updatedMetricObject;
                }
            });
            return copyMetrics;
        case CHANGE_AGGREGATE:
            const aggregateObject = {
                [payload.name]: {
                    ...state[payload.name],
                    operation: payload.value,
                },
            };
            return Object.assign({}, state, aggregateObject);
        case RESET_ALL_AGGREGATE:
            const newState = { ...state };
            return lodashMapValues(newState, ({operation, ...allKeys}) => allKeys);
        case SET_COLUMN_WIDTH_METRICES:
            return {...payload};
        case SET_COLUMN_WIDTH_ALIAS_NAME:
            payload.forEach(item => {
                const metric = item.metric;
                const columnWidth = item.columnWidth;
                // Check if the metric exists in the state object
                if (has(state,metric)) {
                  // Update the columnWidth and aliasName properties in the state object
                  if(columnWidth){
                    state[metric].columnWidth = columnWidth;
                  }
                  state[metric].aliasName = item.aliasName;
                }
            });
            return state;
        case SET_SAVED_WIDTHS:
            payload.forEach(key => {
                if (state.hasOwnProperty(key)) {
                    state[key].savedMetric = true;
                }
            });
            return state;
        case UPDATE_DUMMY_DATA:
            const updatedState = {...state};
            const { columnName, dummyData, isTextField } = payload;
            const newDummyData = isTextField ? dummyData : { min: dummyData.min, max: dummyData.max };

            updatedState[columnName] = {
                ...updatedState[columnName],
                dummyData: newDummyData,
            };
            return updatedState;
        default:
            return state;
    }
};

export default metricReducer;
