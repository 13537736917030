import React, { memo } from 'react';
import { Switch } from 'antd';

const CustomSwitch = (props) => {
  const { value, onChange, size, switchStyle, disabled } = props;
  return (
    <div className={switchStyle}>
      <Switch checked={value} size={size} disabled={disabled} onChange={onChange} />
    </div>
  );
};

export default memo(CustomSwitch);
