import React, { memo } from 'react';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

const UnderlyingColumnsSection = (props) => {
  const { underlyingMetrics } = props;
  const {
    onRemoveUnderlyingMetrics,
    selectableUnderlyingMetrics,
    selectedUnderlyingMetrics,
    onSelect,
    onChangeAlias,
    reorderUnderlyingMetrics,
  } = underlyingMetrics;
  const filteredColumns = selectedUnderlyingMetrics.filter(
    (metric) => metric.section == null,
  );
  const initialValues = filteredColumns.map((data) => data.value);
  const titleChangeHandlerGenerator = (name) => (value) =>
    onChangeAlias(name, value);
  const selectedReport = useSelector(
    (state: any) => state.reportMetaData.selectedReport,
  );
  const metrics =
    useSelector(
      (state: any) => state.reportConfig.reports?.[selectedReport]?.metrics,
    ) || [];

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...selectedUnderlyingMetrics];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    reorderUnderlyingMetrics(newItems);
  };

  return (
    <SectionPartContainer
      showToAddCustomColumnforLocal={false}
      title={'Underlying Columns'}
      onChangeSelect={onSelect}
      options={selectableUnderlyingMetrics}
      initialValue={initialValues}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {filteredColumns.map((row, index) => {
                const currentMetric = metrics.find(
                  (metric) => metric.value === row.value,
                );
                return (
                  <Draggable
                    key={row.value}
                    draggableId={row.value}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <RowContainer
                          onRemove={() => onRemoveUnderlyingMetrics(row.value)}
                          title={row.alias || row.prettyName}
                          prettyName={row.prettyName}
                          description={row.description}
                          isTitleEditable={true}
                          showLinkReport={false}
                          showLeftPane={false}
                          onTitleChange={titleChangeHandlerGenerator(row.value)}
                        ></RowContainer>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </SectionPartContainer>
  );
};

export default memo(UnderlyingColumnsSection);
