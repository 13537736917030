import React, { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useReportTable } from '../../reports/hooks/tableDataHook';
import config from 'src/settings';
import { ReportTableFilters, ReportsTable } from './reportTableUtils';

const useStyle = createUseStyles({
  pagination: {
    display: 'flex',
    justifyContent: 'right',
    marginRight: '40px',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px 24px 0 24px',
  },
});

const StepOne = (props) => {
  const { setLinkedReport, setSelectedReportId, selectedReportId } = props;
  const classes = useStyle();
  const tableData: any = useReportTable();
  const selectedReportType = useSelector(
    (state: any) => state.config.selectedReportType,
  );
  const [type, setType] = useState(selectedReportType || 'all');
  const reports = tableData?.tableData;
  const { setOffSet, reportCount, offSet, searchText: search } = tableData;
  const setTypeState = (value) => {
    setType(value);
    if (value === 'all') {
      tableData.setType(['user', 'default', 'shared']);
    } else {
      tableData.setType([value]);
    }
    tableData.setOffSet(1);
  };
  useEffect(() => {
    setTypeState(selectedReportType);
    tableData.setDateRange(undefined);
    tableData.setCreatedBy([]);
    tableData.setSearch('');
  }, [selectedReportType]);

  return (
    <div className={classes.tableContainer}>
      <ReportTableFilters tableData={tableData} handleTypeChange={setTypeState} search={search} type={type} />
      <ReportsTable
        tableData={tableData}
        setLinkedReport={setLinkedReport}
        reports={reports}
        setSelectedReportId={setSelectedReportId}
        selectedReportId={selectedReportId}
      />
      <div className={classes.pagination}>
        <Pagination
          total={reportCount}
          current={offSet}
          onChange={(page) => setOffSet(page)}
          showQuickJumper
          pageSize={config.REPORT_TABLE_ROWS_COUNT}
          size="small"
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default StepOne;
