import { DatePicker, Input, Menu, Button, Space } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
const { RangePicker } = DatePicker;
import { quickSelectionsOptions, handleQuickSelection} from 'src/components/common/utils/quickSelectionUtils';

const useStyle = createUseStyles({
    select: {
        width: '50%',
    },
    quickSelectionContainer: {
      marginTop: '20px',
    },
    quickSelectionButton: {
      border:'0.5px solid transparent',
      borderColor:'#1890FF',
      backgroundColor: '#e6f7ff',
      color: '#1890FF',
    },
});

const RangePick = (props) => {
  const classes = useStyle();
  const { onChange, value , quickSelection, showTime = false, dateFormat = 'YYYY-MM-DD', style} = props;
  const [selectedDateRange, setSelectedDateRange] = useState<any>();
  const isTabletOrMobile = useSelector((state: any) => state.config.isTabletOrMobile);

  const onRangeChange = (data) => {
    if (data?.length >= 1) {
      const updatedDateFormat =
        dateFormat === 'YYYY-MM-DD HH:mm' ? 'YYYY-MM-DD HH:mm:ss' : dateFormat;
      onChange({
        from: data[0].format(updatedDateFormat),
        to: data[1].format(updatedDateFormat),
      });
      setSelectedDateRange(data);
    }
  };

  const renderExtraFooter = () => {
    const pairs = quickSelectionsOptions.reduce((result, selection, index) => {
      index % 2 === 0 ? result.push([selection]) : result[result.length - 1].push(selection);
      return result;
    }, []);

    return (
      <div className={classes.quickSelectionContainer}>
        <Space wrap align="start">
        {pairs.map(pair => (
          <div style={{ display: isTabletOrMobile ? 'inline-grid' : 'block', gap: isTabletOrMobile ? '0.5rem' : 0 }}>
            {pair.map(selection => (
              <Button
                className={classes.quickSelectionButton}
                style={{ marginRight: isTabletOrMobile ? '10px' : '20px' }}
                onClick={() => handleQuickSelection(selection.value, onRangeChange, setSelectedDateRange)}
              >
                {selection.label}
              </Button>
            ))}
          </div>
        ))}
        </Space>
      </div>
    );
  };

  let rangeValue: any = value?.value
    ? [moment(value.value.from), moment(value.value.to)]
    : null;

  if (selectedDateRange) {
    rangeValue = selectedDateRange;
  }
  return (
    <div>
      <RangePicker
        style={style}
        format = {dateFormat}
        showTime = {showTime}
        value ={rangeValue}
        onChange={onRangeChange}
      />
      <div>{quickSelection ? renderExtraFooter() : null}</div>
    </div>
  );
};

export default RangePick;
