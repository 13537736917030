import React from 'react';
import { Spin } from 'antd';
import { createUseStyles } from 'react-jss';
import { LoadingOutlined } from '@ant-design/icons';

const useStyles = createUseStyles({
    loadingStyle: {
      display:'flex',
      width:'100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

const LoadingComponent = (props) => {
    const { style } = props;
    const classes = useStyles();
   return <>
   <div style={style} className={classes.loadingStyle}>
            <Spin indicator={<LoadingOutlined style={{ ...(style || {fontSize: '24px'}), color:'#24538D' }} spin />} size="large" />
  </div></>;
};

export default LoadingComponent;
