import lodashUnset from 'lodash/unset';
import lodashForOwn from 'lodash/forOwn';
import lodashMapKeys from 'lodash/mapKeys';
import lodashOmit from 'lodash/omit';
import lodashCloneDeep from 'lodash/cloneDeep';

import { FILTER_DATA_CHANGED,
    FILTER_SELECTED_VALUE_CHANGED,
    FILTER_DATA_REMOVED,
    CUSTOM_FILTER_DATA_CHANGED,
    FILTER_SELECTED_QUERY_CHANGED,
    FILTER_SELECTED_TYPE_CHANGED,
    FILTER_DATA_RESET,
    FILTER_RADIO_SELECTED,
    INITIALIZE_FILTERS, FILTER_TYPE,
    FILTER_GEAR_DATA_CHANGED,
} from '../reduxActions/actionNameEnums';

const initialState = {};

const filterReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type){
        case INITIALIZE_FILTERS:
        case FILTER_DATA_CHANGED:
            const defaultFilterSelected = payload.metricData.map(filter => {
                return {
                    filterId: filter.filterId || `${payload.selectedReport}.${filter.value}`,
                    filterPriority: filter.filterPriority || 10,
                    overrideOtherFilters: filter.overrideOtherFilters || false,
                    selectedFilter: payload.filtersMetaData[filter.type][0].name,
                    ...filter,
                };
            });
            const metricfilterObject = lodashMapKeys(defaultFilterSelected, (data, keys) => data.value);
            return metricfilterObject;
        case FILTER_DATA_REMOVED:
            return lodashOmit(state, payload);
        case FILTER_DATA_RESET:
            const baseState = {};
            lodashForOwn(state, (value, key) => {
                if(value.filterMetaData?.required){
                    const newValue = lodashOmit(value, ['data', 'selectedFilter']);
                    baseState[key] = newValue;
                }
            });
            return baseState;
        case FILTER_RADIO_SELECTED:
            const radioChange = {
                [payload.metricsName]: {
                    ...state[payload.metricsName],
                    selectedFilter: payload.selectedFilter,
                    },
            };
            const newState = Object.assign({}, state, radioChange);
            lodashUnset(newState, `${payload.metricsName}.data`);
            return newState;
        case FILTER_SELECTED_VALUE_CHANGED:
            const changeData = {
                [payload.metricsName]: {
                    ...state[payload.metricsName],
                    data: payload.data,
                },
            };
            return Object.assign({}, state, changeData);
        case FILTER_SELECTED_QUERY_CHANGED:
            const changeQueryData = {
                [payload.metricsName]: {
                    ...state[payload.metricsName],
                    sql: payload.sql,
                },
            };
            return Object.assign({}, state, changeQueryData);
        case FILTER_SELECTED_TYPE_CHANGED:
            const typeChange = {
                [payload.metricsName]: {
                        ...state[payload.metricsName],
                        type: payload.type,
                        selectedFilter: payload.filtersMetaData[payload.type][0].name,
                    },
            };
            const typeChangeState = Object.assign({}, state, typeChange);
            lodashUnset(typeChangeState, `${payload.metricsName}.data`);
            return typeChangeState;
        case CUSTOM_FILTER_DATA_CHANGED:
            return lodashCloneDeep(payload);
        case FILTER_TYPE:
            if(!payload.advancedFilterSwitch) {
                delete state[payload.metricsName]?.data?.additionalFiterData;
            }
            const updatedData = {
                [payload.metricsName]: {
                    ...state[payload.metricsName],
                    filterType: payload.filterType,
                    advancedFilterSwitch: payload.advancedFilterSwitch,
                },
            };
            return Object.assign({}, state, updatedData);
        case FILTER_GEAR_DATA_CHANGED:
            const gearData = {
                [payload.metricsName]: {
                    ...state[payload.metricsName],
                    filterId: payload.filterIdValue,
                    filterPriority: payload.filterPriority,
                    overrideOtherFilters: payload.overrideOtherFilters,
                },
            };
            return Object.assign({}, state, gearData);
        default:
            return state;
    }
};

export default filterReducer;
