import React from 'react';
import { Drawer, Input, Form, Space, Button } from 'antd';
import Handlers from './Handlers';
import Tags from './Tags';
import { createUseStyles } from 'react-jss';

const FormItemLabel = Form;

const useStyle = createUseStyles({
  formItemLabel: {
    letterSpacing: '0.08em',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    marginBottom: '1rem',
  },
  textFieldContainer: {
    background: '#f9f9f9',
    padding: '0.75rem 1rem',
    borderRadius: '5px',
  },
});

const DynamicFormatting = (props) => {
  const {
    visible,
    handlers,
    tags,
    handlerDrawerClose,
    onApply,
    title,
    selectedReports,
  } = props;

  const [templateValue, setTemplateValue] = React.useState('');

  const classes = useStyle();
  const templateTextAreaRef = React.useRef<any>();

  return (
    <Drawer
      width={550}
      zIndex={2}
      bodyStyle={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
      title={title}
      placement="right"
      closable
      visible={visible}
      onClose={handlerDrawerClose}
      extra={
        <Space>
          <Button onClick={handlerDrawerClose}>Cancel</Button>
          <Button onClick={() => onApply(templateValue)} type="primary">
            Apply
          </Button>
        </Space>
      }
    >
      <div className={classes.textFieldContainer}>
        <FormItemLabel className={classes.formItemLabel}>TEXT</FormItemLabel>
        <Input.TextArea
          rows={4}
          placeholder={'Output Text'}
          onChange={(e) => setTemplateValue(e.target.value)}
          value={templateValue}
          ref={templateTextAreaRef}
        />
      </div>
      <Tags
        onSelectTag={(val) => setTemplateValue(val)}
        data={tags}
        templateText={templateValue}
        templateTextAreaRef={templateTextAreaRef}
        selectedReports={selectedReports}
      />
      <Handlers handlers={handlers} />
    </Drawer>
  );
};
export default DynamicFormatting;
