/* eslint-disable no-else-return */
import { useLazyQuery } from '@apollo/client';
import { message } from 'antd';
import moment from 'moment-timezone';
import lodashDebounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { SAVE_SCHEDULER, SEND_TEST_EMAIL } from '../reportsQuery';
import {
  GET_DASHBOARD_GROUP,
  SEARCH_EMPLOYEE_BY_EMAIL,
} from '../../../../api/routes';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { validateAlertsAndSchedulersParams } from '../utils';
import {
  getDGTemplateMetaData,
  getReportTemplateMetaData,
  showcaseTemplateData,
  saveTemplateData,
  getProjectName,
} from '../../../common/utils/utils';
import {
  mailTypes,
  entityTypes,
  frequencyTypes,
  weekdays,
  intervals,
} from '../../../../constants';
import { useSelector } from 'react-redux';
import { GET_REPORT_BY_ID } from '../../dashboard/hooks/dashboardQueries';

const {
  TEXT: TEXT_MAIL_TYPE,
  CXO: CXO_MAIL_TYPE,
  MJML: MJML_MAIL_TYPE,
} = mailTypes;
const { REPORT, DASHBOARD_GROUP, CXO: CXO_ENTITY } = entityTypes;
const { YEAR, MONTH, DAY, WEEK, HOUR } = frequencyTypes;
const { DAILY, WEEKLY_DAY, MONTHLY_DAY, YEARLY_DAY, WEEKLY_WEEKDAYS, CUSTOM } =
  intervals;
const initialUsers = [];

export const useScheduler = (
  editMetaData: any = null,
  onCreateCallBack = () => {},
) => {
  const [visible, setVisible] = useState(false);
  const [entityData, setEntityData] = useState<any>({});
  const { entityId, entityType } = entityData;
  const openDrawer = (record) => {
    setEntityData(record);
    setVisible(true);
  };
  const onClose = () => setVisible(false);
  const data = useSchedulerData(
    entityId,
    entityType,
    editMetaData,
    setVisible,
    onCreateCallBack,
    visible,
  );
  return {
    openDrawer,
    visible,
    setVisible,
    onClose,
    data,
    entityType,
  };
};

export const useSchedulerData = (
  entityId,
  entityType,
  editMetaData,
  setVisible,
  onCreateCallBack,
  visible,
) => {
  const timeZone = useSelector(
    (state: any) => state?.masterData?.organisationConfig?.timezone,
  );
  const [schedulerId, setSchedulerId] = useState(undefined);
  const [title, setTitle] = useState('');
  const [subjects, setSubjects] = useState('');
  const [summary, setSummary] = useState('');
  const [emailRecipients, setEmailRecipients] = useState('');
  const [messageinfo, setMessage] = useState('');
  const [toEmails, setToEmails] = useState([]);
  const [mailType, setMailType] = useState(TEXT_MAIL_TYPE);
  const [showcaseTemplate, setShowcaseTemplate] = useState({});
  const [saveTemplate, setSaveTemplate] = useState('');
  const [startDate, setStartDate] = useState(moment().add(1, 'days'));
  const [startTime, setStartTime] = useState(moment().add(1, 'hours'));
  const [startTimestamp, setStartTimestamp] = useState(
    moment().add(1, 'days').add(1, 'hours'),
  );
  const [repeat, setRepeat] = useState(1);
  const [frequencyData, setFrequencyData] = useState({});
  const [frequency, setFrequency] = useState(DAY);
  const [selectedInterval, setSelectedInterval] = useState(DAILY);
  const [endTimestamp, setEndTimestamp] = useState<any>('');
  const [status, setStatus] = useState('on');
  const [isActive, setIsActive] = useState(true);
  const [fromToRange, setFromToRange] = useState({});
  const [selectedEntityList, setSelectedEntityList] = useState([]);
  const [
    selectedEntityListForSubjectAndSummary,
    setSelectedEntityListForSubjectAndSummary,
  ] = useState([]);
  const [dashboardReports, setDashboardReports] = useState<any>([]);
  const [handlers, setHandlers] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [ccEmails, setCCEmails] = useState([]);
  const [employeeSearchQuery, setEmployeeSearchQuery] = useState('');

  const [
    getDashboardGroupMetaData,
    { loading: dashboardGroupfetchInProgress, data: dashboardGroupData },
  ] = useLazyAPI(GET_DASHBOARD_GROUP, {
    type: 'GET',
    onCompleted: (data) => {
      const templateData = getDGTemplateMetaData(data, mailType);
      setSaveTemplate(JSON.stringify(saveTemplateData(templateData)));
      setShowcaseTemplate(showcaseTemplateData(templateData));
    },
    onError: (data) => {
      message.error({
        key: 'dashboardMetaDataStatus',
        duration: 0.5,
        content: 'Failed fetching Dashboard Meta Data',
      });
    },
  });

  const [getEmailsForEmps, { loading: loadingEmployeeEmails }] = useLazyAPI(
    SEARCH_EMPLOYEE_BY_EMAIL,
    {
      type: 'GET',
      onCompleted: (data) => {
        setEmployeeOptions(
          data.map((option) => {
            return {
              name: option.email,
              value: option.empId,
              label: option.name,
            };
          }),
        );
      },
      onError: (data) => {
        message.error({
          key: 'getEmailsForEmps',
          duration: 0.5,
          content: 'Failed fetching employees data',
        });
      },
    },
  );

  const onDropdownVisibleChange = (visible) => {
    setEmployeeOptions([]);
    setEmployeeSearchQuery('');
    // handling a case where user clicks on the input box and from the previous search the employee search query was empty
    // so in that case useEffect for employee search query will not be called
    if (visible && employeeSearchQuery === '') {
      getEmailsForEmps({ variables: { searchQuery: '' } });
    }
  };

  const debouncedSearch = useCallback(
    lodashDebounce((nextValue) => getEmailsForEmps(nextValue), 800),
    [],
  );

  const onEmployeeSearch = (value) => {
    setEmployeeSearchQuery(value);
  };

  const [getReportData] = useLazyQuery(GET_REPORT_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const record = data.getReportById;
      const templateData = getReportTemplateMetaData(record, mailType);
      setSaveTemplate(JSON.stringify(saveTemplateData(templateData)));
      setShowcaseTemplate(showcaseTemplateData(templateData));
    },
    onError: (data) => {
      message.error({
        key: 'dashboarReportStatus',
        duration: 0.5,
        content: 'Failed fetching Report Meta Data',
      });
    },
  });

  const getMessageTemplate = async () => {
    if (entityType === REPORT) {
      await getReportData({
        variables: {
          reportId: entityId,
        },
      });
    } else if (entityType === DASHBOARD_GROUP) {
      await getDashboardGroupMetaData({
        URLQueryPath: entityId,
      });
    } else {
      setSaveTemplate(JSON.stringify({}));
      setShowcaseTemplate({});
    }
  };

  const onChangeScheduleInterval = (value, timestamp) => {
    const dayString = moment(timestamp).format('dddd');
    const dateString = moment(timestamp).date().toString();
    const dateMonthString = moment(timestamp).format('DD/MM');
    if (value !== CUSTOM) {
      setRepeat(1);
    }
    if (value === DAILY) {
      setFrequencyData({});
      setFrequency(DAY);
    } else if (value === WEEKLY_DAY) {
      setFrequency(WEEK);
      setFrequencyData({ dayOfWeek: [dayString] });
    } else if (value === MONTHLY_DAY) {
      setFrequency(MONTH);
      setFrequencyData({ date: [dateString] });
    } else if (value === YEARLY_DAY) {
      setFrequency(YEAR);
      setFrequencyData({ dateMonth: [dateMonthString] });
    } else if (value === WEEKLY_WEEKDAYS) {
      setFrequency(WEEK);
      setFrequencyData({ dayOfWeek: weekdays });
    } else {
      setFrequency(editMetaData?.frequency || DAY);
    }
  };

  useEffect(() => {
    setEmployeeOptions([]); // clear options
    if (
      employeeSearchQuery.length > 2 ||
      (employeeSearchQuery === '' && visible)
    ) {
      debouncedSearch({ variables: { searchQuery: employeeSearchQuery } });
    }
  }, [employeeSearchQuery]);

  useEffect(() => {
    onChangeScheduleInterval(selectedInterval, startTimestamp);
  }, [selectedInterval]);

  useEffect(() => {
    const dayString = moment(startTimestamp).format('dddd');
    const dateString = moment(startTimestamp).date().toString();
    const dateMonthString = moment(startTimestamp).format('DD/MM');
    if (selectedInterval === CUSTOM) {
      if (frequency === HOUR) {
        setFromToRange({
          timeRange: editMetaData?.fromToRange?.timeRange || [],
        });
        setFrequencyData({});
        return;
      } else if (frequency === DAY) {
        setFrequencyData({});
      } else if (frequency === WEEK) {
        setFromToRange({});
        setFrequencyData({ dayOfWeek: [dayString] });
      } else if (frequency === MONTH) {
        setFromToRange({});
        setFrequencyData({ date: [dateString] });
      } else if (frequency === YEAR) {
        setFromToRange({});
        setFrequencyData({ dateMonth: [dateMonthString] });
      }
      setFromToRange({});
    }
  }, [frequency]);

  useEffect(() => {
    if (entityId && entityType) {
      getMessageTemplate();
    }
  }, [mailType]);

  useEffect(() => {
    if (entityId && entityType && visible) {
      getMessageTemplate();
    }
    if (!visible) {
      setSchedulerData(
        '',
        '',
        '',
        TEXT_MAIL_TYPE,
        initialUsers,
        1,
        DAY,
        {},
        DAILY,
        'on',
        true,
        undefined,
        '',
        '',
        [],
        [],
        [],
        [],
      );
    }
  }, [visible]);

  useEffect(() => {
    if (editMetaData) {
      const {
        _id,
        title,
        status,
        isActive,
        mailType,
        toEmails,
        ccEmails,
        recipientsRoles,
        emailMessage,
        emailSubject,
        frequency,
        frequencyData,
        startTimestamp,
        repeat,
        intervalType,
        endTimestamp: savedEndTimestamp,
        timeRange,
        selectedEntityList,
        summary,
        selectedEntityListForSubjectAndSummary,
      } = editMetaData;
      const timestampInOrgTz = moment(new Date(startTimestamp)).tz(timeZone);
      const endTimestampInOrgTz = savedEndTimestamp
        ? moment(new Date(savedEndTimestamp)).tz(timeZone)
        : endTimestamp;
      setSchedulerData(
        title,
        emailSubject,
        emailMessage,
        mailType,
        toEmails,
        repeat,
        frequency,
        frequencyData,
        intervalType,
        status,
        isActive,
        _id,
        toEmails?.map((obj) => obj.email).join(', '),
        summary,
        selectedEntityList,
        recipientsRoles,
        ccEmails?.map((obj) => {
          return {
            label: obj.name,
            value: obj.empId,
            name: obj.email,
          };
        }),
        selectedEntityListForSubjectAndSummary || [],
      );
      setStartTimestamp(timestampInOrgTz);
      setStartDate(timestampInOrgTz);
      setStartTime(timestampInOrgTz);
      setEndTimestamp(endTimestampInOrgTz);
    } else {
      setSchedulerData(
        '',
        '',
        '',
        TEXT_MAIL_TYPE,
        initialUsers,
        1,
        DAY,
        {},
        DAILY,
        'on',
        true,
        undefined,
        '',
        '',
        [],
        [],
        [],
        [],
      );
    }
  }, [editMetaData]);

  const [saveScheduler, { loading: savingScheduler }] = useLazyQuery(
    SAVE_SCHEDULER,
    {
      fetchPolicy: 'network-only',
      onCompleted: async (res) => {
        if (res.saveSchedule.error) {
          message.error({
            key: 'saveScheduler',
            content: res.saveSchedule.error,
          });
          return;
        }
        setVisible(false);
        await onCreateCallBack();
        message.success({
          key: 'saveScheduler',
          content: 'Scheduler Saved successfully',
        });
      },
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors.length) {
          message.error({
            key: 'saveScheduler',
            content: graphQLErrors[0]?.message,
          });
          return;
        }
        message.error({
          key: 'saveScheduler',
          content: 'Failed to Save Scheduler',
        });
      },
    },
  );

  const [sendTestEmail, { loading: sendingTestEmail }] = useLazyQuery(
    SEND_TEST_EMAIL,
    {
      fetchPolicy: 'network-only',
      onCompleted: async (res) => {
        message.success({
          key: 'sendTestEmail',
          content: 'Test Email Sent Successfully!',
        });
      },
      onError: (error: any) => {
        if (error?.networkError?.result?.errors[0]?.message) {
          message.error({
            key: 'saveScheduler',
            content: error?.networkError?.result?.errors[0]?.message,
          });
          return;
        }
        message.error({
          key: 'sendTestEmail',
          content: 'Failed to Send Test Email',
        });
      },
    },
  );

  const setSchedulerData = (
    title,
    subject,
    message,
    mailType,
    toEmails,
    repeat,
    frequency,
    frequencyData,
    selectedInterval,
    status,
    isActive,
    schedulerId,
    emailRecipients,
    summary,
    selectedEntityList,
    recipientsRoles,
    ccEmails,
    selectedEntityListForSubjectAndSummary,
  ) => {
    setTitle(title);
    setSubjects(subject);
    setSummary(summary);
    setMessage(message);
    setMailType(mailType);
    setToEmails(toEmails);
    setRepeat(repeat);
    setFrequency(frequency);
    setFrequencyData(frequencyData);
    setSelectedInterval(selectedInterval);
    setStatus(status);
    setIsActive(isActive);
    setSchedulerId(schedulerId);
    setEmailRecipients(emailRecipients);
    setSelectedEntityList(selectedEntityList);
    setSelectedRoles(recipientsRoles);
    setCCEmails(ccEmails);
    setSelectedEntityListForSubjectAndSummary(
      selectedEntityListForSubjectAndSummary,
    );
  };

  const updateRecipientsData = (scheduleData) => {
    if (getProjectName() === 'logistics') {
      if ([CXO_MAIL_TYPE, TEXT_MAIL_TYPE].includes(scheduleData.mailType)) {
        return { toEmails: [], recipientsRoles: [], ...scheduleData };
      } else {
        return { toEmails: [], ccEmails: [], ...scheduleData };
      }
    } else {
      return scheduleData;
    }
  };
  const getData = () => {
    const ccEmailsArray = ccEmails.map(
      ({ label: name, value: empId, name: email }) => ({ name, empId, email }),
    );
    const scheduleData = {
      _id: schedulerId,
      entityId,
      entityType,
      title,
      status,
      isActive,
      mailType: entityType === CXO_ENTITY ? CXO_MAIL_TYPE : mailType,
      toEmails:
        emailRecipients.length > 0
          ? emailRecipients.split(',').map((email) => ({
              name: '',
              email: email.trim(),
            }))
          : [],
      ccEmails: ccEmailsArray,
      recipientsRoles: selectedRoles,
      emailMessage: entityType === CXO_ENTITY ? undefined : messageinfo,
      emailSubject: subjects,
      timeZone,
      attachmentType: 'csv',
      messageTemplate: saveTemplate,
      startTimestamp: startTimestamp.tz(timeZone, true).utc().toString(),
      repeat,
      frequency,
      frequencyData,
      startDate,
      startTime,
      intervalType: selectedInterval,
      fromToRange,
      endTimestamp: endTimestamp
        ? endTimestamp
        :startTimestamp.add(30, 'days').tz(timeZone, true).utc().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'),
      ...(selectedEntityList.length && {
        selectedEntityList: selectedEntityList.map((report: any) => {
          return {
            entityId: report.id,
            entityType: 'report',
          };
        }),
      }),
      summary,
      ...(selectedEntityListForSubjectAndSummary.length && {
        selectedEntityListForSubjectAndSummary:
          selectedEntityListForSubjectAndSummary.map((report: any) => {
            return {
              entityId: report.id,
              entityType: 'report',
            };
          }),
      }),
    };
    const updatedScheduleData = updateRecipientsData(scheduleData);
    return updatedScheduleData;
  };
  const onCreateScheduler = async () => {
    const data = getData();
    const isUpdate = editMetaData ? true : false;
    const isAllDataSelected = validateAlertsAndSchedulersParams(data, isUpdate);
    if (isAllDataSelected) {
      await saveScheduler({ variables: data });
      message.loading({ key: 'saveScheduler', content: 'Saving Scheduler' });
    }
  };

  const onSendTestEmail = async () => {
    const data = getData();
    const isUpdate = editMetaData ? true : false;
    const isTestEmail = true;
    const isAllDataSelected = validateAlertsAndSchedulersParams(
      { ...data, isTestEmail },
      isUpdate,
    );
    if (isAllDataSelected) {
      await sendTestEmail({ variables: data });
      message.loading({ key: 'sendTestEmail', content: 'Sending Test Email' });
    }
  };

  const onUpdateScheduler = async (data) => {
    await saveScheduler({ variables: data });
    message.loading({ key: 'saveScheduler', content: 'Saving Scheduler' });
  };

  const updateUserDetails = (value, index, key) => {
    const newEmailsString = JSON.stringify(toEmails);
    const newEmailsList = JSON.parse(newEmailsString);
    newEmailsList[index][key] = value;
    setToEmails(newEmailsList);
  };

  const onChangeDate = (value) => {
    let dateString;
    if (!value) {
      const initialDate = moment().add(1, 'days');
      setStartDate(initialDate);
      dateString = moment(initialDate).format('DD/MM/YYYY');
    } else {
      setStartDate(value);
      dateString = moment(value).format('DD/MM/YYYY');
    }
    const timeString = startTime.format('HH:mm');
    const updatedStartTimestamp = moment(
      `${dateString} ${timeString}`,
      'DD/MM/YYYY HH:mm',
    );
    setStartTimestamp(updatedStartTimestamp);
  };

  const onChangeTime = (value) => {
    let timeString;
    if (!value) {
      const initialTime = moment().add(1, 'hours');
      setStartTime(initialTime);
      timeString = initialTime.format('HH:mm');
    } else {
      setStartTime(value);
      timeString = value.format('HH:mm');
    }
    const dateString = moment(startDate).format('DD/MM/YYYY');
    const updatedStartTimestamp = moment(
      `${dateString} ${timeString}`,
      'DD/MM/YYYY HH:mm',
    );
    setStartTimestamp(updatedStartTimestamp);
  };

  const onChangeTimeRange = (values) => {
    if (values) {
      const timeRangeStringList = values.map((value) => value.format('HH:mm'));
      return setFromToRange({ timeRange: timeRangeStringList });
    }
    setFromToRange({});
  };

  return {
    title,
    setTitle,
    timeZone,
    frequency,
    setFrequency,
    subjects,
    setSubjects,
    summary,
    setSummary,
    entityId,
    entityType,
    emailRecipients,
    setEmailRecipients,
    message: messageinfo,
    setMessage,
    onCreateScheduler,
    onSendTestEmail,
    selectedEntityList,
    setSelectedEntityList,
    updateUserDetails,
    toEmails,
    mailType,
    setMailType,
    showcaseTemplate,
    setStartDate,
    startDate,
    setStartTime,
    startTime,
    startTimestamp,
    setStartTimestamp,
    selectedInterval,
    setSelectedInterval,
    repeat,
    setRepeat,
    endTimestamp,
    setEndTimestamp,
    onChangeTime,
    onChangeDate,
    onUpdateScheduler,
    onChangeTimeRange,
    fromToRange,
    dashboardReports,
    setDashboardReports,
    sendingTestEmail,
    handlers,
    setHandlers,
    tags,
    setTags,
    selectedRoles,
    setSelectedRoles,
    employeeOptions,
    ccEmails,
    setCCEmails,
    selectedEntityListForSubjectAndSummary,
    setSelectedEntityListForSubjectAndSummary,
    loadingEmployeeEmails,
    onEmployeeSearch,
    onDropdownVisibleChange,
    employeeSearchQuery,
  };
};
