export const getErrorMessage = (err) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = err.response.data;
    if (responseData && responseData.error) {
      errorMessage = responseData.error;
    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};

export const getErrorMessageForBlob = async (err) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = await new Response(err.response.data).json();
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;
    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};

export const getErrorForBlob = async (err) => {
  let error: any = {};
  if (!err.response) {
    error.message = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = await new Response(err.response.data).json();
    if (responseData && responseData.error) {
      error = responseData.error;
    } else {
      error.message = `Something went wrong\n${err.message}`;
    }
  }
  return error;
};

export const generateQueryString = (paramsArray) => {
  const queryString = paramsArray
    .map(({ key, val, isArray }) => {
      if (isArray) {
        return val
          .map((x) => `${key}=${encodeURIComponent(x.label)}`)
          .filter((elem: any) => elem !== null)
          .join('&');
      }
      if (val || val === 0) {
        return `${key}=${encodeURIComponent(val)}`;
      }
      return null;
    })
    .filter((elem: any) => elem !== null)
    .join('&');
  return queryString;
};

export const generateQueryStringFromObject = (obj) => {
  if (!obj) {
    return '';
  }
  const keys = Object.keys(obj);
  const x = keys.map((key) => {
    return { key, val: obj[key], isArray: Array.isArray(obj[key]) };
  });
  return generateQueryString(x);
};
export const generateObjectFromList = (key, list) => {
  const generatedList: any = [];
  if (list) {
    list.forEach((element) => {
      generatedList.push({ key, val: element });
    });
  }
  return generatedList;
};

export const getFileExtension = (file) => {
  return file.name.split('.').pop();
};
