import { Switch } from 'antd';
import React, { memo, useEffect } from 'react';
import lodashIsEmpty from 'lodash/isEmpty';
import { createUseStyles } from 'react-jss';
import FilterMenu from 'src/components/common/FilterComponent/SingleSelectionMenu';
import RowContainer from 'src/components/common/rowContainer';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import PopOverPane from './popOverPane';
import { useAggregateSwitch } from './useMetricsHook';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { UPDATE_DUMMY_DATA } from 'src/reduxActions/actionNameEnums';
import moment from 'moment';

const useStyles = createUseStyles({
  switchStyle: {
    marginRight: '10px',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  aggregate: {
    width: '50px',
  },
  linkReportContainer: {
    backgroundColor: '#FDF5DB',
    minHeight: '46px',
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0px',
  },
  linkIcon: {
    margin: '0px 12px 0px 12px',
    color: '#006EC3',
  },
  deleteIcon: {
    margin: '0px 12px 0px 12px',
    color: '#FF4D4F',
    cursor: 'pointer',
  },
  deleteIconContainer: {
    marginLeft: 'auto',
  },
  linkReportTitle: {
    color: '#006EC3',
  },
});

const ColumnsSection = (props) => {
    const classes = useStyles();
    const { aggregate, setCustomColumnType } = props;
    const { metricsMetaData, selectedAggregate } = aggregate;
    const isAggregate = useSelector((state:any) => state.reportMetaData.isAggregate);
    const [checked, onChangeChecked] = useAggregateSwitch(isAggregate);
    const filteredColumns = metricsMetaData.selectedMetrics.filter((metric) => metric.section == null);
    const initialValues = filteredColumns.map(data => data.value);
    const titleChangeHandlerGenerator = name => (value) => metricsMetaData.onChangeAlias(name, value);
    const aggregateHandlerGenerator = name => data => metricsMetaData.onChangeAggregate(name, data?.[0].key);
    const selectedReport = useSelector((state:any) => state.reportMetaData.selectedReport);
    const metrics = useSelector((state:any) => state.reportConfig.reports?.[selectedReport]?.metrics) || [];
    const metrices = useSelector((state: any) => state.metrics);
    const columnAggregateSelected = useSelector(
      (state: any) => state.reportMetaData.isAggregated,
    );
    const { enableDummyData, setRenderDataDumpDrawer, setColumnData} = props;
    const dispatch = useDispatch();

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...metricsMetaData.selectedMetrics];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    metricsMetaData.reorderMetrices(newItems);
  };

  const initalDummyDataPayload = (args) => {
    const {columnDataType, columnOperation, columnName, dummyDataRangeFetched} = args;
    const payload = {
      isTextField:
        columnDataType === 'text' && (!columnOperation || columnOperation === 'any'),
      dummyData:
        columnDataType === 'text' && (!columnOperation || columnOperation === 'any')
          ? dummyDataRangeFetched ? dummyDataRangeFetched : ['list_of_words']
          : dummyDataRangeFetched ? dummyDataRangeFetched : {
              min: columnDataType==='timestampz'?moment(moment().subtract(1, 'year').format()) : 1,
              max: columnDataType==='timestampz'?moment(moment().format()) : 100,
            },
      columnName,
    };
    return payload;
  };
  const initialDummyDataDispatch = () => {
    filteredColumns
      .forEach((row, index) => {
        const currentMetric = metrics.find((metric) => metric.value === row.value) || row;
        const operations = currentMetric ? currentMetric.operations : [];
        const optionsValue = operations?.length ?
            operations.find(element => element.value === row.operation) : [];

        const columnDataType = currentMetric?.type;
        const columnOperation = optionsValue?.value;
        const columnName = currentMetric?.value;
        const dummyDataRangeFetched = metrices[columnName]?.dummyData;
        dispatch({
          type: UPDATE_DUMMY_DATA,
          payload: initalDummyDataPayload({columnDataType, columnOperation, columnName, dummyDataRangeFetched}),
        });
      });
  };

  useEffect(() => {
    initialDummyDataDispatch();
  },[filteredColumns.length]);

  const showReportLinkingOption = (row, isRowInAggregate) => {
    return row?.customColumnType === 'local' || columnAggregateSelected
      ? true
      : selectedAggregate.length === 0
      ? false
      : !isRowInAggregate;
  };
    return <SectionPartContainer
                setCustomColumnType={setCustomColumnType}
                showToAddCustomColumnforLocal={selectedAggregate.length > 0 || checked}
                title={'Columns'}
                onChangeSelect={metricsMetaData.onSelect}
                options={metricsMetaData.selectableMetrics}
                initialValue={initialValues}
                onTitleChanges={() => {}}
                showCount
                >
                <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                {filteredColumns
                  .map((row, index) => {
                    const isRowInAggregate = selectedAggregate.some(aggregate => aggregate.value === row.value);
                    const shouldShowRightPane = row?.customColumnType === 'local'? false :
                      selectedAggregate.length === 0 ? checked : !isRowInAggregate;
                    const showLinkReport = showReportLinkingOption(row, isRowInAggregate);
                    const currentMetric = metrics.find((metric) => metric.value === row.value) || row;
                    const operations = currentMetric ? currentMetric.operations : [];
                    const optionsValue = operations?.length ?
                        operations.find(element => element.value === row.operation) : [];
                    const defaultSelection = {value: null, prettyName: 'Select'};
                    return (
                      <Draggable
                        key={row.value}
                        draggableId={row.value}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            snapshot={snapshot}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <RowContainer
                              onRemove={() =>
                                metricsMetaData.onRemoveMetrics(row.value)
                              }
                              isColumn={true}
                              columnDataType={currentMetric?.type}
                              columnOperation={optionsValue?.value}
                              columnName={currentMetric?.value}
                              linkedReport={row.linkedReport}
                              title={row.alias || row.prettyName}
                              prettyName={row.prettyName}
                              description={row.description}
                              isTitleEditable={true}
                              showLinkReport={showLinkReport}
                              setRenderDataDumpDrawer={setRenderDataDumpDrawer}
                              setColumnData={setColumnData}
                              enableDummyData={enableDummyData}
                              onAddLinkedReport={(
                                reportId,
                                reportName,
                                linkedMetrices,
                              ) =>
                                metricsMetaData.onAddLinkedReport(
                                  row.value,
                                  reportId,
                                  reportName,
                                  linkedMetrices,
                                )
                              }
                              showLeftPane={shouldShowRightPane}
                              onTitleChange={titleChangeHandlerGenerator(
                                row.value,
                              )}
                              LeftPaneComponent={PopOverPane}
                              leftPaneProps={{
                                Content: (
                                  <FilterMenu
                                    options={operations}
                                    value={row.operation}
                                    onChange={aggregateHandlerGenerator(
                                      row.value,
                                    )}
                                  />
                                ),
                                value: optionsValue || defaultSelection,
                              }}
                            >
                              {row.linkedReport &&
                              !lodashIsEmpty(row.linkedReport) ? (
                                <div className={classes.linkReportContainer}>
                                  <LinkOutlined className={classes.linkIcon} />
                                  <span className={classes.linkReportTitle}>
                                    {row.linkedReport.reportName}
                                  </span>
                                  <div className={classes.deleteIconContainer}>
                                    <DeleteOutlined
                                      onClick={() =>
                                        metricsMetaData.onRemoveLinkedReport(
                                          row.value,
                                        )
                                      }
                                      className={classes.deleteIcon}
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </RowContainer>

                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    );})}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
                </DragDropContext>
                <div className={classes.switchContainer}>
        {selectedAggregate.length === 0 ?
            <><Switch
                className={classes.switchStyle}
                size="small"
                checked={checked}
                onChange={onChangeChecked} />
                <span style={{ margin: '1rem 0' }}>{'Aggregate'}</span></>: null}
    </div>
    </SectionPartContainer>;
};

export default memo(ColumnsSection);
