import React, { memo, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import ChartPane from 'src/components/common/Charts';
import TopBar from 'src/components/pages/chartBuilder/visualisationPaneCompponents/TopBar';
import VisualisationPaneImagePlaceHolders from 'src/components/pages/chartBuilder/visualisationPaneCompponents/VisualisationImagePlaceFolder';
import lodash from 'lodash';
import { useSubmitReport } from 'src/components/pages/chartBuilder/hooks/submitReportHook';
import { selectNumCompletedTodos } from 'src/components/utils/utils';
import { useChartInteractions } from 'src/components/common/hooks/useChartInterctions';
import ChartInteractionPopOver from 'src/components/common/ChartInteractionPopOver';

const useStyles = createUseStyles({
  container: {
    flexDirection: 'column',
    flex: '1',
    marginLeft: '16px',
  },
  baseCardStyle: {
    borderRadius: '4px 4px 0px 0px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flex: '1',
    height: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  disableSaveMessage: {
    backgroundColor: '#e5f7ff',
    padding: '1rem',
    border: '0.5px solid #006EC3',
    borderRadius: '0.3rem',
    marginBottom: '1rem',
  },
});

const MetricVisualisationPane = (props) => {
  const classes = useStyles();
  const { incidentAlerts } = props;
  const chartRef: any = useRef();
  const updateChartRef = (plot) => {
    if (chartRef) {
      chartRef.current = plot;
    }
  };
  const chartData = useSelector(selectNumCompletedTodos);
  const chartInteractionHook = useChartInteractions();
  const { submitQueryloading, stopQuery } = useSubmitReport();
  return (
    <div className={classes.container}>
      <div className={classes.baseCardStyle}>
        <TopBar
          options={{
            type: 'metric',
            validateObject: true,
            validateTimeline: true,
          }}
          isAlertDisableModalVisible={incidentAlerts?.length > 0}
          tab="metric"
          title={'Metric'}
        />
        {lodash.keys(chartData.chartData).length !== 0 ? (
          <>
            <ChartInteractionPopOver
              chartInteractionHook={chartInteractionHook}
            />
            <ChartPane
              type={chartData.chartType}
              loaded={true}
              fetching={submitQueryloading && !stopQuery}
              chartData={chartData.chartData}
              formData={chartData.formData}
              visualizeMetaData={chartData.visualizeMetaData}
              updateChartRef={updateChartRef}
              chartInteractionHook={chartInteractionHook}
            />
          </>
        ) : (
          <VisualisationPaneImagePlaceHolders />
        )}
      </div>
    </div>
  );
};
export default memo(MetricVisualisationPane);
