import React, { useEffect, lazy } from 'react';
import Routes from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { onMessageRecieved } from 'src/components/common/utils/utils';
import LoadingComponent from './components/common/LoadingComponent';
import ChartBuilder from './components/pages/chartBuilder';
import DownloadModal from './components/common/downloadModal';
import { getProjectName } from 'src/components/common/utils/utils';
import { isMobileViewAllowed } from 'src/components/utils/utils';
const ReportsPage = lazy(() => import('./components/pagesv2/reports'));
import { isMobile } from 'react-device-detect';
const DashboardConsole = lazy(
  () => import('./components/pages/dashboard/dashboardConsole'),
);
import DashboardPage from './components/pages/dashboard';
const SchedulerPage = lazy(
  () => import('./components/pages/alertsAndScheduler/scheduler'),
);
const MetricsPage = lazy(
  () => import('./components/pagesv2/metrics/MetricsConsole'),
);
const IncidentAlerts = lazy(
  () => import('./components/pagesv2/incidentAlerts/IncidentAlertsPage'),
);
const NotFound404 = lazy(() => import('./components/pagesv2/NotFound404'));
import MetricBuilder from './components/pagesv2/metrics/visualisation/MetricsBuilder';
import { initializeTagManager } from './utils/googleAnalytics';
import LandingPage from './components/pagesv2/LandingPage';
import { SET_DEVICE_TYPE } from './reduxActions/actionNameEnums';
const ScheduledEmails = lazy(
  () => import('./components/pagesv2/scheduledEmails/ScheduledEmails'),
);

export const routeMetaData = {
  dashboardPage: {
    prettyName: 'Dashboard',
    route: '/dashboard/:dashboardId',
    component: DashboardPage,
    allowMobile: true,
  },
  dashboardConsole: {
    prettyName: 'Dashboard',
    route: '/dashboard',
    component: DashboardConsole,
    allowMobile: true,
  },
  savedReport: {
    prettyName: 'Reports',
    route: '/reports',
    component: ReportsPage,
    excludedPermissions: ['user'],
  },
  visualizeReport: {
    prettyName: 'Reports',
    route: '/visualize/:reportId',
    component: ChartBuilder,
    excludedPermissions: ['user'],
  },
  visualize: {
    prettyName: 'Visualize',
    route: '/visualize',
    component: ChartBuilder,
    excludedPermissions: ['user'],
  },
  landingPage: {
    route: '/',
    component: LandingPage,
    allowMobile: true,
  },
  // downloadPage:{
  //   prettyName: 'Download',
  //   route: '/dumpDownloads',
  //   component: DownloadStatusPage,
  //   setAsTab: true,
  // },
  scheduler: {
    prettyName: 'Scheduler',
    route: '/:entity/scheduler/:entityId',
    component: SchedulerPage,
  },
  createMetric: {
    prettyName: 'Metric',
    route: '/metric',
    component: MetricBuilder,
  },
  metric: {
    prettyName: 'Metric',
    route: '/metric/:metricId',
    component: MetricBuilder,
  },
  metricsList: {
    prettyName: 'Metrics',
    route: '/metrics',
    component: MetricsPage,
  },
  incidentAlerts: {
    prettyName: 'Alerts',
    route: '/alerts',
    component: IncidentAlerts,
  },
  scheduledEmails: {
    prettyName: 'Scheduled Emails',
    route: '/scheduled-emails',
    component: ScheduledEmails,
    excludedPermissions: ['user'],
  },
  notFound: {
    prettyName: 'Not Found',
    route: undefined,
    component: NotFound404,
    exact: false,
    allowMobile: true,
  },
};

const App = () => {
  const credentialRecieved = useSelector(
    (state: any) => state.config.recievedParentCredential,
  );
  const recievedLandingPage = useSelector(
    (state: any) => state.config.recievedLandingPage,
  );
  const dispatch = useDispatch();
  const projectName = getProjectName();

  // const handleMediaQueryChange = (matches) => {
  //   if(window.innerWidth > 800 && window.innerWidth < 1224){
  //     dispatch({ type: SET_DEVICE_TYPE, payload: isMobile });
  //     return;
  //   }
  //   dispatch({ type: SET_DEVICE_TYPE, payload: matches });
  // };

  // const isTabletOrMobile = useMediaQuery(
  //   { maxWidth: 1224 },
  //   undefined,
  //   handleMediaQueryChange,
  // );

  const setDeviceType = () => {
    if (!isMobileViewAllowed(projectName)) {
      dispatch({ type: SET_DEVICE_TYPE, payload: false });
      return;
    }
    dispatch({ type: SET_DEVICE_TYPE, payload: isMobile });
  };

  useEffect(() => {
    const message = JSON.stringify({
      type: 'LOADED',
      applicationId: 'GENERIC_ANALYTICS',
    });
    window.parent.postMessage(message, '*');
    initializeTagManager();
    setDeviceType();
  }, []);

  useEffect(() => {
    if(credentialRecieved){
      window.parent.postMessage({loaderZIndex:100}, '*');
    }
  },[credentialRecieved]);

  return (
    <div style={{ height: '100%' }}>
      <Router>
        {credentialRecieved ? <LandingPage /> : null}
        {credentialRecieved && recievedLandingPage ? (
          <Routes routeMetaData={routeMetaData} />
        ) : (
          <LoadingComponent style={{ fontSize: '40px' }} />
        )}
        <DownloadModal />
        <Communicator />
      </Router>
    </div>
  );
};

export default App;

const Communicator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const messageEventHandler = (e) => onMessageRecieved(e, history, dispatch);

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => window.removeEventListener('messge', messageEventHandler);
  }, []);

  return <></>;
};
