import { Modal, Select } from 'antd';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { getReportIdsFromReportData } from '../../reports/utils';
const { Option } = Select;
const useStyle = createUseStyles({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    selectStyle: {
        width: '300px',
    },
});
const AddReportModal = (props) => {
    const {
        setSelectedReportsData,
        onAddReport,
        reports,
        addingReport,
        loadingReports,
        visible,
        setVisibility,
        selectedReportsData,
        dashbboardId,
    } = props;

    useEffect(() => setSelectedReportsData([]),[visible]);

    const classes = useStyle();
    return <Modal
        visible={visible}
        onCancel={() => setVisibility(false)}
        title={'Add Reports'}
        confirmLoading={addingReport}
        onOk={() => onAddReport(dashbboardId)}
        okText={'Add Report'}>
        <div className={classes.container}>
            <span>Select reports to add</span>
            <Select
            filterOption={(inputValue, option) => {
                const reportName = (option?.label + '').toLowerCase() || '';
                const searchValue = inputValue.toLowerCase();
                return reportName.startsWith(searchValue);
            }}
            value={getReportIdsFromReportData(selectedReportsData)}
            onChange={(value, options) => setSelectedReportsData(options)}
            mode="multiple" className={classes.selectStyle}
            options = {reports.map((report) => ({label: report.name, value: report._id, type: report.chartType, id: report._id}))}
            />
        </div>
    </Modal>;
};

export default AddReportModal;
