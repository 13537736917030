import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, List, Modal, Select, Switch } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useShareDashboardGroup } from '../hooks/shareDashboardGroup';

const useStyle = createUseStyles({
basePane: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px',
},
  divider: {
    marginTop: '21px',
  },
  empty: {
    marginTop: '40px',
  },
  prettyName: {
    display: 'flex',
    color: '#303030',
    fontSize: '12px',
},
dashboardName: {
  display: 'flex',
  color: '#303030',
  fontSize: '15px',
  marginLeft: '1%',
  marginBottom: '4%',
  width: '100%',
},
OwnerTitle: {
  display: 'flex',
  color: '#303030',
  fontSize: '13px',
  marginLeft: '1%',
  marginBottom: '4%',
},
OwnerName: {
  display: 'flex',
  color: 'rgba(0, 0, 0, 0.45)',
  fontSize: '13px',
  marginRight: '1%',
},
box: {
    display: 'flex',
    marginTop: '16px',
},
shareToggle: {
    marginRight: '8px',
},
shareToggleRow: {
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: '1%',
  marginBottom: '5%',
},
});

const ShareModal = (props) => {
  const classes = useStyle();
  const { modalVisibility, setModalVisibility, sharedDashboardGroupData, dashboardName = '' , owner ='' } = props;
  const { Option } = Select;
  const showShareToCustomerToggle = sharedDashboardGroupData?.isSharableToCustomer || false;
  const showShareToVendorToggle = sharedDashboardGroupData?.isSharableToVendor || false;
  const {
    sharedGroupDashboard,
    empDetailsArray,
    onSelect,
    onDeselect,
    onSearch,
    toggleOrgShare,
    onShare,
    onCancelShare,
    toggleCustomerPortalSharing,
    toggleVendorPortalSharing} = useShareDashboardGroup(sharedDashboardGroupData, setModalVisibility, modalVisibility);
  return (
    <Modal
      title={<div>
        <div style={{display: 'flex' }}>
        <div>Share Dashboard</div>
      </div>
        </div>}
      centered
      width={'45%'}
      visible={modalVisibility}
      onOk={onShare}
      onCancel={onCancelShare}
      footer={[
        <Button type="primary" onClick={onShare}>
          Share
        </Button>,
      ]}
    >
      <div className={classes.dashboardName}>{dashboardName}</div>
      <div className={classes.OwnerTitle}><div className={classes.OwnerName}>{'Owner: '} </div>{owner || ' Shipsy'}</div>
      <div className={classes.shareToggleRow}>
        <div className={classes.prettyName}>
          <Switch
            checked={sharedGroupDashboard.isSharableToAll}
            className={classes.shareToggle}
            onChange={toggleOrgShare}
          />
          {'Make It Public  '}
        </div>
        {showShareToCustomerToggle ? (
          <div className={classes.prettyName} style={{ marginLeft: '5%' }}>
            <Switch
              checked={sharedGroupDashboard.sharedToCustomerPortal}
              className={classes.shareToggle}
              onChange={toggleCustomerPortalSharing}
            />
            {'Share On Customer Portal'}
          </div>
        ) : null}
        {showShareToVendorToggle ? (
          <div className={classes.prettyName} style={{ marginLeft: '5%' }}>
            <Switch
              checked={sharedGroupDashboard.sharedToVendorPortal}
              className={classes.shareToggle}
              onChange={toggleVendorPortalSharing}
            />
            {'Share On Vendor Portal'}
          </div>
        ) : null}
      </div>
    <div className={classes.basePane}>
      <Select
        placeholder={'Add Users'}
        disabled={sharedGroupDashboard.isSharableToAll}
        mode="multiple"
        value={sharedGroupDashboard.sharedToEmployeeDetails ? sharedGroupDashboard.sharedToEmployeeDetails.map(({empId, email, name}) => {
          return {label: email, value: `${empId}_&_${email}_&_${name}`};
        }): []}
        style={{ width: '100%' }}
        onSelect={onSelect}
        onSearch={onSearch}
        onDeselect={onDeselect}
      >
      {
        empDetailsArray.map((empDetails: any) => {
          const {empId, email, name} = empDetails;
          return (<Option value={`${empId}_&_${email}_&_${name}`}>{empDetails.email}</Option>);
        })
      }
      </Select>
      <div>
      <Divider plain />
      <div style={{overflow: 'auto', maxHeight: '250px'}}>
      {/* OWNER LIST */}
      <List
        itemLayout="horizontal"
        dataSource={sharedDashboardGroupData.ownerDetail ? sharedDashboardGroupData.ownerDetail.map((empDetails) => {
          return {title: empDetails.email, description: empDetails.name};
        }): []}
        renderItem={(item: any) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar style={{ backgroundColor: 'rgb(123 133 233)' }} icon={<UserOutlined />} />}
              title={item.title}
              description={item.description}
            />
        <div style={{fontStyle: 'italic'}}>Owner</div>
      </List.Item>
        )}
      />
      {/* SHARED LIST */}
      {sharedDashboardGroupData?.sharedToEmployeeDetails?.length ?
      <List
        itemLayout="horizontal"
        dataSource={sharedDashboardGroupData.sharedToEmployeeDetails ? sharedDashboardGroupData.sharedToEmployeeDetails.map((empDetails) => {
          return {title: empDetails.email, description: empDetails.name};
        }): []}
        renderItem={(item: any) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />}
              title={item.title}
              description={item.description}
            />
        <div style={{fontStyle: 'italic'}}>Viewer</div>
      </List.Item>
        )}
      /> : null}
      </div>
      </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
