import axios from 'axios';
import { getAuthenticationHeaders, logoutUser } from '../components/common/utils/utils';
import config from '../settings';

const { BACKEND_URL } = config;

const apiProvider = axios.create({});

apiProvider.interceptors.request.use((config) => {
  return config;
});

// Add 401 interceptor
apiProvider.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      logoutUser();
    }

    return Promise.reject(error);
  },
);

const apiProviderWithBaseUrl = axios.create({ baseURL: BACKEND_URL });

apiProviderWithBaseUrl.interceptors.request.use((config) => {
  const { headers } = config;
  const newHeaders = getAuthenticationHeaders();
  if (headers) {
    config.headers = { ...headers, ...newHeaders };
  }
  config.headers = newHeaders;
  return config;
});

// Add 401 interceptor
apiProviderWithBaseUrl.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      logoutUser();
    }

    return Promise.reject(error);
  },
);

export { apiProviderWithBaseUrl };

export default apiProvider;
