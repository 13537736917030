import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    character: {
        color: (styles: any) => styles.color || 'black',
    },
});

const getDefaultColor = (character) => {
    if (character === '*') {
        return 'red';
    }

    return 'black';
};

const SpecialCharacter = (props) => {
    const { character, color } = props;
    const requiredColor = color ||  getDefaultColor(character);
    const classes = useStyles({ color: requiredColor });

    return (
        <span className={classes.character}>*</span>
    );
};

export default SpecialCharacter;
