import { Input } from 'antd';
import React from 'react';

const CustomInput = (props) => {
  const { size, style, onChange, value, placeholder = '', disabled = false } = props;
  return (
    <Input
      style={style}
      size={size}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default CustomInput;
