import React, { useState, useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
const { Option } = Select;
import { Divider, Input, Select, Space, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const useStyle = createUseStyles({
  basePane: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px',
    marginBottom: '20px',
  },
  prettyName: {
    lineHeight: '14px',
    letterSpacing: '0px',
    color: '#303030',
    height: '14px',
    marginBottom: '0.5rem',
    fontSize: '12px',
  },
  divider: {
    margin: '0.5rem 0',
  },
  space: {
    padding: '0 0.5rem 0.25rem',
  },
});
const SelectFilter = (props) => {
  let index = 0;
  const classes = useStyle();
  const {
    onChange,
    prettyName,
    value,
    type = 'singleSelect',
    data,
    placeHolder = 'Select',
    size = 'small',
    showSearch = false,
    isModeTags = false,
    style,
    ...rest
  } = props;
  const onChangeSelect = (value) => onChange(value);
  const options = data || [];
  const [items, setItems] = useState(options);
  useEffect(() => {
    setItems(options);
  }, [options]);
  const [name, setName] = useState(value);
  const inputRef = useRef<any>(null);
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const trimmedName = name.trim();

    if (!trimmedName) {
      message.error(`${prettyName} name cannot be empty!`);
      return;
    }

    if (items.includes(trimmedName)) {
      message.error(`${trimmedName} ${prettyName} is already present`);
      return;
    }

    setItems([...items, trimmedName || `New item ${index++}`]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  return (
    <>
      <Select
        getPopupContainer={(trigger) => trigger.parentNode}
        showSearch={showSearch}
        style={style}
        placeholder={placeHolder}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider className={classes.divider} />
            <Space className={classes.space}>
              <Input
                placeholder="Please enter metric type"
                ref={inputRef}
                value={name}
                onChange={onNameChange}
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                Add item
              </Button>
            </Space>
          </>
        )}
        options={items.map((item) => ({ label: item, value: item }))}
        onChange={onChangeSelect}
        value={value}
        filterOption={(input, option) =>
          JSON.stringify(option?.label ?? '')
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        {...rest}
      />
    </>
  );
};

const LabelHandlerComponent = (props) => {
  const classes = useStyle();
  const { prettyName, noLabel = false, mandatory, ...rest } = props;
  const selectProps = { ...props, ...rest };
  return noLabel ? (
    <SelectFilter {...selectProps} />
  ) : (
    <div className={classes.basePane}>
      <span className={classes.prettyName}>
        {prettyName} {mandatory && <span style={{ color: 'red' }}>*</span>}
      </span>
      <SelectFilter {...selectProps} />
    </div>
  );
};

export default LabelHandlerComponent;
