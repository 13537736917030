import React from 'react';
import NumberFilter from './numberFilter';
import TextFilter from './textFilter';
import TimeFilter from './timeFilter';

const SimpleFilterComponentMap = {
    number: NumberFilter,
    text: TextFilter,
    timestampz: TimeFilter,
    date: TimeFilter,
};

export default SimpleFilterComponentMap;
