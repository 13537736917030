import React, { memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import {
  Select,
  Tooltip,
  Modal,
  Divider,
  Menu,
  Button,
  Popconfirm,
} from 'antd';
import lodashValues from 'lodash/values';
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  FORMATTING_SELECT_METRIC,
  FORMATTING_DELETE_METRIC,
  FORMATTING_CONDITION_DELETE,
  FORMATTING_CONDITION_ADD,
} from 'src/reduxActions/actionNameEnums';
import ConditionForm from './ConditionForm';
import ActionForm from './ActionForm';
import { useStyles } from './styles';

const defaultIfAction = [
  {
    actionType: 'backgroundColor',
    value: 'automatic',
    color: '#62BF7F',
    gradientStart: '#FFFFFF',
    gradientEnd: '#FB6A6D',
    noOfBuckets: 6,
  },
  {
    actionType: 'textColor',
    value: 'custom',
    color: '#000',
    gradientStart: '#62BF7F',
    gradientEnd: '#FB6A6D',
    noOfBuckets: 6,
  },
];

const defaultElseIfCondition = [
  {
    actionType: 'backgroundColor',
    value: 'custom',
    color: '#F6F6F6',
    gradientStart: '#62BF7F',
    gradientEnd: '#FB6A6D',
    noOfBuckets: 6,
  },
  {
    actionType: 'textColor',
    value: 'custom',
    color: '#000',
    gradientStart: '#62BF7F',
    gradientEnd: '#FB6A6D',
    noOfBuckets: 6,
  },
];

const defaultThenAction = [
  {
    actionType: 'backgroundColor',
    value: 'custom',
    color: '#F6F6F6',
    gradientStart: '#62BF7F',
    gradientEnd: '#FB6A6D',
    noOfBuckets: 6,
  },
  {
    actionType: 'textColor',
    value: 'custom',
    color: '#000',
    gradientStart: '#62BF7F',
    gradientEnd: '#FB6A6D',
    noOfBuckets: 6,
  },
];

const ListOptions = (props) => {
  const classes = useStyles();
  const { options, onChange, title, style, ...rest } = props;

  return (
    <div>
      <Select
        style={{ width: '100%', display: 'block', ...style }}
        onChange={onChange}
        options={options}
        className={classes.option}
        {...rest}
      />
    </div>
  );
};

const ConditionalFormatting = (props) => {
  const { visible, setVisible } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const supportedFilters = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const rules =
    useSelector((state: any) => state.chartsMetaData.formatting) || [];
  const [showSelect, setShowSelect] = useState(false);
  const [ruleIndex, setRuleIndex] = useState(0);

  const handleRuleIndex = ({ key }) => {
    setRuleIndex(key);
  };

  const columnsMap = useSelector((state: any) => state.metrics);
  const columns = lodashValues(columnsMap);

  const ruleMetrics = rules.map((format) => format.metric);

  const columnOptions = columns
    .map((col) => {
      if (!ruleMetrics.includes(col.value)) {
        if (
          col.type === 'text' &&
          ['min', 'max', 'any', null, undefined].includes(col.operation)
        ) {
          return;
        }
        return {
          ...col,
          label: col.prettyName,
        };
      }
    })
    .filter((condition) => condition);

  const getFilterType = (columnMetaData) => {
    if (columnMetaData?.type === 'text') {
      if (['min', 'max'].includes(columnMetaData.operation)) {
        return columnMetaData.type;
      }
      return 'number';
    }

    if (columnMetaData?.type === 'boolean') {
      if (
        ['count_true', 'count_false', 'count'].includes(
          columnMetaData.operation,
        )
      ) {
        return 'number';
      }
      return 'boolean';
    }

    return columnMetaData?.type || 'number';
  };

  const renderActionButtons = (index) => {
    return (
      <div>
        {index !== rules[ruleIndex].rules.length - 1 && (
          <div className={classes.addButton}>
            <Button
              style={{ borderRadius: '10rem' }}
              icon={<PlusOutlined />}
              onClick={() => addCondition(index)}
            />
          </div>
        )}
        {index !== 0 && index !== rules[ruleIndex].rules.length - 1 && (
          <div className={classes.deleteButton}>
            <Button
              style={{ borderRadius: '10rem' }}
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={() => deleteCondition(index)}
            />
          </div>
        )}
      </div>
    );
  };

  const addCondition = (index) => {
    const rulesCopy = [...rules];
    const columnMetaData = columnsMap[rules[ruleIndex].metric];
    const filterType = getFilterType(columnMetaData);
    const filterListRadio = supportedFilters[filterType] || [];
    const operatorOptions = filterListRadio.map((option) => {
      return {
        ...option,
        key: option.name,
        value: option.name,
        label: option.prettyName,
      };
    });
    rulesCopy[ruleIndex].rules.splice(
      rulesCopy[ruleIndex].rules.length - 1,
      0,
      {
        conditions: [
          {
            columns: [rules[ruleIndex].metric],
            operator: operatorOptions[0].name,
          },
        ],
        actions: defaultElseIfCondition,
      },
    );

    dispatch({
      type: FORMATTING_CONDITION_ADD,
      payload: rulesCopy,
    });
  };

  const deleteCondition = (index) => {
    const rulesCopy = [...rules];
    rulesCopy[ruleIndex].rules.splice(index, 1);

    dispatch({
      type: FORMATTING_CONDITION_DELETE,
      payload: rulesCopy,
    });
  };

  const deleteRule = () => {
    const rulesCopy = [...rules];
    rulesCopy.splice(ruleIndex, 1);

    dispatch({
      type: FORMATTING_DELETE_METRIC,
      payload: rulesCopy,
    });
  };

  const applyRules = () => setVisible(false);

  const flipSelectShow = () => setShowSelect(!showSelect);

  const onSelectMetric = (option) => {
    const columnMetaData = columnsMap[option.value];
    const filterType = getFilterType(columnMetaData);
    const filterListRadio = supportedFilters[filterType] || [];
    const operatorOptions = filterListRadio.map((option) => {
      return {
        ...option,
        key: option.name,
        value: option.name,
        label: option.prettyName,
      };
    });
    const defaultOption = operatorOptions.find(
      (option) => option.label === 'Is not null',
    );

    const rulesCopy = [
      ...rules,
      {
        metric: option.value,
        rules: [
          {
            conditions: [
              {
                columns: [option.value],
                operator: defaultOption
                  ? defaultOption.name
                  : operatorOptions[0].name,
              },
            ],
            actions: defaultIfAction,
          },
          {
            conditions: [],
            actions: defaultThenAction,
          },
        ],
      },
    ];
    flipSelectShow();
    setRuleIndex(rulesCopy.length - 1);
    dispatch({
      type: FORMATTING_SELECT_METRIC,
      payload: rulesCopy,
    });
  };

  return (
    <Modal
      title={'Conditional Formatting'}
      className={classes.modalContainer}
      visible={visible}
      width={1200}
      onOk={() => {}}
      okText={'Apply Rules'}
      onCancel={() => {
        setVisible(false);
      }}
      footer={
        <div>
          <Button type="primary" onClick={applyRules}>
            Apply Rules
          </Button>
        </div>
      }
      bodyStyle={{ height: 500 }}
    >
      <div className={classes.modalWrapper}>
        <div className={classes.menuContainer}>
          <Menu
            className={classes.sideMenu}
            onClick={handleRuleIndex}
            style={{ height: '100%' }}
            defaultActiveFirst
          >
            {rules.map((rule, index) => {
              return (
                <>
                  <Menu.Item key={`${index}`}>
                    <div style={{ display: 'flex' }}>
                      <div>{columnsMap[rule.metric]?.prettyName}</div>
                      <div style={{ marginLeft: 'auto' }}>
                        {' '}
                        <Popconfirm
                          title={`Are you sure to delete ${
                            columnsMap[rules[ruleIndex]?.metric]?.prettyName
                          }?`}
                          onConfirm={deleteRule}
                          okText="Yes"
                          cancelText="Cancel"
                        >
                          <Tooltip placement="right" title={'Delete Metric'}>
                            <DeleteOutlined style={{ color: 'red' }} />
                          </Tooltip>
                        </Popconfirm>
                      </div>
                    </div>
                  </Menu.Item>
                  {index !== rules.length - 1 && (
                    <Divider className={classes.actionDivider} />
                  )}
                </>
              );
            })}
            {columnOptions.length ? (
              <div style={{ margin: '1rem' }}>
                <div style={{ margin: '0.5rem' }}>
                  {showSelect && (
                    <ListOptions
                      placeholder="Select Metric"
                      options={columnOptions}
                      onChange={(_, option) => onSelectMetric(option)}
                    />
                  )}
                </div>
                <Button
                  className={classes.addMetricBtn}
                  onClick={flipSelectShow}
                  icon={<PlusOutlined />}
                >
                  Add New Metric
                </Button>
              </div>
            ) : null}
          </Menu>
        </div>
        {rules[ruleIndex] && (
          <div className={classes.ruleIndexContainer}>
            <div className={classes.ruleIndexWrapper}>
              {rules[ruleIndex].rules.map((rule, index) => {
                return (
                  <div className={classes.ruleContainer}>
                    <ConditionForm
                      rules={rules}
                      index={index}
                      ruleIndex={ruleIndex}
                    />
                    <ActionForm
                      rules={rules}
                      index={index}
                      ruleIndex={ruleIndex}
                    />
                    {renderActionButtons(index)}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!rules.length && (
          <div className={classes.noRulesMessage}>
            <ExclamationCircleOutlined style={{ color: '#006EC3' }} /> No
            conditional formatting rules have been configured. Click on Add New
            Metric to get started.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default memo(ConditionalFormatting);
