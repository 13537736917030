import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Collapse, Button, Select, Tooltip, Popover } from 'antd';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import CustomInput from 'src/components/common/customInput';
import { CHANGE_METRIC_STRING_MANUAL_QUERY, INITIALIZE_AGGREGATE, INITIALIZE_METRICES, SET_IS_AGGREGATED } from 'src/reduxActions/actionNameEnums';
import { generateMetricArray, isValidJSON } from 'src/components/common/utils/utils';
import { useSelector, useDispatch } from 'react-redux';

const { Panel } = Collapse;
const { Option } = Select;

const useStyles = createUseStyles({
    sectionPartContainer:{
        width: '100%',
        marginBottom: '16px',
        border: '1px solid #F1F2F6',
        borderRadius: '4px 4px 0px 0px',
    },
    title: {
        height: '14px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '14px',
        letterSpacing: '0',
        color: '#303030',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '38px',
        paddingLeft: '16px',
        paddingRight: '16px',
        backgroundColor: '#F1F2F6',
    },
    divider:{
        margin: '5px 0 5px 0',
    },
    addButton: {
        height: '24px',
        width: '58px',
        borderRadius: '13px',
        padding: '0',
        borderColor: '#F0F0F0',
        backgroundColor: '#F0F0F0',
    },
    buttonText: {
        height: '12px',
        color: '#1890FF',
        fontSize: '12px',
        letterSpacing: '0',
        lineHeight: '12px',
    },
    select: {
        width: '100%',
    },
    infoTip: {
        marginLeft: '0.3rem',
    },
    asterisk: {
        color: 'red',
    },
    count: {
        backgroundColor: '#1890FF',
        borderRadius: '1rem',
        color: 'white',
        height: '1.1rem',
        width: '1.1rem',
        marginLeft: '0.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.75rem',
    },
});

const SectionPartContainer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showSelect, setShowSelect] = useState(false);
    const metricString = useSelector(
      (state: any) => state.reportMetaData.metricString,
    );
    const {
        title,
        description,
        children,
        options = [],
        initialValue = [],
        onChangeSelect,
        showTitle = true,
        showAddButton = true,
        setCustomColumnType,
        isMandatory,
        showCount = false,
        onAddButtonClick,
        isCustomInput,
    } = props;
    const { showToAddCustomColumnforLocal, selectMode, optionDisabled } = props;
    const lazyUpdate = () => {
      let metricString = '';
      let inside = false;
      return (value) => {
        metricString = value;
        if (inside === false) {
          inside = true;
          setTimeout(() => {
            dispatch({
              type: CHANGE_METRIC_STRING_MANUAL_QUERY,
              payload: {
                metricString,
              },
            });
            if (isValidJSON(metricString)) {
              const metricArray = JSON.parse(metricString);
              const summarizeArray = metricArray.filter(
                ({ groupBy }) => !!(groupBy && groupBy.toApply),
              );
              const metricObjectArray = generateMetricArray(metricArray);
              const summarizeObjectArray = generateMetricArray(summarizeArray);
              dispatch({
                type: INITIALIZE_AGGREGATE,
                payload: summarizeObjectArray,
              });
              dispatch({
                type: INITIALIZE_METRICES,
                payload: metricObjectArray,
              });
              const allMetricsHasOperation = metricObjectArray.length && metricObjectArray.every(metricObject => metricObject.operation);
              if(!summarizeObjectArray.length && allMetricsHasOperation){
                dispatch({ type: SET_IS_AGGREGATED, payload: true });
              } else {
                dispatch({ type: SET_IS_AGGREGATED, payload: false });
              }
            }
            inside = false;
          });
        }
      };
    };
    const flipSelectShow = () => setShowSelect(!showSelect);
    return  <div className={classes.sectionPartContainer}>
                { showTitle && <><div className={classes.titleContainer}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className={classes.title}>{title} {isMandatory && <span className={classes.asterisk}> *</span>}</span>
                    {description && <Tooltip placement="right" title={description}><InfoCircleOutlined className={classes.infoTip} /></Tooltip>}
                    {showCount && initialValue.length ? <div className={classes.count}>{initialValue.length}</div> : null}
                    </div>
                    {showAddButton && !isCustomInput ? (
                        <Button
                            onClick={onAddButtonClick || flipSelectShow}
                            className={classes.addButton}
                        >
                        <span className={classes.buttonText}>
                            <PlusOutlined /> Add
                        </span>
                        </Button>
                    ) : null}
                    {showAddButton && isCustomInput ? (
                        <Popover
                            content={
                            <div style={{ width: '400px' }}>
                                <CustomInput
                                    onChange={lazyUpdate()}
                                    value={metricString}
                                    placeholder={'Please Input Columns'}
                                    isCodeEditor={true}
                                    width={'100%'}
                                    highlightActiveLine={false}
                                    theme={'monokai'}
                                />
                            </div>
                            }
                            placement="right"
                            trigger="click"
                            visible={showSelect}
                            onVisibleChange={flipSelectShow}
                        >
                        <Button
                          onClick={onAddButtonClick || flipSelectShow}
                          className={classes.addButton}
                        >
                          <span className={classes.buttonText}>
                            <PlusOutlined /> Add
                          </span>
                        </Button>
                      </Popover>
                    ) : null}
                </div>
               </>}
                {showSelect && !isCustomInput ?
                 <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(a,b) => {
                        onChangeSelect(a,b);
                    }}
                    autoFocus={true}
                    filterOption={(a, b: any) => {
                        if(b !== undefined && b?.children !== undefined &&
                            b?.children?.props !== undefined && typeof b?.children?.props?.title === 'string'){
                            const dataInLowerCase = (b?.children.props.children + '').toLowerCase();
                            const pos = dataInLowerCase.search(a.toLowerCase());
                            return pos !== -1;
                        }
                        return true;
                    }}
                    defaultValue={initialValue}
                    className={classes.select}
                    defaultOpen={true}
                    mode={'multiple'}
                    onDropdownVisibleChange={setShowSelect}>
                    {showToAddCustomColumnforLocal && <Option disabled={true} value="forcustomcolumn">
                        <Button onClick={() => {setCustomColumnType('local');}} style={
                        {padding:'0', color: '#006EC3'}} type="link">+ Add Custom Fields</Button>
                        <Divider style={{margin: '5px 0', height:'1px'}} type="horizontal"/>
                    </Option>}
                    {options.map(option => <Option title={option.description} value={option.value} disabled={optionDisabled}>
                        <Tooltip placement="right" title={option.description ? option.description : option.prettyName}>
                        {option.prettyName}
                        </Tooltip>
                        </Option>)}
                 </Select>: null}
                 <div style={{ width: '100%', height: '100%', paddingLeft: '16px', paddingRight: '16px'}}>
                 {children}
                 </div>
            </div>;
};

export default SectionPartContainer;
