import React, { EffectCallback, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ChartConfigCollapse from './chartConfigCollapse';
import Configs from './configChartType';
import { createUseStyles } from 'react-jss';
import { selectNumCompletedTodos } from 'src/components/utils/utils';

const useStyles = createUseStyles({
  selectContainer: {
    flex: '0 0 80%',
  },
  sectionPartContainer: {
    width: '100%',
    marginBottom: '16px',
    border: '1px solid #F1F2F6',
    borderRadius: '4px 4px 0px 0px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '38px',
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: '#F1F2F6',
  },
  title: {
    height: '14px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '14px',
    letterSpacing: '0',
    color: '#303030',
  },
  chartConfig: {
    width: '126%',
  },
});

const ChartConfig = () => {
  const classes = useStyles();
  const chartOptions = useSelector(
    (state: any) => state.chartsMetaData.chartOptions,
  );
  const { chartData, formData } = useSelector(selectNumCompletedTodos);
  const selectedChart = useSelector(
    (state: any) => state.chartsMetaData.chartType,
  );
  const configs = Configs(
    formData.x_axis_label,
    formData.y_axis_label,
    selectedChart === 'bar' &&
      chartOptions['xAxis']?.barType?.data === 'horizontal'
      ? 'horizontal'
      : undefined,
  );
  const config = configs[selectedChart];

  return (
    <div className={classes.sectionPartContainer}>
      <div className={classes.titleContainer}>
        <span className={classes.title}>Chart Config</span>
      </div>
      <div className={classes.chartConfig}>
        <div>
          {Object.entries(config).map(([key, value]: [any, any]) => (
            <ChartConfigCollapse
              prettyName={value.prettyName}
              name={value.name}
              child={value.config}
              type={value.type}
              defaultValue={value.defaultValue}
              section={selectedChart}
              selectedChart={selectedChart}
              collapseStyle={classes.selectContainer}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(ChartConfig);
