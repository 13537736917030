import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import lodashPick from 'lodash/pick';
import { useLazyQuery } from '@apollo/client';
import { message } from 'antd';
import { SET_DASHBOARD_POSITION } from './dashboardQueries';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { SET_DASHBOARD_TABS } from 'src/api/routes';
import { useSelector, useDispatch } from 'react-redux';
import { SET_DASHBOARD_METADATA } from 'src/reduxActions/actionNameEnums';
import { LAYOUT_VIEW_TYPES } from 'src/constants';

const useLayout = (reportData, dashboardId, selectedLayoutView = LAYOUT_VIEW_TYPES.DESKTOP) => {
    const [canChangeLayout, setCanChangeLayout] = useState(false);
    const [processedlayout, setProcessedLayout] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [idMapping, setIdMapping] = useState({});
    const [prepareLayoutToSave, setPrepareLayoutToSave] = useState<any>([]);
    const isTabletOrMobile = useSelector((state: any) => state.config.isTabletOrMobile);
    const dashboardGroupMetaData = useSelector((state: any) => state.dashboardGroupMetaData.metadata);
    const dashboardTab = useSelector((state: any) => state.dashboardGroupMetaData.currentDashboardGroupTab);
    const reportPaneHeights = useSelector((state: any) => state.dashboardGroupMetaData.reportPaneHeights);
    const dashboardReportsChartConfigs = useSelector((state: any) => state.dashboardGroupMetaData.dashboardReportsChartConfigs);
    const dashboardGroupId = dashboardGroupMetaData.id;

    const dispatch = useDispatch();

    const [editPositions, { loading:editingPosition }] = useLazyQuery(SET_DASHBOARD_POSITION, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            const updatedDashboardGroupMetaData = { ...dashboardGroupMetaData };
            updatedDashboardGroupMetaData?.dashboards[dashboardTab].dashboardVisualisations.reports.map((report) => {
                const newReport = prepareLayoutToSave.find(newReport => newReport.reportId === report.reportId);
                if (newReport) {
                    report.position = newReport.position;
                    report.mobilePosition = newReport.mobilePosition;
                }
            });
            dispatch({
                type: SET_DASHBOARD_METADATA,
                payload: updatedDashboardGroupMetaData,
            });
            message.success({ key: 'editReportPosition', content: 'Report Positions Changed' });
        },
        onError: () => {
            message.error({ key: 'editReportPosition', content: 'Failed to change Report position' });
        },
      });

    const [setDashboardTabs, { loading:settingDashboardTabs }] = useLazyAPI(SET_DASHBOARD_TABS, {
        type: 'POST',
        onCompleted: () => {
            message.success({ key: 'saveTabsPosition', content: 'Dashboard Tabs Position Changed' });
        },
        onError: () => {
            message.error({ key: 'saveTabsPosition', content: 'Failed to save tabs position' });
        },
    });

    useEffect(() => {
        const resetStatic:any = processedlayout.map((layout:any) => {
            return {
                ...layout,
                static: !canChangeLayout,
            };
        });
        setProcessedLayout(resetStatic);
    }, [canChangeLayout]);

    useEffect(() => {
        const layoutArr:any = [];
        const chartDataArr:any = [];
        const data = reportData || [];
        const idMap = {};
        let currentHeight = 0;
        const isMobilePositionSet = data.some((chart) => chart.mobilePosition);
        const desktopNewPosition = { x: 0, y: 0, w: 8, h: 10 };
        data.forEach((chart, index) => {
            idMap[chart.reportId] = chart.reportId;
            let height = chart.chartType === 'number' ? 5 : 10;
            if (chart.chartType === 'number') {
              const reportChartConfig = dashboardReportsChartConfigs
                ? dashboardReportsChartConfigs[chart.reportId]
                : {};
              const labelPresent =
                reportChartConfig?.graphic?.label?.data ?? true;
              if (reportPaneHeights && reportPaneHeights[chart.reportId]) {
                height = labelPresent
                  ? Math.max(
                      (reportPaneHeights[chart.reportId] - 1) * 2 + 5,
                      height,
                    )
                  : Math.max(
                      (reportPaneHeights[chart.reportId] - 1) * 1.5 + 4,
                      height,
                    );
              }
              if (
                reportPaneHeights &&
                reportPaneHeights[chart.reportId] === 1
              ) {
                height = labelPresent ? 4 : 3.5;
              }
            }
            const tabletOrMobileNewPosition = isMobilePositionSet
              ? desktopNewPosition
              : { x: 0, y: currentHeight, w: 12, h: height };
            const tabletOrMobilePosition =
              chart.mobilePosition || tabletOrMobileNewPosition;
            const desktopPosition = chart.position || desktopNewPosition;
            currentHeight += height;
            layoutArr.push({
              position: desktopPosition,
              mobilePosition: tabletOrMobilePosition,
              static: !canChangeLayout,
              i: chart.reportId,
            });
            chartDataArr.push({ ...chart, id: chart.reportId });
        });
        setProcessedLayout(layoutArr);
        setChartData(chartDataArr);
        setIdMapping(idMap);
    }, [reportData.length, dashboardId, selectedLayoutView, ...(isTabletOrMobile ? [reportPaneHeights] : [])]);
    const onChangeLayout = (layouts) => {
        const newLayout = layouts.map((layoutObject) => {
            const lastLayoutObject: any = processedlayout.find(
                (layout: any) => layout.i === layoutObject.i,
            ) || {};
            const newPositionObject = lodashPick(layoutObject, ['x', 'y', 'w', 'h']);
            const newLayoutObject = {
              static: layoutObject.static,
              i: layoutObject.i,
              position:
                selectedLayoutView === LAYOUT_VIEW_TYPES.DESKTOP
                  ? newPositionObject
                  : lastLayoutObject.position,
              mobilePosition:
                selectedLayoutView === LAYOUT_VIEW_TYPES.MOBILE
                  ? newPositionObject
                  : lastLayoutObject.mobilePosition,
            };
            return newLayoutObject;
        });
        setProcessedLayout(newLayout);
    };

    const onChangeAllowLayoutChanges = async (value) => {
        if (!value) {
            const prepareLayoutToSave = processedlayout.map((layout:any) => {
                const layoutObject = {
                    reportId: idMapping[layout.i],
                    position: layout.position,
                    mobilePosition: layout.mobilePosition,
                };
                return layoutObject;
            });
            setPrepareLayoutToSave(prepareLayoutToSave);
            message.loading({
              key: 'editReportPosition',
              content: 'Updating Report Positions',
            });
            await editPositions({
              variables: {
                reportPositions: prepareLayoutToSave,
                dashboardId,
              },
            });

            const dashboardTabIds = dashboardGroupMetaData.dashboards.map(dashboard => dashboard?.dashboardVisualisations?.dashboard?._id);
            message.loading({
              key: 'saveTabsPosition',
              content: 'Updating Dashboard Tabs Position',
            });
            await setDashboardTabs({variables:{
                dashboardTabsPosition: dashboardTabIds,
                dashboardGroupId,
            }});
        }
    };

    return {
      chartData,
      layout: processedlayout.map(
        ({ position, mobilePosition, ...rest }: any) => {
          const layoutPosition: any =
            selectedLayoutView === LAYOUT_VIEW_TYPES.MOBILE
              ? mobilePosition
              : position;
          return {
            ...layoutPosition,
            ...rest,
          };
        },
      ),
      canChangeLayout,
      onChangeAllowLayoutChanges,
      onChangeLayout,
      editingPosition,
      setCanChangeLayout,
      settingDashboardTabs,
    };
};
export default useLayout;
