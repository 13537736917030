import * as React from 'react';
import lodash from 'lodash';
import classNames from 'classnames';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Menu, Input, Row } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  tagsContainer: {
    marginTop: '15px',
  },
  mainMenu: {
    background: '#f9f9f9',
    padding: '0.75rem 1rem',
    borderRadius: '4px',
  },
  tagBuckets: {
    border: '1px solid #d3d3d3',
    height: '255px',
    overflow: 'scroll',
  },
  tagContent: {
    background: 'white',
    border: '1px solid #d3d3d3',
  },
  searchCol: {
    marginLeft: 'auto',
  },
  overflowScroll: {
    maxHeight: '250px',
    overflow: 'scroll',
    fontSize: '12px',
    padding: '0px 10px 10px 10px',
  },
  padding10px: {
    padding: '10px',
  },
  tagTextDecoration: {
    textDecoration: 'underline',
  },
  verticalAlign: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
  },
  tagsTitle: {
    letterSpacing: '0.08em',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  noTagsFound: {
    color: 'rgba(0, 0, 0, 0.45)',
    margin: '0.5rem',
  },
  addButton: {
    border: 'none',
    boxShadow: 'none',
  },
});

const Tags = (props) => {
  const classes = useStyle();
  const {
    selectedReports,
    data,
    templateText: textValue,
    onSelectTag,
    templateTextAreaRef,
  } = props;
  const [tagHover, setTagHover] = React.useState('');
  const placeholder: any = {
    placeholderData: [],
    tagCategories: [],
  };
  const [searchTag, setSearchTag] = React.useState('');

  const getMatchingReports = (keyword = '') => {
    return data.filter((obj) =>
      Object.keys(obj).some((key) => {
        if (keyword) {
          return selectedReports.some(
            (item) => item.label === key && key.toLowerCase().includes(keyword),
          );
        }

        return selectedReports.some((item) => item.label === key);
      }),
    );
  };

  const [matchingReports, setMatchingReports] = React.useState(
    getMatchingReports(),
  );

  React.useEffect(() => {
    setMatchingReports(getMatchingReports());
  }, [selectedReports]);

  const handleAddToTextArea = (text) => {
    if (!templateTextAreaRef) return;
    let startIndex = null;

    startIndex = lodash.get(
      templateTextAreaRef,
      'current.resizableTextArea.textArea.selectionStart',
      0,
    );
    let templateText = textValue;
    templateText =
      templateText?.substring(0, startIndex) +
      text +
      templateText?.substring(startIndex);

    onSelectTag(templateText);
  };

  const handleTagHover = (key) => {
    setTagHover(key);
  };

  const [selectedItemKey, setSelectedItemKey] = React.useState<any>(
    data?.[0] ? Object.keys(data[0])[0] : '',
  );

  const [selectedItemMetrics, setSelectedItemMetrics] = React.useState<any>(
    data?.[0] ? data[0][Object.keys(data[0])[0]]?.metrics : [],
  );

  const handleMenuItemClick = (e) => {
    setSelectedItemKey(e.key);
    const selectedItem = data?.find((elem) => elem.hasOwnProperty(e.key));
    if (selectedItem) {
      setSelectedItemMetrics(selectedItem[e.key].metrics);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTag(value);
    setMatchingReports(getMatchingReports(value));
  };

  return (
    <div className={classes.mainMenu}>
      <Row>
        <Col span={10} className={classes.tagsTitle}>
          TAGS
        </Col>
        <Col span={14} className={classes.searchCol}>
          <Input.Search
            placeholder="Search Reports"
            value={searchTag}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>
      {matchingReports?.length ? (
        <Row className={classes.tagsContainer}>
          <Col span={10}>
            <Menu className={classes.tagBuckets} selectedKeys={selectedItemKey}>
              {matchingReports?.map((item) => {
                return Object.entries(item).map(([key, value]) => {
                  return (
                    <Menu.Item key={key} onClick={handleMenuItemClick}>
                      {key}
                    </Menu.Item>
                  );
                });
              })}
            </Menu>
          </Col>
          <Col span={14} className={classes.tagContent}>
            {selectedItemMetrics.map((elem, index) => {
              return (
                <Row key={index} className={classes.padding10px}>
                  <Col
                    span={12}
                    className={classNames({
                      [classes.tagTextDecoration]: elem.metric === tagHover,
                    })}
                  >
                    <div className={classes.verticalAlign}>
                      {elem.prettyName}
                    </div>
                  </Col>
                  <Col
                    onMouseOver={() => handleTagHover(elem.metric)}
                    onMouseLeave={() => handleTagHover('')}
                    style={{ marginLeft: 'auto' }}
                  >
                    <Button
                      className={classes.addButton}
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        handleAddToTextArea(
                          '{{[' + selectedItemKey + '].' + elem.metric + '}}',
                        );
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      ) : (
        <div className={classes.noTagsFound}>
          No tags found. Please select a report and try again.
        </div>
      )}
    </div>
  );
};

export default Tags;
