import lodashUnset from 'lodash/unset';
import lodashForOwn from 'lodash/forOwn';
import lodashMapKeys from 'lodash/mapKeys';
import lodashOmit from 'lodash/omit';

import {
  GENERIC_FILTER_DATA_CHANGED,
  GENERIC_FILTER_SELECTED_VALUE_CHANGED,
  GENERIC_FILTER_DATA_REMOVED,
  GENERIC_FILTER_DATA_RESET,
  GENERIC_FILTER_RADIO_SELECTED,
  GENERIC_INITIALIZE_FILTERS,
} from '../reduxActions/actionNameEnums';

const initialState = {};

const genericFilterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GENERIC_INITIALIZE_FILTERS:
      if (payload.metricData?.length) {
        const defaultFilterSelected = payload.metricData.filter((filter) => {
          const filterListRadio = payload.filtersMetaData[filter.type];
          const defaultSelection = filterListRadio.find(
            (filter) => filter.prettyName === 'Is not null',
          );
          if (filter.section === payload.filterType) {
            return {
              selectedFilter: defaultSelection
                ? defaultSelection.name
                : filterListRadio[0].name,
              section: filter.section,
              ...filter,
            };
          }
        });
        const metricfilterObject = lodashMapKeys(
          defaultFilterSelected,
          (data, keys) => data.value,
        );
        return { ...state, [payload.filterType]: metricfilterObject };
      }
      return payload.metricData;
    case GENERIC_FILTER_DATA_CHANGED:
      const defaultFilterSelected = payload.metricData.map((filter) => {
        const filterListRadio = payload.filtersMetaData[filter.type];
        const defaultSelection = filterListRadio.find(
          (filter) => filter.prettyName === 'Is not null',
        );
        return {
          selectedFilter: defaultSelection
            ? defaultSelection.name
            : filterListRadio[0].name,
          section: payload.filterType,
          ...filter,
        };
      });
      const metricfilterObject = lodashMapKeys(
        defaultFilterSelected,
        (data, keys) => data.value,
      );
      return { ...state, [payload.filterType]: metricfilterObject };
    case GENERIC_FILTER_DATA_REMOVED:
      return lodashOmit(state, payload);
    case GENERIC_FILTER_DATA_RESET:
      const baseState = {};
      lodashForOwn(state, (value, key) => {
        if (value.filterMetaData?.required) {
          const newValue = lodashOmit(value, ['data', 'selectedFilter']);
          baseState[key] = newValue;
        }
      });
      return baseState;
    case GENERIC_FILTER_RADIO_SELECTED:
      const radioChange = {
        [payload.metricsName]: {
          ...state[payload.filterType][payload.metricsName],
          selectedFilter: payload.selectedFilter,
        },
      };
      const newState = Object.assign({}, state, {
        [payload.filterType]: { ...state[payload.filterType], ...radioChange },
      });
      lodashUnset(newState[payload.filterType], `${payload.metricsName}.data`);
      return newState;
    case GENERIC_FILTER_SELECTED_VALUE_CHANGED:
      const changeData = {
        [payload.metricsName]: {
          ...state[payload.filterType][payload.metricsName],
          data: payload.data,
        },
      };

      return Object.assign({}, state, {
        [payload.filterType]: { ...state[payload.filterType], ...changeData },
      });
    default:
      return state;
  }
};

export default genericFilterReducer;
