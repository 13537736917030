// reports
const GET_REPORTS_BY_NAME = 'report/searchByName';
const GET_REPORTS_SEARCH_BY_NAME = 'report/getReportsSearchByName';
const GET_METRIC_TYPES = 'report/metricTypes';
const TRANSFER_REPORT_OWNERSHIP = 'report/transferOwnership';

// downloads
const GET_DOWNLOADS = 'download';
const GET_TEXT_DUMPS = 'download/textDumps';

// dashboards
const GET_DASHBOARDS_BY_NAME = 'dashboardGroup/searchByName';
const GET_DASHBOARD_GROUP = 'dashboardGroup';
const GET_DASHBOARD_REPORTS_RELOADING_DATA = 'dashboardGroup/reportsData';
const SET_DASHBOARD_TABS = 'dashboardGroup/setTabs';
const SAVE_DASHBOARD_GROUP_SETTINGS = 'dashboardGroup/settings';
const TRANSFER_DASHBOARD_OWNERSHIP = 'dashboardGroup/transferOwnership';

// metadata config
const GET_METADATA = 'metaData/config';
const GET_USER_ROLES = 'metadata/userRoles';
const GET_REPORT_CONFIG = 'metadata/report';

// schedules
const GET_SCHEDULERS = 'schedules/getSchedulesByEntity';

const FAVOURITE_DASHBOARD_GROUP = 'dashboardGroup/updateFavourite';

const SEARCH_EMPLOYEE_BY_EMAIL = 'search/employee';
const EXPORT_REPORT = 'report/export';
const IMPORT_REPORT = 'report/import';
const EXPORT_DASHBOARD = 'dashboardGroup/exports';
const IMPORT_DASHBOARD = 'dashboardGroup/import';

const GET_OWNER_DETAILS = 'search/employee/getOwnerDetails';

export {
  GET_REPORTS_BY_NAME,
  GET_DOWNLOADS,
  GET_TEXT_DUMPS,
  GET_DASHBOARDS_BY_NAME,
  GET_METADATA,
  GET_SCHEDULERS,
  GET_METRIC_TYPES,
  FAVOURITE_DASHBOARD_GROUP,
  SEARCH_EMPLOYEE_BY_EMAIL,
  GET_REPORT_CONFIG,
  GET_USER_ROLES,
  GET_DASHBOARD_GROUP,
  EXPORT_REPORT,
  EXPORT_DASHBOARD,
  IMPORT_REPORT,
  IMPORT_DASHBOARD,
  GET_DASHBOARD_REPORTS_RELOADING_DATA,
  SET_DASHBOARD_TABS,
  SAVE_DASHBOARD_GROUP_SETTINGS,
  GET_REPORTS_SEARCH_BY_NAME,
  TRANSFER_REPORT_OWNERSHIP,
  TRANSFER_DASHBOARD_OWNERSHIP,
  GET_OWNER_DETAILS,
};
