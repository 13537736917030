import React from 'react';
import { useSelector } from 'react-redux';
import lodashUniqBy from 'lodash/uniqBy';
import lodashKeys from 'lodash/keys';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashValues from 'lodash/values';
import lodashDifference from 'lodash/difference';
import lodashIsNil from 'lodash/isNil';
import { message, Modal } from 'antd';
import {
  getfilterWithUnSelectedValues,
  gethavingFiltersWithUnSelectedValues,
  transformFilters,
  transformHavingFilters,
} from 'src/components/common/utils/filter';
import lodashUnion from 'lodash/union';
import lodashCloneDeep from 'lodash/cloneDeep';
import { countOfErrors } from '../../dashboard/util';
import { TszError } from '../../dashboard/util';
import {
  extractUniqueNamesInTripleCurlyBraces,
  extractUniqueNamesInSquareBrackets,
} from 'src/components/common/utils/utils';
import {
  ALLOW_SANATIZE_TAGS_HTML,
  ALLOW_SANATIZE_ATTRS_HTML,
} from 'src/constants';
import DOMPurify from 'dompurify';
import { isHtmlSupported } from 'src/components/utils/utils';

interface submissionData {
  type?: string;
  ignoreMetricValidation?: boolean;
  ignoreFilterValidation?: boolean;
  validateTimeline?: boolean;
  validateObject?: boolean;
}

const validateLinkedReport = (validationMetrics, evaluationMetrics, errors) => {
  Object.keys(validationMetrics).forEach((metric) => {
    const linkedReport = validationMetrics[metric]?.linkedReport;
    if (linkedReport?.reportId) {
      const linkedMetrices = JSON.parse(linkedReport?.linkedMetrices || '{}');
      const linkedMetriceKeys = Object.keys(linkedMetrices);
      if (linkedMetriceKeys.length === 0) {
        errors.push('Please map the linked report columns');
      } else {
        evaluationMetrics.forEach((evaluationKey) => {
          if (!linkedMetrices[evaluationKey]) {
            errors.push('Please map all the linked report columns');
          }
        });
      }
    }
  });
};

const validateDrilldown = (summarizationMetrics, chartOptions, errors) => {
  const drilldownMetricNames: any = [];
  Object.keys(summarizationMetrics).forEach((metricName) => {
    if (summarizationMetrics[metricName].drilldown) {
      drilldownMetricNames.push(summarizationMetrics[metricName]);
    }
  });
  if (drilldownMetricNames.length > 1) {
    errors.push('Please apply drill down on only one metric');
    return;
  }
  if (drilldownMetricNames.length === 1) {
    const pivotRowsData = chartOptions?.rowColumns?.row?.data;
    if (pivotRowsData?.length > 1) {
      errors.push('Please add only drill down metric in pivot rows');
    }
  }
};

const validateActionList = (actionsList, errors) => {
  actionsList.forEach(({ selectedActionId, additionalFields }) => {
    if (!selectedActionId) {
      errors.push('Please add only drill down metric in pivot rows');
    }
    additionalFields.forEach(({ value, key }) => {
      if (!value || !key) {
        errors.push('Please input all the additional fields');
      }
    });
  });
};

export const useSubmissionData = (options: submissionData = {}) => {
  const {
    type,
    ignoreMetricValidation,
    ignoreFilterValidation,
    validateTimeline,
    validateObject,
  }: submissionData = options;
  const sortMetric = useSelector((state: any) => state.sortMetaData);
  const chartType = useSelector((state: any) => state.chartsMetaData.chartType);
  const pageSize = useSelector((state: any) => state.chartsMetaData.pageSize);
  const chartConfig = useSelector(
    (state: any) => state.chartsMetaData.chartConfig,
  );
  const formatting = useSelector(
    (state: any) => state.chartsMetaData.formatting,
  );
  const selectedAggregateObject = useSelector(
    (state: any) => state.summarization,
  );
  const genericSelectedAggregateObject = useSelector(
    (state: any) => state.genericSummarisation,
  );
  const selectedMetricsAsObject = useSelector((state: any) => state.metrics);
  const filterData = useSelector((state: any) => state.filterData);
  const genericFilterData = useSelector(
    (state: any) => state.genericFilterData,
  );
  const selectedReport = useSelector(
    (state: any) => state.reportMetaData.selectedReport,
  );
  const reportID = useSelector((state: any) => state.reportMetaData.reportID);
  const reportName = useSelector(
    (state: any) => state.reportMetaData.reportName,
  );
  const enableDummyData = useSelector(
    (state: any) => state.reportMetaData.dummyDataSwitch,
  );
  const sortMetaData = useSelector((state: any) => state.sortMetaData);
  const allMetrics =
    useSelector(
      (state: any) => state.reportConfig?.reports?.[selectedReport]?.metrics,
    ) || [];
  const allMetricsMaster = allMetrics;
  const isAggregatedSwitchState =
    useSelector((state: any) => state.reportMetaData.isAggregated) || false;
  const filterDataArray = lodashValues({
    ...filterData,
    ...genericFilterData['coverage'],
  });
  const supportedFilters = useSelector(
    (state: any) => state.masterData?.supportedFilters,
  );
  const chartOptions = useSelector(
    (state: any) => state.chartsMetaData.chartOptions,
  );
  const suggestedFilterStrings =
    useSelector((state: any) => state.reportMetaData.suggestedFilterStrings) ||
    [];
  const havingFilterData = useSelector((state: any) => state.havingFilterData);
  const havingFilterDataArray = lodashValues(havingFilterData);
  const objectValue =
    useSelector((state: any) => state.reportMetaData.object) || [];
  const parentFilterKeysForChildReport = lodashUnion(
    Object.keys(filterData),
    Object.keys(selectedAggregateObject),
  );
  const selectedUnderlyingMetricsObject =
    useSelector((state: any) => state.underlyingMetrics) || [];
  const automationData =
    useSelector((state: any) => state.automationData) || {};
  const dbType =
    useSelector(
      (state: any) => state.reportConfig?.reports?.[selectedReport]?.dbType,
    ) || [];
  const selectedActionList =
    useSelector((state: any) => state.actionData) || [];
  const queryString =
    useSelector((state: any) => state.reportMetaData.queryString) || '';
  const underlyingQueryString =
    useSelector((state: any) => state.reportMetaData.underlyingQueryString) ||
    '';
  const isAdminReport =
    useSelector((state: any) => state.reportMetaData.isAdminReport) || false;
  const customReportMetricsString =
    useSelector((state: any) => state.reportMetaData.metricString) || '[]';
  const dataMartType = useSelector(
    (state: any) => state.reportMetaData.dataMartType,
  );
  const mongoCollectionName = useSelector(
    (state: any) => state.reportMetaData.mongoCollectionName,
  );
  const htmlData: string = useSelector(
    (state: any) => state.chartsMetaData.htmlText,
  );

  const validate = (variables) => {
    if (isHtmlSupported(variables.reportType)) {
      return { isValidated: true, errors: [] };
    }
    const errors: string[] = [];
    let dataDumpErrors = false;
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (type !== 'metric') {
      const filtersCount = Object.keys(filterDataArray).length;
      const filterNameAccessKey = 'selectedFilter';
      const errorCount = countOfErrors(
        filterDataArray,
        selectedAggregateObject,
        false,
        filterNameAccessKey,
        dbType,
      );
      if (filtersCount === 1 && errorCount === filtersCount) {
        dataDumpErrors = true;
        Modal.warning({
          content: TszError('single'),
        });
      } else if (errorCount === filtersCount && filtersCount > 1) {
        dataDumpErrors = true;
        Modal.warning({
          content: TszError('multiple'),
        });
      }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (!ignoreMetricValidation) {
      const columnMetrics =
        variables.metrices.filter((column) => column.section == null) || [];
      if (
        columnMetrics.length === 0 &&
        variables.customAggregateMetrices.length === 0
      ) {
        errors.push('Select At Least One Column To Proceed');
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const requiredMetricsNames = allMetrics
      .filter((metric) => metric.filterMetaData?.required)
      .map((filter) => filter.prettyName);
    const selectedFilterNames = filterDataArray.map(
      (filter) => filter.prettyName,
    );
    const unselectedFilters = lodashDifference(
      requiredMetricsNames,
      selectedFilterNames,
    );
    if (unselectedFilters.length > 0) {
      errors.push(
        `Apply filter upon ${unselectedFilters.join(' ')} metrics to proceed`,
      );
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const isAggregated =
      !lodashIsEmpty(selectedAggregateObject) || isAggregatedSwitchState;
    if (isAggregated) {
      let selectedMetrics = lodashValues(selectedMetricsAsObject);
      selectedMetrics = selectedMetrics.filter(
        (item) =>
          variables.customAggregateMetrices.findIndex(
            (items) => items.metric === item.name,
          ) === -1,
      );
      const metricsWithoutOperation = selectedMetrics
        .filter((metric) => {
          if (
            metric.value in selectedAggregateObject ||
            (genericSelectedAggregateObject['timeline'] &&
              metric.value in genericSelectedAggregateObject['timeline']) ||
            (genericSelectedAggregateObject?.['summariseBy'] &&
              metric.value in genericSelectedAggregateObject['summariseBy'])
          ) {
            return false;
          }
          return lodashIsNil(metric.operation);
        })
        .map((metric) => metric.prettyName);
      if (metricsWithoutOperation.length > 0) {
        errors.push(
          `Please Select Aggregate operations for ${metricsWithoutOperation.join(
            ', ',
          )}`,
        );
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const sortMetrics = lodashValues(sortMetric);
    const columnsWithSortByNotSelected = sortMetrics
      .filter((metric) => !metric.sortBy?.type)
      .map((metric) => metric.prettyName);
    if (columnsWithSortByNotSelected.length !== 0) {
      errors.push(
        `Please Select sorting Order for ${columnsWithSortByNotSelected.join(
          ', ',
        )}`,
      );
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const aggregateObjects =
      type === 'metric'
        ? {
            ...selectedAggregateObject,
            ...genericSelectedAggregateObject['timeline'],
            ...genericSelectedAggregateObject['summariseBy'],
          }
        : {
            ...selectedAggregateObject,
          };
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const summaristionList = lodashValues(aggregateObjects);
    const unSelectedBucketList = summaristionList
      .filter((metric) => {
        const isBucketedSummarisation =
          (metric.groupBy.buckets || []).length > 0;
        if (isBucketedSummarisation) {
          if (!metric.groupBy.bucketValue) return true;
          return false;
        }
        return false;
      })
      .map((metric) => metric.prettyName);
    if (unSelectedBucketList.length !== 0) {
      errors.push(
        `Please select the frequencies of ${unSelectedBucketList.join(', ')}`,
      );
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (!ignoreFilterValidation) {
      const filtersWithUnselectedValues = getfilterWithUnSelectedValues(
        filterDataArray,
        supportedFilters,
      );
      if (filtersWithUnselectedValues.length !== 0) {
        errors.push(
          `Select Filter Values For ${filtersWithUnselectedValues.join(', ')}`,
        );
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const havingFiltersWithUnselectedValues =
      gethavingFiltersWithUnSelectedValues(
        havingFilterDataArray,
        supportedFilters,
      );
    if (havingFiltersWithUnselectedValues.length !== 0 && type !== 'metric') {
      errors.push(
        `Select Having Filter Values For ${havingFiltersWithUnselectedValues.join(
          ', ',
        )}`,
      );
    }

    if (
      validateObject &&
      objectValue?.name !== 'Overall' &&
      !objectValue?.field?.metric
    ) {
      errors.push('Please Select a Field');
    }
    if (validateObject && !objectValue?.name) {
      errors.push('Please Provide an Object Name');
    }
    if (validateTimeline) {
      if (!lodashValues(genericSelectedAggregateObject['timeline']).length) {
        errors.push('Please Select a Timeline');
      }
    }
    if (chartType === 'pivotTable') {
      if (chartOptions.rowColumns?.row?.data?.length < 1) {
        errors.push('At least 1 field in Rows is required');
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    validateLinkedReport(
      variables.metrices,
      parentFilterKeysForChildReport,
      errors,
    );
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    validateDrilldown(selectedAggregateObject, chartOptions, errors);
    validateActionList(selectedActionList, errors);
    return {
      isValidated: errors.length === 0 && !dataDumpErrors,
      errors,
    };
  };

  const customReportValidate = (variables) => {
    const errors: string[] = [];
    if (isHtmlSupported(variables.reportType)) {
      if (!queryString.length) {
        errors.push('Please input the query string');
      } else {
        const allFilters = extractUniqueNamesInTripleCurlyBraces(queryString);
        const optionalFilters = extractUniqueNamesInSquareBrackets(queryString);
        const mandatoryFilters = allFilters.filter(
          (item: any) => !optionalFilters.includes(item),
        );
        if (!mandatoryFilters.includes('organisationId')) {
          errors.push('Please add mandatory organisationId filter in query');
        }
        return {
          isValidated: errors.length === 0,
          errors,
        };
      }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (!ignoreMetricValidation) {
      if (variables?.metrices.length === 0) {
        errors.push('Select At Least One Column To Proceed');
      }
      variables.metrices.forEach(({ type, prettyName, metric }) => {
        if (!type || !prettyName || !metric) {
          errors.push('Please input all the metric details in Custom Columns');
        }
      });
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (!queryString.length) {
      errors.push('Please input the query string');
    } else {
      // check if mandatory filters are present (organisationId and limit)
      const allFilters = extractUniqueNamesInTripleCurlyBraces(queryString);
      const optionalFilters = extractUniqueNamesInSquareBrackets(queryString);
      const mandatoryFilters = allFilters.filter(
        (item: any) => !optionalFilters.includes(item),
      );
      if (
        !mandatoryFilters.includes('organisationId') ||
        !mandatoryFilters.includes('limit')
      ) {
        errors.push(
          'Please add mandatory organisationId and limit filter in query',
        );
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (!ignoreFilterValidation) {
      const filtersWithUnselectedValues = getfilterWithUnSelectedValues(
        filterDataArray,
        supportedFilters,
      );
      const filtersWithUnselectedValuesInCustomReport =
        filtersWithUnselectedValues.filter(
          (metric) =>
            !['organisationId', 'hubId'].includes(metric),
        );
      if (filtersWithUnselectedValuesInCustomReport.length !== 0) {
        errors.push(
          `Select Filter Values For ${filtersWithUnselectedValuesInCustomReport.join(
            ', ',
          )}`,
        );
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (chartType === 'pivotTable') {
      if (chartOptions.rowColumns?.row?.data?.length < 1) {
        errors.push('At least 1 field in Rows is required');
      }
    }
    return {
      isValidated: errors.length === 0,
      errors,
    };
  };
  const getSubmissionVariables = () => {
    const selectedMetrics = lodashValues(selectedMetricsAsObject);
    const selectedUnderlyingMetrics = lodashValues(
      selectedUnderlyingMetricsObject,
    );
    const aggregateObjects =
      type === 'metric'
        ? {
            ...selectedAggregateObject,
            ...genericSelectedAggregateObject['timeline'],
            ...genericSelectedAggregateObject['summariseBy'],
          }
        : {
            ...selectedAggregateObject,
          };
    const selectedAggregate = lodashValues(aggregateObjects);
    const selectedMetricesCopy = lodashCloneDeep(selectedMetrics);
    const selectedMetricsTransformed = selectedMetricesCopy.map((metric) => {
      let linkedReport;
      if (metric?.linkedReport?.linkedMetrices) {
        linkedReport = {
          ...metric?.linkedReport,
          linkedMetrices: JSON.stringify(metric.linkedReport.linkedMetrices),
        };
      }
      const newMetric: Record<string, any> = {
        metric: metric.value,
        sortBy: sortMetaData[metric.value]?.sortBy,
        prettyName: metric.prettyName,
        type: metric.type,
        section: metric.section,
        linkedReport,
      };
      if (metric?.dummyData && Object.keys(metric?.dummyData).length) {
        newMetric.dummyData = JSON.stringify(metric.dummyData);
      }
      if (
        metric.type === 'boolean' &&
        (!metric.operation || metric?.operation === 'any')
      ) {
        newMetric.dummyData = JSON.stringify({});
      }
      if (metric.operation) {
        newMetric.operation = metric.operation;
      }
      if (metric.alias) {
        newMetric.alias = metric.alias;
      }
      if (metric.columnWidth) {
        newMetric.columnWidth = metric.columnWidth;
      }
      return newMetric;
    });

    const selectedAggregateCopy = lodashCloneDeep(selectedAggregate);
    const aggregatedMetricTransformed = selectedAggregateCopy.map((metric) => {
      let linkedReport;
      if (metric?.linkedReport?.linkedMetrices) {
        linkedReport = {
          ...metric?.linkedReport,
          linkedMetrices: JSON.stringify(metric.linkedReport.linkedMetrices),
        };
      }
      const newMetric: Record<string, any> = {
        metric: metric.value,
        groupBy: { toApply: true },
        sortBy: sortMetaData[metric.value]?.sortBy,
        prettyName: metric.prettyName,
        type: metric.type,
        section: metric.section,
        linkedReport,
        drilldown: metric.drilldown,
      };
      if (metric.operation) {
        newMetric.operation = metric.operation;
      }
      if (metric.groupBy.bucketValue) {
        newMetric.groupBy.bucketValue = metric.groupBy.bucketValue;
      }
      if (metric.alias) {
        newMetric.alias = metric.alias;
      }
      return newMetric;
    });
    const selectedUnderlyingMetricsTransformed = lodashCloneDeep(
      selectedUnderlyingMetrics,
    ).map((metric) => {
      const newMetric: Record<string, any> = {
        metric: metric.value,
        prettyName: metric.prettyName,
        type: metric.type,
      };
      if (metric.alias) {
        newMetric.alias = metric.alias;
      }
      return newMetric;
    });
    const selectedMetricsTransformedList = selectedMetricsTransformed.map(
      (x) => x.metric,
    );
    const aggregatedMetricTransformedList = aggregatedMetricTransformed.map(
      (x) => x.metric,
    );
    const foundMetrices = selectedMetricsTransformedList.filter((x) =>
      aggregatedMetricTransformedList.includes(x),
    );
    let allMetrics = mergeMetrics(
      aggregatedMetricTransformed,
      selectedMetricsTransformed,
    );
    if (foundMetrices?.length > 0) {
      allMetrics = mergeMetrics(
        selectedMetricsTransformed,
        aggregatedMetricTransformed,
      );
    }
    // adding index for maintaining order
    allMetrics = allMetrics.map((item, index) => ({ index, ...item }));
    const underlyingMetrics = selectedUnderlyingMetricsTransformed.map(
      (item, index) => ({ index, ...item }),
    );
    let allMetricTransformed = lodashUniqBy(
      allMetrics,
      (metric) => metric.metric,
    );
    const filtersProcessed = transformFilters(filterDataArray);
    const havingFiltersProcessed = transformHavingFilters(
      havingFilterDataArray,
    );
    let customColumnAggregate = selectedMetrics.filter(
      (item) => item.customColumnType === 'local',
    );
    customColumnAggregate = customColumnAggregate.map((item) => {
      let linkedReport;
      if (item?.linkedReport?.linkedMetrices) {
        linkedReport = {
          ...item?.linkedReport,
          linkedMetrices: JSON.stringify(item.linkedReport.linkedMetrices),
        };
      }
      const index = allMetrics.findIndex(
        (metricData) => metricData.metric === item.name,
      );
      return {
        metric: item.name,
        prettyName: item.prettyName,
        sql: item.sql,
        type: item.type,
        alias: item.alias || item.prettyName,
        index,
        columnWidth: item.columnWidth,
        linkedReport,
        dummyData: JSON.stringify(item?.dummyData),
      };
    });
    customColumnAggregate = customColumnAggregate.map((item) => {
      const index = allMetricTransformed.findIndex(
        (item2) => item2.metric === item.metric,
      );
      return {
        ...item,
        sortBy: allMetricTransformed[index].sortBy,
      };
    });
    let customMetrices = allMetricsMaster.filter(
      (item) => item.customColumnType === 'global',
    );
    customMetrices = customMetrices.map((item) => ({
      metric: item.name,
      prettyName: item.prettyName,
      sql: item.sql,
      type: item.type,
    }));
    const chartOptionsKeys = lodashKeys(chartOptions);
    const optionList = chartOptionsKeys.reduce((accumulator, currentValue) => {
      const sectionData = chartOptions[currentValue];
      const sectionKeys = lodashKeys(sectionData);
      const optionArr: any[] = [];
      sectionKeys.forEach((element) => {
        const option = sectionData[element];
        const optionsReframed = {
          section: currentValue,
          option: element,
          data: JSON.stringify({ value: option.data }),
        };
        optionArr.push(optionsReframed);
      });
      return accumulator.concat(optionArr);
    }, []);
    allMetricTransformed = allMetricTransformed.filter(
      (item) =>
        customColumnAggregate.findIndex(
          (items) => items.metric === item.metric,
        ) === -1,
    );

    const requiredAutomationData = { ...automationData };
    if (automationData) {
      requiredAutomationData.intervalType =
        requiredAutomationData.selectedInterval;
      delete requiredAutomationData.selectedInterval;
    }

    const sanitizedHtml = DOMPurify.sanitize(htmlData, {
      ALLOWED_TAGS: ALLOW_SANATIZE_TAGS_HTML,
      ALLOWED_ATTR: ALLOW_SANATIZE_ATTRS_HTML,
    });

    const variables = {
      reportId: reportID,
      reportType: selectedReport,
      metrices: allMetricTransformed,
      filters: filtersProcessed,
      havingFilters: havingFiltersProcessed,
      chartConfig: JSON.stringify(chartConfig),
      formatting,
      pageSize,
      chartType,
      customMetrices,
      customAggregateMetrices: customColumnAggregate,
      chartOptions: optionList,
      suggestedFilterStrings,
      name: reportName,
      automationData: requiredAutomationData,
      underlyingMetrics,
      isAggregate: isAggregatedSwitchState,
      isDummyDataRequest: enableDummyData,
      actions: selectedActionList,
      htmlData: sanitizedHtml,
    };
    const { isValidated, errors } = validate(variables);
    errors.map((error) => message.warning(error));
    return { variables, validationPassed: isValidated };
  };

  const getCustomReportSubmissionVariables = () => {
    let customReportMetrics;
    try {
      customReportMetrics = JSON.parse(customReportMetricsString);
      if (Object.keys(selectedMetricsAsObject).length) {
        customReportMetrics.forEach((metric, index) => {
          metric['columnWidth'] =
            selectedMetricsAsObject[metric.metric]?.columnWidth;
          metric['alias'] = selectedMetricsAsObject[metric.metric]?.aliasName;
          if (metric.linkedReport) {
            metric.linkedReport = {
              ...metric.linkedReport,
              linkedMetrices: JSON.stringify(metric.linkedReport.linkedMetrices),
            };
          }
        });
      }
    } catch (e) {
      message.warning('Invalid Custom Report Metrics String');
      return { validationPassed: false, variables: {} };
    }
    const chartOptionsKeys = lodashKeys(chartOptions);
    const filtersProcessed = transformFilters(filterDataArray);
    const optionList = chartOptionsKeys.reduce((accumulator, currentValue) => {
      const sectionData = chartOptions[currentValue];
      const sectionKeys = lodashKeys(sectionData);
      const optionArr: any[] = [];
      sectionKeys.forEach((element) => {
        const option = sectionData[element];
        const optionsReframed = {
          section: currentValue,
          option: element,
          data: JSON.stringify({ value: option.data }),
        };
        optionArr.push(optionsReframed);
      });
      return accumulator.concat(optionArr);
    }, []);
    const requiredAutomationData = { ...automationData };
    if (automationData) {
      requiredAutomationData.intervalType =
        requiredAutomationData.selectedInterval;
      delete requiredAutomationData.selectedInterval;
    }
    const variables = {
      isAdminReport,
      reportType: selectedReport,
      queryString,
      reportId: reportID,
      metrices: customReportMetrics,
      filters: filtersProcessed,
      chartConfig: JSON.stringify(chartConfig),
      formatting,
      pageSize,
      chartType,
      chartOptions: optionList,
      suggestedFilterStrings,
      name: reportName,
      automationData: requiredAutomationData,
      isAggregate: isAggregatedSwitchState,
      dataMartType,
      mongoCollectionName,
      underlyingQueryString:
        underlyingQueryString?.length ? underlyingQueryString : undefined,
    };
    const { isValidated, errors } = customReportValidate(variables);
    errors.map((error) => message.warning(error));
    return { variables, validationPassed: isValidated };
  };

  return { getSubmissionVariables, getCustomReportSubmissionVariables };
};

const mergeMetrics = (firstMetricArray, secondMetricArray) => {
  const uniqArray = lodashUniqBy(
    [...firstMetricArray, ...secondMetricArray],
    (metric) => metric.metric,
  );
  const mergedArray = uniqArray.map((filter) => {
    const metricInFirstArray =
      firstMetricArray.find((metric) => metric.metric === filter.metric) || {};
    const metricInSecondArray =
      secondMetricArray.find((metric) => metric.metric === filter.metric) || {};
    return Object.assign({}, filter, metricInFirstArray, metricInSecondArray);
  });
  return mergedArray;
};
