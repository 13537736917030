import React, { useState, useEffect } from 'react';
import { Button, Divider, Modal, Select, message, Spin } from 'antd';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { entityTypes } from 'src/constants';
import { createUseStyles } from 'react-jss';
import {
  TRANSFER_REPORT_OWNERSHIP,
  TRANSFER_DASHBOARD_OWNERSHIP,
} from 'src/api/routes';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';

const { REPORT, DASHBOARD_GROUP } = entityTypes;

const useStyle = createUseStyles({
  basePane: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginTop: '21px',
  },
  reportName: {
    display: 'flex',
    fontSize: '15px',
    marginLeft: '1%',
    marginBottom: '4%',
  },
  titleName: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '15px',
    marginRight: '2%',
  },
  modalDisclaimer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
  },
});

const TransferOwnershipModal = (props) => {
  const classes = useStyle();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const {
    transferOwnershipModalVisibility,
    setTransferOwnershipModalVisibility,
    record,
    ownerDetails = [],
    allUsers = [],
    dashboardGroupId,
    dashboardName,
    loadingOwnerDetails,
    loadingUserDetails,
    entityType,
    refetch,
    onSearch,
  } = props;
  const { Option } = Select;

  const [transferReportOwnership, { loading: loadingReportTransfer }] =
    useLazyAPI(TRANSFER_REPORT_OWNERSHIP, {
      type: 'POST',
      onCompleted: () => {
        message.success('Ownership Transferred Successfully');
        refetch();
      },
      onError: () => {
        message.error('Ownership Transfer Failed');
      },
    });

  const [transferDashboardOwnership, { loading: loadingDashboardTransfer }] =
    useLazyAPI(TRANSFER_DASHBOARD_OWNERSHIP, {
      type: 'POST',
      onCompleted: () => {
        message.success('Ownership Transferred Successfully');
        refetch();
      },
      onError: () => {
        message.error('Ownership Transfer Failed');
      },
    });

  const onCancelTransferOwnership = () => {
    setTransferOwnershipModalVisibility(false);
    setShowConfirmationModal(false);
  };

  const onNextStepClick = () => {
    if (showConfirmationModal) {
      entityType === REPORT
        ? transferReportOwnership({
            variables: {
              reportId: record._id,
              senderUserId: ownerDetails[0]?.empId,
              senderName: ownerDetails[0]?.name,
              senderEmail: ownerDetails[0]?.email,
              receiverUserId: selectedUser,
            },
          })
        : transferDashboardOwnership({
            variables: {
              dashboardGroupId,
              senderUserId: ownerDetails[0]?.empId,
              senderName: ownerDetails[0]?.name,
              senderEmail: ownerDetails[0]?.email,
              receiverUserId: selectedUser,
            },
          });
    } else {
      setShowConfirmationModal(true);
    }
  };

  useEffect(() => {
    if (
      (entityType === REPORT && !loadingReportTransfer) ||
      (entityType === DASHBOARD_GROUP && !loadingDashboardTransfer)
    ) {
      setTransferOwnershipModalVisibility(false);
      setShowConfirmationModal(false);
    }
  }, [loadingReportTransfer, loadingDashboardTransfer]);

  const onSelectChange = (val) => {
    setSelectedUser(val);
  };

  return (
    <Modal
      title={<div>Transfer Ownership</div>}
      centered
      width={'30%'}
      bodyStyle={{ height: '11.5rem' }}
      visible={transferOwnershipModalVisibility}
      onCancel={onCancelTransferOwnership}
      footer={[
        <Button type="primary" onClick={onCancelTransferOwnership}>
          {showConfirmationModal ? 'No' : 'Cancel'}
        </Button>,
        <Button
          type="primary"
          onClick={onNextStepClick}
          disabled={!selectedUser}
          loading={loadingReportTransfer || loadingDashboardTransfer}
        >
          {showConfirmationModal ? 'Yes, Transfer' : 'Transfer'}
        </Button>,
      ]}
    >
      {showConfirmationModal ? (
        <div className={classes.modalDisclaimer}>
          <ExclamationCircleFilled style={{ color: '#FFA940' }} />
          <div>
            Are you sure you want to proceed with the ownership transfer?
          </div>
        </div>
      ) : (
        <>
          <div className={classes.reportName}>
            <div className={classes.titleName}>{'Name: '} </div>
            {entityType === REPORT ? record?.name || '' : dashboardName || ''}
          </div>
          <div className={classes.reportName}>
            <div className={classes.titleName}>{'Owner: '} </div>
            {loadingOwnerDetails ? (
              <div>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />}
                />
              </div>
            ) : (
              ownerDetails[0]?.email || ''
            )}
          </div>
          <Divider plain />
          <div className={classes.basePane}>
            <div className={classes.titleName}>Transfer to:</div>
            <Select
              showSearch
              placeholder={'Add Users'}
              style={{ flexGrow: 1 }}
              onSelect={onSelectChange}
              onSearch={onSearch}
              value={selectedUser}
              filterOption={(input, option) =>
                JSON.stringify(option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              optionFilterProp="label"
            >
              {loadingUserDetails ? (
                <Option value="" disabled>
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                </Option>
              ) : (
                allUsers.map((empDetails: any) => {
                  const { empId, email, name } = empDetails;
                  return empId !== ownerDetails[0]?.empId ? (
                    <Option value={empId} label={email || name}>
                      {email || name}
                    </Option>
                  ) : null;
                })
              )}
            </Select>
          </div>
        </>
      )}
    </Modal>
  );
};

export default TransferOwnershipModal;
