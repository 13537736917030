/* eslint-disable no-cond-assign */
import React, { memo } from 'react';
import CustomInput from 'src/components/common/customInput';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  CUSTOM_FILTER_DATA_CHANGED,
  CUSTOM_QUERY_STRING,
} from 'src/reduxActions/actionNameEnums';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import {
  extractUniqueNamesInTripleCurlyBraces,
  extractUniqueNamesInSquareBrackets,
  getFiltersObjects,
  getFilterObjectsWithPrevState,
} from 'src/components/common/utils/utils';

const CustomQuery = () => {
  const dispatch = useDispatch();
  const queryString = useSelector(
    (state: any) => state.reportMetaData.queryString,
  );
  const filterData = useSelector((state: any) => state.filterData);
  const filtersMetaData = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const debouncedDispatch = debounce(dispatch, 500);
  const onChange = (customQuery) => {
    const allFilters = extractUniqueNamesInTripleCurlyBraces(customQuery);
    const optionalFilters = extractUniqueNamesInSquareBrackets(customQuery);
    const mandatoryFilters = allFilters.filter(
      (item: any) => !optionalFilters.includes(item),
    );
    const mandatoryFiltersWithoutLimit = mandatoryFilters.filter(
      (item) => item !== 'limit',
    );
    const OptionalFiltersWithoutLimit = optionalFilters.filter(
      (item) => item !== 'limit',
    );
    const filtersObjects = getFiltersObjects(
      mandatoryFiltersWithoutLimit,
      OptionalFiltersWithoutLimit,
      filtersMetaData,
    );
    const filterObjectWithPrevState = getFilterObjectsWithPrevState(
      filtersObjects,
      filterData,
    );
    dispatch({
      type: CUSTOM_QUERY_STRING,
      payload: {
        queryString: customQuery,
      },
    });
    debouncedDispatch({
      type: CUSTOM_FILTER_DATA_CHANGED,
      payload: filterObjectWithPrevState,
    });
  };
  // if you select any text in sqlInputEditor and paste after selection then onChange will be called
  // twice once for cut and second for paste and setState will stuck in a infinite loop
  // to prevent this problem
  const lazyUpdate = () => {
    let query = '';
    let inside = false;
    return (value) => {
      query = value;
      if (inside === false) {
        inside = true;
        setTimeout(() => {
          onChange(query);
          inside = false;
        });
      }
    };
  };

  return (
    <SectionPartContainer
      title={'Query'}
      showAddButton={false}
    >
      <div style={{ marginTop: '16px' }}>
        <CustomInput
          onChange={lazyUpdate()}
          value={queryString}
          placeholder={'Please Input Query'}
          isCodeEditor={true}
          width={'100%'}
          highlightActiveLine={false}
          theme={'monokai'}
        />
      </div>
    </SectionPartContainer>
  );
};

export default memo(CustomQuery);
