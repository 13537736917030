import { Select } from 'antd';
import React from 'react';

const CustomSelect = (props) => {
  const {
    value,
    data,
    onChange,
    size,
    style,
    placeholder='',
    dropDownClass,
  } = props;

  return (
    <Select
      getPopupContainer={(trigger) => trigger.parentNode}
      style={style}
      dropdownClassName={dropDownClass}
      size={size}
      placeholder={placeholder}
      options={data}
      value={value}
      onChange={onChange}
    />
  );
};

export default CustomSelect;
