/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from 'react';
import { Select, Button, Input, message } from 'antd';
import { DeleteOutlined, MinusCircleOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import CustomDivider from 'src/components/common/CustomDivider';
import lodashCloneDeep from 'lodash/cloneDeep';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_CHANGED } from 'src/reduxActions/actionNameEnums';

const defaultActionObject = {
  selectedActionId: '',
  additionalFields: [],
};

const useStyles = createUseStyles({
  addButton: {
    height: '24px',
    width: '58px',
    borderRadius: '13px',
    padding: '0',
    borderColor: '#F0F0F0',
    backgroundColor: '#F0F0F0',
  },
  buttonText: {
    height: '12px',
    color: '#1890FF',
    fontSize: '12px',
    letterSpacing: '0',
    lineHeight: '12px',
  },
  eligibleActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    marginTop: '10px',
  },
  additionalFieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  selectActionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  additionalFieldInputHeading: {
    width: '47%',
    color: 'rgb(157 153 153)',
  },
  additionalFieldRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    gap: '0.5rem',
  },
});

const Action = () => {
  const classes = useStyles();
  const reportsData = useSelector((state: any) => state.reportConfig.reports);
  const selectedReportType = useSelector((state: any) => state.reportMetaData.selectedReport);
  const objectType = useSelector(
    (state: any) => state.reportMetaData?.object?.name,
  ) || null;
  const eligibleActions = reportsData?.[selectedReportType]?.metricActions || [];
  const eligibleActionsOption = eligibleActions
    .filter(action => action.objectType === objectType)
    .map((action: any) => {
      return {
        value: action.id,
        label: action.prettyName,
      };
    });
  const selectedActionList = useSelector(
    (state: any) => state.actionData,
  );
  const dispatch = useDispatch();

  const dispatchNewActions = (newActions) => {
    dispatch({
      type: ACTION_CHANGED,
      payload: newActions,
    });
  };

  const getAvailableEligibleOptions = () => {
    return eligibleActionsOption.filter(
      ({ value }) =>
        !selectedActionList.some(
          ({ selectedActionId }) => selectedActionId === value,
        ),
    );
  };

  const handleAddSelect = () => {
    if (!getAvailableEligibleOptions()?.length) {
      message.error('No actions available');
      return;
    }
    const selectedActionListClone = lodashCloneDeep(selectedActionList);
    selectedActionListClone.push(defaultActionObject);
    dispatchNewActions(selectedActionListClone);
  };

  const handleDeleteSelect = index => {
    const updatedSelectList = lodashCloneDeep(selectedActionList).filter(
      (_, i) => i !== index,
    );
    dispatchNewActions(updatedSelectList);
  };

	const handleSelectChange = (actionIndex, value) => {
		const selectedActionListClone = lodashCloneDeep(selectedActionList);
    selectedActionListClone[actionIndex].selectedActionId = value;
    dispatchNewActions(selectedActionListClone);
  };

	const handleAddField = (actionIndex) => {
		const selectedActionListClone = lodashCloneDeep(selectedActionList);
    selectedActionListClone[actionIndex].additionalFields.push({ key: '', value: '' });
    dispatchNewActions(selectedActionListClone);
  };

  const handleDeleteField = (actionIndex, additionFieldIndex) => {
		const selectedActionListClone = lodashCloneDeep(selectedActionList);
		const updatedActionFields = selectedActionListClone[
      actionIndex
    ].additionalFields.filter((_, i) => i !== additionFieldIndex);
    selectedActionListClone[actionIndex].additionalFields = updatedActionFields;
    dispatchNewActions(selectedActionListClone);
  };

	const handleFieldChange = (actionIndex, additionFieldIndex, key, value) => {
		const selectedActionListClone = lodashCloneDeep(selectedActionList);
		selectedActionListClone[actionIndex].additionalFields[additionFieldIndex][
      key
    ] = value;
    dispatchNewActions(selectedActionListClone);
	};

  return (
    <SectionPartContainer
      title={'Eligible Actions'}
      description={
        'Select the actions that will be eligible for this alert. You can add multiple actions.'
      }
      onAddButtonClick={() => handleAddSelect()}
    >
      <div>
        {selectedActionList.map((selectValue, actionIndex) => (
          <div key={actionIndex} className={classes.eligibleActionContainer}>
            <div className={classes.selectActionContainer}>
              <span style={{ fontWeight: 500 }}>Action {actionIndex}</span>
              <DeleteOutlined
                style={{ color: 'red' }}
                onClick={() => handleDeleteSelect(actionIndex)}
              />
            </div>
            <div style={{ marginTop: '5px', marginBottom: '10px' }}>
              <span style={{ fontWeight: 400 }}>Select Action</span>
            </div>
            <Select
              value={selectValue.selectedActionId}
              onChange={value => handleSelectChange(actionIndex, value)}
              options={getAvailableEligibleOptions()}
            />
            <div style={{ marginTop: '1rem' }}>
              <div className={classes.additionalFieldContainer}>
                <span style={{ fontWeight: 400 }}>Additional Fields</span>
                <PlusCircleOutlined
                  style={{ color: '#1890FF' }}
                  onClick={() => handleAddField(actionIndex)}
                />
              </div>
              <div className={classes.additionalFieldRow}>
                <div className={classes.additionalFieldInputHeading}>
                  <span style={{ fontWeight: 400 }}>Key</span>
                </div>
                <div className={classes.additionalFieldInputHeading}>
                  <span style={{ fontWeight: 400 }}>Value</span>
                </div>
              </div>
              {selectedActionList[actionIndex].additionalFields.map(
                (field, additionFieldIndex) => (
                  <div
                    key={additionFieldIndex}
                    className={classes.additionalFieldRow}
                  >
                    <Input
                      placeholder="key"
                      value={field.key}
                      style={{ width: '47%' }}
                      onChange={e =>
                        handleFieldChange(
                          actionIndex,
                          additionFieldIndex,
                          'key',
                          e.target.value,
                        )
                      }
                    />
                    <Input
                      placeholder="Value"
                      value={field.value}
                      style={{ width: '47%' }}
                      onChange={e =>
                        handleFieldChange(
                          actionIndex,
                          additionFieldIndex,
                          'value',
                          e.target.value,
                        )
                      }
                    />
                    <MinusCircleOutlined
                      style={{ color: 'red', width: '4%' }}
                      onClick={() =>
                        handleDeleteField(actionIndex, additionFieldIndex)
                      }
                    />
                  </div>
                ),
              )}
            </div>
            <CustomDivider style={{ marginTop: '1rem' }} />
          </div>
        ))}
      </div>
    </SectionPartContainer>
  );
};

export default Action;
