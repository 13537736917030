/* eslint-disable react/no-danger */
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import DOMPurify from 'dompurify';
import {
  ALLOW_SANATIZE_TAGS_HTML,
  ALLOW_SANATIZE_ATTRS_HTML,
} from 'src/constants';
import { HTML_EDITOR_REPORT_TYPE } from 'src/constants';

const useStyles = createUseStyles({
  previewHTML: {
    overflow: 'scroll',
    height: '100%',
  },
});
const HtmlViz = (props) => {
  const { htmlData, newRelicUrl, chartsFromDashboard, chartConfig } = props;
  const classes = useStyles();
  const chartConfigData = useSelector(
    (state: any) => state.chartsMetaData.chartConfig,
  );
  const chartConfigValue = chartsFromDashboard ? chartConfig : chartConfigData;
  const selectedReport = useSelector(
    (state: any) => state.reportMetaData.selectedReport,
  );
  let modifiedUrl = newRelicUrl;
  if (newRelicUrl) {
    modifiedUrl =
      modifiedUrl +
      `?timepicker=${chartConfigValue?.graphic?.timePicker?.data ?? true}`;
  }
  const html = `<div style="height: 100%; overflow:visible">
  <iframe src=${modifiedUrl} width="100%"  scrolling = ${
    chartConfigValue?.graphic?.scrollable?.data === false? 'no': 'yes'
  } height="100%" frameborder="0" style="display: block;">
  </iframe>
  </div>`;
  const sanitizedHtml =
    selectedReport === 'newRelic' || (chartsFromDashboard && newRelicUrl)
      ? html
      : DOMPurify.sanitize(htmlData, {
          ALLOWED_TAGS: ALLOW_SANATIZE_TAGS_HTML,
          ALLOWED_ATTR: ALLOW_SANATIZE_ATTRS_HTML,
        });
  return (
    <>
      {((htmlData && selectedReport === HTML_EDITOR_REPORT_TYPE) ||
        (selectedReport === 'newRelic' && newRelicUrl) ||
        (newRelicUrl && chartsFromDashboard)) && (
        <div
          className={classes.previewHTML}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        />
      )}
    </>
  );
};

export default HtmlViz;
