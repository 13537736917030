import React, { memo, useEffect, useState } from 'react';
import { Collapse, Button, Switch, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { createUseStyles } from 'react-jss';
import { CHART_CONFIG_VALUE_CHANGED } from 'src/reduxActions/actionNameEnums';
import { configObjects, tableData } from './configObjects';
import Inputbox from './InputBox';
import { colors, tableColor } from 'src/components/utils/colors';
import {
  selectNumCompletedTodos,
  tranformPieChartData,
  transformBarChartData,
} from 'src/components/utils/utils';
import { DEFAULT_THEME } from 'src/constants';
const { Panel } = Collapse;

const useStyles = createUseStyles({
  selectContainer: {
    display: 'flex',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '8%',
    padding: '2% 0% 2% 11%',
  },
  child: {
    flex: '0 0 104.5%',
  },
  divider: {
    margin: '5px',
  },
});
const tableArray = ['table', 'pivotTable'];
const optionsInitialState = (
  chartData,
  selectedChart,
  chartConfigData,
  theme: string = DEFAULT_THEME,
) => {
  let color = tableArray.includes(selectedChart) ? tableColor : colors[theme]; //using default color
  if (
    chartConfigData?.graphic?.color?.data &&
    (chartConfigData[selectedChart] || tableArray.includes(selectedChart))
  ) {
    color = chartConfigData?.graphic?.color?.data; //using saved color
    color = color.concat(colors);
  }
  if (tableArray.includes(selectedChart)) {
    const options = tableData?.map((item, index) => ({
      value: item.value,
      label: item.label,
      color: color[index],
    }));
    return options;
  }
  if (!chartData || !Object.keys(chartData).length) {
    return [{ value: 'Default', label: 'Default', color: '#1890FF' }];
  }
  if (selectedChart === 'pie' || selectedChart === 'progressBar') {
    const options = chartData?.map((item, index) => ({
      value: item?.x,
      label: item?.x,
      color: color[index % 20], //defined the color array of size 20
    }));
    return options;
  }
  if (selectedChart === 'table') {
    const options = tableData?.map((item, index) => ({
      value: item.value,
      label: item.label,
      color: color[index],
    }));
    return options;
  }
  const hasZ = chartData.length > 1 && chartData[0].hasOwnProperty('z');
  const optionsType = Array.from(
    new Set(chartData.map((item) => (hasZ ? item.z : item.value))),
  );
  const options = optionsType?.map((item, index) => ({
    value: item,
    label: item,
    color: color[index % 20],
  }));
  return options;
};

const ChartConfigCollapse = (props) => {
  const {
    section,
    prettyName,
    child,
    type,
    defaultValue,
    collapseStyle,
    name,
    selectedChart,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const reducerVal = useSelector(
    (state: any) => state.chartsMetaData.chartConfig,
  );
  const chartConfigData = useSelector(
    (state: any) => state.chartsMetaData.chartConfig,
  );
  const savedSwitchValue = get(chartConfigData, [selectedChart, name, 'data']);
  const themeChart = get(chartConfigData, ['graphic', 'themeCategory', 'data']);
  const thresholdPercentage = get(chartConfigData, ['graphic','percentageThreshold','data']);
  const initialSwitchCollapsible = savedSwitchValue ?? defaultValue;
  const [switchCollapsible, setSwitchCollapsible] = useState(
    type === 'collapse' || initialSwitchCollapsible,
  );
  const [clickCollapse, setClickCollapse] = useState(
    type === 'collapse' || initialSwitchCollapsible,
  );
  const { chartData, formData } = useSelector(selectNumCompletedTodos);
  const [colorOptions, setColorOptions] = useState(
    optionsInitialState(chartData, selectedChart, chartConfigData, themeChart),
  );
  const [colorSelectedOption, setColorSelectedOption] = useState<any>(
    colorOptions[0] || {},
  );

  useEffect(() => {
    const newOptions = optionsInitialState(
      selectedChart === 'pie'
        ? tranformPieChartData(chartData, thresholdPercentage):
        selectedChart === 'bar'
        ? transformBarChartData(chartData, thresholdPercentage)
        : chartData,
      selectedChart,
      chartConfigData,
      themeChart,
    );
    setColorOptions(newOptions);
    setColorSelectedOption(newOptions[0] || {});
  }, [chartData, thresholdPercentage]);

  useEffect(() => {
    if (
      formData.hasOwnProperty('bar_type') &&
      formData.bar_type === 'horizontal'
    ) {
      onChange('right', 'position', 'graphicLabel');
    }
  }, [formData]); //setting up for 'horizontal' bar type

  const onChange = (value, name, section) =>
    dispatch({
      type: CHART_CONFIG_VALUE_CHANGED,
      payload: {
        optionName: name,
        value,
        section,
      },
    });

  useEffect(() => {
    if (type === 'switchCollapse') {
      if (!reducerVal?.[section]?.[name]) {
        setSwitchCollapsible(initialSwitchCollapsible);
        onChange(defaultValue, name, section);
      }
    }
  }, [name, child, type]);

  const handleToggleCollapsible = (checked) => {
    setSwitchCollapsible(checked);
    setClickCollapse(checked);
    onChange(checked, name, section);
  };
  const chartOptions = useSelector(
    (state: any) => state.chartsMetaData.chartOptions,
  );

  const isDisabled = (val) => {
    return typeof configObjects[val.type]?.disabled === 'function'
      ? configObjects[val.type]?.disabled(chartOptions)
      : undefined;
  };

  return (
    <div className={classes.selectContainer}>
      <Collapse
        collapsible={!switchCollapsible ? 'disabled' : undefined}
        ghost
        bordered={false}
        className={collapseStyle}
        activeKey={clickCollapse && switchCollapsible ? name : null}
        onChange={() => setClickCollapse(!clickCollapse)}
      >
        <Panel
          header={prettyName}
          key={name}
          extra={
            type === 'switchCollapse' ? (
              <Switch
                checked={switchCollapsible}
                onChange={handleToggleCollapsible}
                size="small"
              />
            ) : null
          }
        >
          <div>
            {Object.entries(child).map(([key, val]: [any, any]) =>
              val?.child ? (
                <ChartConfigCollapse
                  section={name}
                  prettyName={configObjects[val?.type].prettyName}
                  name={configObjects[val?.type].name}
                  child={val.child}
                  type={configObjects[val?.type].type}
                  defaultValue={val?.defaultValue}
                  collapseStyle={classes.child}
                  selectedChart={selectedChart}
                />
              ) : (
                <Inputbox
                  onChange={onChange}
                  rowContainer={classes.rowContainer}
                  parent={name}
                  rowProperties={configObjects[val?.type]}
                  type={val?.type}
                  disabled={isDisabled(val)}
                  selectedChart={selectedChart}
                  defaultValue={val?.defaultValue}
                  colorOptions={colorOptions}
                  setColorOptions={setColorOptions}
                  colorSelectedOption={colorSelectedOption}
                  setColorSelectedOption={setColorSelectedOption}
                />
              ),
            )}
            <Divider className={classes.divider} />
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default memo(ChartConfigCollapse);
