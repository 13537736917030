import React from 'react';
import MultiInput from './MultiInput';
import SelectFilter from './selectFilters';

const InputOrSelectFilter = (props) => {
    const { data, onChange } = props;
    const options = data?.options || [];
    if(options.length === 0){
        return <MultiInput {...props}/>;
    }
    const changeType = {
        ...props,
        type: 'multiSelect',
    };
    return <SelectFilter {...changeType}/>;
};

export default InputOrSelectFilter;
