import {
  MOBILE_DASHBOARD_SEARCH_FILTERS,
  SWITCH_SEARCH_VISIBILITY,
} from '../reduxActions/actionNameEnums';

const initialState = {
  createdBy: [],
  updatedAt: [],
  searchValue: '',
  showSearchBox: false,
};

const mobileDashboardSearchFiltersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MOBILE_DASHBOARD_SEARCH_FILTERS:
      return {
        ...state,
        searchValue: payload.searchValue,
        createdBy: payload.createdBy,
        updatedAt: payload.updatedAt,
      };
    case SWITCH_SEARCH_VISIBILITY:
      return {
        ...state,
        showSearchBox: payload.showSearchBox,
      };
    default:
      return state;
  }
};

export default mobileDashboardSearchFiltersReducer;
