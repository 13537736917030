import { createSelector } from 'reselect';
import keys from 'lodash/keys';
import { HTML_EDITOR_REPORT_TYPE, projectNames } from 'src/constants';

export const selectNumCompletedTodos = createSelector(
  (state) => state?.chartsMetaData?.chartData,
  (chartData:any) => ({
    chartData: JSON.parse(chartData?.chartData || '{}'),
    formData: JSON.parse(chartData?.formData || '{}'),
    chartType: chartData?.chartType,
    visualizeMetaData: JSON.parse(chartData?.visualizeMetaData || '{}'),
    formatting: chartData?.formatting,
  }),
);

// FOr Pie Chart, it combines the data below threshold into 'Others
export const tranformPieChartData = (chartData, thresholdPercentage) => {

  if(keys(chartData).length === 0){
    return chartData;
  }
  const totalSum = chartData.reduce((sum, item) => sum + item.y, 0);
  const threshold = ((thresholdPercentage) / 100) * totalSum;
  let sumBelowThreshold = 0;
  const finalData = chartData.filter(item => {
    if (item.y > threshold) {
      return true;
    }
    sumBelowThreshold += item.y;
    return false;
  });

  if (sumBelowThreshold > 0) {
    finalData.push({
      x: 'Others',
      y: sumBelowThreshold,
      rawData: {},
    });
  }
  return finalData;
};

export const transformBarChartData = (chartData, thresholdPercentage) => {
  if (keys(chartData).length === 0) {
    return chartData;
  }

  if (chartData.length > 1 && chartData[0].hasOwnProperty('z')) {
    const zValuesWithSum = chartData.reduce(
      (obj, item) => ((obj[item.x] = (obj[item.x] || 0) + item.y), obj),
      {},
    );
    const zValuesWithThreshold = {};
    for (const [key, value] of Object.entries(zValuesWithSum)) {
      zValuesWithThreshold[key] =
        (thresholdPercentage / 100) * zValuesWithSum[key];
    }

    const sumBelowThreshold = {};
    const finalData = chartData.filter((item) => {
      if (!item.z) {
        return false;
      }
      if (item.y > zValuesWithThreshold[item.x]) {
        return true;
      }
      sumBelowThreshold[item.x] = (sumBelowThreshold[item.x] || 0) + item.y;
      return false;
    });

    for (const [key, value] of Object.entries(sumBelowThreshold)) {
      finalData.push({
        x: key,
        y: value,
        z: 'Others',
        value: key,
      });
    }
    return finalData;
  }
  const sumBelowThreshold = {};
  const fieldValuesWithThresholds = {};
  let fieldValue = 'value';
  if (chartData.length > 1 && chartData[0].x === chartData[1].x) {
    fieldValue = 'x';
  }
  const distinctFieldValues = Array.from(
    new Set(chartData.map((item) => item[fieldValue])),
  );
  distinctFieldValues.forEach((value: any) => {
    const totalSum = chartData
      .filter((data) => data[fieldValue] === value)
      .reduce((sum, data) => sum + data.y, 0);
    const threshold = (thresholdPercentage / 100) * totalSum;

    fieldValuesWithThresholds[value] = {
      threshold,
      totalSum,
    };
  });
  const finalData = chartData.filter((item) => {
    if (item.y > fieldValuesWithThresholds[item[fieldValue]].threshold) {
      return true;
    }
    sumBelowThreshold[item[fieldValue]] =
      (sumBelowThreshold[item[fieldValue]] || 0) + item.y;
    return false;
  });

  if (Object.keys(sumBelowThreshold).length > 0) {
    for (const [key, value] of Object.entries(sumBelowThreshold)) {
      fieldValue === 'value'
        ? finalData.push({
            x: 'Others',
            y: value,
            value: key,
            rawData: {},
            name: key,
          })
        : finalData.push({
            x: key,
            y: value,
            value: 'Others',
            rawData: {},
            name: 'Others',
          });
    }
  }
  return finalData;
};

export const jsonToCsv = (csvRows = []) => {
  if (!csvRows.length) return '';
  const columnNames = Object.keys(csvRows[0]);
  const rows = csvRows.map((obj) => columnNames.map((key) => obj[key]));
  const csvArray = [columnNames.join(','), ...rows.map((row) => row.join(','))];
  return csvArray.join('\n');
};

export const downloadCSV = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'download.csv');
  link.click();
  URL.revokeObjectURL(url);
};

export const directDownloadChartData = (chartType, chartData) => {
  const filteredData =
    !['table', 'pivotTable'].includes(chartType) && chartData[0]?.rawData
      ? chartData.map((dataPoint) => dataPoint.rawData)
      : chartData;
  const csv = jsonToCsv(filteredData);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  downloadCSV(url);
};

export const isReportTypeHtmlEditior = (dataMartType) => {
  return dataMartType === HTML_EDITOR_REPORT_TYPE;
};

export const isChartTypeHtml = (chartType) => {
  return chartType === 'html';
};

export const isHtmlSupported = (dataMartType) => {
  return dataMartType === HTML_EDITOR_REPORT_TYPE || dataMartType === 'newRelic';
};

export const isMobileViewAllowed = (projectName) => {
  return projectName !== projectNames.CUSTOMERLOGISTICS;
};
