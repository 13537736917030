import { Button } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import lodashKeys from 'lodash/keys';
import { PoweroffOutlined } from '@ant-design/icons';
import { isReportTypeHtmlEditior } from 'src/components/utils/utils';
import {
  FILTER_DATA_RESET,
  METRICS_RESET,
  RESET_CHART_OPTIONS,
  SELECTED_REPORT_CHANGED,
  SET_CHART_TYPE,
  SORT_DATA_RESET,
  RESET_REPORT_USER_TYPE,
  RESET_REPORT_NAME_AND_DESCRIPTION_AND_ID,
  SUMMARISATION_LIST_RESET,
  RESET_CUSTOM_COLUMNS_GLOBAL,
  HAVING_FILTER_DATA_RESET,
  SELECTED_DBTYPE_CHANGED,
  HAVING_INITIALIZE_FILTERS,
  INITIALIZE_FILTERS,
  GENERIC_INITIALIZE_FILTERS,
  GENERIC_FILTER_DATA_RESET,
  RESET_OBJECT,
  GENERIC_SUMMARISATION_LIST_RESET,
  RESET_FORMATTING,
  ACTION_INITIALISE,
  ADMIN_REPORT_SWITCH,
  CHANGE_METRIC_STRING_MANUAL_QUERY,
  CHANGE_MONGO_COLLECTION_NAME,
  CUSTOM_QUERY_STRING,
  UNDERLYING_CUSTOM_QUERY_STRING,
} from 'src/reduxActions/actionNameEnums';

const useStyles = createUseStyles({
  topBar: {
    width: 'calc(100% - 1rem)',
    height: '45px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: '1rem',
    marginLeft: '1rem',
    fontWeight: '500',
    lineHeight: '1rem',
    color: '#262626',
  },
  divider: {
    margin: '0px',
  },
  resetAll: {
    color: 'red',
  },
  bottomQueryBarContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0.8rem 1rem',
    borderRadius: '4px 4px 0px 0px',
    border: '1px solid rgba(0, 0, 0, 0.06)',
    overflow: 'scroll',
    backgroundColor: 'white',
    gap: '1rem',
  },
  stopQueryButton: {
    borderRadius: '0 0.2rem 0.2rem 0',
  },
});

const CreateQueryBottomBar = (props) => {
  const { submitReport, defaultChartType } = props;
  const dispatch = useDispatch();
  const allCharts = useSelector((state: any) => state.reportConfig.reports);
  const filtersMetaData = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const selectedReport = useSelector(
    (state: any) => state.reportMetaData.selectedReport,
  );
  const metrics =
    useSelector(
      (state: any) => state.reportConfig.reports?.[selectedReport]?.metrics,
    ) || [];
  const chart = lodashKeys(allCharts);

  React.useEffect(() => {
    dispatch({
      type: SET_CHART_TYPE,
      payload: {
        chartType: 'line',
      },
    });
  }, []);

  const resetHandler = () => {
    dispatch({ type: FILTER_DATA_RESET });
    dispatch({ type: GENERIC_FILTER_DATA_RESET });
    dispatch({ type: HAVING_FILTER_DATA_RESET });
    const requiredFilterKeys = metrics.filter(
      (metric) => metric?.filterMetaData?.required,
    );
    dispatch({ type: INITIALIZE_FILTERS, payload: { metricData: requiredFilterKeys, filtersMetaData, selectedReport }});
    dispatch({
      type: GENERIC_INITIALIZE_FILTERS,
      payload: { metricData: { coverage: [] }, filtersMetaData },
    });
    dispatch({ type: HAVING_INITIALIZE_FILTERS, payload: { metricData: [], filtersMetaData }});
    dispatch({ type: GENERIC_SUMMARISATION_LIST_RESET });
    dispatch({ type: SUMMARISATION_LIST_RESET });
    dispatch({ type: METRICS_RESET });
    dispatch({ type: SORT_DATA_RESET });
    dispatch({ type: RESET_FORMATTING });
    dispatch({ type: RESET_CHART_OPTIONS });
    dispatch({ type: RESET_REPORT_USER_TYPE });
    dispatch({ type: SET_CHART_TYPE, payload: { chartType: defaultChartType || 'table' } });
    dispatch({ type: RESET_OBJECT });
    dispatch({ type: RESET_REPORT_NAME_AND_DESCRIPTION_AND_ID });
    dispatch({ type: RESET_CUSTOM_COLUMNS_GLOBAL, payload: {} });
    dispatch({ type: ACTION_INITIALISE });
    if (chart.length > 0) {
      dispatch({ type: SELECTED_REPORT_CHANGED, payload: chart[0] });
      dispatch({
        type: SELECTED_DBTYPE_CHANGED,
        payload: allCharts[chart[0]].dbType,
      });
    }
    dispatch({ type: ADMIN_REPORT_SWITCH, payload: { isAdminReport: false }});
    dispatch({ type: CHANGE_METRIC_STRING_MANUAL_QUERY, payload: { metricString: '' } });
    dispatch({ type: CHANGE_MONGO_COLLECTION_NAME, payload:  undefined });
    dispatch({ type: CUSTOM_QUERY_STRING, payload: { queryString: '' } });
    dispatch({ type: UNDERLYING_CUSTOM_QUERY_STRING, payload: { queryString: '' } });
  };
  const classes = useStyles();
  const { onClickSubmit, submitQueryloading, onClickStop, stopQuery } = submitReport;

  return (
    <>
      <div className={classes.bottomQueryBarContainer}>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          {!isReportTypeHtmlEditior(selectedReport) && (
            <>
              <Button
                size="small"
                type="text"
                style={{ color: 'red' }}
                onClick={resetHandler}
              >
                Reset
              </Button>
              <Button
                size="small"
                style={{
                  marginLeft: '8px',
                  backgroundColor: '#1890FF',
                  color: 'white',
                  padding: '0 1rem',
                  border: 'none',
                  borderRadius: submitQueryloading
                    ? '0.2rem 0 0 0.2rem'
                    : '0.2rem',
                }}
                onClick={onClickSubmit}
                loading={submitQueryloading && !stopQuery}
              >
                Run
              </Button>
              {submitQueryloading && !stopQuery && (
                <Button
                  className={classes.stopQueryButton}
                  type="primary"
                  icon={<PoweroffOutlined />}
                  size="small"
                  onClick={onClickStop}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default CreateQueryBottomBar;
